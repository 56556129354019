<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="主题：">
          <el-input v-model="searchForm.title" placeholder="请输入主题"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchForm.status" placeholder="请选择状态">
            <el-option v-for="(item, index) in status" :key="index" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="searchForm.imageType" placeholder="请选择类型">
            <el-option v-for="(item, index) in imageType" :key="index" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">轮播图列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i
                class="el-icon-plus"></i> 新增轮播图</el-button>
            <el-button size="mini" type="danger" @click="del()" v-if="delButton == '1'" :disabled="ids.length == 0"
              class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="title" label="主题"> </el-table-column>
            <el-table-column prop="imageType" label="类型">
              <template slot-scope="scope">
                <p>
                  {{ scope.row.imageType == 1 ? "广告" : scope.row.imageType == 2 ? "轮播图" : scope.row.imageType == 3
                  ? "积分轮播图" : scope.row.imageType == 4 ?"活动轮播图":"" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="img" label="图片"><template slot-scope="scope">
                <img :src="scope.row.img" alt="" width="100px" />
              </template>
            </el-table-column>
            <el-table-column prop="url" label="链接地址" show-overflow-tooltip min-width="180">
            </el-table-column>
            <el-table-column prop="sort" label="排序"></el-table-column>
            <el-table-column prop="created" label="创建时间"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 1" style="color: #409eff">上架</p>
                <p v-else-if="scope.row.status == 0" style="color: #f56c6c">
                  下架
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="260">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="edit(scope.row.id)">编辑</el-button>
                <el-button size="mini" type="primary" @click="handleActivate(1, scope.row)" v-if="updateButton == '1'"
                  :disabled="scope.row.status == 1">上架</el-button>
                <el-button size="mini" type="primary" @click="handleActivate(0, scope.row)" v-if="updateButton == '1'"
                  :disabled="scope.row.status == 0">下架</el-button>
                <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="del()"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增-->
    <el-dialog :title="alertTlt" :visible.sync="addShow" @close="cancelAdd('addShow')">
      <div class="postForm">
        <el-form :model="formData" :inline="true" :rules="addRules" ref="bannerForm" label-width="120px"
          class="demo-ruleForm" style="padding-top: 20px">
          <el-form-item label="主题名称：" prop="title">
            <!-- maxlength="25" -->
            <el-input v-model="formData.title" style="width: 300px" placeholder="请输入主题名称" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="链接地址：" prop="url">
            <el-input v-model="formData.url" style="width: 300px" placeholder="请输入链接地址"></el-input>
          </el-form-item>
          <el-form-item label="图片类型：" prop="imageType">
            <el-select v-model="formData.imageType" placeholder="请选择图片类型" style="width: 300px">
              <el-option v-for="(item, index) in imageType" :key="index" :label="item.text" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片排序：">
            <el-input v-model="formData.sort" style="width: 300px" placeholder="请输入图片排序"></el-input>
          </el-form-item>
          <el-form-item label="上传图片：" prop="img" style="display: block">
            <!-- <el-upload
              :action="uploadUrl"
              :headers="headers"
              list-type="picture-card"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <i class="el-icon-plus"></i>
            </el-upload> -->
            <el-upload :action="uploadUrl" :file-list="fileList" :headers="headers" name="files"
              list-type="picture-card" :on-success="handleAvatarSuccess">
              <i class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('bannerForm')">提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitBtnLoad: false,
      formData: {},
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      departmentNameList: [],
      departmentNameList2: [],
      //搜索
      searchForm: {
        title: "",
        status: "", //状态
      },
      status: [
        {
          id: "0",
          text: "下架",
        },
        {
          id: "1",
          text: "上架",
        },
      ],
      imageType: [
        {
          id: "1",
          text: "广告",
        },
        {
          id: "2",
          text: "轮播图",
        },
        // {
        //   id: "3",
        //   text: "积分轮播图",
        // },
         {
          id: "4",
          text: "活动轮播图",
        },
      ],
      typeVal: "",
      addRules: {
        title: [
          {
            required: true,
            message: "请输入主题",
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: "请输入链接地址",
            trigger: "blur",
          },
        ],
        img: [
          {
            required: true,
            message: "请上传图片",
            trigger: "blur",
          },
        ],
        imageType: [
          {
            required: true,
            message: "请选择图片类型",
            trigger: "change",
          },
        ],
      },

      addShow: false,
      loading: true,
      alertTlt: "",

      tableData: [],
      departmentArr: [], //机构列表
      rolesData: [], //角色列表
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      fileList: [],
    };
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "advertising_update") {
        this.updateButton = "1";
      } else if (item.authority == "advertising_query") {
        this.searchButton = "1";
      } else if (item.authority == "advertising_add") {
        this.addButton = "1";
      } else if (item.authority == "advertising_delete") {
        this.delButton = "1";
      }
    });
  },
  // watch: {
  //   // 如果路由有变化，会再次执行该方法
  //   $route: "listEvent",
  //   addShow: {
  //     handler(n) {
  //       if (!n) {
  //         Object.keys(this.formData).forEach((key) => {
  //           this.formData[key] = "";
  //         });
  //         this.departmentNameList2 = [];
  //         this.roles = [];
  //       } else {
  //         this.generateData();
  //       }
  //     },
  //   },
  // },
  methods: {
    //递归差机构父级name
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.name == id) {
            temp.push(item.name);
            forFn(arr1, item.parentName);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    //编辑
    edit(id) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/advertising/details", {
          params: {
            id: id,
          },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            that.alertTlt = "编辑";
            that.addShow = true;
            res.data.data.imageType = res.data.data.imageType.toString();
            that.fileList = [{ url: res.data.data.img }];
            that.formData = res.data.data;
          } else {
            that.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    add() {
      this.addShow = true;
      this.alertTlt = "新增";
    },
    //上下架
    handleActivate(type, row) {
      var _this = this;
      let msg = "";
      if (type == 0) {
        msg = "是否确认下架此内容？";
      } else {
        msg = "是否确认上架此内容？";
      }
      _this
        .$confirm(msg, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          row.status = type;
          _this
            .$axios({
              url: this.$axios.defaults.basePath + "/advertising/update",
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              data: JSON.stringify(row),
            })
            .then(function (res) {
              console.log(res);
              if (res.data.errcode == 0) {
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //获取列表数据
    listEvent() {
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/advertising", {
          params: _this.searchForm,
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.departmentNameList = [];
      this.roles = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 提交
    handleSubmit(formName) {
      var _this = this;
      let api = "";
      if (_this.alertTlt == "编辑") {
        api = "/advertising/update";
      } else {
        api = "/advertising/add";
      }

      this.$refs[formName].validate((val) => {
        if (val) {
          console.log("123");
          _this.submitBtnLoad = true;
          let data = JSON.stringify(_this.formData);
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          }).then(function (res) {
            console.log(res);
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.addShow = false;
              _this.formData = {};
              _this.listEvent();
              _this.$message({
                message: "操作成功",
                type: "success",
              });
            } else {
              _this.submitBtnLoad = false;
              _this.$message.error(res.data.errmsg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
      this.formData = {};
      this.fileList = [];
    },
    del() {
      var _this = this;
      let ids = "";
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的用户");
        return false;
      } else {
        ids = _this.ids.join(",");
      }
      _this
        .$confirm("是否确认执行此操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/advertising/delete", {
              params: {
                ids: ids,
              },
            })
            .then(function (res) {
              console.log(res);
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message.error(res.data.errmsg);
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      if (this.formData.img != "") {
        this.$set(this.formData, "img", "");
      }
      this.fileList = [];
      this.$set(this.formData, "img", res);
      setTimeout(() => {
        this.$set(this.fileList, 0, { url: res });
      }, 100);
      console.log(res, this.fileList);
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.$set(this.formData, "img", "");
      this.fileList = [];
    },
  },
};
</script>

<style scoped>
</style>
