<template>
  <div>
    <div class="postForm">
      <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm">
        <el-form-item label="当前仓库：">
          <el-input v-model="formData.oldWarehouseName" disabled></el-input>
        </el-form-item>
        <el-form-item label="目标仓库：" prop="newWarehouseId">
          <el-select v-model="formData.newWarehouseId" placeholder="请搜索目标仓库" @change="changeWarehouse($event)" :remote-method="areaEvent" :loading="searchUserLoading" filterable clearable remote>
            <el-option v-for="item in warehouseData" :key="item.id" :disabled="formData.oldWarehouseName==item.name" :label="
                item.name
              " :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="移出数量：" prop="num">
          <el-input v-model="formData.num" type="tel" @input="changeNum($event)" placeholder="请输入移出数量"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="goodsList" tooltip-effect="dark" style="width: 100%">
      <el-table-column type="index" width="50" label="序号">
      </el-table-column>
      <el-table-column prop="warehouseName" label="仓库名称">
      </el-table-column>
      <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
      <el-table-column prop="num" label="库存"> </el-table-column>
      <el-table-column prop="unit" label="单位"> </el-table-column>
      <!-- <el-table-column prop="standard" label="规格"> </el-table-column> -->
      <el-table-column prop="price" label="采购价(元)"> </el-table-column>
      <el-table-column prop="sale" label="销售价(元)">
      </el-table-column>
      <el-table-column prop="scaledValue" label="*规格比" width="100px">
        <template slot-scope="scope">
          <div class="clearfix">
            <p class="float_lf" style="line-height: 40px">
              1 : {{ scope.row.scaledValue }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="minUnitName" label="最小单位">
        <!-- <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.minUnit" filterable :disabled="true" placeholder="最小单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template> -->
      </el-table-column>
      <el-table-column prop="totalNum" label="商品总量"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
    </el-table>
    <div style="text-align:center;margin-top:30px">
      <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交
      </el-button>
      <el-button @click="$emit('changeTransfer',1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import areaCascader from "../components/areaCascader.vue";
export default {
  data() {
    return {
      searchUserLoading: false,
      addPrivilege: {
        newWarehouseId: [
          {
            required: true,
            message: "请选择目标仓库",
            trigger: "blur",
          },
        ],
        num: [
          {
            required: true,
            message: "请输入移出数量",
            trigger: "blur",
          },
        ],
      },
      formData: {
        goodsIds: "",//商品id（多个用逗号隔开）
        newWarehouseId: "",//移入仓库的id
        num: "",//移入仓库的数量
        oldWarehouseId: "",//移出仓库的id
        oldWarehouseName: "",//移出仓库的id
      },
      warehouseData: [],//仓库列表
      submitBtnLoad: false
    }
  },
  watch: {
    goodsList: {
      handler(n) {
        if (n) {
          this.formData.oldWarehouseId = n[0].warehouseId
          this.formData.oldWarehouseName = n[0].warehouseName
          let ids = []
          for (let i in n) {
            ids.push(n[i].goodsId)
          }
          this.formData.goodsIds = ids.join(",")
        }
      },
      immediate: true
    }
  },
  props: {
    goodsList: Array
  },
  components: {
    areaCascader
  },
  created() {
    this.areaEvent()
  },
  methods: {
    changeNum(e) {
      this.formData.num = this.$cjs.getNum(e)
      let arr = []
      for (let i in this.goodsList) {
        arr.push(this.goodsList[i].num)
      }
      let min = Math.min.apply(Math, arr)
      if (this.formData.num > min) {
        this.formData.num = ""
        this.$message({
          message: "移出数量不能大于商品库存的最小值，请重新输入！",
          type: "warning",
        });
      }
    },
    //选择仓库
    changeWarehouse(e) {
      if (e == this.formData.oldWarehouseId) {
        this.formData.newWarehouseId = ""
        this.$message({
          message: "不能选择与当前仓库相同的仓库！",
          type: "warning",
        });
      }
    },
    // 仓库查询
    areaEvent(name) {
      let _this = this;
      this.searchUserLoading = true;
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/warehouse/getWarehouseByAdcode",
          {
            params: {
              name
            },
          }
        )
        .then((res) => {
          _this.searchUserLoading = false;
          if (res.data.errcode == 0) {
            _this.warehouseData = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          this.$axios({
            url: _this.$axios.defaults.basePath + "/warehouse/goodsTransfer",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.formData),
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: "操作成功！",
                type: "success",
              });
              _this.$emit("changeTransfer", 2)
            } else {
              _this.submitBtnLoad = false;
            }
          })
            ;
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
</style>