<template>
  <div class="printBox">
    <el-button class="printBtn" type="primary" id="printButtonRef" v-print="printObj" @click="previewOpenCallback">
      打印单据
    </el-button>
    <div id="print">
      <div class="tableItem" v-for="(item,index) in printOrderData" :key="index">
        <div class="title">河北乡诚商贸有限公司<br/>{{ printType ==1?'其他入库单':'其他出库单' }}</div>
        <div class="header_title">
          <p>单据日期：{{ item.created }}</p>
          <p>客户：公司经办</p>
          <p>单据编号：{{ item.id }}</p>
          <p>业务类型：{{ item.returnType }}</p>
          <p>币别：RMB</p>
        </div>
        <table class="stock_table" border="1" collapse="collapse">
          <thead>
            <tr>
              <th>序号</th>
              <th>商品名称</th>
              <th>规格</th>
              <th>商品类别</th>
              <th>单位</th>
              <th>仓库</th>
              <th>数量</th>
              <th>出库单位成本</th>
              <th>出库成本</th>
              <th>备注</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(child,i) in item.warehouseDetailedList" :key="i">
              <td>{{ i+1 }}</td>
              <td>{{ child.goodsName }}</td>
              <td>{{ child.standard }}</td>
              <td>{{ child.categoryName }}</td>
              <td>{{ child.unit }}</td>
              <td>{{ child.warehouseName }}</td>
              <td>{{ child.goodsNum }}</td>
              <td>{{ child.price }}</td>
              <td>{{ child.totalPrice }}</td>
              <td>{{ child.remark }}</td>
            </tr>
            <tr>
              <td colspan="6">合计</td>
              <td>{{ item.totalNum }}</td>
              <td></td>
              <td>{{ item.totalPrice }}</td>
              <td></td>
            </tr>
            <tr><td colspan="10">合计金额大写：{{ item.moneyZh }}</td></tr>
          </tbody>
        </table>
        <div class="remark">备注：</div>
        <div class="userInfo">
          <p>制单人：{{ item.sysUserName }}</p>
          <p>发货人签字：<span></span></p>
        </div>
        <div class="printTime">打印时间：{{ nowDate }}</div>
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      printObj: { // 打印配置对象
        id: 'print',
        popTitle: '&nbsp;',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        previewBeforeOpenCallback() { console.log('正在加载预览窗口！'); console.log(that.msg, this) }, // 预览窗口打开之前的callback
        previewOpenCallback() { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
        beforeOpenCallback() { console.log('开始打印之前！') }, // 开始打印之前的callback
        openCallback() { 
          console.log('执行打印了！') 

        }, // 调用打印时的callback
        closeCallback() { 
          console.log('关闭了打印工具！')
         }, // 关闭打印的callback(无法区分确认or取消)
        clickMounted() { console.log('点击v-print绑定的按钮了！') },
      },
      // 打印数据 以此为准
      // printOrderDatas: {
      //   multipleRoutes: []
      // },
      username:'',
      nowDate:'',
    }
  },
  props: {
    printOrderData: Array,
    printType:String,
  },
  watch: {
    printOrderData: {
      handler(n) {
        console.log(n, "----------------------")
        if (n.length > 0) {
          this.$nextTick(() => {
            this.printListHeight()
          })
        }
      }
    }
  },
  mounted() {
    this.username = sessionStorage.getItem("userName");
    var myDate = new Date();
    let year = myDate.getFullYear();
    let month = myDate.getMonth() + 1 > 10 ? myDate.getMonth() + 1 : '0' + (myDate.getMonth() + 1);
    let date = myDate.getDate()
    let hh = myDate.getHours() > 10 ? myDate.getHours() : '0' + myDate.getHours()
    let mm = myDate.getMinutes() > 10 ? myDate.getMinutes() : '0' + myDate.getMinutes()
    let ss = myDate.getSeconds() > 10 ? myDate.getSeconds() : '0' + myDate.getSeconds()
    this.nowDate = year + '-' + month + '-' + date + ' ' + hh + ':' + mm + ':' + ss

    if(this.$props.printOrderData.length > 0){
      this.printListHeight()
    }
    
  },
  methods: {
    closeDialog(){
      console.log(this.$parent)
      this.$parent.printStockShow = false
    },
    previewOpenCallback(e) {
      console.log(e, "打印")
    },
    /**
     * 数组拆分事件
     * subGroupLength ---定义为多少条为一组
     */
    handleList(array, subGroupLength) {
      let index = 0;
      const newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    // 优化打印排版
    printListHeight(){
      /**
       * 打印每页最大高度1100
       * 优化打印排版 
       * 1、获取每个tableItem的高度存到数组heightArr
       * 2、将heightArr的两个数值为一组拆分存入copyArr
       * 3、循环copyArr 判断每组是否满足 和小于等于1100
       *    如果<1100 则将两个数值之和 = 1100
       *    如果>1100 则将两个数值分别 = 1100 如果有数值 >1100 则此值 = 2040
       * **/
      let items = this.$el.querySelectorAll('.tableItem')
      let heightArr = []
      items.forEach(item => {
        heightArr.push(item.offsetHeight)
      })

      let copyArr = JSON.parse(JSON.stringify(this.handleList(heightArr,2)))
      let newArr = []
      for(var i=0;i< copyArr.length;i++){
        if(copyArr[i].length == 2){
          if (copyArr[i][0] + copyArr[i][1] <= 1100) {
            copyArr[i][1] = 1100 - copyArr[i][0]
          } else {
            copyArr[i][0] = copyArr[i][0] <= 1100 ? 1100 :2200
            copyArr[i][1] = copyArr[i][1] <= 1100 ? 1100 :2200
          }
        }
        newArr.push(copyArr[i][0])
        if(copyArr[i][1]){
          newArr.push(copyArr[i][1])
        }
      }
      console.log(heightArr, newArr)
      newArr.forEach((item, index) => {
        items[index].style.height = item + 'px'
      })
      this.$el.querySelector('#printButtonRef').click()
    },

  }
}
</script>

<style scoped>
.printBox{
  position: fixed;
  left: -99999px;
  top:-99999px;
  z-index: -1;
}
.tableItem{
  width:48rem;
  /* min-height: 1100px; */
  margin: 0 auto;
}
.tableItem .title{
  color:#333;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 2.5rem 0;
}
/* .tableItem:first-child .title{
  margin-top: 0;
} */
.header_title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.header_title p{
  margin-right: 30px;
  font-size: 16px;
  line-height: 24px;
}
.stock_table{
  width: 100%;
  border-collapse:collapse;
}
tr th{
  font-size: 16px;
  font-weight: bold;
}
tr th,
tr td{
  padding: 5px 10px;
}
.printBtn{
  display: block;
  margin: 0 auto 40px auto;
}
.remark,.printTime{
  height: 40px;
  line-height: 40px;
}
.printTime{
  margin-top: 20px;
}
.userInfo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.userInfo p:first-child{
  margin-right: 200px;
}
.userInfo p:first-child+p{
  display: flex;
  align-items: center;
}
.userInfo p:first-child+p span{
  display: inline-block;
  width: 150px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #333;
}
@media print {
  #print {
    width: 210mm; /* A4 纸张宽度 */
    height: 297mm; /* A4 纸张高度 */
    margin: 0 auto;
  }
}
</style>