/**
 * 限制只能输入数字和小数点后两位
 * @param {*} num //number
 * @returns 
 */
function getNum(num) {
  let n = num
  n = n.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  n = n.replace(/^\./g, ""); //验证第一个字符是数字
  n = n.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
  n = n.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //限制"10.5." 这种情况
  n = n.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
  return n
}
//获取当前年月日 后一天的日期
function getTime() {
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
  let dd = new Date().getDate() + 1 < 10 ? '0' + (new Date().getDate() + 1) : new Date().getDate() + 1;
  var gettime = yy + '-' + mm + '-' + dd;
  return gettime
}
//获取当前年月日时分秒
function getDayTime() {
  let date = new Date();
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  let hour = String(date.getHours());
  let minutes = String(date.getMinutes());
  let seconds = String(date.getSeconds())
  month = month.length === 2 ? month : 0 + month;
  day = day.length === 2 ? day : 0 + day;
  hour = hour.length === 2 ? hour : 0 + hour;
  minutes = minutes.length === 2 ? minutes : 0 + minutes;
  seconds = seconds.length === 2 ? seconds : 0 + seconds;
  let dayTime = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  return dayTime
}
//四舍五入计算方法
function preciseNum(number, n) {
  n = n ? parseInt(n) : 0
  if (n <= 0) {
    return Math.round(number)
  }
  /*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
  // eslint-disable-next-line no-extend-native
  Number.prototype.round = function (digits) {
    digits = Math.floor(digits)
    if (isNaN(digits) || digits === 0) {
      return Math.round(this)
    }
    if (digits < 0 || digits > 16) {
      // eslint-disable-next-line no-throw-literal
      throw 'RangeError: Number.round() digits argument must be between 0 and 16'
    }
    var multiplicator = Math.pow(10, digits)
    return Math.round(this * multiplicator) / multiplicator
  }
  // eslint-disable-next-line no-extend-native
  Number.prototype.fixedprecise = function (d) {
    var s = this + ''

    if (!d) d = 0

    if (s.indexOf('.') === -1) s += '.'

    s += new Array(d + 1).join('0')

    if (new RegExp('^(-|\\+)?(\\d+(\\.\\d{0,' + (d + 1) + '})?)\\d*$').test(s)) {
      let s = '0' + RegExp.$2
      var pm = RegExp.$1
      var a = RegExp.$3.length
      var b = true
      if (a === d + 2) {
        a = s.match(/\d/g)
        if (parseInt(a[a.length - 1]) > 4) {
          for (var i = a.length - 2; i >= 0; i--) {
            a[i] = parseInt(a[i]) + 1
            if (a[i] === 10) {
              a[i] = 0
              b = i !== 1
            } else break
          }
        }
        s = a.join('').replace(new RegExp('(\\d+)(\\d{' + d + '})\\d$'), '$1.$2')
      }
      if (b) s = s.substr(1)
      return (pm + s).replace(/\.$/, '')
    }
    return this + ''
  }
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n)
  number = Number(number).fixedprecise(n)
  if (number === 'NaN') {
    number = 0
  }
  return number
}
export default ({
  getNum,
  getTime,
  getDayTime,
  preciseNum
})