<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="菜单名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入菜单名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">菜单列表</p>

        <template>
          <div class="tableTopBtn">
            <el-button
              @click="handleAdd"
              type="primary"
              class="el-button--mini"
              v-if="addButton == '1'"
              ><i class="el-icon-plus"></i>添加菜单</el-button
            >
            <el-button
              size="mini"
              type="danger"
              :disabled="ids.length == 0"
              @click="handleDel(id)"
              v-if="delButton == '1'"
              class="el-icon-delete"
              >删除</el-button
            >
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100">
            </el-table-column>
            <el-table-column prop="name" label="菜单名称"> </el-table-column>
            <el-table-column prop="desc" label="描述"> </el-table-column>
            <!-- <el-table-column prop="quick" label="快捷路径">
              <template slot-scope="scope">
                <p>{{ scope.row.quick=='1'?'是':'否' }}</p>
              </template>
            </el-table-column> -->
            <el-table-column width="200" prop="created" label="创建时间">
            </el-table-column>
            <el-table-column align="center" width="260" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleDetails(scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)"
                  v-if="updateButton == '1'"
                  class="el-icon-edit"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    
    <el-dialog title="菜单详情" :visible.sync="detailsShow">
      <div class="postForm">
        <el-form
          :model="detailsData"
          :inline="true"
          ref="formData"
          label-width="125px"
          class="demo-ruleForm text-center infoFrom"
        >
          <el-form-item label="ID：">
            <el-input
              v-model="detailsData.id"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单名称：">
            <el-input
              v-model="detailsData.name"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：">
            <el-input
              v-model="detailsData.desc"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序：">
            <el-input
              v-model="detailsData.sort"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="菜单级别：">
            <el-input
              v-if="detailsData.type == 1"
              value="一级"
              disabled
              style="width: 300px"
            ></el-input>
            <el-input
              v-if="detailsData.type == 2"
              value="二级"
              disabled
              style="width: 300px"
            ></el-input>
            <el-input
              v-if="detailsData.type == 3"
              value="三级"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间：">
            <el-input
              v-model="detailsData.created"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
//vue实例
export default {
  data() {
    return {
      submitBtnLoad: false,
      index: "1",
      isCollapse: false,
      iconShow: false,
      loading: true,
      uploadUrl: this.$axios.defaults.imgPath + "/image/AliYunImgUploadList",
      //搜索
      searchForm: {
        name: "",
      },
      tableData: [],
      tabWidth: 200,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      userid: "",
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      fileList: [],
      hoverFileList: [],
      type: [
        {
          value: 1,
          label: "一级菜单",
        },
        {
          value: 2,
          label: "二级菜单",
        },
        {
          value: 3,
          label: "三级菜单",
        },
      ],
      
      detailsShow: false,
      detailsData: [],
      multipleSelection: [],
      ids: [],
      id: "",
      //上传回显图片
      imgArr: [
        {
          url: "",
        },
      ],
      searchButton: "1",
      updateButton: "1",
      addButton: "1",
      delButton: "1",
    };
  },
  created() {
    this.listEvent();

    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "sys_menu_update") {
        this.updateButton = "1";
      } else if (item.authority == "sys_menu_query") {
        this.searchButton = "1";
      } else if (item.authority == "sys_menu_add") {
        this.addButton = "1";
      } else if (item.authority == "sys_menu_delete") {
        this.delButton = "1";
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  methods: {
    listEvent() {
      var _this = this;
      this.loading = true
       _this.searchForm.current = _this.pagesData.currentPage
       _this.searchForm.size = _this.pagesData.currentRows
      //获取菜单列表
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysMenu", {
          params: _this.searchForm,
        })
        .then(function (res) {
          var resData = res.data;
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = resData.data.records;
            _this.pagesData.total = resData.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //侧边栏伸缩
    openAside() {
      if (this.isCollapse) {
        this.isCollapse = false;
        this.tabWidth = 200;
      } else {
        this.isCollapse = true;
        this.tabWidth = 64;
      }
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    handleAdd() {
      this.$router.push({name:'menuForm',params:{type:1}})
    },
    handleEdit(row) {
      this.$router.push({ name: 'menuForm', params: { type: 2,id:row.id} })
    },
    handleDetails(row){
      this.$router.push({ name: 'menuForm', params: { type: 3, id: row.id } })
    },

    //关闭弹框
    cancelAdd(s) {
      this.value = [];
      this[s] = false;
      //this.$refs.formData.resetFields();
    },

    handleDel() {
      var _this = this;
      // _this.roleId = _this.ids.join(',');
      //return false;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            this.$axios
              .get(_this.$axios.defaults.basePath + "/sysMenu/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.listEvent();
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });

                  // setTimeout(function () {
                  //   window.location.reload();
                  // }, 500);
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
};
</script>