<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="姓名">
          <el-input
            style="width: 100%"
            v-model="searchForm.username"
            placeholder="请输入司机姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属仓库：">
          <el-select
            v-model="searchForm.warehouseName"
            placeholder="请选择所属仓库"
            clearable
          >
            <el-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="路线">
          <el-select
            v-model="searchForm.way"
            placeholder="请选择路线"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in wayList"
              :key="index"
              :label="item.way"
              :value="item.way"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">司机列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button
              @click="handleAdd"
              type="primary"
              class="el-button--mini"
              v-if="addButton == '1'"
              ><i class="el-icon-plus"></i>添加司机</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDel()"
              :disabled="ids.length == 0"
              v-if="delButton == '1'"
              class="el-icon-delete"
              >批量删除</el-button
            >
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="username" label="司机账号"> </el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称"> </el-table-column>
            <el-table-column prop="way" label="线路"> </el-table-column>
            <el-table-column prop="alias" label="设备唯一标识"> </el-table-column>
            <el-table-column align="center" width="220" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)"
                  v-if="updateButton == '1'"
                  class="el-icon-edit"
                  >编辑</el-button
                >
                <el-button size="mini" type="primary" @click="handleLock(1, scope.row.id)" v-if="updateButton == '1'"
                  :disabled="scope.row.status == 1">启用</el-button>
                <el-button size="mini" type="primary" @click="handleLock(0, scope.row.id)" v-if="updateButton == '1'"
                  :disabled="scope.row.status == 0">禁用</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.current"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增、编辑司机-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" width="60%" :close-on-click-modal="false">
      <div class="postForm">
        <el-form
          :model="formData"
          ref="formData"
          :inline="true"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号：" prop="username">
            <el-input
              v-model="formData.username"
              placeholder="请输入司机账号"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              v-model="formData.password"
              type="password"
              placeholder="请输入密码"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="唯一标识：" prop="alias">
            <el-input
              v-model="formData.alias"
              placeholder="请输入司机唯一标识"
              style="width: 300px"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="所属仓库：">
            <el-select
              v-model="formData.warehouseId"
              placeholder="请选择所属仓库"
              style="width: 300px"
            >
              <el-option
                v-for="(item, index) in warehouseList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="路线：" prop="way">
            <el-select
              v-model="formData.wayId"
              placeholder="请选择所属仓库"
              style="width: 300px"
            >
              <el-option
                v-for="(item, index) in wayList"
                :key="index"
                :label="item.way"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="经纬度：" prop="location">
            <el-input
              v-model="formData.location"
              placeholder="请输入经纬度"
              style="width: 300px"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="状态：">
            <el-radio-group v-model="formData.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            class="postBtn"
            style="display: block; text-align: center"
          >
            <el-button
              type="primary"
              :loading="submitBtnLoad"
              @click="handleSubmit('formData')"
              >提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "../components/areaCascader.vue";
export default {
  components: {
    areaCascader,
  },
  data() {
    return {
      radio: "1",
      editNumData: {
        type: "1",
      },
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      transferShow: false,
      transferGoodsList: [],
      tableData: [],
      dialogTitle: "",
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //流水分页数据
      liushuipagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      goodsPagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        username: "",
        warehouseName:"",
        way:"",
      },
      warehouseList: [],
      wayList:[],
      //form表单数据
      formData: {
        alias: "",
        location: "",
        password: "",
        status: "",
        username: "",
        warehouseId:"",
        warehouseName:"",
        way:"",
        wayId:"",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      detailedButton: "", //查看流水权限
      goodsRelButton: "", //查看商品明细权限
      splitButton: "", //商品拆分权限
      warehouseGoodsButton: "", //修改商品库存价格权限
      edit_numButton: "", //修改库存
      assmbledButton: "", //商品组合
      transferButton: "", //移库
      editNumShow: false, //修改库存
      warehouseId: "", //仓库id
      warehouseName: "", //仓库名称
    };
  },

  created() {
    this.listEvent();
    this.getWarehouseList();
    this.getWayList();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "driver_update") {
        this.updateButton = "1";
      } else if (item.authority == "driver_query") {
        this.searchButton = "1";
      } else if (item.authority == "driver_add") {
        this.addButton = "1";
      } else if (item.authority == "driver_delete") {
        this.delButton = "1";
      }
    });
  },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driver", {
          params: {
            username: _this.searchForm.username,
            warehouseName: _this.searchForm.warehouseName,
            way: _this.searchForm.way,
            current: 1,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val, flag) {
      this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val, flag) {
      this.pagesData.currentPage = val;
      this.listEvent();
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    //点击添加按钮事件
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = "添加司机";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(row) {
      this.formData = row;
      this.addShow = true;
      this.dialogTitle = "编辑司机";
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      if(_this.formData.warehouseId){
        for(var i=0;i<_this.warehouseList.length;i++){
          if( _this.warehouseList[i].id== _this.formData.warehouseId){
            _this.formData.warehouseId = _this.warehouseList[i].id
            _this.formData.warehouseName = _this.warehouseList[i].name
          }
        }
      }
      if(_this.formData.wayId){
        for(var i=0;i<_this.wayList.length;i++){
          if( _this.wayList[i].id== _this.formData.wayId){
            _this.formData.wayId = _this.wayList[i].id
            _this.formData.way = _this.wayList[i].way
          }
        }
      }
      if (_this.formData.password) {
          _this.formData.password = _this.$md5(_this.formData.password);
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.dialogTitle == "添加司机") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/driver/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/driver/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    //删除操作
    handleDel() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该司机, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            var _this = this;
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/driver/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    handleLock(e, id) {
      let _this = this;
      let api = "";
      if (e) {
        api = "/driver/driverActivate";
      } else {
        api = "/driver/driverDisable";
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + api, {
          params: {
            id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.listEvent();
            _this.$message({
              message: "操作成功！",
              type: "success",
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    // 获取路线下拉列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driverWay")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 区域选择
    areaEvent(e) {
      this.$set(this.formData, "adcode", e);
    },
  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 30px;
  margin-top: 15px;
}
</style>

<style lang="scss" scoped>
// ::v-deep .postForm .el-input {
//   width: auto !important;
// }
</style>
