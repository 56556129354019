import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // 存储token
        Authorization: sessionStorage.getItem('Authorization') ? sessionStorage.getItem('Authorization') : '',
        loginFlag: false,
        versionNo: "V1.2.0"
    },
    mutations: {
        changeLoginFlag(state, value) {
            state.loginFlag = value;
        },
        // 修改token，并将token存入localStorage
        changeLogin(state, user) {
            state.Authorization = user.Authorization;
            sessionStorage.setItem('Authorization', user.Authorization);
        },
        changeMenu(state, payload) {
            state.activeTab = payload;
        },
    },
    modules: {

    },
});

export default store;
