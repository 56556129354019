<template>
  <div id="app">
    <div>
      <el-header style="height: auto" v-if="!$route.meta.flag">
        <div class="header" v-if="!$route.meta.flag">
          <span>团购后台管理系统{{$store.state.versionNo}}</span>
          <!-- <el-button size="mini" class="float_rt getOut" type="primary" @click="getOut">切换系统</el-button> -->
          <!-- <div class="badge float_rt">
            <el-badge :value="12" class="item">
              <el-button size="mini" type="primary">消息</el-button>
            </el-badge>
          </div> -->
          <el-button class="float_rt getOut" type="primary" size="mini" style="color: #fff" @click="getOut">退出</el-button>
          <div class="float_rt clearfix AppuserName">
            <!--<img style="width: 36px;height:36px;border-radius: 50%;margin: 16px 10px 0 0;" src="./assets/img/user_img0.jpeg" alt="" />-->
            <span>你好，{{ username }}</span>
          </div>
        </div>
      </el-header>
      <el-container class="appContainer" v-if="!$route.meta.flag">
        <el-aside class="AppMenu">
          <NavMenu ref="menu"></NavMenu>
          <!-- <div class="beiAn">冀ICP备18022801号-4</div> -->
        </el-aside>
        <el-main class="clearfix" style="overflow-y: visible !important;background-color: #f5f5f5;">
          <div class="crumb float_lf clearfix">
            <p class="asideBtn float_lf" @click="openAside()">
              <span><i class="el-icon-s-fold" style="color: #333"></i></span>
            </p>
            <p class="backhome float_lf" @click="gobackHome()" @mouseenter="handleHover()" @mouseleave="leave()">
              <!-- <span
                ><img
                  v-if="status == 0"
                  style="width: 18px; height: 18px"
                  src="./assets/img/home.png"
              /></span> -->
              <span><img style="width: 18px; height: 18px" src="./assets/img/homeHover.png" /></span>
              <span style="margin-left: 5px">首页</span>

            </p>
            <div class="tabs float_lf clearfix">
              <el-tabs v-model="tabsValue" type="card" v-if="tabList.length >0" closable @tab-remove="removeTab($event)" @tab-click="tabClick($event)">
                <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.title" :name="item.name">
                </el-tab-pane>
              </el-tabs>
            </div>
            <el-button type="primary" v-if="tabList.length!=0" class="close_all float_lf" size="small" plain @click="closeAll">关闭所有</el-button>
          </div>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
        </el-main>
      </el-container>
      <router-view v-if="$route.meta.flag"></router-view>
    </div>
  </div>
</template>

<script>
import NavMenu from "./components/NavMenu.vue";
// import "./assets/js/tiandituApi"
// import "./assets/js/imageOverlay"
export default {
  name: "App",
  data() {
    return {
      username: "",
      status: 0,
      webSocket: null,
      lockReconnect: false,//是否真正建立连接
      reconnectTimer: null,
      sendFixHeartTimer: null,//30s固定发送心跳定时器
      tabList: [],
      tabsValue: ""
    };
  },
  components: {
    NavMenu,
  },
  created() {
    this.username = sessionStorage.getItem("userName");
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (!userInfo) return;
    userInfo.authorities.forEach(item => {
      if (item.authority == 'user_way_add') {
        this.contactSocket();
      }
    })
    if (JSON.parse(sessionStorage.getItem("tabList"))) {
      this.tabList = JSON.parse(sessionStorage.getItem("tabList"))
    }
    this.tabsValue = sessionStorage.getItem("tabsValue");
  },
  mounted() {
    window.addEventListener('setItem', () => {
      if (sessionStorage.getItem("tabList").length == 0 || sessionStorage.getItem("tabList") == "" || sessionStorage.getItem("tabList") == null || sessionStorage.getItem("tabList") == undefined) {
        this.tabList = []
      } else {
        this.tabList = JSON.parse(sessionStorage.getItem("tabList"))
      }
      this.tabsValue = sessionStorage.getItem("tabsValue")
    })
  },
  destroyed() {
    this.webSocket.close();
    clearTimeout(this.sendFixHeartTimer);
  },
  methods: {
    closeAll() {
      this.tabsValue = ""
      this.tabList = []
      let arr = []
      // this.resetSetItem('activeTab', "");
      this.resetSetItem('tabsValue', "");
      this.resetSetItem('tabList', JSON.stringify(arr));
      this.$router.push({ path: "/home" })
    },
    tabClick(val) {
      this.tabsValue = val.name
      for (let i in this.tabList) {
        if (this.tabList[i].name == val.name) {
          this.$router.push({ path: this.tabList[i].path })
          // this.resetSetItem('activeTab', this.tabList[i].tab);
          this.resetSetItem('tabsValue', this.tabList[i].name);
        }
      }
    },
    removeTab(val) {
      if (this.tabList.length == 1) {
        this.resetSetItem('tabsValue', "");
        this.$router.push({ path: "/home" })
      } else {
        for (let i in this.tabList) {
          if (this.tabList[i].name == val) {
            if (i == 0) {
              this.resetSetItem('tabsValue', this.tabList[this.tabList.length - 1].name);
              this.$router.push({ path: this.tabList[this.tabList.length - 1].path })
            } else {
              this.resetSetItem('tabsValue', this.tabList[i - 1].name);
              this.$router.push({ path: this.tabList[i - 1].path })
            }
          }
        }
      }
      var arr = this.tabList.filter(function (item) {
        return item.name != val;
      });
      this.resetSetItem('tabList', JSON.stringify(arr));
    },
    //侧边栏伸缩
    openAside() {
      this.$refs["menu"].isClossTabFun();
    },
    getOut() {
      var _this = this;
      this.$message({
        message: "请重新登录",
        type: "warning",
      });
      setTimeout(function () {
        _this.$router.push({ path: "/login" });
      }, 500);
    },
    gobackHome() {
      this.$router.push({ path: "/home" });
    },
    handleHover() {
      this.status = 1;
    },
    leave() {
      this.status = 0;
    },

    // websocket
    doSend(message) {
      this.webSocket.send(message)
    },
    contactSocket() {
      let _this = this;
      if (!this.$axios.defaults.WEB_SOCKET_URL) return;
      // 建立websocket连接 用户审核通过后根据权限'user_way_add'进行通知
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let webSocket = new WebSocket(`${this.$axios.defaults.WEB_SOCKET_URL}${userInfo.id}`);
      _this.webSocket = webSocket
      webSocket.onopen = function () {
        console.log("连接成功！");
        clearTimeout(_this.sendFixHeartTimer);
        _this.doSend('ping');
        _this.sendFixHeart();
      };
      webSocket.onmessage = function (evt) {
        var received_msg = evt.data;
        console.log("接受消息：" + received_msg);
        clearTimeout(_this.sendFixHeartTimer);
        _this.sendFixHeart();
        if (received_msg != 'pong' && JSON.parse(received_msg).type) {
          let type = JSON.parse(received_msg).type
          if (type == 1) {
            _this.$notify.closeAll();
            _this.$notify.info({
              title: '提示',
              message: '有新的客户已通过审核，请及时设置路线！',
              duration: 0,
              offset: 50,
              onClick: () => {
                _this.$router.push({ name: 'user', query: { type: 1 } })
              }
            });
          }
        }
      };
      webSocket.onclose = function () {
        console.log("连接关闭！");
        _this.lockReconnect = false;
        clearTimeout(_this.sendFixHeartTimer);
        _this.reconnect()
      };
      webSocket.onerror = function () {
        console.log("连接异常！");
        _this.lockReconnect = false;
        clearTimeout(_this.sendFixHeartTimer);
        _this.reconnect()
      };
    },

    //重新连接  设置延迟避免请求过多
    reconnect() {
      //设置lockReconnect变量避免重复连接
      if (this.lockReconnect) return;
      this.lockReconnect = true;
      this.reconnectTimer && clearTimeout(this.reconnectTimer);
      this.reconnectTimer = setTimeout(() => {
        this.contactSocket();
        this.lockReconnect = false;
      }, 10000);
    },
    // 固定发送心跳
    sendFixHeart() {
      let _this = this;
      this.sendFixHeartTimer = setInterval(() => {
        _this.doSend('ping');
      }, 30000);
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route(to, form) {
      if (form.path == "/login") {
        window.location.reload();
      }
      //添加导航
      let obj = {
        title: to.meta.title,
        path: to.path,
        name: to.name
      }
      if (to.path == "/warehouse_stock") {
        obj.path = to.fullPath
        if (to.query.type == 1) {
          obj.title = "入库管理"
          obj.name = "warehouseStockIn"
        } else {
          obj.title = "出库管理"
          obj.name = "warehouseStockOut"
        }
      }
      if (to.path != '/home' && to.path != '/login') {
        if (this.tabList.length == 0) {
          this.tabList.push(obj)
          this.resetSetItem('tabList', JSON.stringify(this.tabList));
          this.resetSetItem('tabsValue', obj.name);
        } else {
          for (let i in this.tabList) {
            if (this.tabList[i].name == obj.name) {
              this.resetSetItem('tabsValue', obj.name);
              return
            } else {
              if (i == this.tabList.length - 1) {
                this.tabList.push(obj)
                this.resetSetItem('tabList', JSON.stringify(this.tabList));
                this.resetSetItem('tabsValue', obj.name);
              }
            }
          }
        }
      }

    },
  },
};
</script>
<style>
.AppMenu {
  position: relative;
}
.beiAn {
  width: 100%;
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: 0;
}
@import "../src/assets/index.css";
.el-aside {
  width: auto !important;
  max-width: 160px !important;
  min-height: 400px;
}
.el-menu-vertical-demo {
  width: 160px;
}
.el-menu-vertical-demo.el-menu--collapse {
  width: 64px !important;
}
.appContainer {
  margin-top: 0px;
}
.AppuserName {
  height: 70px;
  margin-right: 10px;
}
.AppuserName span {
  font-size: 16px;
  color: #cfd9ff;
  line-height: 70px;
}
.el-header {
  padding: 0;
}
.header {
  width: 100%;
  height: 70px !important;
  background: #1a2241;
  padding: 0 20px;
  font-size: 20px;
  color: #ffffff;
  box-sizing: border-box;
  line-height: 70px;
}
.header img {
  float: left;
}
.header > span {
  color: #ffffff;
  float: left;
}

.crumb {
  height: 56px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.crumb p.asideBtn {
  height: 100%;
  padding: 15px 0;
  text-align: center;
  box-sizing: border-box;
}
.crumb p.asideBtn span {
  display: block;
  padding: 2px 15px;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #e5e5e5;
}
.crumb p.asideBtn span i {
  font-size: 22px;
}
.crumbMain {
  color: #666;
  line-height: 56px;
  padding: 0 20px;
}
.crumbMain a {
  color: #666;
}
.backhome {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: #333;
  padding: 0 15px;
  /* position: relative; */
}
.backhome span:nth-child(1) {
  /* position: absolute;
  left: 0;
  top: 2px; */
}

.backhome span:nth-child(2) {
  /* position: absolute;
  left: 0px;
  top: 2px; */
  font-size: 14px;
  /* display: none; */
}

.backhome:hover {
  color: #0070d8;
  cursor: pointer;
}
.backhome:hover span:nth-child(2) {
  display: block;
}
.el-tree-node:focus > .el-tree-node__content {
  background-color: #5a79ff !important;
}
.el-main {
  position: relative;
  overflow: visible !important;
  overflow-x: auto !important;
}
.badge {
  height: 30px;
  line-height: 1;
  margin: 20px 15px 0 15px;
}
.tabs {
  /* padding: 0 20px; */
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 1500px;
  box-sizing: border-box;
  /* padding-bottom: 15px; */
  /* border-bottom: 1px solid #f4f5fa; */
}
.tabs .el-tabs {
  max-width: 1500px;
}
.tabs .el-tabs .el-tabs__header {
  margin: 0 !important;
  border: none !important;
  height: 100%;
}

.tabs >>> .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-prev {
  height: 100%;
  line-height: 100%;
}
.tabs >>> .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-next {
  height: 100%;
  line-height: 100%;
}
.tabs .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav {
  border: none;
}
.tabs .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__item {
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border: none;
}
.close_all {
  margin: 13px 0 0 10px !important;
}
</style>
