<template>
  <el-row class="tac">
    <el-col>
      <el-menu :default-active="index" class="el-menu-vertical-demo" :collapse="isCollapse" :unique-opened="true" @open="handleOpen" @close="handleClose" background-color="#010e41" text-color="#fff" active-text-color="#ffd04b">
        <div v-for="(item, index) in menus" :key="index" :index="item.tab">
          <!-- :title="item.name" -->
          <el-popover v-if="item.child && item.child.length > 0" placement="right" trigger="hover" >
            <!-- 菜单弹出框 -->
            <div class="" v-if="item.child[0].child.length == 0">
              <div class="tabs_name" v-for="(rItem, n) in item.child" :key="n" :index="String(rItem.id)"
                @click="handleClick(rItem.id, rItem.targetUrl, rItem.tab, rItem)">
                <p>{{ rItem.name }}</p>
              </div>
            </div>
            <div class="child_list" v-else>
              <!-- <div class="quick_tabs list_item" v-if="item.quickData && item.quickData.length>0">
                <div class="tabs_name" v-for="(quickItem,qIndex) in item.quickData" :key="qIndex">
                  <p  @click="$router.push({ path: quickItem.url,query:{type:'add'}})">{{ quickItem.title }}</p>
                </div>
              </div> -->
              <div class="list_tabs list_item" v-for="(tItem,tIndex) in item.child" :key="tIndex">  
                <p class="tabs_title">{{ tItem.name }}</p>
                <div v-if="tItem.child.length>0">
                  <div class="tabs_name" v-for="(eItem, n) in tItem.child" :key="n" :index="String(eItem.id)"
                    @click="handleClick(eItem.id, eItem.targetUrl, eItem.tab, eItem)">
                    <p>{{ eItem.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 菜单名称 -->
            <el-menu-item slot="reference" :index="String(item.id)">
              <img class="menuIcon" :src="item.icon" />
              <span style="color: #fff; margin-left: 9px" v-if="!isCollapse">{{item.name}}</span>
            </el-menu-item>
          </el-popover>

          <div v-else>
            <el-menu-item slot="reference" :index="String(item.id)" @click="handleClick(item.id, item.targetUrl, item.tab,item)">
              <img class="menuIcon" :src="item.icon" />
              <span style="color: #fff; margin-left: 9px" v-if="!isCollapse">{{item.name}}</span>
            </el-menu-item>
          </div>

          <!-- <el-submenu :index="String(item.id)" v-else>
            <template slot="title">
              <img class="menuIcon" :src="item.icon" />
              <a @click="handleClick(item.id, item.targetUrl, item.tab)"
                ><span slot="title" style="color: #fff">{{
                  item.name
                }}</span></a
              >
            </template>
            <el-menu-item-group v-for="(twoItem, n) in item.child" :key="n">
              <el-menu-item
                v-if="twoItem.child.length == 0"
                :index="twoItem.tab"
              >
                <a
                  href="javascript:;"
                  @click="
                    handleClick(twoItem.id, twoItem.targetUrl, twoItem.tab)
                  "
                  style="color: #fff"
                  >{{ twoItem.name }}</a
                >
              </el-menu-item>
              <el-submenu v-else :index="twoItem.tab">
                <template slot="title">{{ twoItem.name }}</template>
                <el-menu-item
                  v-for="(threeItem, m) in twoItem.child"
                  :key="m"
                  class="twoNav"
                  :index="threeItem.tab"
                  ><a
                    href="javascript:;"
                    @click="handleClick(threeItem.id, threeItem.targetUrl)"
                    >{{ threeItem.name }}</a
                  ></el-menu-item
                >
              </el-submenu>
            </el-menu-item-group>
          </el-submenu> -->
        </div>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      menus: [],
      userName: "",
      defaultNav: "",
      isCollapse: false,
      tabWidth: "",
      newNav: [],
      nowTitle: "",
      iconSrc: "",
      index: "",
      tabList: [],
      menusData:[] //原始菜单数据
    };
  },
  computed: {
    ...mapState(['activeTab'])
  },
  created() {
    this.userName = sessionStorage.getItem("userName");
    this.menusData = JSON.parse(sessionStorage.getItem("myMenu"));
    var menusData = JSON.parse(sessionStorage.getItem("menus"));
    this.menus = [];
    
    menusData.forEach((item) => {
      // 是否有快捷路径
      
      // if(item.child.length>0){
      //   let list = []
      //   item.quickData = [];
      //   item.child.forEach((cItem)=>{
      //     if(cItem.quick == '1'){
      //       let cJson = {
      //         title:cItem.name,
      //         url:cItem.targetUrl
      //       }
      //       list.push(cJson)
      //     }
      //   })
      //   item.quickData = list
      // }
      // 按sort值排序
      item.child = item.child.sort(this.sortId);
    });
    this.menus = menusData.sort(this.sortId);
    // console.log(this.menus)

    if (sessionStorage.getItem("activeTab")) {
      this.index = sessionStorage.getItem("activeTab");
      this.changeMenu(this.index)
    }
  },
  mounted() {
    // window.addEventListener('setItem', () => {
    //   if (sessionStorage.getItem("tabList") == "" || sessionStorage.getItem("tabList") == null || sessionStorage.getItem("tabList") == undefined || sessionStorage.getItem("tabList").length == 0) {
    //     this.tabList = []
    //   } else {
    //     this.tabList = JSON.parse(sessionStorage.getItem("tabList"))
    //   }
    // });
  },
  watch: {
    $route: function (data) {
      this.nowTitle = data.meta.title;
      this.iconSrc = data.meta.icon;

      let url = data.fullPath
      let parentKey = ''
      this.menusData.forEach(item=>{
        if(item.targetUrl == url){
          if(item.parentKey){
            parentKey = item.parentKey.split('.')[0]
          }else{
            parentKey = item.id
          }
        }
      })
      sessionStorage.setItem("activeTab", parentKey);
      this.index = parentKey
    },
    activeTab() {
      if (sessionStorage.getItem("activeTab")) {
        this.index = sessionStorage.getItem("activeTab");
      }
    }
  },
  methods: {
    ...mapMutations(['changeMenu']),
    sortId(a, b) {
      return a.sort - b.sort;
    },
    handleOpen(key, keyPath) { },
    handleClose(key, keyPath) { },
    handleClick(id, url, tab, item) {
      var _this = this;
      localStorage.setItem("menuId", id);
      _this.$router.push({ path: url });
      this.index = tab;
      this.changeMenu(tab);
      sessionStorage.setItem("activeTab", tab);
      // let obj = {
      //   id: item.id,
      //   name: item.name,
      //   tab: tab,
      //   path: item.targetUrl
      // }

      // if (_this.tabList.length == 0) {
      //   _this.tabList.push(obj)
      //   _this.resetSetItem('tabList', JSON.stringify(_this.tabList));
      //   _this.resetSetItem('tabsValue', item.targetUrl);
      // } else {
      //   for (let i in _this.tabList) {
      //     if (_this.tabList[i].id == item.id) {
      //       _this.resetSetItem('tabsValue', item.targetUrl);
      //       return
      //     } else {
      //       if (i == _this.tabList.length - 1) {
      //         _this.tabList.push(obj)
      //         _this.resetSetItem('tabList', JSON.stringify(_this.tabList));
      //         _this.resetSetItem('tabsValue', item.targetUrl);
      //       }
      //     }
      //   }
      // }
    },
    isClossTabFun() {
      if (!this.isCollapse) {
        this.tabWidth = 64;
        // this.$parent.navWidth = 64;
      } else {
        this.tabWidth = 160;
      }
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>
<style>
.twoNav {
  padding-left: 60px !important;
  color: #fff;
}
* {
  padding: 0px;
  margin: 0px;
}
.tac .el-menu-item-group__title {
  display: none;
}
.workbenchMenu,
.tac .el-menu-item {
  background-color: #010e41 !important;
  color: #fff !important;
}

.tac .el-menu-item.is-active {
  background-color: #0070d8 !important;
  color: #fff !important;
}
.el-menu-vertical-demo {
  width: 64px;
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 160px;
  min-height: 400px;
}
.el-aside {
  line-height: normal !important;
}
.el-aside {
  min-height: calc(100vh - 70px);
  overflow-x: hidden !important;
  background-color: #010e41;
  transition: all 0.6s;
  -moz-transition: all 0.6s;
  -webkit-transition: all 0.6s;
}

.child_list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.child_list .list_item {
  /* width: 140px; */
  text-align: left;
  box-sizing: border-box;
  border-left: 1px solid #e5e5e5;
}
.child_list .list_item:first-child {
  border-left: 0;
}
.list_item .tabs_title {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 25px;
}
.tabs_name p {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 25px;
}
.tabs_name p:hover {
  color: #409eff;
}
</style>
