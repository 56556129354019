<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="仓库名称">
          <el-input v-model="searchForm.name" placeholder="请输入仓库名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">仓库列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i>添加仓库</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length == 0" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
            <!-- <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl3" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess" :on-error="handleAvatarErr" :headers="importHeaders" v-if="updateButton == '1'">
              <el-button type="primary" class="el-button--mini" v-loading="uploadLoading">商品库存数据导入</el-button>
            </el-upload> -->
            <!-- <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl4" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess" :on-error="handleAvatarErr" :headers="importHeaders" v-if="updateButton == '1'">
              <el-button type="primary" class="el-button--mini" v-loading="uploadLoading2">导入仓库列表</el-button>
            </el-upload> -->
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="phone" label="联系方式"> </el-table-column>
            <el-table-column prop="principal" label="负责人"> </el-table-column>
            <el-table-column prop="address" label="地址">
              <template slot-scope="scope">
                <div>{{regionToText(scope.row.adcode)}}</div>
                <div>{{scope.row.address}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column align="center" width="360" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="edit(scope.row.id)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                <el-button size="mini" type="primary" @click="handleLiuShui(scope.row.id)" v-if="detailedButton == '1'">流水明细</el-button>
                <el-button size="mini" type="primary" @click="handleGoodsList(scope.row.id,scope)" v-if="goodsRelButton == '1'">商品明细</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.current" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增、编辑仓库-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow">
      <div class="postForm">
        <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm">
          <el-form-item label="仓库名称：" prop="name">
            <el-input v-model="formData.name" placeholder="请输入仓库名称" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="负责人：" prop="principal">
            <el-input v-model="formData.principal" placeholder="请输入仓库负责人" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="电话：" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入联系方式" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="所属地区：" prop="adcode">
            <area-cascader :areaValue="formData.adcode" @areaChange="areaEvent"></area-cascader>
          </el-form-item>
          <el-form-item label="地址：" prop="address">
            <el-input v-model="formData.address" placeholder="请输入仓库地址" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input v-model="formData.remark" placeholder="请输入备注" style="width: 300px" type="textarea"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--仓库流水明细-->
    <el-dialog title="仓库流水明细" :visible.sync="liuShuiShow" width="90%">
      <el-form :model="liuShuiSearch" :inline="true" ref="liuShuiSearch" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="商品名称">
          <el-input v-model="liuShuiSearch.goodsName" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="出入库类型">
          <el-select v-model="liuShuiSearch.form">
            <el-option v-for="item in form" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <!-- created -->
          <el-date-picker v-model="liuShuiSearch.created" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="liuShuiSearchSubmit('liuShuiSearch')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm2('liuShuiSearch')">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="liuShuiList" tooltip-effect="dark" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <!-- <el-table-column prop="standard" label="规格"> </el-table-column> -->
        <el-table-column prop="unit" label="单位"> </el-table-column>
        <el-table-column prop="totalNum" label="商品总量"> </el-table-column>
        <el-table-column prop="minUnitName" label="最小单位">
          <!-- <template slot-scope="scope">
            <el-select v-model="scope.row.minUnit" filterable :disabled="true" placeholder="最小单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template> -->
        </el-table-column>
        <el-table-column prop="type" label="订单类型">
          <template slot-scope="scope">{{
            scope.row.type == 1 ? "采购单" : "销售单"
          }}</template>
        </el-table-column>
        <el-table-column prop="form" label="出入库类型">
          <template slot-scope="scope">{{
            scope.row.form == 1 ? "入库" : "出库"
          }}</template>
        </el-table-column>
        <el-table-column prop="warehouseName" label="仓库名称">
        </el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template slot-scope="scope">{{
            scope.row.status == 0 ? "未审核" : "已审核"
          }}</template>
        </el-table-column>
        <el-table-column prop="created" label="创建时间" min-width="120">
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="scope">
            <el-button v-if="assmbledButton == '1' && scope.row.isAssembledGoods" type="primary" size="mini" @click="handleLiuShuiChild(scope)">查看组合商品明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="padding: 10px 15px">
        <el-pagination @size-change="handleSizeChange($event, 1)" @current-change="handleCurrentChange($event, 1)" :current-page="liushuipagesData.currentPage" :page-sizes="liushuipagesData.rows" :page-size="liushuipagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="liushuipagesData.total">
        </el-pagination>
        <!-- </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="liuShuiShow = false" type="primary">关 闭</el-button>
      </div>
    </el-dialog>
    <!--组合商品明细-->
    <el-dialog title="组合商品明细" :visible.sync="liuShuiChildShow" width="95%">
      <el-table :data="liuShuiChildList" tooltip-effect="dark" style="width: 100%">
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <!-- <el-table-column prop="standard" label="规格"> </el-table-column> -->
        <el-table-column prop="unit" label="单位"> </el-table-column>
        <!-- <el-table-column prop="totalNum" label="商品总量"> </el-table-column>
        <el-table-column prop="minUnitName" label="最小单位">
        </el-table-column>
        <el-table-column prop="type" label="订单类型">
          <template slot-scope="scope">{{
            scope.row.type == 1 ? "采购单" : "销售单"
          }}</template>
        </el-table-column>
        <el-table-column prop="form" label="出入库类型">
          <template slot-scope="scope">{{
            scope.row.form == 1 ? "入库" : "出库"
          }}</template>
        </el-table-column>
        <el-table-column prop="warehouseName" label="仓库名称">
        </el-table-column>
        <el-table-column prop="status" label="审核状态">
          <template slot-scope="scope">{{
            scope.row.status == 0 ? "未审核" : "已审核"
          }}</template>
        </el-table-column>
        <el-table-column prop="created" label="创建时间" min-width="120">
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column> -->
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="liuShuiChildShow = false" type="primary">关 闭</el-button>
      </div>
    </el-dialog>
    <!--仓库商品明细-->
    <el-dialog title="仓库商品明细" :visible.sync="goodsListShow" width="90%">
      <!-- <div class="postForm"> -->
      <el-form :model="goodsSearch" :inline="true" ref="goodsSearch" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="商品名称">
          <el-input v-model="goodsSearch.goodsName" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-switch v-model="goodsSearch.flag" inactive-text="库存大于0" active-text="全部"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goodsSearchSubmit('goodsSearch')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm3('goodsSearch')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="tableTopBtn">
        <el-button @click="exportGoods" type="primary" :disabled="goodsList.length==0" class="el-button--mini">导出</el-button>
        <!-- <el-button @click="showCompose" v-if="assmbledButton == '1'" type="primary" :disabled="goodsList.length==0" class="el-button--mini">添加组合商品</el-button> -->
        <!-- <el-button @click="transfer" type="primary" v-if="transferButton == '1'" :disabled="transferGoodsList.length==0" class="el-button--mini">批量移库</el-button> -->
        <div class="tip">
          <p>*注释：</p>
          <p>
            1、规格比是商品规格与商品最小单位的比例，例：商品规格为10斤/件，则规格比为1:10；
          </p>
          <p>2、规格比用于商品的拆卸单，没有规格比无法拆分商品；</p>
        </div>
      </div>
      <el-table :data="goodsList" tooltip-effect="dark" style="width: 100%" @selection-change="selectionGoods">
        <el-table-column type="selection" width="55" :selectable="selectable"> </el-table-column>
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="warehouseName" label="仓库名称">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="standard" label="规格"> </el-table-column>
        <el-table-column prop="num" label="库存"> </el-table-column>
        <el-table-column prop="unit" label="单位"> </el-table-column>

        <el-table-column prop="price" label="采购价(元)"> </el-table-column>
        <el-table-column prop="sale" label="销售价(元)">
          <template slot-scope="scope">
            <!-- <el-input :disabled="warehouseGoodsButton != '1'" @input="changeSale(scope.row, $event, 'sale')" v-model="scope.row.sale"></el-input> -->
            {{scope.row.sale}}
          </template>
        </el-table-column>
        <el-table-column prop="scaledValue" label="*规格比" width="100px">
          <template slot-scope="scope">
            <div class="clearfix">
              <p class="float_lf" style="line-height: 40px">
                1 : {{ scope.row.scaledValue }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="minUnitName" label="最小单位">
          <!-- <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.minUnit" filterable :disabled="true" placeholder="最小单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template> -->
        </el-table-column>
        <el-table-column prop="totalNum" label="商品总量"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <!-- <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button type="primary" :disabled="scope.row.num == 0 || !scope.row.scaledValue" size="mini" v-if="!scope.row.isAssembledGoods && splitButton == '1'" @click="dismantleEvent(scope.row)">拆卸单</el-button>
            <el-button type="primary" :disabled="scope.row.type == 1" v-if="!scope.row.isAssembledGoods && edit_numButton=='1'" size="mini" @click="editNum(scope.row)">修改库存</el-button>
            <el-button type="primary" size="mini" v-if="transferButton=='1'" :disabled="scope.row.num==0" @click="transfer(0,scope.row)">移库</el-button>
            <el-button type="primary" size="mini" v-if="transferButton=='1'" @click="editStandardEvent(scope.row)">修改规格比</el-button>
            <el-button type="primary" v-if="scope.row.isAssembledGoods && assmbledButton == '1'" size="mini" @click="editCompose(scope.row.goodsId,true)">查看组合商品</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-dialog width="50%" title="移库" :visible.sync="transferShow" append-to-body>
        <transfer v-if="transferShow" :goodsList="transferGoodsList" @changeTransfer="transfer"></transfer>
      </el-dialog>
      <el-dialog width="50%" title="修改库存" :visible.sync="editNumShow" append-to-body>
        <el-row :gutter="24">
          <el-col :span="10">
            <div class="grid-content bg-purple">
              <!-- {{ editNumData.parentGoodsName }} -->
              主商品：
              <el-select v-model="editNumData.goodsId" @change="changeGoods">
                <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.goodsId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              主商品库存：{{ editNumData.parentGoodsNum }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              主商品规格比：1：{{ editNumData.parentScaledValue }}
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-top: 20px">
          <el-col :span="10">
            <div class="grid-content bg-purple">
              当前商品：{{ editNumData.goodsName+'（'+editNumData.standard+'）' }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              当前库存：{{ editNumData.ynum }}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              当前规格比：1：{{ editNumData.scaledValue }}
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin: 20px 0">
          <el-col :span="12">
            <el-radio @change="changeRadio($event)" v-model="radio" label="1">增加库存</el-radio>
            <el-radio @change="changeRadio($event)" v-model="radio" label="2">减少库存</el-radio>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-input type="number" v-model="editNumData.num" @input="chengeKucun($event)" placeholder="请输入库存"></el-input>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editNumShow = false">关 闭</el-button>
          <el-button type="primary" @click="bcKucun()">保 存</el-button>
        </div>
      </el-dialog>
      <div class="block" style="padding: 10px 15px">
        <el-pagination @size-change="handleSizeChange($event, 2)" @current-change="handleCurrentChange($event, 2)" :current-page="goodsPagesData.currentPage" :page-sizes="goodsPagesData.rows" :page-size="goodsPagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="goodsPagesData.total">
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsListShow = false">关 闭</el-button>
        <!-- <el-button type="primary" v-if="warehouseGoodsButton == '1'" @click="goodsInfoSubmit()">保 存</el-button> -->
      </div>
    </el-dialog>
    <!--拆卸单弹框-->
    <el-dialog title="拆卸单" :visible.sync="dismantleShow" width="94%">
      <el-button type="primary" size="mini" @click="addDismantleEvent()">新增拆卸单</el-button>
      <div class="tip">
        <p>*注释：</p>
        <p>
          1、规格比是商品规格与商品最小单位的比例，例：商品规格为10斤/件，则规格比为1:10；
        </p>
        <p>2、规格比用于商品的拆卸单，没有规格比无法拆分商品；</p>
        <p>3、新增拆卸单商品名称不能与原商品名称重复</p>
      </div>
      <el-table :data="dismantleData" tooltip-effect="dark" style="width: 100%">
        <!-- <el-table-column prop="warehouseName" label="仓库名称">
        </el-table-column> -->
        <el-table-column prop="name" label="商品名称" width="150px">
          <template slot-scope="scope">
            <el-input placeholder="商品名称" :disabled="scope.row.type != 2" v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="categoryId" label="商品类型" width="150px">
          <template slot-scope="scope">
            <el-cascader ref="typeCascader" style="width: 140px" :options="goodsType" v-model="scope.row.goodsTypeId" placeholder="请选择商品类型" :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }" :disabled="scope.row.type != 2" clearable @change="cascaderChange2(scope.row, $event)"></el-cascader>
          </template>
        </el-table-column>
        <el-table-column prop="color" label="颜色">
          <template slot-scope="scope">
            <el-input placeholder="颜色" :disabled="scope.row.type != 2" v-model="scope.row.color"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="商品描述">
          <template slot-scope="scope">
            <el-input placeholder="商品描述" :disabled="scope.row.type != 2" v-model="scope.row.describe"></el-input>
          </template>
        </el-table-column>
        <!-- describe -->
        <el-table-column prop="enabled" label="状态">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.enabled" filterable :disabled="scope.row.type != 2" placeholder="状态">
              <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="规格">
          <template slot-scope="scope">
            <el-input placeholder="规格" :disabled="scope.row.type != 2" v-model="scope.row.standard"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="scaledValue" label="规格比" width="150px">
          <template slot-scope="scope">
            <div class="clearfix">
              <p class="float_lf" style="line-height: 40px">1 :&nbsp;</p>
              <el-input class="float_lf" :disabled="scope.row.type != 2" @input="changeScaledValue(scope.row, $event)" placeholder="规格比" style="width: 80px !important" v-model="scope.row.scaledValue">
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="库存">
          <template slot-scope="scope">
            <el-input :placeholder="scope.row.max" @input="changeNewNum(scope.row, $event)" :disabled="scope.row.type != 2" v-model="scope.row.num" :max="scope.row.max"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.unit" filterable :disabled="scope.row.type != 2" placeholder="单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="minUnit" label="最小单位">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.minUnit" filterable :disabled="scope.row.type != 2" placeholder="最小单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="mfrs" label="供应商">
          <template slot-scope="scope">
            <el-input placeholder="供应商" :disabled="scope.row.type != 2" v-model="scope.row.mfrs"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="model" label="型号">
          <template slot-scope="scope">
            <el-input placeholder="型号" :disabled="scope.row.type != 2" v-model="scope.row.model"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="采购价(元)">
          <template slot-scope="scope">
            <el-input placeholder="采购价" :disabled="scope.row.type != 2" v-model="scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sale" label="销售价(元)">
          <template slot-scope="scope">
            <el-input placeholder="销售价" :disabled="scope.row.type != 2" v-model="scope.row.sale"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-input placeholder="备注" :disabled="scope.row.type != 2" v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" v-if="scope.$index != 0" @click="dismantleDel(scope)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dismantleShow = false">关 闭</el-button>
        <el-button @click="dismantleSubmit" type="primary">提 交</el-button>
      </div>
    </el-dialog>
    <!--组合商品-->
    <el-dialog width="80%" title="组合商品" :visible.sync="composeShow" custom-class="addPurchaseDialog">
      <div class="postForm">
        <el-form :model="composeFormData" :inline="true" :rules="composeRule" ref="composeFormData" label-width="100px" class="demo-ruleForm">
          <el-form-item label="组合名称" prop="name">
            <el-input v-model="composeFormData.name" placeholder="请输入组合名称" autocomplete="off" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合类型">
            <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2" placeholder="请选择组合类型" :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }" :disabled="composeDisable" clearable @change="composeFormData.categoryId = $event[$event.length - 1]"></el-cascader>
          </el-form-item>
          <el-form-item label="组合单位">
            <el-select v-model="composeFormData.unitId" style="width: 300px" @change="unitChange" :disabled="composeDisable">
              <el-option v-for="item in goodsUnit" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组合颜色">
            <el-input v-model="composeFormData.color" placeholder="请输入组合颜色" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合型号">
            <el-input v-model="composeFormData.model" placeholder="请输入组合型号" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合规格">
            <el-input v-model="composeFormData.standard" placeholder="请输入组合规格" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合库存" v-if="detailsShow == true">
            <el-input v-model="composeFormData.num" placeholder="请输入组合库存" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合制造商">
            <el-input v-model="composeFormData.mfrs" placeholder="请输入组合制造商" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合状态">
            <el-select v-model="composeFormData.enabled" placeholder="请选择组合状态" style="width: 300px" :disabled="composeDisable">
              <el-option v-for="item in goodsStatus" :key="item.id" :label="item.label" :value="item.id" :disabled="composeDisable"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组合价格">
            <el-input-number v-model="composeFormData.sale" style="width: 300px" :disabled="composeDisable" :precision="2" :step="0.1" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="组合库存">
            <el-input-number v-model="composeFormData.num" style="width: 300px" :disabled="composeDisable" :step="1" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="组合描述">
            <el-input type="textarea" v-model="composeFormData.describe" placeholder="请输入组合描述" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合备注">
            <el-input type="textarea" v-model="composeFormData.remark" placeholder="请输入组合备注" style="width: 300px" :disabled="composeDisable"></el-input>
          </el-form-item>
          <el-form-item label="组合图片">
            <el-upload :action="uploadUrl" :file-list="fileList" :headers="headers" :limit="4" name="files" :disabled="composeDisable" list-type="picture-card" :on-success="handleAvatarSuccess">
              <i class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="userTable boxMain goodsTable">
          <template>
            <div class="tableTopBtn" v-if="!composeDisable">
              <el-button @click="addGoods" type="primary" class="el-button--mini">
                <i class="el-icon-plus"></i>新增商品
              </el-button>
              <el-button size="mini" type="danger" @click="delGoods" class="el-icon-delete">删除商品</el-button>
            </div>
            <el-table ref="multipleTable1" :summary-method="getSummaries" show-summary :data="composeList" :stripe="true" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChangeGoods" row-key="id">
              <el-table-column v-if="!composeDisable" type="selection" :reserve-selection="true" width="55">
              </el-table-column>
              <el-table-column prop="icons" label="商品图片">
                <template slot-scope="scope">
                  <div class="demo-image__preview" v-if="scope.row.icons">
                    <el-image style="width: 60px" :src="scope.row.icons" :preview-src-list="scope.row.imgs.split(',')">
                    </el-image>
                  </div>
                  <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
                </template>
              </el-table-column>
              <el-table-column prop="name" label="商品名称"> </el-table-column>
              <el-table-column prop="describe" label="商品描述" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="color" label="颜色" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="category" label="类型" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="standard" label="规格" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="model" label="型号" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="unit" label="单位" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="num" label="库存" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="num" label="数量" width="240">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.composeNum" :disabled="composeDisable" :step="1" :min="1" :max="scope.row.num ? scope.row.num : 999999"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="采购单价(元)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="sale" label="销售单价(元)" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="mfrs" label="制造商" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="enabled" label="状态">
                <template slot-scope="scope">
                  <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                  <p v-else style="color: #67c23a">启用</p>
                </template>
              </el-table-column>
              <el-table-column prop="purchaseRmark" label="备注" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div class="btns" style="text-align: center;margin-top: 20px">
          <el-button v-if="!composeDisable" type="primary" :loading="submitBtnLoad" @click="handleComposeSubmit('composeFormData')">提交</el-button>
          <el-button @click="composeId = '';cancelAdd('composeShow')">{{composeDisable ? '关闭' : '取消'}}</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="composeGoodsShow" width="80%" top="5vh" :modal="true" append-to-body>
      <sales-goods-vue v-if="composeGoodsShow" :warehouseName="warehouseName" :isPresell="2" :sections.sync="composeSections"></sales-goods-vue>
      <span slot="footer" class="dialog-footer">
        <el-button @click="
            composeSections = [];
            composeGoodsShow = false;
          ">取 消</el-button>
        <el-button type="primary" @click="addComposeList">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改规格比-->
    <el-dialog title="编辑规格比" :visible.sync="editStandardShow" width="94%">
      <div class="tip">
        <p>*注释：</p>
        <p>
          1、规格比是商品规格与商品最小单位的比例，例：商品规格为10斤/件，则规格比为1:10；
        </p>
        <p>2、规格比用于商品的拆卸单，没有规格比无法拆分商品；</p>
      </div>
      <el-table :data="editStandardData" tooltip-effect="dark" style="width: 100%">
        <!-- <el-table-column prop="warehouseName" label="仓库名称">
        </el-table-column> -->
        <el-table-column prop="name" label="商品名称" width="150px">
          <template slot-scope="scope">
            <el-input placeholder="商品名称" disabled v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="categoryId" label="商品类型" width="150px">
          <template slot-scope="scope">
            <el-cascader ref="typeCascader" style="width: 140px" :options="goodsType" v-model="scope.row.goodsTypeId" placeholder="请选择商品类型" :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }" disabled clearable @change="cascaderChange2(scope.row, $event)"></el-cascader>
          </template>
        </el-table-column>
        <el-table-column prop="color" label="颜色">
          <template slot-scope="scope">
            <el-input placeholder="颜色" disabled v-model="scope.row.color"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="商品描述">
          <template slot-scope="scope">
            <el-input placeholder="商品描述" disabled v-model="scope.row.describe"></el-input>
          </template>
        </el-table-column>
        <!-- describe -->
        <el-table-column prop="enabled" label="状态">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.enabled" filterable disabled placeholder="状态">
              <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="规格">
          <template slot-scope="scope">
            <el-input placeholder="规格" disabled v-model="scope.row.standard"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="scaledValue" label="规格比" width="150px">
          <template slot-scope="scope">
            <div class="clearfix">
              <p class="float_lf" style="line-height: 40px">1 :&nbsp;</p>
              <el-input class="float_lf" @input="changeScaled(scope, $event)" placeholder="规格比" style="width: 80px !important" v-model="scope.row.scaledValue">
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="库存">
          <template slot-scope="scope">
            <el-input :placeholder="scope.row.max" @input="changeNewNum(scope.row, $event)" disabled v-model="scope.row.num" :max="scope.row.max"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.unit" filterable disabled placeholder="单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="minUnit" label="最小单位">
          <template slot-scope="scope">
            <el-select style="width: 80px !important" v-model="scope.row.minUnit" filterable placeholder="最小单位">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="mfrs" label="供应商">
          <template slot-scope="scope">
            <el-input placeholder="供应商" disabled v-model="scope.row.mfrs"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="model" label="型号">
          <template slot-scope="scope">
            <el-input placeholder="型号" disabled v-model="scope.row.model"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="采购价(元)">
          <template slot-scope="scope">
            <el-input placeholder="采购价" disabled v-model="scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="sale" label="销售价(元)">
          <template slot-scope="scope">
            <el-input placeholder="销售价" disabled v-model="scope.row.sale"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
            <el-input placeholder="备注" disabled v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editStandardShow = false">关 闭</el-button>
        <el-button @click="editStandardSubmit" type="primary">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "../../components/areaCascader.vue";
import SalesGoodsVue from "@/components/SalesGoods.vue";
import transfer from "../../components/transfer.vue"
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  components: {
    areaCascader,
    SalesGoodsVue,
    transfer
  },
  data() {
    var reg =
      /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/;
    var phone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (reg.test(value) === false) {
          callback(new Error("手机号格式不正确，请重新输入"));
        }
        callback();
      }
    };
    return {
      editStandardShow: false,
      editStandardData: [],
      editStandardData2: {},
      uploadLoading: false,
      uploadLoading2: false,
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      uploadUrl3: this.$axios.defaults.basePath + "/import/importGoodsStocks",
      uploadUrl4: this.$axios.defaults.basePath + "/import/importWarehouseInfo",
      regionData,
      CodeToText,
      radio: "1",
      editNumData: {
        type: "1",
      },
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      transferShow: false,
      transferGoodsList: [],
      tableData: [],
      dialogTitle: "",
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //流水分页数据
      liushuipagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      goodsPagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        name: "",
      },
      //form表单数据
      formData: {
        name: "",
        principal: "",
        phone: "",
        address: "",
        remark: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      detailedButton: "", //查看流水权限
      goodsRelButton: "", //查看商品明细权限
      splitButton: "", //商品拆分权限
      warehouseGoodsButton: "", //修改商品库存价格权限
      edit_numButton: "",//修改库存
      assmbledButton: "",//商品组合
      transferButton: "",//移库
      addPrivilege: {
        name: [
          {
            required: true,
            message: "请输入仓库名称",
            trigger: "blur",
          },
        ],
        principal: [
          {
            required: true,
            message: "请输入仓库负责人",
            trigger: "blur",
          },
        ],
        phone: [{ required: true, validator: phone, trigger: "blur" }],
        adcode: [
          {
            required: true,
            message: "请选择所属区域",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入仓库地址",
            trigger: "blur",
          },
        ],
      },
      editNumShow: false, //修改库存
      // 流水明细
      liuShuiShow: false,
      liuShuiChildShow: false,
      liuShuiList: [],
      liuShuiChildList: [],
      // 商品明细
      goodsListShow: false,
      goodsList: [],
      warehouseId: "", //仓库id
      warehouseName: "", //仓库名称
      // 流水明细查询
      form: [
        {
          id: 1,
          name: "入库",
        },
        {
          id: 2,
          name: "出库",
        },
      ],
      liuShuiSearch: {},
      // 商品明细查询
      goodsSearch: {
        flag: false,
      },
      // 拆卸单数据
      dismantleData: [],
      dismantleShow: false,
      // 商品单位
      goodsUnit: [],
      // 商品类型
      goodsType: [],
      // 商品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      composeId: '',
      composeShow: false,
      composeFormData: {
        sale: 0,
        num: 0,
      },
      composeRule: {
        name: [
          { required: true, message: "请输入组合名称", trigger: "blur" },
        ],
      },
      composeSections: [],
      composeList: [],
      composeGoodsShow: false,
      composeDisable: false,
      detailsShow: false,
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      goodsList: []
    };
  },

  created() {
    this.listEvent();
    this.getGoodsUnit();
    this.getGoodsType();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "warehouse_update") {
        this.updateButton = "1";
      } else if (item.authority == "warehouse_query") {
        this.searchButton = "1";
      } else if (item.authority == "warehouse_add") {
        this.addButton = "1";
      } else if (item.authority == "warehouse_delete") {
        this.delButton = "1";
      } else if (item.authority == "warehouse_detailed") {
        this.detailedButton = "1"; //查看流水明细权限
      } else if (item.authority == "warehouse_goods_rel") {
        this.goodsRelButton = "1"; //查看商品明细权限
      } else if (item.authority == "warehouse_goods_split") {
        this.splitButton = "1"; //商品拆分
      } else if (item.authority == "warehouse_goods_rel_update") {
        this.warehouseGoodsButton = "1"; //修改商品库存价格
      } else if (item.authority == "warehouse_goods_edit_num") {
        this.edit_numButton = "1"; //修改商品库存
      } else if (item.authority == "warehouse_goods_assmbled") {
        this.assmbledButton = "1"; //商品组合权限
      } else if (item.authority == "warehouse_goods_transfer") {
        this.transferButton = "1"; //商品移库权限
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
    goodsListShow: {
      handler(n) {
        if (!n) {
          this.goodsPagesData.currentPage = 1
        }
      }
    },
    liuShuiShow: {
      handler(n) {
        if (!n) {
          this.liushuipagesData.currentPage = 1
        }
      }
    },
  },
  methods: {
    changeScaled(scope, e) {
      scope.row.scaledValue = this.$cjs.getNum(e)
    },
    //修改规格比提交
    editStandardSubmit() {
      let _this = this
      let data = JSON.parse(JSON.stringify(this.editStandardData[0]))
      if (!data.scaledValue) {
        _this.$message({
          message: "规格比不能为空！",
          type: "warning",
        });
        return
      }
      this.editStandardData2.scaledValue = data.scaledValue
      this.editStandardData2.minUnit = data.minUnit
      this.$axios({
        url: _this.$axios.defaults.basePath + "/warehouse/editGoodsInfo",
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: JSON.stringify(_this.editStandardData2),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.handleGoodsList(_this.warehouseId);
          _this.editStandardShow = false;
          // _this.goodsListShow = false;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //修改规格比
    editStandardEvent(row) {
      let _this = this;
      this.editStandardData2.id = row.id
      _this.$axios
        .get(
          _this.$axios.defaults.basePath + "/warehouse/getWarehouseGoodsRel",
          {
            params: {
              id: row.id,
            },
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.editStandardData = []
            res.data.data.type = 1;
            res.data.data.goodsId = row.goodsId;
            // 回显商品类型 递归获取父级ID
            let idArr = _this
              .familyTree(_this.goodsType, res.data.data.categoryId)
              .reverse();
            res.data.data.goodsTypeId = idArr;
            res.data.data.total =
              res.data.data.num * res.data.data.scaledValue;
            res.data.data.totalNum = res.data.data.num;
            _this.editStandardData.push(res.data.data);
            _this.editStandardShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });

    },
    handleAvatarErr() {
      this.uploadLoading = false;
    },
    //导入修改订单状态数据
    uploadSuccess(res, file) {
      this.uploadLoading = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    beforeAvatarUpload(file) {
      this.uploadLoading = true;
    },
    regionToText(adcode) {
      let adLabel = '';
      const adcodes = [];
      for (let i = 0; i < Math.floor(adcode.length / 2); i++) {
        let result = adcode.substring(0, i * 2 + 2)
        result = result.padEnd(adcode.length, "0")
        adcodes.push(result)
      }
      adcodes.reduce((prevVal, curVal) => {
        if (prevVal) {
          const area = prevVal.find(item => item.value == curVal)
          adLabel = adLabel.concat(area.label)
          if (area && area.children) {
            return area.children
          }
        }
        return
      }, regionData)
      return adLabel
    },
    //多选框禁用
    selectable(row, index) {
      if (row.num == 0) {
        return false;
      } else {
        return true;
      }
    },
    selectionGoods(e) {
      this.transferGoodsList = e
    },
    //打开移库弹框
    transfer(e, row) {
      if (e == 0) {
        //单个商品移库
        let arr = []
        arr.push(row)
        this.transferGoodsList = arr
        this.transferShow = true
      } else if (e == 1) {
        //取消弹框
        this.transferShow = false
      } else if (e == 2) {
        //移库成功
        this.transferShow = false
        this.handleGoodsList(this.warehouseId);
      } else {
        //批量移库
        this.transferShow = true
      }

    },
    // 添加组合商品
    showCompose() {
      this.composeShow = true;
      this.composeDisable = false
      this.composeId = this.warehouseId
      this.composeFormData = {
        sale: 0,
        num: 0,
      }
      this.composeSections = [];
      this.composeList = [];
      this.fileList = [];
      this.imgData = [];
    },
    // 查看编辑组合商品
    async editCompose(goodsId, disable = false) {
      let _this = this;
      this.composeDisable = disable
      this.composeShow = true;
      this.composeId = this.warehouseId
      const res = await this.$axios
        .get(
          _this.$axios.defaults.basePath + "/warehouse/goodsAssmbledQuery",
          {
            params: {
              goodsId,
              warehouseId: this.warehouseId,
            },
          }
        )
      if (res.data.errcode == 0) {
        const { data, data: { childGoods } } = res.data;
        this.composeFormData = Object.assign({}, data, {
          childGoods: null,
        })
        // 连级选择器回显
        this.goodsTypeId2 = this
          .familyTree(this.goodsType, data.categoryId)
          .reverse();
        this.composeSections = [];
        this.composeList = childGoods;
        this.imgData = data.imgs ? data.imgs.split(',') : [];
        this.fileList = this.imgData.map(url => ({ url }));
      } else {
        _this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
      }
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      this.imgData.push(file.response);
    },
    // 上传图片删除
    handleRemove(file) {
      let imgArr = [];
      this.imgData.forEach((item) => {
        if (file.url != item) {
          imgArr.push(item);
        }
      });
      this.imgData = imgArr;
      let ind = "";
      this.fileList.forEach((item, index) => {
        if (file.url == item.url) {
          ind = index;
        }
      });
      this.fileList.splice(ind, 1);
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.composeFormData.unit = item.name;
        }
      });
    },
    // 选择商品添加到组合
    addComposeList() {
      const sections = JSON.parse(JSON.stringify(this.composeSections));
      this.composeList = [...this.composeList, ...sections].map((item, index) => {
        item.index = index
        item.composeNum = item.composeNum ? item.composeNum : 0
        return item
      })
      this.composeGoodsShow = false;
    },
    //商品组合弹框  添加一条商品事件
    addGoods() {
      this.composeGoodsShow = true;
    },
    //删除商品
    delGoods() {
      this.composeList.forEach((item, i) => {
        const index = this.composeSelectionList.findIndex(select => select.index == item.index)
        if (index > -1) this.composeList.splice(i, 1)
      })
      this.$refs.multipleTable1.clearSelection()
    },
    //总计计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          if (index == 10) {
            sums[index] = data.map((item) => Number(item['composeNum'])).reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                if (index == 12) {
                  this.formData.totalPrice = prev + curr;
                } else if (index == 10) {
                  this.formData.totalNum = prev + curr;
                }
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "个";
          }
          if (index == 12) {
            sums[index] = data.map((item) => Number(item['composeNum']) * Number(item['sale'])).reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "元";
          }
        }
      });

      return sums;
    },
    //添加商品组合 商品列表左侧复选框选择
    handleSelectionChangeGoods(val) {
      this.composeSelectionList = val
    },
    bcKucun() {
      let _this = this;
      if (this.editNumData.num == 0) {
        this.$message({
          message: "请输入库存",
          type: "error",
        });
        return;
      } else {
        this.editNumData.type = this.radio;
        this.$axios({
          url:
            _this.$axios.defaults.basePath + "/warehouse/editGoodsWareHouseNum",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          data: _this.editNumData,
        }).then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "保存成功",
              type: "success",
            });
            _this.editNumShow = false;
            _this.handleGoodsList(_this.warehouseId);
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
      }
    },
    changeRadio(e) {
      this.editNumData.type = e;
      this.editNumData.parentGoodsNum = (this.editNumData.yparentNum / this.editNumData.parentScaledValue).toFixed(2);
      this.editNumData.ynum = this.editNumData.yynum;
      this.editNumData.num = 0;
    },
    //输入库存
    chengeKucun(e) {
      this.editNumData.num = this.$cjs.getNum(e);
      let splitNum = this.editNumData.splitNum;
      let scaledV = this.editNumData.scaledValue;
      let scaledValue = Number(this.editNumData.parentScaledValue);
      if (this.editNumData.num) {
        let num = Number(this.editNumData.parentGoodsNum);
        let total = scaledValue * num;
        let syNum = 0;
        if (this.editNumData.type == 1) {
          //1增加库存  2减少库存
          syNum = ((this.editNumData.yparentNum - this.editNumData.num * scaledV) / scaledValue).toFixed(2);
          this.editNumData.ynum =
            Number(this.editNumData.yynum) + Number(this.editNumData.num);
        } else {
          if (this.editNumData.num > this.editNumData.yynum) {
            this.$message({
              message: "输入的库存不可以大于当前库存",
              type: "warning",
            });
            this.editNumData.num = "";
            this.editNumData.parentGoodsNum = (this.editNumData.yparentNum / scaledValue).toFixed(2);
            this.editNumData.ynum = this.editNumData.yynum;
            return;
          } else {
            this.editNumData.ynum =
              Number(this.editNumData.yynum) - Number(this.editNumData.num);
            syNum =
              (((Number(this.editNumData.yparentNum) +
                Number(this.editNumData.num) * scaledV)) /
                scaledValue).toFixed(2);
          }
        }
        if (syNum < 0) {
          this.$message({
            message: "剩余库存不足",
            type: "warning",
          });
          this.editNumData.num = "";
          this.editNumData.parentGoodsNum = (this.editNumData.yparentNum / scaledValue).toFixed(2);
          this.editNumData.ynum = this.editNumData.yynum;
        } else {
          this.$set(this.editNumData, "parentGoodsNum", syNum);
          this.editNumData.splitNum = Number(total) + Number(splitNum) - syNum;
        }
      } else {
        this.editNumData.parentGoodsNum = (this.editNumData.yparentNum / scaledValue).toFixed(2);
        this.editNumData.ynum = this.editNumData.yynum;
      }
    },
    //修改库存
    editNum(row) {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/warehouse/warehouseGoodsRelInfo",
          {
            params: {
              id: row.id,
            },
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.editNumData = JSON.parse(JSON.stringify(res.data.data));
            _this.getGoodsList(_this.editNumData.goodsId, _this.editNumData.warehouseId)
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeGoods(e) {
      let _this = this
      let data = this.goodsList
      for (let i in data) {
        if (data[i].goodsId == e) {
          _this.editNumData.parentGoodsNum = data[i].num
          _this.editNumData.parentScaledValue = data[i].scaledValue
        }
      }
      _this.editNumData.ynum = _this.editNumData.num;
      _this.editNumData.yynum = _this.editNumData.num;
      _this.editNumData.num = 0;
      _this.editNumData.type = "1";
      _this.editNumData.splitNum =
        (_this.editNumData.ynum * _this.editNumData.scaledValue).toFixed(2);
      _this.editNumData.yparentNum =
        (_this.editNumData.parentGoodsNum *
          _this.editNumData.parentScaledValue).toFixed(2);
    },
    // 拆分商品列表
    getGoodsList(id, warehouseId) {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse/getGoodsList", {
          params: {
            id, warehouseId
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            let data = res.data.data
            for (let i in data) {
              data[i].name = data[i].goodsName + '（' + data[i].standard + '）'
              if (_this.editNumData.goodsId == data[i].goodsId) {
                _this.editNumData.parentGoodsNum = data[i].num
                _this.editNumData.parentScaledValue = data[i].scaledValue
              }
            }

            _this.editNumData.ynum = _this.editNumData.num;
            _this.editNumData.yynum = _this.editNumData.num;
            _this.editNumData.num = 0;
            _this.editNumData.type = "1";
            _this.editNumData.splitNum =
              (_this.editNumData.ynum * _this.editNumData.scaledValue).toFixed(2);
            _this.editNumData.yparentNum =
              (_this.editNumData.parentGoodsNum *
                _this.editNumData.parentScaledValue).toFixed(2);
            _this.goodsList = data;
            _this.editNumShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$nextTick(() => {
              _this.goodsType = res.data.data;
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //商品明细导出
    exportGoods() {
      window.open(
        this.$axios.defaults.basePath +
        "/warehouse/export?id=" +
        this.warehouseId + "&adcode=" + JSON.parse(sessionStorage.getItem("userInfo")).adcode
      );
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse", {
          params: {
            name: _this.searchForm.name,
            current: 1,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val, flag) {
      var _this = this;
      //flag 1是流水  2是商品明细
      if (flag == 1) {
        _this.liushuipagesData.currentRows = val;
        _this.handleLiuShui(_this.warehouseId);
      } else if (flag == 2) {
        _this.goodsPagesData.currentRows = val;
        _this.handleGoodsList(_this.warehouseId);
      } else {
        _this.pagesData.currentRows = val;
        this.pagesEvent(_this.pagesData.currentPage, val);
      }
    },
    //第几页
    handleCurrentChange(val, flag) {
      var _this = this;
      //flag 1是流水  2是商品明细
      if (flag == 1) {
        _this.liushuipagesData.currentPage = val;
        _this.handleLiuShui(_this.warehouseId);
      } else if (flag == 2) {
        _this.goodsPagesData.currentPage = val;
        _this.handleGoodsList(_this.warehouseId);
      } else {
        _this.pagesData.currentPage = val;
        this.pagesEvent(val, _this.pagesData.currentRows);
      }
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse", {
          params: {
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        });
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    add() {
      this.$router.push({ name: 'warehouseForm', params: { type: 'add' } })
    },
    //编辑
    edit(row) {
      this.$router.push({ name: 'warehouseForm', params: { type: 'edit', id: row } })
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = "添加仓库";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(id) {
      let _this = this;
      this.id = id;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse/view", {
          params: {
            id: id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.formData = res.data.data;
            _this.addShow = true;
            _this.dialogTitle = "编辑仓库";
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    handleComposeSubmit(formName) {
      if (this.composeDisable) {
        this.composeId = '';
        this.cancelAdd('composeShow')
        return
      }
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!_this.composeFormData.id && !(_this.composeList.every(item => !item.num) || _this.composeList.every(item => !!item.num))) {
            _this.$message({
              type: "warning",
              message: "不能将无库存与有库存商品组合",
            });
            return
          }
          let url = _this.composeFormData.id ? "/warehouse/updateGoodsAssmnled" : "/warehouse/goodsAssmbled";
          if (this.imgData.length > 0) {
            _this.composeFormData.imgs = this.imgData.join(",");
          }
          _this.composeFormData.warehouseId = _this.warehouseId
          const data = Object.assign({}, _this.composeFormData, {
            childGoods: _this.composeList.map(item => ({
              id: item.id,
              name: item.name,
              num: item.composeNum,
            }))
          })
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.composeShow = false;
              _this.handleGoodsList(_this.warehouseId);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.dialogTitle == "添加仓库") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/warehouse/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
                _this.submitBtnLoad = false;
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/warehouse/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
                _this.submitBtnLoad = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    //删除操作
    handleDel() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该仓库, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            var _this = this;
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/warehouse/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 仓库流水明细
    handleLiuShui(id) {
      let _this = this;
      _this.warehouseId = id;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/warehouse/warehouseDetailedByPager",
          {
            params: {
              form: _this.liuShuiSearch.form,
              goodsName: _this.liuShuiSearch.goodsName,
              created: _this.liuShuiSearch.created,
              id: _this.warehouseId,
              current: _this.liushuipagesData.currentPage,
              size: _this.liushuipagesData.currentRows,
            },
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.liuShuiList = res.data.data.records;
            _this.liushuipagesData.total = res.data.data.total;
            _this.liuShuiShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 组合商品明细
    handleLiuShuiChild({ row: { id: parentId } }) {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/warehouse/warehouseDetailedByChildGoods",
          {
            params: {
              parentId,
            },
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.liuShuiChildList = res.data.data;
            _this.liuShuiChildShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 仓库商品明细
    handleGoodsList(id, scope) {
      let _this = this;
      _this.warehouseId = id;
      if (scope && scope.row.name) _this.warehouseName = scope.row.name;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/warehouse/warehouseGoodsRelByPager",
          {
            params: {
              id: _this.warehouseId,
              goodsName: _this.goodsSearch.goodsName,
              current: _this.goodsPagesData.currentPage,
              size: _this.goodsPagesData.currentRows,
              flag: _this.goodsSearch.flag ? 1 : 0,
            },
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              let unitName = "";
              _this.goodsUnit.forEach((child) => {
                if (child.id == item.minUnit) {
                  unitName = child.name;
                }
              });
              // item.totalNum =
              //   (Number(item.scaledValue) * Number(item.num)).toString() +
              //   unitName;
            });
            _this.goodsList = res.data.data.records;
            _this.goodsPagesData.total = res.data.data.total;
            _this.goodsListShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 流水明细查询
    liuShuiSearchSubmit() {
      this.handleLiuShui(this.warehouseId);
    },
    //流水明细查询表单重置
    resetForm2() {
      this.liuShuiSearch = {};
      this.liushuipagesData.currentPage = 1;
      this.liushuipagesData.currentRows = 10;
      this.handleLiuShui(this.warehouseId);
    },
    // 商品明细查询
    goodsSearchSubmit() {
      this.handleGoodsList(this.warehouseId);
    },
    // 商品明细查询表单重置
    resetForm3() {
      this.goodsSearch = {};
      this.goodsPagesData.currentPage = 1;
      this.goodsPagesData.currentRows = 10;
      this.handleGoodsList(this.warehouseId);
    },
    // 商品明细-销售单价修改
    changeSale(row, e, name) {
      if (!e || Number(e) <= 0) {
        this.$message({
          message: "请输入正确的价格",
          type: "error",
        });
        row[name] = "";
        return;
      } else {
        row[name] = e;
      }
    },
    // 修改销售单价提交
    goodsInfoSubmit() {
      let _this = this;
      let newArr = [];
      for (let i in _this.goodsList) {
        if (_this.goodsList[i].sale == "") {
          _this.$message({
            message: "商品销售单价不能为空和0!",
            type: "warning",
          });
          newArr = [];
          break;
        } else {
          let newJ = {
            id: _this.goodsList[i].id,
            sale: _this.goodsList[i].sale,
          };
          newArr.push(newJ);
        }
      }

      if (newArr.length > 0) {
        _this
          .$axios({
            url:
              _this.$axios.defaults.basePath +
              "/warehouse/updateWarehouseGoodsRel",
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            data: newArr,
          })
          .then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.goodsListShow = false;
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
      }
    },
    // 拆卸单商品类型修改
    cascaderChange2(row, e) {
      row.categoryId = e[e.length - 1];
    },
    // 拆卸单
    dismantleEvent(row) {
      let _this = this;
      _this.dismantleData = [];
      if (!row.scaledValue) {
        this.$message({
          message: "此商品没有规格比，无法拆分",
          type: "warning",
        });
      } else {
        _this.$axios
          .get(
            _this.$axios.defaults.basePath + "/warehouse/getWarehouseGoodsRel",
            {
              params: {
                id: row.id,
              },
            }
          )
          .then((res) => {
            if (res.data.errcode == 0) {
              _this.dismantleShow = true;
              res.data.data.type = 1;
              res.data.data.goodsId = row.goodsId;
              // 回显商品类型 递归获取父级ID
              let idArr = _this
                .familyTree(_this.goodsType, res.data.data.categoryId)
                .reverse();
              res.data.data.goodsTypeId = idArr;
              res.data.data.total =
                res.data.data.num * res.data.data.scaledValue;
              res.data.data.totalNum = res.data.data.num;
              _this.dismantleData.push(res.data.data);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
      }
    },
    // 新增拆卸单
    addDismantleEvent() {
      // 回显商品类型 递归获取父级ID
      let idArr = this.familyTree(
        this.goodsType,
        this.dismantleData[0].categoryId
      ).reverse();
      let newJ = {
        categoryId: JSON.parse(
          JSON.stringify(this.dismantleData[0].categoryId)
        ),
        // 商品类型回显字段
        goodsTypeId: idArr,
        color: this.dismantleData[0].color,
        describe: this.dismantleData[0].describe,
        enabled: this.dismantleData[0].enabled,
        goodsId: this.dismantleData[0].goodsId,
        imgs: this.dismantleData[0].imgs,
        mfrs: this.dismantleData[0].mfrs,
        model: this.dismantleData[0].model,
        unit: this.dismantleData[0].unit,
        unitId: this.dismantleData[0].unitId,
        warehouseId: this.dismantleData[0].warehouseId,
        minUnit: this.dismantleData[0].minUnit,
        type: 2,
        name: this.dismantleData[0].name,
        num: "",
        price: "",
        remark: "",
        sale: "",
        scaledValue: "",
        standard: "",
        splitNum: 0,
      };
      this.dismantleData.push(newJ);
    },
    // 编辑回显商品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 新增拆卸单输入规格比
    changeScaledValue(row, e) {
      if (row.num) {
        let scaledValue = Number(this.dismantleData[0].scaledValue);
        let num = Number(this.dismantleData[0].num);
        let total = scaledValue * num;
        let syNum = 0;
        if (row.splitNum != 0) {
          syNum =
            (Number(total) + Number(row.splitNum) - row.num * e) / scaledValue;
        } else {
          syNum = (total - row.num * e) / scaledValue;
        }
        if (syNum < 0) {
          this.$message({
            message: "剩余库存不足，无法拆卸",
            type: "warning",
          });
          row.scaledValue = "";
        } else {
          this.$set(this.dismantleData[0], "num", syNum);
          this.$set(
            this.dismantleData[0],
            "splitNum",
            this.dismantleData[0].totalNum - syNum
          );
          row.splitNum =
            Number(total) + Number(row.splitNum) - syNum * scaledValue;
        }
      }
    },
    // 新增拆卸单输入库存计算被拆卸商品剩余库存
    changeNewNum(row, e) {
      // 判断规格比有无数据
      if (row.scaledValue) {
        let scaledValue = Number(this.dismantleData[0].scaledValue);
        let num = Number(this.dismantleData[0].num);
        let total = scaledValue * num;
        let syNum = 0;
        if (row.splitNum != 0) {
          syNum =
            (Number(total) + Number(row.splitNum) - row.scaledValue * e) /
            scaledValue;
        } else {
          syNum = (total - row.scaledValue * e) / scaledValue;
        }
        if (syNum < 0) {
          this.$message({
            message: "剩余库存不足，无法拆卸",
            type: "warning",
          });
          row.num = "";
        } else {
          this.$set(this.dismantleData[0], "num", syNum);
          this.$set(
            this.dismantleData[0],
            "splitNum",
            this.dismantleData[0].totalNum - syNum
          );
          row.splitNum =
            Number(total) + Number(row.splitNum) - syNum * scaledValue;
        }
      }
    },
    // 新增拆卸单提交
    dismantleSubmit() {
      let _this = this;
      let tip = false;
      if (_this.dismantleData.length == 1) {
        _this.$message({
          message: "至少添加一条拆卸单!",
          type: "warning",
        });
        return;
      }
      for (let i = 0; i < _this.dismantleData.length; i++) {
        if (
          i > 0 &&
          _this.dismantleData[i].name == _this.dismantleData[0].name
        ) {
          _this.$message({
            message: "商品名称不能重复!",
            type: "warning",
          });
          tip = false;
          break;
        } else if (
          _this.dismantleData[i].scaledValue == "" ||
          _this.dismantleData[i].num == ""
        ) {
          _this.$message({
            message: "规格比和库存数量不能为空!",
            type: "warning",
          });
          tip = false;
          break;
        } else {
          tip = true;
        }
      }
      if (tip == true) {
        this.$axios({
          url: _this.$axios.defaults.basePath + "/warehouse/goodsSplit",
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          data: JSON.stringify(_this.dismantleData),
        }).then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.handleGoodsList(_this.warehouseId);
            _this.dismantleShow = false;
            // _this.goodsListShow = false;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
      }
    },
    // 删除新增的拆分单
    dismantleDel(scope) {
      let num =
        (this.dismantleData[0].num * this.dismantleData[0].scaledValue +
          scope.row.num * scope.row.scaledValue) /
        this.dismantleData[0].scaledValue;
      this.$set(
        this.dismantleData[0],
        "splitNum",
        this.dismantleData[0].splitNum -
        scope.row.splitNum / this.dismantleData[0].scaledValue
      );
      this.$set(this.dismantleData[0], "num", num);
      this.dismantleData.splice(scope.$index, 1);
    },
    // 区域选择
    areaEvent(e) {
      this.$set(this.formData, "adcode", e);
    },
  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 30px;
  margin-top: 15px;
}
.grid-content {
  height: 40px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
::v-deep .postForm .el-input {
  width: auto !important;
}
</style>
