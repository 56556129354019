<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" :rules="rules" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="所属仓库" prop="warehouseId">
            <el-select v-model="searchForm.warehouseId" clearable placeholder="请选择所属仓库">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="日期：" prop="date">
          <el-date-picker v-model="searchForm.date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="checkoutAdd" :loading="btnLoading">结账</el-button>
          <el-button @click="checkoutDelete" :loading="btnLoading" :disabled="tableData.length==0">反结账</el-button>
        </el-form-item>
      </el-form>
      <p style="padding-bottom: 20px;color: #E6A23C;">注：结账日期不能小于或等于上次的结账日期<span v-if="tableData.length>0 && tableData[0].closingDate">{{ tableData[0].closingDate }}</span>，结账日期之前的数据只能查询不能修改。</p>
      <div class="userTable boxMain">
        <p class="boxTitle">结账/反结账列表</p>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column prop="closingDate" label="结账日"> </el-table-column>
            <el-table-column prop="created" label="操作日期"> </el-table-column>
            <el-table-column prop="createByName" label="操作员">
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: "1",
      tableData: [],
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        warehouseId:'',
        date:'',
      },
      rules:{
        warehouseId:[
          { required: true, message: "请选择所属仓库", trigger: "change" },
        ],
        date:[
          { required: true, message: "请选择结账日期", trigger:'change'},
        ]
      },
      searchButton: "",
      checkoutDeleteButton: "",
      checkoutAddButton: "",
      warehouseList:[],
      userInfo:{},
      btnLoading:false,
    };
  },

  created() {
    this.listEvent();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "checkout_query") {
        this.searchButton = "1";
      } else if (item.authority == "checkout_add") {
        this.checkoutAddButton = "1";
      } else if (item.authority == "checkout_delete") {
        this.checkoutDeleteButton = "1";
      }
    });
    this.getWarehouseList();
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/checkout", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            warehouseId:_this.searchForm.warehouseId
          },
        })
        .then(function (res) {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.pagesEvent(_this.pagesData.currentPage, val);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.pagesEvent(val, _this.pagesData.currentRows);
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/supplier", {
          params: {
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        });
    },

    //结账
    checkoutAdd() {
      let _this = this
      _this.loading = true
      if(!_this.searchForm.date || !_this.searchForm.warehouseId){
        _this.$message({
          message: '所属仓库和结账日期不能为空',
          type: "warning",
        });
        return
      }
      _this.btnLoading = true;
      this.$axios({
        url: _this.$axios.defaults.basePath + "/checkout/addCheckout",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          createByName:_this.userInfo.username,
          createBy:_this.userInfo.id,
          closingDate:_this.searchForm.date,
          warehouseId: _this.searchForm.warehouseId,
        },
      }).then((res) => {
        _this.loading = false
        _this.btnLoading = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
      
    },
    // 反结账
    checkoutDelete(){
      let _this = this
      let startTime = '',endTime = '';
      if(_this.tableData.length>1){
        startTime = _this.tableData[1].closingDate
        endTime = _this.tableData[0].closingDate
      }else if(_this.tableData.length == 1){
        startTime = ''
        endTime = _this.tableData[0].closingDate
      }
      this.btnLoading = true
      this.$axios({
        url: _this.$axios.defaults.basePath + "/checkout/deleteCheckout",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: _this.tableData[0].id,
          startTime: startTime,
          endTime: endTime,
        },
      }).then((res) => {
        _this.btnLoading = false
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },

    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    
  },
};
</script>

<style scoped>/* .postForm .el-form-item__label {
  width: 100px !important;
} */</style>
