<template>
  <div class="goods">
    <div class="pageMain">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabOrderType" :key="index" :label="item.label" :name="item.id">
        </el-tab-pane>
      </el-tabs>
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="产品名称">
          <el-input v-model="searchForm.name" clearable placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="goodsTypeId" placeholder="请选择产品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.isTopCarriage" placeholder="请选择状态" clearable>
            <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属仓库">
          <el-select v-model="searchForm.warehouseId" clearable placeholder="请选择所属仓库" :disabled="detailsShow">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否有订单">
          <el-select v-model="searchForm.isOrder" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in isOrder" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">产品列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="danger" :disabled="ids.length == 0 && childIds.length == 0" @click="handleDel" v-if="delButton == '1'" class="el-icon-delete">删除</el-button>
          <el-button size="mini" type="primary" @click="handleSort" v-if="delButton == '1'">批量排序</el-button>
          <el-button size="mini" type="primary" @click="exportData" v-if="searchButton == '1'">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table ref="multipleTable2" @selection-change="
                  (selection) => handleSelectionChange(selection, props.row)
                " style="width: 100%" :data="props.row.goodsMallDTOList">
                  <el-table-column type="expand">
                    <template slot-scope="mainG">
                      <el-table v-if="mainG.row.childGoodsMallFormList && mainG.row.childGoodsMallFormList.length>0" ref="multipleTable3" @selection-change="(selection) => handleSelectionChange3(selection, props.row)" style="width: 100%;" :data="mainG.row.childGoodsMallFormList">

                        <el-table-column type="selection" width="55" :selectable="selectStatus">
                        </el-table-column>
                        <!-- <el-table-column prop="code" label="产品编码" min-width="100" show-overflow-tooltip>
                      </el-table-column> -->
                        <el-table-column prop="standard" label="产品规格"></el-table-column>
                        <el-table-column prop="isPresell" label="是否是预售产品">
                          <template slot-scope="child">
                            {{ child.row.isPresell == 1 ? '是' : '否' }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="isCurrentWeighing" label="是否是现称产品">
                          <template slot-scope="child">
                            {{ child.row.isCurrentWeighing == 1 ? '是' : '否' }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="isGift" label="是否是赠品">
                          <template slot-scope="child">
                            {{ child.row.isGift == 1 ? '是' : '否' }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="num" label="库存"></el-table-column>
                        <el-table-column prop="saleTotalNum" label="商品总量">
                          <template slot-scope="child">
                            {{ child.row.type == 0 ? 0 : child.row.saleTotalNum }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="purchasing" label="进货价"></el-table-column>
                        <el-table-column prop="price" label="原价">
                          <template slot-scope="child">
                            {{ child.row.price }} 元
                          </template>
                        </el-table-column>
                        <el-table-column prop="salePrice" label="乡兴供货价">
                          <template slot-scope="child">
                            {{ child.row.salePrice }} 元
                          </template>
                        </el-table-column>
                        <el-table-column prop="salePrice" label="自提价">
                          <template slot-scope="child">
                            {{ child.row.takeSale }} 元
                          </template>
                        </el-table-column>
                        <el-table-column prop="wholesalePrice" label="批发商价">
                          <template slot-scope="child">
                            {{ child.row.wholesalePrice ? child.row.wholesalePrice : 0 }} 元
                          </template>
                        </el-table-column>
                        <el-table-column prop="scaledValue" label="规格比">
                          <template slot-scope="child">
                            <p v-if="child.row.scaledValue">1 : {{ child.row.scaledValue }}</p>
                          </template>
                        </el-table-column>
                        <el-table-column prop="limit" label="限购量" v-if="props.row.type == 3"></el-table-column>
                        <el-table-column prop="minimum" label="起订量" v-if="props.row.type != 3"></el-table-column>
                        <!-- 隐藏积分相关功能 -->
                        <!-- <el-table-column prop="pointNum" label="积分倍数"></el-table-column>
                      <el-table-column prop="point" label="积分（参考值）"></el-table-column> -->
                        <el-table-column prop="isTopCarriage" label="状态" min-width="80">
                          <template slot-scope="child">
                            <p v-if="child.row.isTopCarriage == 0" style="color: red">
                              未上架
                            </p>
                            <p v-else-if="child.row.isTopCarriage == 1" style="color: #67c23a">
                              已上架
                            </p>
                            <p v-else-if="child.row.isTopCarriage == 2" style="color: red">已下架</p>
                            <p v-else-if="child.row.isTopCarriage == 3" style="color: red">已截单</p>
                          </template>
                        </el-table-column>
                        <el-table-column align="center" width="300" label="操作" fixed="right">
                          <template slot-scope="child">
                            <el-button size="mini" type="primary" @click="editChildEvent(child.row, child.$index, props.row,mainG.$index)" v-if="updateButton == '1'" :disabled="child.row.isTopCarriage == 1">编辑</el-button>
                            <el-button size="mini" type="primary" @click="childTransPu(child.row.id,props.row.warehouseId)" v-if="updateButton == '1'">转为普通规格</el-button>
                            <el-button size="mini" type="primary" @click="handleSetGit(child.row)" v-if="updateButton == '1'">{{ child.row.isGift == 1 ? '取消赠品' : '设为赠品' }}</el-button>
                            <!-- <el-button size="mini" type="primary" @click="handleEditNum(scope.row)"
                              v-if="scope.row.num == 0 || scope.row.num<scope.row.minimum">修改库存</el-button> -->
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <el-table-column type="selection" width="55" :selectable="selectStatus">
                  </el-table-column>
                  <!-- <el-table-column prop="code" label="产品编码" min-width="100" show-overflow-tooltip>
                  </el-table-column> -->
                  <el-table-column prop="standard" label="产品规格"></el-table-column>
                  <el-table-column prop="isPresell" label="是否是预售产品">
                    <template slot-scope="props">
                      {{props.row.isPresell==1?'是':'否'}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="isCurrentWeighing" label="是否是现称产品">
                    <template slot-scope="props">
                      {{props.row.isCurrentWeighing==1?'是':'否'}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="type" label="是否是主产品">
                    <template slot-scope="props">
                      {{ props.row.type == 1 ? '是' : '否' }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="isGift" label="是否是赠品">
                    <template slot-scope="props">
                      {{ props.row.isGift == 1 ? '是' : '否' }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="num" label="库存"></el-table-column>
                  <el-table-column prop="saleTotalNum" label="商品总量">
                    <template slot-scope="scope">
                      {{scope.row.type==0?0:scope.row.saleTotalNum}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="purchasing" label="进货价"></el-table-column>
                  <el-table-column prop="price" label="原价">
                    <template slot-scope="props">
                      {{ props.row.price }} 元
                    </template>
                  </el-table-column>
                  <el-table-column prop="salePrice" label="乡兴供货价">
                    <template slot-scope="props">
                      {{ props.row.salePrice }} 元
                    </template>
                  </el-table-column>
                  <el-table-column prop="salePrice" label="自提价">
                    <template slot-scope="props">
                      {{ props.row.takeSale }} 元
                    </template>
                  </el-table-column>
                  <el-table-column prop="wholesalePrice" label="批发商价">
                    <template slot-scope="props">
                      {{ props.row.wholesalePrice? props.row.wholesalePrice:0 }} 元
                    </template>
                  </el-table-column>
                  <el-table-column prop="scaledValue" label="规格比">
                    <template slot-scope="props">
                      <p v-if="props.row.scaledValue">1 : {{props.row.scaledValue}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="limit" label="限购量" v-if="props.row.type == 3"></el-table-column>
                  <el-table-column prop="minimum" label="起订量" v-if="props.row.type != 3"></el-table-column>
                  <!-- 隐藏积分相关功能 -->
                  <!-- <el-table-column prop="pointNum" label="积分倍数"></el-table-column>
                  <el-table-column prop="point" label="积分（参考值）"></el-table-column> -->
                  <el-table-column prop="isTopCarriage" label="状态" min-width="80">
                    <template slot-scope="scope">
                      <p v-if="scope.row.isTopCarriage == 0" style="color: red">
                        未上架
                      </p>
                      <p v-else-if="scope.row.isTopCarriage == 1" style="color: #67c23a">
                        已上架
                      </p>
                      <p v-else-if="scope.row.isTopCarriage == 2" style="color: red">已下架</p>
                      <p v-else-if="scope.row.isTopCarriage == 3" style="color: red">已截单</p>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="290" label="操作" fixed="right">
                    <template slot-scope="scope">
                      <!-- <el-button size="mini" type="primary" @click="matchPresellOrder(props.row, scope.row.id)" v-if="updateButton == '1'" :disabled="scope.row.type == 0">匹配预售单</el-button> -->
                      <el-button size="mini" type="primary" @click="handleEdit(props.row, scope.row.id)" v-if="updateButton == '1'" :disabled="scope.row.isTopCarriage == 1">编辑</el-button>
                      <el-button size="mini" type="primary" @click="setAddChild(props.row, scope.row.id)" v-if="scope.row.type == 1">设置子产品</el-button>
                      <!-- <el-button size="mini" type="primary" @click="handleUp(scope.row,'',props.row)" v-if="upDownButton == '1'" :disabled="scope.row.isTopCarriage == 1">上架</el-button>
                      <el-button size="mini" type="danger" @click="handleDown(scope.row, 2, props.row)" v-if="upDownButton == 1" :disabled="scope.row.isTopCarriage != 1">下架</el-button> -->
                      <el-button size="mini" type="primary" @click="handleSetGit(scope.row)" v-if="updateButton =='1'">{{ scope.row.isGift==1?'取消赠品':'设为赠品' }}</el-button>
                      <!-- <el-button size="mini" type="primary" @click="handleEditNum(scope.row)" v-if="upDownNumButton=='1'">修改库存</el-button> -->
                      <!-- <el-button size="mini" type="primary" @click="handleEditNum(scope.row)"
                          v-if="scope.row.num == 0 || scope.row.num<scope.row.minimum">修改库存</el-button> -->
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="icons" label="产品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px; height: 60px" :src="scope.row.icons" :preview-src-list="scope.row.imgs.split(',')">
                  </el-image>
                </div>
                <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="产品名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="describe" label="产品描述" width="130px" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column prop="color" label="颜色"> </el-table-column> -->
            <!-- <el-table-column prop="categoryName" label="类型">
            </el-table-column> -->
            <!-- <el-table-column prop="standard" label="规格" show-overflow-tooltip>
            </el-table-column> -->
            <!-- <el-table-column prop="model" label="型号"> </el-table-column> -->
            <!-- <el-table-column prop="unit" label="单位"> </el-table-column> -->
            <!-- <el-table-column prop="mfrs" label="制造商"> </el-table-column> -->
            <el-table-column prop="type" label="订单类型">
              <template slot-scope="scope">
                {{
                scope.row.type == 1
                ? "限时订单"
                : scope.row.type == 2
                ? "限量订单"
                : scope.row.type == 3
                ? "限购订单"
                : scope.row.type == 4?"热销现货": scope.row.type == 5?"满减活动":""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="warehouse" label="所属仓库" width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ findWarehouse(scope.row.warehouseId) }}
              </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序"> </el-table-column>
            <el-table-column prop="startTime" label="开始时间" width="160">
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间" width="160">
            </el-table-column>
            <el-table-column prop="deliveryTime" label="配送时间" min-width="150">
            </el-table-column>
            <!-- fixed="right" -->
            <el-table-column align="center" width="550" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleBuyList(scope.row,1)" v-if="searchButton == '1'">购买记录
                </el-button>
                <el-button size="mini" type="primary" @click="handleBuyList(scope.row,2)" v-if="searchButton == '1'">产品销量
                </el-button>
                <el-button size="mini" type="primary" @click="handleDetails(scope.row)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="addStandard(scope.row)" v-if="updateButton == '1'">新增规格
                </el-button>
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" :disabled="checkEdit(scope.row)">编辑</el-button>
                <!-- <el-button size="mini" type="primary" :disabled="checkEdit(scope.row)" @click="handleUp(scope.row, 1)" v-if="upDownButton == '1'">一键上架
                </el-button>
                <el-button size="mini" type="danger" :disabled="!checkEdit(scope.row)" @click="handleDown(scope.row, 1)" v-if="upDownButton == 1">一键下架
                </el-button> -->
                <el-button size="mini" type="primary" @click="handleSetRule(scope.row)">活动规则</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false" @close="addEditCancel">
      <el-form v-if="dialogFormVisible" :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px" style="padding: 20px">
        <div v-if="
          dialogTitle == '详情' ||
          dialogTitle == '新增' ||
          (dialogTitle == '编辑' && !standardId)
        ">
          <div class="titleBox">
            <p>产品基础信息</p>
          </div>
          <el-form-item label="选择产品" prop="goodsId">
            <el-select v-model="addEditForm.goodsId" filterable style="width: 300px" :disabled="detailsShow" placeholder="可搜索选项" @change="changeGoodsEvents">
              <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类型" v-if="addEditForm.goodsId">
            <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2" placeholder="请选择产品类型" :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }" :disabled="true" clearable @change="cascaderChange2"></el-cascader>
          </el-form-item>
          <el-form-item label="产品单位" v-if="addEditForm.goodsId">
            <el-select v-model="addEditForm.unitId" style="width: 300px" @change="unitChange" :disabled="true">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="颜色" v-if="addEditForm.goodsId">
            <el-input v-model="addEditForm.color" placeholder="请输入颜色" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="型号" v-if="addEditForm.goodsId">
            <el-input v-model="addEditForm.model" placeholder="请输入型号" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="制造商" v-if="addEditForm.goodsId">
            <el-input v-model="addEditForm.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="描述" v-if="addEditForm.goodsId">
            <el-input type="textarea" v-model="addEditForm.describe" placeholder="请输入描述" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="产品图片" style="display: block" v-if="addEditForm.goodsId">
            <template>
              <img v-for="(items, i) in imgData" :key="i" :src="items" alt="" width="100px" style="margin-right: 15px" />
            </template>
          </el-form-item>
          <el-form-item label="产品视频" v-if="addEditForm.goodsId && addEditForm.videos" style="display: block">
            <video :src="addEditForm.videos" width="200px" controls></video>
          </el-form-item>
        </div>
        <div v-if="
          dialogTitle == '详情' ||
          dialogTitle == '新增' ||
          (dialogTitle == '编辑' && !standardId)
        ">
          <div class="titleBox">
            <p>产品销售信息</p>
          </div>
          <!-- <el-button size="mini" type="primary" @click="addGoodsType">添加产品规格</el-button> -->
          <!-- goodsStandard -->
          <!-- <el-form-item label="商品编码" prop="code">
            <el-input type="text" v-model="addEditForm.code" placeholder="请输入商品编码" style="width: 300px"></el-input>
          </el-form-item> -->
          <el-form-item label="订单类型" prop="type">
            <el-select v-model="addEditForm.type" placeholder="请选择订单类型" style="width: 300px" :disabled="minimumchange" @change="changeOrderType">
              <el-option v-for="(item, index) in orderType" :key="index" :label="item.label" :value="item.id" :disabled="detailsShow"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间" prop="startTime" v-if="addEditForm.type !=4 ">
            <el-date-picker v-model="addEditForm.startTime" type="datetime" style="width: 300px" :disabled="detailsShow" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
          <!-- addEditForm.type == 1 || addEditForm.type == 3  -->
          <el-form-item label="结束时间" prop="endTime" v-if="addEditForm.type !=4 ">
            <el-date-picker v-model="addEditForm.endTime" type="datetime" style="width: 300px" :disabled="detailsShow" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="配送时间" v-if="addEditForm.type != 4">
            <!-- 限时限量限购显示 -->
            <el-input v-model="addEditForm.deliveryTime" placeholder="请输入配送时间" style="width: 300px" :disabled="detailsShow"></el-input>
            <!-- <el-date-picker
              v-model="addEditForm.deliveryTime"
              type="date"
              style="width: 300px"
              :disabled="detailsShow"
              value-format="yyyy-MM-dd"
              placeholder="选择配送时间"
            >
            </el-date-picker> -->
          </el-form-item>
          <!-- 新增时仓库为多选、编辑时为单选 -->
          <el-form-item label="所属仓库" prop="warehouseId">
            <el-select v-if="dialogTitle == '新增'" v-model="warehouseId" placeholder="请选择所属仓库" style="width: 300px" multiple :disabled="detailsShow" @change="changeWarehouse">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id" :disabled="detailsShow"></el-option>
            </el-select>
            <!-- :disabled="detailsShow || dialogTitle == '编辑'" -->
            <el-select v-else v-model="addEditForm.warehouseId" placeholder="请选择所属仓库" style="width: 300px" :disabled="detailsShow">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id" :disabled="detailsShow"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序">
            <el-input type="text" v-model="addEditForm.sort" placeholder="请输入排序" style="width: 300px" :disabled="detailsShow"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="addEditForm.remark" placeholder="请输入备注" style="width: 300px" :disabled="detailsShow"></el-input>
          </el-form-item>
          <el-form-item label="是否为活动" v-if="addEditForm.type != 3">
            <el-switch v-model="addEditForm.isActivity" active-color="#13ce66" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="detailsShow">
            </el-switch>
            <p style="color:red">注：若设置为活动，则必须有多个规格，且都有起订量</p>
          </el-form-item>
        </div>

        <div v-if="
          dialogTitle == '详情' ||
          dialogTitle == '新增' ||
          dialogTitle == '编辑' ||
          dialogTitle == '添加规格信息'
        " class="titleBox clearfix">
          <p class="float_lf">产品规格信息</p>
          <el-button v-if="
            dialogTitle == '新增' ||
            dialogTitle == '添加规格信息'
          " class="float_rt" size="mini" type="primary" @click="addGoodsType">添加规格信息</el-button>
        </div>
        <!-- 规格信息 -->
        <div v-if="
          dialogTitle == '详情' ||
          dialogTitle == '新增' ||
          dialogTitle == '编辑' ||
          dialogTitle == '添加规格信息'
        " class="standard_box">
          <div v-for="(child, i) in addEditForm.goodsStandard" :key="i">
            <div class="standard_list" v-if="
              dialogTitle == '详情' ||
              dialogTitle == '新增' ||
              dialogTitle == '添加规格信息' ||
              (standardId && child.id == standardId) ||
              !standardId
            " :style="{
              borderTop:
                standardId && child.id == standardId
                  ? 'none'
                  : '1px dashed #333',
            }">
              <el-button v-if="
                dialogTitle == '新增' ||
                dialogTitle == '添加规格信息'
              " class="float_rt" size="mini" type="danger" @click="delGoodsType(i, child.id, addEditForm.warehouseId)">
                删除</el-button>
              <el-form-item label="产品编码" :prop="'goodsStandard[' + i + '].code'">
                <el-input type="text" v-model="addEditForm.goodsStandard[i].code" placeholder="请输入商品编码" style="width: 300px"></el-input>
              </el-form-item>
              <el-form-item label="产品规格" :prop="'goodsStandard[' + i + '].standard'">
                <el-input v-model="addEditForm.goodsStandard[i].standard" placeholder="请输入产品规格" style="width: 300px" :disabled="detailsShow"></el-input>
              </el-form-item>
              <!-- :prop="'goodsStandard[' + i + '].purchasing'" -->
              <el-form-item label="采购价(元)">
                <el-input v-model="addEditForm.goodsStandard[i].purchasing" placeholder="请输入采购价" style="width: 300px" :disabled="detailsShow"></el-input>
              </el-form-item>
              <!-- :prop="'goodsStandard[' + i + '].price'" -->
              <el-form-item label="原价(元)">
                <el-input v-model="addEditForm.goodsStandard[i].price" placeholder="请输入原价" style="width: 300px" :disabled="detailsShow"></el-input>
              </el-form-item>
              <!-- :prop="'goodsStandard[' + i + '].salePrice'" -->
              <el-form-item label="乡兴供货价(元)">
                <el-input v-model="addEditForm.goodsStandard[i].salePrice" placeholder="请输入乡兴供货价" style="width: 300px" :disabled="detailsShow" @input="pointNumInput(child, i)"></el-input>
              </el-form-item>
              <!-- <el-form-item label="库存" :prop="'goodsStandard[' + i + '].num'">
                <el-input v-model="addEditForm.goodsStandard[i].num" placeholder="请输入库存" style="width: 300px" :disabled="detailsShow" @change="changeNum"></el-input>
              </el-form-item> -->

              <el-form-item label="起订量" v-if="addEditForm.type != 3" :prop="'goodsStandard[' + i + '].minimum'">
                <el-input v-model="addEditForm.goodsStandard[i].minimum" placeholder="请输入起订量" style="width: 300px" :disabled="minimumchange" @input="changeMinimum"></el-input>
              </el-form-item>
              <el-form-item label="限购份数" v-if="addEditForm.type == 3" :prop="'goodsStandard[' + i + '].limit'">
                <el-input :min="1" v-model="addEditForm.goodsStandard[i].limit" placeholder="请输入限购份数" style="width: 300px" :disabled="detailsShow"></el-input>
              </el-form-item>
              <!-- 隐藏积分相关功能 -->
              <!-- <el-form-item label="积分倍数" :prop="'goodsStandard[' + i + '].pointNum'">
                <el-input v-model="addEditForm.goodsStandard[i].pointNum" placeholder="请输入积分倍数" style="width: 300px"
                  :disabled="detailsShow" type="text" @input="pointNumInput(child, i)"></el-input>
              </el-form-item>
              <el-form-item label="积分(参考值)">
                <el-input v-model="addEditForm.goodsStandard[i].point" placeholder="请输入积分倍数" style="width: 300px"
                  :disabled="true"></el-input>
              </el-form-item> -->
              <el-form-item label="产品规格图片" prop="imgs" style="display: block">
                <cropperAndUploadVue :disabled="detailsShow" :list="goodsStandard[i].standardImg" :size="[11, 11]" :limit="1" :sortable="false" @change="(list) => handleChange(list, i)" @success="(list) => handleSuccess(list, i)"></cropperAndUploadVue>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" :loading="submitBtnLoad" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 点击下架时提示框 -->
    <el-dialog title="提示" :visible.sync="upDownShow" width="30%" :close-on-click-modal="false">
      <span>请选择执行下架还是截单操作？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upDownShow = false">取 消</el-button>
        <el-button type="primary" @click="downEvent(1)">下 架</el-button>
        <el-button type="primary" @click="downEvent(2)">截 单</el-button>
      </span>
    </el-dialog>
    <!-- 拖动排序 -->
    <el-dialog title="拖动排序" :visible.sync="sortShow" width="80%" :close-on-click-modal="false">
      <div class="sort_goods" v-loading="loading">
        <template>
          <!-- 选择仓库 -->
          <el-select v-if="true" v-model="sortWarehouseId" placeholder="请选择所属仓库" style="width: 200px" @change="sortChangeWarehouse" :disabled="userName!='admin'">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id" :disabled="detailsShow"></el-option>
          </el-select>
          <div class="list_title flex_between">
            <div>产品图片</div>
            <div>产品名称</div>
            <div>产品描述</div>
            <div>订单类型</div>
            <div>所属仓库</div>
            <div>排序</div>
            <div>开始时间</div>
            <div>结束时间</div>
            <div>配送时间</div>
          </div>
          <ul class="list">
            <li @dragenter="dragenter($event, index)" @dragover="dragover($event, index)" @dragstart="dragstart(index)" draggable v-for="(item, index) in goodsUpList" :key="index" class="list-item flex_between">

              <div style="overflow:hidden;"><img :src="item.imgs[0]" alt="" height="60px"></div>
              <div class="txt-num1">{{item.name}}</div>
              <div class="txt-num1">{{ item.describe }}</div>
              <div>{{ item.type == 1 ? '限时订单' : item.type == 2 ? '限量订单' : item.type == 3?'限购订单':'热销现货'}}</div>
              <div>{{ item.warehouse }}</div>
              <div>{{ item.sort }}</div>
              <div>{{item.startTime}}</div>
              <div>{{item.endTime}}</div>
              <div>{{ item.deliveryTime }}</div>
            </li>
          </ul>

        </template>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sortShow = false">取 消</el-button>
        <el-button type="primary" @click="sortSubmit" :loading="submitBtnLoad">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 购买记录 -->
    <el-dialog title="购买记录" :visible.sync="buyListShow" width="80%" :close-on-click-modal="false">
      <div class="goods_buys">
        <el-form :model="buyListForm" :inline="true" ref="buyListForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
          <el-form-item label="用户" prop="userId">
            <el-select v-model="buyListForm.userName" clearable filterable style="width: 150px" placeholder="可搜索选项">
              <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat" :value="item.wechat">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="规格">
            <el-select v-model="buyListForm.standardList" multiple filterable clearable placeholder="请选择规格" :disabled="detailsShow">
              <el-option v-for="(item, index) in buyStandardList" :key="index" :label="item.standard" :value="item.standard">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否符合起订量">
            <el-select v-model="buyListForm.isMinimum" clearable placeholder="请选择是否符合起订量">
              <el-option v-for="(item, index) in isMinimumList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择区域:">
            <el-cascader ref="buyListTree" style="width: 300px" :options="regionalRouteData" v-model="buyListRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="选择路线">
            <el-select v-model="buyListForm.wayIdList" filterable multiple clearable>
              <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为赠品单">
            <el-select style="width: 200px" v-model="buyListForm.isGift" clearable placeholder="请选择是否为赠品单">
              <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为预售单">
            <el-select style="width: 200px" v-model="buyListForm.isPresell" clearable placeholder="请选择是否为预售单">
              <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="buySearchSubmit('buyListForm')" icon="el-icon-search">搜索</el-button>
            <el-button @click="buyResetForm('buyListForm')">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="buys_title">产品购买记录</div> -->
        <div class="buys_total clearfix">
          <el-button type="primary" size="mini" class="float_lf">
            已下单人数：{{ buyNum }}
          </el-button>
          <el-button type="primary" size="mini" class="float_lf">
            已下单量：
            <span v-for="(item, index) in orderNum" :key="index">
              <span>{{item.name}}：{{ item.value }}</span> &nbsp;&nbsp;
            </span>
          </el-button>
        </div>
        <el-button size="mini" type="primary" @click="orderTypeData.show=true" :disabled="!orderTypeData.orderIdList.length">批量修改类型</el-button>
        <!-- 列表 -->
        <template>
          <el-table :data="buysListData" style="width: 100%" @selection-change="handleOrder">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="icon" label="头像" width="100">
              <template slot-scope="scope">
                <img :src="scope.row.icon" alt="" style="width: 40px; border-radius: 50%" />
              </template>
            </el-table-column>
            <el-table-column prop="wechat" label="昵称" width="180">
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="180"> </el-table-column>
            <el-table-column prop="num" label="数量"> </el-table-column>
            <el-table-column prop="price" label="单价"> </el-table-column>
            <el-table-column prop="isGift" label="是否是赠品">
              <template slot-scope="props">
                {{ props.row.isGift == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column prop="isPresell" label="是否为预售单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isPresell == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="way" label="路线"> </el-table-column>
            <el-table-column prop="created" label="时间" width="180"> </el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="seeOrder(scope.row.userId)">查看订单
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="block" style="padding: 10px 15px">
          <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows" :page-size="pagesData2.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData2.total">
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="buyListShow = false">关 闭</el-button>
      </span>
      <!-- 批量修改订单类型 -->
      <el-dialog title="批量修改订单类型" :visible.sync="orderTypeData.show" width="80%" append-to-body :close-on-click-modal="false">
        <el-radio v-model="orderTypeData.radio" label="1">预售</el-radio>
        <el-radio v-model="orderTypeData.radio" label="2">非预售</el-radio>
        <el-radio v-model="orderTypeData.radio" label="3">现称</el-radio>
        <el-radio v-model="orderTypeData.radio" label="4">非现称</el-radio>
        <div slot="footer" class="dialog-footer">
          <el-button @click="orderTypeData.show=false">取 消</el-button>
          <el-button type="primary" @click="updateOrderType">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 查看订单 -->
      <el-dialog title="查看订单" :visible.sync="userBuyOrderShow" width="80%" append-to-body :close-on-click-modal="false">
        <el-table ref="multipleTable" :data="userBuyOrderList" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="icons" label="商品图片">
            <template slot-scope="scope">
              <div class="demo-image__preview" v-if="scope.row.icons">
                <el-image style="width: 60px;height:60px;" :src="scope.row.icons">
                </el-image>
              </div>
              <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="title" label="商品名称" min-width="140" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="describe" label="商品描述" width="130px" show-overflow-tooltip>
            </el-table-column> -->
          <el-table-column prop="wechat" label="用户昵称" show-overflow-tooltip> </el-table-column>
          <!-- <el-table-column prop="categoryName" label="类型">
            </el-table-column> -->
          <el-table-column prop="standard" label="规格"> </el-table-column>
          <!-- <el-table-column prop="model" label="型号"> </el-table-column> -->
          <!-- <el-table-column prop="unit" label="单位"> </el-table-column> -->
          <!-- <el-table-column prop="mfrs" label="制造商"> </el-table-column> -->
          <el-table-column prop="num" label="商品数量" :filter-multiple="false" :filters="[{ text: '小于10', value: '10' }, { text: '小于20', value: '20' }]" :filter-method="filterNum"> </el-table-column>
          <el-table-column prop="price" label="价格"> </el-table-column>
          <!-- 隐藏积分功能 -->
          <!-- <el-table-column prop="point" label="积分"> </el-table-column> -->
          <el-table-column prop="totalPrice" label="订单总价">
          </el-table-column>
          <el-table-column prop="type" label="订单类型">
            <template slot-scope="scope">
              {{ scope.row.type == 1 ? "限时订单" : scope.row.type == 2 ? "限量订单" : scope.row.type == 3 ? "限购订单" : "热销现货" }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="startTime" label="开始时间" min-width="150">
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间" min-width="150">
            </el-table-column>
            <el-table-column
              prop="deliveryTime"
              label="配送时间"
              min-width="150"
            >
            </el-table-column> -->
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 0" style="color: #409eff">
                已下单
              </p>
              <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                退货
              </p>
              <p v-else-if="scope.row.status == 2" style="color: #333">
                取消
              </p>
              <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                已完成
              </p>
              <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
                已截单
              </p>
              <p v-else-if="scope.row.status == 5" style="color: red">
                已出库
              </p>
              <p v-else-if="scope.row.status == 6" style="color: #67c23a">
                已配送
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="way" width="80" label="路线" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="type" label="是否为预售单" width="120" align="center">
            <template slot-scope="scope">
              {{ scope.row.isPresell == 1 ? "是" : "否"  }}
            </template>
          </el-table-column>
          <el-table-column prop="created" width="160" label="下单时间">
          </el-table-column>
          <!-- <el-table-column prop="outboundDeliveryTime" width="160" label="出库时间">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 4"></p>
              <p v-else>{{ scope.row.outboundDeliveryTime }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="completeTime" width="160" label="完成时间">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 4 || scope.row.status == 5"></p>
              <p v-else>{{ scope.row.completeTime }}</p>
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="openOrderNumShow(scope.row)">修改订单数量</el-button>
              <el-button size="mini" type="danger" @click="handleCancelEvent(scope.row)" :disabled="scope.row.status != 4">取消</el-button>
              <!-- <el-button size="mini" type="primary" @click="handleRefound(scope.row)" :disabled="scope.row.status == 1 || scope.row.status == 2 ">退货</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="userBuyOrderShow = false">关 闭</el-button>
        </span>
        <!-- 修改订单数量 -->
        <el-dialog title="修改订单数量" :visible.sync="orderNumShow" append-to-body :close-on-click-modal="false" width="35%">
          <el-form :model="updateOrderNumData" ref="updateOrderNumData" :inline="true" label-width="120px">
            <el-form-item label="订单数量">
              <el-input style="width: 300px" v-model="updateOrderNumData.num" placeholder="请输入订单数量"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="hanleCancel('orderNumShow')">取 消</el-button>
            <el-button type="primary" @click="updateOrder">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 退货 -->
        <el-dialog title="退货" :visible.sync="returnShow" append-to-body :close-on-click-modal="false" width="35%">
          <el-form :model="returnForm" ref="returnForm" :rules="returnRules" :inline="true" label-width="120px">
            <el-form-item label="商品名称">
              <el-input style="width: 300px" v-model="returnForm.title" placeholder="请输入商品名称" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="购买数量">
              <el-input style="width: 300px" v-model="returnForm.num" placeholder="请输入购买数量" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="退货数量" prop="returnGoodsNum">
              <el-input style="width: 300px" type="text" min="0" :max="returnForm.num" @change="goodsNumChange" v-model="returnForm.returnGoodsNum" placeholder="请输入商品数量"></el-input>
            </el-form-item>
            <el-form-item label="退货原因">
              <el-input style="width: 300px" type="textarea" v-model="returnForm.reason" placeholder="请输入退货原因"></el-input>
            </el-form-item>
            <el-form-item label="是否需要带回" v-if="returnForm.status == 3 || returnForm.status==4||returnForm.status==5||returnForm.status==6">
              <el-switch v-model="returnForm.needBack" active-text="是" inactive-text="否">
              </el-switch>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="hanleCancel('returnShow')">取 消</el-button>
            <el-button type="primary" @click="returnSubmit('returnForm')">确 定</el-button>
          </div>
        </el-dialog>
      </el-dialog>
    </el-dialog>
    <!-- 产品销量 -->
    <el-dialog title="产品销量" :visible.sync="productSaleShow" width="80%" :close-on-click-modal="false">
      <div class="goods_buys">
        <el-form :model="productSaleForm" :inline="true" ref="productSaleForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
          <el-form-item label="用户" prop="userId">
            <el-select v-model="productSaleForm.userName" clearable filterable style="width: 150px" placeholder="可搜索选项">
              <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat" :value="item.wechat">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格">
            <el-select v-model="productSaleForm.standardList" filterable multiple clearable placeholder="请选择规格" :disabled="detailsShow">
              <el-option v-for="(item, index) in buyStandardList" :key="index" :label="item.standard" :value="item.standard">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否符合起订量">
            <el-select v-model="productSaleForm.isMinimum" clearable placeholder="请选择是否符合起订量">
              <el-option v-for="(item, index) in isMinimumList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择区域:">
            <el-cascader ref="productSaleTree" style="width: 300px" :options="regionalRouteData" v-model="productSaleRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="选择路线">
            <el-select v-model="productSaleForm.wayIdList" filterable multiple clearable>
              <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为赠品单">
            <el-select style="width: 200px" v-model="productSaleForm.isGift" clearable placeholder="请选择是否为赠品单">
              <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否为预售单">
            <el-select style="width: 200px" v-model="productSaleForm.isPresell" clearable placeholder="请选择是否为预售单">
              <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="productSaleForm.statusList" multiple clearable>
              <el-option v-for="(item, index)  in orderStatus" :key="index" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期:">
            <el-date-picker :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="buySearchSubmit('productSaleForm')" icon="el-icon-search">搜索</el-button>
            <el-button @click="buyResetForm('productSaleForm')">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="buys_title">产品购买记录</div> -->
        <div class="buys_total clearfix">
          <el-button type="primary" size="mini" class="float_lf">
            已下单人数：{{ buyNum }}
          </el-button>
          <el-button type="primary" size="mini" class="float_lf">
            已下单量：
            <span v-for="(item, index) in orderNum" :key="index">
              <span>{{item.name}}：{{ item.value }}</span> &nbsp;&nbsp;
            </span>
          </el-button>
        </div>
        <!-- 列表 -->
        <template>
          <el-table :data="buysListData" style="width: 100%">
            <el-table-column prop="icon" label="头像" width="100">
              <template slot-scope="scope">
                <img :src="scope.row.icon" alt="" style="width: 40px; border-radius: 50%" />
              </template>
            </el-table-column>
            <el-table-column prop="wechat" label="昵称" width="180">
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="180"> </el-table-column>
            <el-table-column prop="num" label="数量"> </el-table-column>
            <el-table-column prop="price" label="单价"> </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  已下单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                  退货
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #333">
                  取消
                </p>
                <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                  已完成
                </p>
                <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
                  已截单
                </p>
                <p v-else-if="scope.row.status == 5" style="color: red">
                  已出库
                </p>
                <p v-else-if="scope.row.status == 6" style="color: #67c23a">
                  已配送
                </p>
                <p v-else-if="scope.row.status == 9" style="color: red">
                  已结账
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="isGift" label="是否是赠品">
              <template slot-scope="props">
                {{ props.row.isGift == 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column prop="isPresell" label="是否为预售单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isPresell == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="way" label="路线"> </el-table-column>
            <el-table-column prop="created" label="时间" width="180"> </el-table-column>
          </el-table>
        </template>
        <div class="block" style="padding: 10px 15px">
          <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows" :page-size="pagesData2.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData2.total">
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="productSaleShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 修改库存 -->
    <el-dialog title="修改库存" :visible.sync="editNumShow" :close-on-click-modal="false">
      <div class="postForm">
        <el-form :model="formData" :rules="editNum" ref="formData" label-width="140px" class="demo-ruleForm">
          <el-form-item label="库存：" prop="num">
            <el-input v-model="formData.num" placeholder="请输入库存数量" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block;margin-top:50px; text-align:right">
            <el-button type="primary" @click="handleSubmit('formData')">确定
            </el-button>
            <el-button @click="cancelAdd()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 设置活动规则 -->
    <active-rule-setting v-if="rulesShow" :dialogShow="rulesShow" :dialogTitle="dialogTitle" :ruleList="ruleList" :guiGeList="guiGeList" :giftGoodsList="giftGoodsList" :activityType="activityType" :goodsIds="goodsIds" :salesGoodsId="salesGoodsId" @closeClick="closeDialog"></active-rule-setting>
    <!-- 普通规格转子规格 -->
    <el-dialog title="添加子规格" :visible.sync="transShow" :close-on-click-modal="false">
      <div class="postForm">
        <el-form :model="transForm" ref="transForm" label-width="140px" class="demo-ruleForm">
          <el-form-item label="选择规格：" prop="num" v-if="transType == 1">
            <el-select v-model="transForm.goodsIds" placeholder="请选择规格(可多选)" multiple clearable>
              <el-option v-for="(item, index) in childList" :key="index" :label="item.standard" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库存：" prop="num" v-else>
            <el-input v-model="childNum" placeholder="请输入库存数量" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block;margin-top:50px; text-align:right">
            <el-button type="primary" @click="transSubmit('transForm')" :loading="submitBtnLoad">确定
            </el-button>
            <el-button @click="cancelAdd('transShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import app from "element-china-area-data";
import { allSettled } from "q";
// import transition_group from 'transition_group'
export default {
  name: "goodsIndex",
  components: {
    cropperAndUploadVue: () => import("@/components/cropperAndUpload.vue"),
    activeRuleSetting: () => import("@/components/activeRulesSetting.vue"),
    // transition_group,
  },
  data() {
    return {
      // 产品类型
      goodsType: [],
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      buyListRegionalList: [],
      productSaleRegionalList: [],
      regionalRouteData: [],
      orderTypeData: {
        show: false,
        orderIdList: [],
        radio: "1"
      },
      //订单状态
      orderStatus: [
        {
          label: "已下单",
          id: "0",
        },
        {
          label: "退货",
          id: "1",
        },
        {
          label: "取消",
          id: "2",
        },
        {
          label: "已完成",
          id: "3",
        },
        {
          label: "已截单",
          id: "4",
        }, {
          label: "已出库",
          id: "5",
        }, {
          label: "已配送",
          id: '6',
        },
        {
          label: "已结账",
          id: '9',
        },
      ],
      // 是否是预售单
      isPresellList: [
        {
          label: "否",
          id: 0,
        },
        {
          label: "是",
          id: 1,
        },

      ],
      wayList: [], //路线列表
      isMinimumList: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "符合起订量"
        },
        {
          id: 2,
          name: "不符合起订量"
        }
      ],
      orderNumShow: false,
      updateOrderNumData: {
        id: "",//订单id
        num: "",//订单数量
      },
      seeBuyOrderUserId: "",
      returnRules: {
        returnGoodsNum: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
        ],
      },
      returnShow: false,
      returnForm: {},
      userBuyOrderShow: false,
      userBuyOrderList: [],//用户订单列表
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      upDownNumButton: '',
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
        isOrder: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      // 购买记录分页
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 产品类型
      goodsType: [],
      // 产品单位
      goodsUnit: [],
      // 产品状态
      goodsStatus: [
        {
          label: "未上架",
          id: "0",
        },
        {
          label: "已上架",
          id: "1",
        }, {
          label: "已下架",
          id: "2",
        }, {
          label: "已截单",
          id: "3",
        },
      ],
      isOrder: [
        {
          label: "查全部",
          id: "0",
        },
        {
          label: "查有订单的",
          id: "1",
        }
      ],
      activeName: "",
      // 订单类型
      orderType: [
        {
          label: "限时订单",
          id: "1",
        },
        {
          label: "限量订单",
          id: "2",
        },
        {
          label: "限购订单",
          id: "3",
        },
        {
          label: "热销现货订单",
          id: "4",
        }, {
          label: "活动订单",
          id: "5",
        },
        // {
        //   label: "赠品",
        //   id: "6",
        // },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉产品
      selectGoods: [],
      warehouseList: [],
      // 产品购买列表
      buysListData: [],
      // 仓库多选
      warehouseId: [],
      // 已下单人数
      buyNum: "",
      // 下单量
      orderNum: "",
      submitBtnLoad: false,
      // 起订量是否可修改
      minimumchange: false,
      // 产品规格数组
      goodsStandard: [
        {
          standard: "",
          purchasing: "",
          price: "",
          salePrice: "",
          num: "",
          minimum: "",
          limit: "",
          pointNum: "",
          point: "",
          standardImg: [],
        },
      ],
      standardId: "",
      upDownShow: false,
      downRowData: {},
      downType: "", //一键下架/规格下架
      downWarehouseId: "", //下架仓库ID
      sortShow: false,
      goodsUpList: [],
      adcode: sessionStorage.getItem('adcode'),
      // 排序仓库ID
      sortWarehouseId: '',
      dragIndex: '',
      enterIndex: '',
      userName: sessionStorage.getItem('userName'),
      // 购买记录
      buyListShow: false,
      buyGoodsId: '',
      buyWarehouseId: '',
      buyStandardList: [],
      buyListForm: {
        standardList: [],
        standard: "",
        userName: '',
        isMinimum: '',
        wayIdList: []
      },
      datePicker: [],
      orderListFlag: null,
      //产品销量
      productSaleShow: false,
      productSaleForm: {
        standardList: [],
        standard: '',
        userName: '',
        isMinimum: '',
        statusList: [],
        wayIdList: []
      },
      userList: [],
      editNumShow: false,
      formData: {
        num: '',
      },
      editNum: {
        num: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }]
      },
      // 设置活动规则 查询产品下的规格
      guiGeList: [],
      rulesShow: false,
      ruleList: [
        {
          discounts: '',
          threshold: '',
        },
      ],
      // 规格下标 --设置活动规则
      standardIndex: '',
      salesGoodsId: '',
      goodsIds: [],

      // 活动类型 0满减 1满赠
      activityType: 0,
      giftGoodsList: [],
      // 设置活动时选中的销售产品信息
      nowGoodsData: {},
      // 子规格复选框选中数组
      childIds: [],
      multipleSelection3: [],
      // 普通规格转子规格弹框
      transShow: false,
      transForm: {},
      childList: [],
      childNum: '',
      transType: 1, //1:普规格转子规格 2:子规格转普规格
      warehouseId: '',
      childStandardId: '',

    };

  },
  computed: {
    tabOrderType() {
      return [
        {
          label: "全部",
          id: "",
        },
        ...this.orderType,
      ];
    },
    addEditRules() {
      // 结束时间不能小于开始时间验证
      var timeRules = (rule, value, callback) => {
        var start = new Date(this.addEditForm.startTime).getTime();
        var end = new Date(value).getTime();
        if (end < start) {
          return callback(new Error("结束时间不能小于开始时间！"));
        } else {
          callback();
        }
      };
      let data = {
        goodsId: [{ required: true, message: "请选择产品", trigger: "change" }],
        standard: [
          { required: true, message: "请输入产品规格", trigger: "blur" },
        ],
        // purchasing: [
        //   { required: true, message: "请输入产品采购价", trigger: "blur" },
        // ],
        // price: [{ required: true, message: "请输入产品原价", trigger: "blur" }],
        // salePrice: [
        //   { required: true, message: "请输入产品原价", trigger: "blur" },
        // ],
        // num: [{ required: true, message: "请输入产品库存", trigger: "blur" }],
        pointNum: [
          { required: true, message: "请输入积分倍数", trigger: "blur" },
        ],
        point: [{ required: true, message: "请输入所需积分", trigger: "blur" }],
        type: [
          { required: true, message: "请选择订单类型", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
          { validator: timeRules, trigger: "blur" },
        ],
        warehouseId: [
          { required: true, message: "请选择所属仓库", trigger: "change" },
        ],
      };

      this.goodsStandard.forEach((standard, index) => {
        data = Object.assign({}, data, {
          ["goodsStandard[" + index + "].code"]: [
            { required: true, message: "请输入商品编码", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].standard"]: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          // ["goodsStandard[" + index + "].purchasing"]: [
          //   { required: true, message: "请输入采购价", trigger: "blur" },
          // ],
          // ["goodsStandard[" + index + "].price"]: [
          //   { required: true, message: "请输入原价", trigger: "blur" },
          // ],
          // ["goodsStandard[" + index + "].salePrice"]: [
          //   { required: true, message: "请输入乡兴供货价", trigger: "blur" },
          // ],
          ["goodsStandard[" + index + "].num"]: [
            { required: true, message: "请输入库存", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].minimum"]: [
            { required: true, message: "请输入起订量", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].limit"]: [
            { required: true, message: "请输入限购份数", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].pointNum"]: [
            { required: true, message: "请输入积分倍数", trigger: "blur" },
          ],
        });
      });

      return data;
    },
  },
  watch: {
    buyListShow: {
      handler(n, o) {
        if (!n) {
          Object.keys(this.buyListForm).forEach((key) => {
            this.buyListForm[key] = "";
          });
        } else {
          this.getWayList()
        }
      }
    }
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    this.getSelectGoods();
    this.getWarehouseList();
    this.getWayList();
    this.getGoodsType()
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_mall_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_mall_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_mall_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_mall_query") {
        this.searchButton = "1";
      } else if (item.authority == "goods_mall_upDown") {
        this.upDownButton = "1";
      } else if (item.authority == "goods_mall_update_num") {
        this.upDownNumButton = "1";
      }
      // goods_mall_update_num
    });
    console.log(this.$route.params)
    if (this.$route.params.type == 'back') {
      window.location.reload();
    }
  },
  activated() {
    console.log(this.$route.params)
    this.listEvent()
    this.getRegionalRouteList()
    if (this.$route.params.type == 'back') {
      window.location.reload();
    }
  },
  methods: {
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
            _this.goodsType.push({
              name: "无",
              id: "无",
              childs: [],
              parentId: null
            })
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 处理数据方法
    changeKey(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].value = arr[i].id
        arr[i].label = arr[i].name
        if (arr[i].childs.length) {
          this.changeKey(arr[i].childs)
        } else {
          delete arr[i].childs
        }
      }
      return arr
    },
    // 区域列表
    async getRegionalRouteList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/regionalRoute/selectRegionalTree", {
          params: {
            current: 1,
            size: 1000
          }
        })
        .then(function (res) {
          if (res.status == 200) {
            if (res.data.length > 0) {
              // 递归 如果当前层级没有下级 则删除childs
              var newTree = _this.changeKey(res.data)
              _this.$nextTick(() => {
                _this.regionalRouteData = newTree
              })
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //购买记录列表选择
    handleOrder(val) {
      this.orderTypeData.orderIdList = val.map((i) => i.orderIds)
    },
    //批量修改订单状态
    updateOrderType() {
      //ids :订单ID (逗号分割)
      //type:“类型: 0.修改是否是预售;1.修改是否是现称”
      //value:“修改的类型值 0为否;1.为是”
      let obj = {}
      let _this = this;
      this.$confirm('此操作将修改订单类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let r = this.orderTypeData.radio
        if (r == "1" || r == "2") {
          obj.type = 0
          obj.value = r == "1" ? 1 : 0
        } else if (r == "3" || r == "4") {
          obj.type = 1
          obj.value = r == "3" ? 1 : 0
        }
        obj.ids = this.orderTypeData.orderIdList.join(",")
        this.$axios({
          url: this.$axios.defaults.basePath + '/saleChildOrder/batchModifyOrder',
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          data: JSON.stringify(obj)
        }).then(res => {
          if (res.data.errcode == 0) {
            _this.getBuysList(_this.buyGoodsId, _this.buyWarehouseId);
            _this.listEvent();
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.orderTypeData.show = false
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
      }).catch(() => {
      });
    },
    // 普通规格转子规格
    setAddChild(row, mainId) {
      let _this = this
      console.log(row, mainId)
      this.transType = 1
      this.$set(this.transForm, 'id', mainId)
      this.$set(this.transForm, 'warehouseId', row.warehouseId)
      this.$set(this.transForm, 'goodsIds', [])
      // 获取主规格同级普通规格
      this.$axios.get(_this.$axios.defaults.basePath + '/goodsMall/getGeneralGoodsList', {
        params: {
          goodsId: row.id,
          warehouseId: row.warehouseId
        }
      }).then(res => {
        if (res.data.errcode == 0) {
          if (res.data.data.length > 0) {
            _this.childList = res.data.data
            _this.transShow = true
          } else {
            _this.$message({
              message: '暂无同级规格',
              type: 'warning'
            });
          }
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }
      })

    },
    // 普/子转换提交
    transSubmit() {
      let _this = this
      console.log(this.transForm)
      // 普转子
      if (this.transType == 1) {
        if (this.transForm.goodsIds.length == 0) {
          _this.$message({
            message: '请选择规格',
            type: 'warning'
          });
          return
        }
        this.transForm.goodsIds = this.transForm.goodsIds.join(',')
        this.$axios({
          url: this.$axios.defaults.basePath + '/goodsMall/generalChangeChild',
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          data: JSON.stringify(_this.transForm)
        }).then(res => {
          console.log(res)
          _this.submitBtnLoad = false;
          if (res.data.errcode == 0) {
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.transShow = false;
            _this.transform = {}
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
      } else { //子转普
        if (!this.childNum) {
          _this.$message({
            message: '请输入库存',
            type: 'warning'
          });
          return
        }
        this.$axios({
          url: this.$axios.defaults.basePath + '/goodsMall/childChangeGeneral',
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          data: JSON.stringify({
            id: _this.childStandardId,
            num: _this.childNum,
            warehouseId: _this.warehouseId
          })
        }).then(res => {
          console.log(res)
          _this.submitBtnLoad = false;
          if (res.data.errcode == 0) {
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.transShow = false;
            _this.childNum = ''
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
      }


    },
    // 子规格转普
    childTransPu(standardId, warehouseId) {
      this.transType = 2
      this.transShow = true
      this.childStandardId = standardId
      this.warehouseId = warehouseId
      // let _this = this

    },


    //匹配预售单
    matchPresellOrder(p, id) {
      console.log(p, id)
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/matchPresellOrder", {
          params: {
            warehouseId: p.warehouseId,
            goodsId: id
          }
        })
        .then(function (res) {
          // if (res.data.errcode == 0) {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
          // } else {
          // _this.$message({
          //   message: res.data.errmsg,
          //   type: "warning",
          // });
          // }
        });
    },
    // 编辑子产品
    editChildEvent(child, childIndex, parentData, parentIndex) {
      this.$router.push(
        {
          name: 'salesChildGoodsAdd',
          params: {
            index: parentIndex,
            type: 'editStandard',
            childIndex: childIndex,
            title: '编辑规格信息',
            data: JSON.stringify(parentData),
            childData: JSON.stringify(child),
            flag: 1,  //1表示当前是在销售产品列表点击编辑
          }
        }
      )
    },
    closeDialog() {
      this.rulesShow = false
    },
    // 获取赠品列表
    getGiftList() {
      let _this = this;
      // 获取赠品列表
      this.$axios
        .get(this.$axios.defaults.basePath + '/goodsMall/getList?isGift=1')
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            if (res.data.data.length == 0) {
              _this.$message({
                message: '暂无赠品数据',
                type: 'warning'
              })
            } else {
              res.data.data.forEach(item => {
                item.giftName = item.name + '（' + item.standard + '）'
              })
              _this.giftGoodsList = res.data.data
            }
          }
        })
    },
    // 设为赠品
    handleSetGit(row) {
      let _this = this;
      this.$axios({
        url: _this.$axios.defaults.basePath + '/goodsMall/setGift',
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          id: row.id,
          isGift: row.isGift == 0 ? 1 : 0
        })
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: '操作成功',
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 设置活动规则
    handleSetRule(row) {
      console.log(row)
      let _this = this;
      _this.ruleList = []
      this.nowGoodsData = row
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/getList?goodsId=" + row.id)
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            if (res.data.data.length > 0) {
              _this.dialogTitle = '设置活动规则'
              _this.guiGeList = res.data.data
              _this.salesGoodsId = row.id
              _this.$axios
                .get(_this.$axios.defaults.basePath + "/goodsMallActivityRule?goodsId=" + row.id)
                .then((res) => {
                  if (res.data.errcode == 0) {
                    if (res.data.data.rulesList.length) {
                      _this.ruleList = res.data.data.rulesList
                      _this.activityType = res.data.data.activityType
                      _this.getGiftList()
                    } else {
                      _this.ruleList.push({
                        discounts: '',
                        threshold: '',
                      })
                    }
                    _this.goodsIds = res.data.data.goodsIdList
                    _this.rulesShow = true;
                  } else {
                    _this.$message({
                      message: res.data.errmsg,
                      type: "warning",
                    });
                  }
                });
            } else {
              _this.$message({
                message: '当前产品没有规格',
                type: "warning",
              });
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //导出
    exportData() {
      let _this = this;
      let data = JSON.parse(JSON.stringify(this.searchForm))
      delete data.current
      delete data.size
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/export", {
          params: data,
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = "销售产品表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })
    },
    // 取消
    handleCancelEvent(row) {
      let _this = this;
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .get(this.$axios.defaults.basePath + "/saleChildOrder/cancel", {
            params: {
              id: row.id,
              warehouseId: row.warehouseId
            },
          })
          .then((res) => {
            if (res.data.errcode == 0) {
              _this.seeOrder(_this.seeBuyOrderUserId)
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
      }).catch(() => {
      });

    },
    //修改订单数量
    updateOrder() {
      if (!this.updateOrderNumData.num || this.updateOrderNumData.num == 0) {
        this.$message({
          message: '请输入订单数量！',
          type: "warning",
        });
        return
      }
      let url = '/saleChildOrder/update';
      let obj = {
        id: '',
        remark: ''
      }
      // 是否为现称产品
      if (this.updateOrderNumData.isCurrentWeighing == 1) {
        url = 'saleChildOrder/updateRemark'
        obj.id = this.updateOrderNumData.id
        obj.remark = this.updateOrderNumData.num
      }
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + url, {
          params: this.updateOrderNumData.isCurrentWeighing == 1 ? obj : this.updateOrderNumData,
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.orderNumShow = false
            _this.seeOrder(_this.seeBuyOrderUserId);
            _this.getBuysList(_this.buyGoodsId, _this.buyWarehouseId);
            _this.$message({
              message: '修改成功！',
              type: "success",
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //打开修改订单数量弹框
    openOrderNumShow(row) {
      this.updateOrderNumData.id = row.id
      this.updateOrderNumData.num = row.num
      this.updateOrderNumData.userId = row.userId
      this.updateOrderNumData.isPresell = row.isPresell
      this.updateOrderNumData.isCurrentWeighing = row.isCurrentWeighing
      this.orderNumShow = true
    },
    hanleCancel(s) {
      this[s] = false;
    },
    goodsNumChange(e) {
      if (e > this.returnForm.num) {
        // this.$message({
        //   message: "退货数量不能大于购买数量",
        //   type: "warning",
        // });
        // this.$set(this.returnForm, "returnGoodsNum", "");
        // return;
      }
    },
    // 订单退货
    handleRefound(row) {
      this.returnForm = JSON.parse(JSON.stringify(row));
      this.returnShow = true;
    },
    returnSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.returnForm.returnGoodsNum > this.returnForm.num) {
            this.$message({
              message: "退货数量不能大于购买数量",
              type: "warning",
            });
            return
          }
          let needBack = _this.returnForm.needBack ? 1 : 0
          this.$axios({
            url: _this.$axios.defaults.basePath + "/saleChildOrder/orderReturn",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              id: _this.returnForm.id,
              goodsId: _this.returnForm.goodsId,
              returnGoodsNum: _this.returnForm.returnGoodsNum,
              reason: _this.returnForm.reason,
              needBack
            },
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.returnShow = false;
              _this.seeOrder(_this.seeBuyOrderUserId);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    filterNum(value, row, column) {
      return row.num < value;
    },
    //查看订单
    seeOrder(userId) {
      this.seeBuyOrderUserId = userId
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/saleChildOrder/getOrderListByUser", {
          params: {
            userid: this.seeBuyOrderUserId,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.userBuyOrderList = res.data.data
            _this.userBuyOrderShow = true
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.userList = res.data.data;
          }
        });
    },
    findWarehouse(warehouseId) {
      if (!warehouseId) return "";
      const warehouses = warehouseId.split(",");
      return this.warehouseList
        .filter((warehouse) => warehouses.includes(warehouse.id))
        .map((warehouse) => warehouse.name)
        .join(",");
    },
    handleClick() {
      this.resetForm();
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      let json = {
        current: _this.pagesData.currentPage,
        size: _this.pagesData.currentRows,
        name: _this.searchForm.name,
        isTopCarriage: _this.searchForm.isTopCarriage,
        warehouseId: _this.searchForm.warehouseId,
        isOrder: _this.searchForm.isOrder,
        categoryId: _this.searchForm.categoryId,
      };
      // 如果type=5 查询活动商品传值isActivity
      if (Number(_this.activeName) == 5) {
        json.isActivity = 1
      } else {
        json.type = _this.activeName == '0' ? '' : _this.activeName
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall", {
          params: json,
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
              // if (item.pointNum) {
              // }
              item.goodsMallDTOList = item.goodsMallDTOList.map((standard) => {
                standard.point = Math.trunc(
                  standard.salePrice * standard.pointNum
                );
                return standard;
              });
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显产品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取产品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取产品下拉列表
    getSelectGoods() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goods/selectGoodsList")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.selectGoods = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      this.pagesData2.currentRows = val;
      this.getBuysList(this.buyGoodsId, this.buyWarehouseId);
    },
    //第几页
    handleCurrentChange2(val) {
      this.pagesData2.currentPage = val;
      this.getBuysList(this.buyGoodsId, this.buyWarehouseId);
    },
    // 表格复选框
    handleSelectionChange(val, parent) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      parent.selection = val.map((item) => ({
        id: item.id,
        warehouseId: parent.warehouseId,
      }));
      this.ids = this.tableData
        .filter((data) => data.selection)
        .map((data) => data.selection)
        .flat();
    },
    // 表格复选框
    handleSelectionChange3(val, parent) {
      var _this = this;
      console.log(val, parent)
      _this.childIds = [];
      _this.multipleSelection3 = val;
      parent.selection = val.map((item) => ({
        id: item.id,
        warehouseId: parent.warehouseId,
      }));
      this.childIds = this.tableData
        .filter((data) => data.selection)
        .map((data) => data.selection)
        .flat();
      console.log(this.childIds)
    },
    // 新增
    handleAdd() {
      this.$router.push({ name: 'salesGoodsAdd', params: { type: 'add' } })
    },
    checkEdit(row) {
      return row.goodsMallDTOList.some(
        (standard) => standard.isTopCarriage == 1
      );
    },
    // 修改
    handleEdit(row, standardId) {
      console.log(row, standardId);
      if (!row.warehouseId && standardId) {
        this.$message({
          message: "当前产品没有关联仓库，请编辑主产品仓库",
          type: "warning",
        });
        return
      }

      this.standardId = standardId || "";
      // that.loading = true;
      // 判断已上架的产品在下架后不可修改起订量
      if (row.isTopCarriage == 2) {
        this.minimumchange = true;
      }
      // sessionStorage.setItem("typeParams", JSON.stringify(row))
      if (!standardId) {
        this.$router.push({ name: 'salesGoodsAdd', params: { type: 'edit', data: JSON.stringify(row), id: row.id, warehouseId: row.warehouseId, minimumchange: this.minimumchange } })
      } else {
        this.$router.push({ name: 'salesGoodsAdd', params: { type: 'editStandard', data: JSON.stringify(row), standardId: standardId, minimumchange: this.minimumchange } })
      }
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
      this.warehouseId = [];
      this.minimumchange = false;
      this.detailsShow = false;
      this.goodsStandard = [
        {
          standard: "",
          purchasing: "",
          price: "",
          salePrice: "",
          num: "",
          minimum: "",
          limit: "",
          pointNum: "",
          point: "",
          standardImg: [],
        },
      ];
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      _this.loading = true;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const goodsStandard = JSON.parse(JSON.stringify(_this.goodsStandard));

          if (goodsStandard.some((standard) => standard.standardImg.some((img) => img.raw ? !img.response : false))) {
            await this.$confirm("有规格图片尚未上传, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            });
          }
          // for (let i in goodsStandard) {
          //   console.log(goodsStandard[i],"ii")
          // }
          console.log(goodsStandard, "goodsStandardgoodsStandard")
          // return
          let url = "";
          this.submitBtnLoad = true;
          var json = {
            goodsId: _this.addEditForm.goodsId,
            remark: _this.addEditForm.remark,
            deliveryTime: _this.addEditForm.deliveryTime,
            startTime: _this.addEditForm.startTime,
            endTime: _this.addEditForm.endTime,
            type: _this.addEditForm.type,
            warehouseId: _this.addEditForm.warehouseId,
            sort: _this.addEditForm.sort,
            isActivity: _this.addEditForm.isActivity,
            goodsMallFormList: goodsStandard.map((standard) => {
              standard.standardImg = standard.standardImg
                .map((img) => (img.raw ? img.response : img.url))

              // standard.point = Math.trunc(
              //   standard.salePrice * standard.pointNum
              // );
              return standard;
            }),
          };


          if (this.dialogTitle == "新增") {
            url = "/goodsMall/add";
            json.warehouseId = _this.warehouseId.join(",");
          } else if (this.dialogTitle != "添加规格信息" && this.standardId) {
            url = "/goodsMall/update";
            // json.id = _this.addEditForm.id;
            json.warehouseId = _this.warehouseId.join(",");
          } else if (this.dialogTitle == "添加规格信息") {
            url = "/goodsMall/addStandard";
            json = {
              goodsId: _this.addEditForm.goodsId,
              warehouseId: _this.addEditForm.warehouseId,
              goodsMallFormList: goodsStandard.map((standard) => {
                standard.standardImg = standard.standardImg
                  .map((img) => (img.raw ? img.response : img.url))
                  ;
                return standard;
              }),
            };
          } else {
            url = "/goodsMall/editAll";
            json.id = _this.addEditForm.id;
            json.warehouseId = _this.warehouseId.join(",");
          }
          if (this.addEditForm.type == 3 && this.addEditForm.limit == "") {
            this.$message({
              message: "请输入限购份数",
              type: "warning",
            });
            return;
          }
          for (let i in json.goodsMallFormList) {
            json.goodsMallFormList[i].standardImg = json.goodsMallFormList[i].standardImg.join(",")
          }
          if (this.standardId && url == "/goodsMall/update") {
            json = json.goodsMallFormList.find(
              (standard) => standard.id == this.standardId
            );
            this.$confirm('是否同步已截单订单价格?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              json.isSyncOrderPrice = 1
              this.changeGoods(json, url)
            }).catch(() => {
              json.isSyncOrderPrice = 0
              this.changeGoods(json, url)
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + url,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(json),
            }).then((res) => {
              _this.loading = false;
              _this.submitBtnLoad = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.standardId = "";
                _this.dialogFormVisible = false;
                _this.listEvent();
              } else {
                // _this.standardId = "";
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          }
        } else {
          this.submitBtnLoad = false;
          return false;
        }
      });
    },
    changeGoods(json, url) {
      json.warehouseId = this.addEditForm.warehouseId
      let _this = this
      this.$axios({
        url: _this.$axios.defaults.basePath + url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(json),
      }).then((res) => {
        _this.loading = false;
        _this.submitBtnLoad = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.standardId = "";
          _this.dialogFormVisible = false;
          _this.listEvent();
        } else {
          // _this.dialogFormVisible = false;
          // _this.standardId = "";
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      return new Promise((resolve, reject) => {
        if (_this.ids.length > 0 && _this.childIds.length > 0) {
          _this.$message({
            message: '只能选择删除同层级规格',
            type: "warning",
          })
          return
        }

        let ids = _this.ids.length > 0 ? _this.ids : _this.childIds
        _this.$confirm("是否确认删除此产品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "",
        })
          .then(() => {
            _this
              .$axios({
                url: _this.$axios.defaults.basePath + "/goodsMall/delete",
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify(ids),
              })
              .then((res) => {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  resolve();
                  _this.listEvent();
                } else {
                  _this.$message({
                    message: res.data.errmsg,
                    type: "warning",
                  });
                  reject();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
            reject();
          });
      });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      this.$router.push({ name: 'salesGoodsAdd', params: { type: 'details', id: row.id, warehouseId: row.warehouseId } })
    },
    handleBuyList(row, flag) {
      this.buyGoodsId = row.id;
      this.orderListFlag = flag
      this.buyWarehouseId = row.warehouseId;
      let arr = []
      for (let i in row.goodsMallDTOList) {
        arr.push(row.goodsMallDTOList[i])
        if (row.goodsMallDTOList[i].childGoodsMallFormList.length) {
          for (let j in row.goodsMallDTOList[i].childGoodsMallFormList) {
            arr.push(row.goodsMallDTOList[i].childGoodsMallFormList[j])
          }
        }
      }

      this.buyStandardList = arr;
      this.getUserList();
      this.getBuysList(row.id, row.warehouseId);
    },
    // 查看详情获取购买记录
    getBuysList(id, warehouseId) {
      let that = this;
      let data = {}
      //flag 1 是购买记录  2是产品销量
      if (that.orderListFlag == 1) {
        let buyListForm = JSON.parse(JSON.stringify(that.buyListForm))

        data = {
          goodsId: id,
          current: that.pagesData2.currentPage,
          size: that.pagesData2.currentRows,
          warehouseId: warehouseId,
          standard: buyListForm.standard,
          userName: buyListForm.userName,
          isMinimum: buyListForm.isMinimum,
          wayId: buyListForm.wayIdList.length ? buyListForm.wayIdList.join(',') : '',
          isPresell: buyListForm.isPresell,
          isGift: buyListForm.isGift,
          status: 4,
          regionalId: buyListForm.regionalId
        }
      } else {
        let productSaleForm = JSON.parse(JSON.stringify(that.productSaleForm))

        data = {
          goodsId: id,
          current: that.pagesData2.currentPage,
          size: that.pagesData2.currentRows,
          warehouseId: warehouseId,
          standard: productSaleForm.standard,
          userName: productSaleForm.userName,
          isMinimum: productSaleForm.isMinimum,
          wayId: productSaleForm.wayIdList.length ? productSaleForm.wayIdList.join(',') : '',
          isPresell: productSaleForm.isPresell,
          isGift: productSaleForm.isGift,
          status: productSaleForm.statusList.length ? productSaleForm.statusList.join(',') : '',
          startTime: that.datePicker.length ? that.datePicker[0] : '',
          endTime: that.datePicker.length ? that.datePicker[1] : '',
          regionalId: productSaleForm.regionalId
        }
      }

      // 获取商品购买记录
      that.$axios
        .get(that.$axios.defaults.basePath + "/goodsMall/orderList", {
          params: data,
        })
        .then((rea) => {
          if (rea.data.errcode == 0) {
            if (rea.data.data) {
              that.buysListData = rea.data.data.list.records;
              that.buyNum = rea.data.data.buyNum;
              that.orderNum = rea.data.data.orderNum;
              that.pagesData2.total = rea.data.data.list.total;
              if (that.orderListFlag == 1) {
                that.buyListShow = true;
              } else {
                that.productSaleShow = true;
              }
            } else {
              that.$message({
                message: "暂无数据！",
                type: "warning",
              });
            }
          }

        });
    },
    // 检索
    buySearchSubmit() {
      //flag 1 是购买记录  2是产品销量
      if (this.orderListFlag == 1) {
        var treedata = this.$refs.buyListTree.getCheckedNodes();
        console.log(treedata, "treedatatreedata")
        let ids = []
        for (let i in treedata) {
          if (!treedata[i].children.length) {
            ids.push(treedata[i].value)
          }
        }
        if (ids && ids.length) {
          this.buyListForm.regionalId = ids.join(",")
        } else {
          this.buyListForm.regionalId = ""
        }
        let standardList = JSON.parse(JSON.stringify(this.buyListForm.standardList))
        if (standardList && standardList.length) {
          this.buyListForm.standard = standardList.join(',')
        } else {
          this.buyListForm.standard = ""
        }


      } else {
        var treedata = this.$refs.productSaleTree.getCheckedNodes();
        let ids = []
        for (let i in treedata) {
          if (!treedata[i].children.length) {
            ids.push(treedata[i].value)
          }
        }
        if (ids && ids.length) {
          this.productSaleForm.regionalId = ids.join(",")
        } else {
          this.productSaleForm.regionalId = ""
        }

        let standardList = JSON.parse(JSON.stringify(this.productSaleForm.standardList))
        if (standardList && standardList.length) {
          this.productSaleForm.standard = standardList.join(',')
        } else {
          this.productSaleForm.standard = ""
        }

      }
      this.pagesData2.currentPage = 1;
      this.pagesData2.currentRows = 10;
      this.getBuysList(this.buyGoodsId, this.buyWarehouseId);
    },

    // 检索重置
    buyResetForm(formName) {
      Object.keys(this[formName]).forEach((key) => {
        this[formName][key] = "";
      });
      this.buyListRegionalList = []
      this.productSaleRegionalList = []
      this.pagesData2.currentPage = 1;
      this.pagesData2.currentRows = 10;
      this.datePicker = []
      this[formName].statusList = []
      this[formName].wayIdList = []
      this[formName].standardList = []
      this.getBuysList(this.buyGoodsId, this.buyWarehouseId);
    },
    // 上架
    handleUp(row, all, child) {
      let _this = this;
      _this.loading = true;
      this.downWarehouseId = props ? props.warehouseId : row.warehouseId
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          (all ? "/goodsMall/upDownAll" : "/goodsMall/upDown"),
          {
            params: {
              id: row.id,
              isTopCarriage: 1,
              warehouseId: row.warehouseId ? row.warehouseId : child.warehouseId,
            },
          }
        )
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 下架
    handleDown(row, all, props) {
      let _this = this;
      let url = "";

      this.downType = all;
      if (all == 2) {
        url = "/goodsMall/upDown";
        this.downWarehouseId = props.warehouseId;
        // 判断当前规格是否属于热销现货类型 不是则弹出提示;
        if (props.type != 4) {
          this.upDownShow = true;
          this.downRowData = row;
        } else {
          _this.loading = true;
          this.$axios
            .get(_this.$axios.defaults.basePath + url, {
              params: {
                id: row.id,
                isTopCarriage: 2,
                warehouseId: _this.downWarehouseId,
              },
            })
            .then((res) => {
              _this.loading = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        }
      } else {
        url = "/goodsMall/upDownAll";
        this.downWarehouseId = row.warehouseId;
        // 判断当前规格是否属于热销现货类型 不是则弹出提示;
        if (row.type != 4) {
          this.upDownShow = true;
          this.downRowData = row;
        } else {
          this.$axios
            .get(_this.$axios.defaults.basePath + url, {
              params: {
                id: row.id,
                isTopCarriage: 2,
                warehouseId: _this.downWarehouseId,
              },
            })
            .then((res) => {
              _this.loading = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        }
      }
    },
    // 执行下架/截单操作 type =1 下架 2截单
    downEvent(type) {
      let _this = this;
      _this.loading = true;
      let status = type;
      let isTopCarriage = 2;
      if (type == 2) {
        isTopCarriage = 3
      } else {
        isTopCarriage = 2
      }
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          (_this.downType == 1
            ? "/goodsMall/upDownAll"
            : "/goodsMall/upDown"),
          {
            params: {
              id: _this.downRowData.id,
              isTopCarriage: isTopCarriage,
              status: status,
              warehouseId: _this.downWarehouseId,
            },
          }
        )
        .then((res) => {
          _this.loading = false;
          _this.upDownShow = false;
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    handleChange(list, index) {
      console.log(list, "执行了111")
      this.$set(
        this.goodsStandard,
        index,
        Object.assign({}, this.goodsStandard[index], {
          standardImg: list,
        })
      );
    },
    handleSuccess(list, index) { },
    //上传图片事件
    handleAvatarSuccess(res, file, index) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.$set(
        this.goodsStandard,
        index,
        Object.assign({}, this.goodsStandard[index], {
          standardImg: [
            ...this.goodsStandard[index].standardImg,
            { url: file.response },
          ],
        })
      );
    },
    // 上传图片删除
    handleRemove(file, index) {
      let url = file.response || file.url;
      const standardImg = this.goodsStandard[index].standardImg.filter(
        (img) => img.url != url
      );
      this.$set(
        this.goodsStandard,
        index,
        Object.assign({}, this.goodsStandard[index], {
          standardImg,
        })
      );
    },
    // 修改乡兴供货价
    changeSalePrice(e) {
      if (!this.addEditForm.pointNum) return;
      this.addEditForm.point = Math.trunc(e * this.addEditForm.pointNum);
    },
    // 积分倍数
    pointNumInput(data, index) {
      const e = data.pointNum;
      // 价格取整
      if (e < 0) this.$set(this.goodsStandard[index], "pointNum", 0);
      if (!data.salePrice) return;
      const point = Math.trunc(data.salePrice * e);
      this.$set(this.goodsStandard[index], "point", point);
    },

    checkExist() {
      if (!(this.addEditForm.warehouseId && this.addEditForm.id)) return;
      let _this = this;
      // 判断当前用户是否有该商品的有效订单
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/checkGoodsMall", {
          params: {
            goodsId: this.addEditForm.id,
            warehouseId: this.addEditForm.warehouseId,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            // 如果存在该商品的订单且未过期则弹出该订单详情进行编辑
            if (res.data.data) {
              _this
                .$confirm("当前列表已有此商品，请前往该商品进行编辑", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  _this.dialogFormVisible = false;
                  // 根据ID调用编辑事件显示弹框
                  // _this.handleEdit({ id: res.data.data });
                })
                .catch(() => {
                  _this.$message({
                    type: "info",
                    message: "已取消",
                  });
                  _this.dialogFormVisible = false;
                });
            } else {
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 新增时仓库多选
    changeWarehouse(e) {
      this.warehouseId = e;
      this.$set(this.addEditForm, "warehouseId", e.join(","));
      if (this.dialogTitle == "新增") this.checkExist();
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.isTopCarriage == 1) {
        //已上架状态不可选中
        return false;
      } else {
        return true;
      }
    },


    // 删除规格
    async delGoodsType(index, id, warehouseId) {
      const _warehouseId =
        typeof warehouseId == "object" ? warehouseId[0] : warehouseId;
      if (id) {
        this.ids = [{ id: id, warehouseId: _warehouseId }];
        await this.handleDel();
      }
      this.goodsStandard.splice(index, 1);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
    },
    tableRowClassName(row) {
      return "rowStyle";
    },
    // 下拉选择产品
    changeGoodsEvents(e) {
      if (e) {
        var goods = {};
        this.selectGoods.forEach((item) => {
          if (item.id == e) {
            goods = item;
          }
        });
        this.$set(this.addEditForm, "id", e);
        // this.addEditForm.categoryId = goods.categoryId;
        // this.$set(this.addEditForm, "categoryId", goods.categoryId);
        this.goodsTypeId2 = this.familyTree(
          this.goodsType,
          goods.categoryId
        ).reverse();
        this.addEditForm.unitId = goods.unitId;
        this.addEditForm.color = goods.color;
        this.addEditForm.model = goods.model;

        this.addEditForm.mfrs = goods.mfrs;
        this.addEditForm.enabled = goods.enabled.toString();
        this.$set(this.addEditForm, "standard", goods.standard);
        this.fileList = [];
        if (goods.imgs) {
          let imgArr = goods.imgs.split(",");
          this.imgData = imgArr;
          imgArr.forEach((item) => {
            let json = {
              url: item,
            };
            this.fileList.push(json);
          });
        }
        if (this.dialogTitle == "销售产品新增") this.checkExist();
      }
    },
    // 选择订单类型
    changeOrderType(e) {
      if (e == 1 || e == 2) {
        //限时、限量
        this.$set(this.addEditForm, "limit", 1);
      } else if (e == 3) {
        //限购订单
        this.$set(this.addEditForm, "minimum", 1);
      } else if (e == 4) {
        //热销现货
        this.$set(this.addEditForm, "limit", 1);
        this.$set(this.addEditForm, "minimum", 1);
        this.$set(this.addEditForm, "startTime", "");
        this.$set(this.addEditForm, "endTime", "");
      }
    },
    // 添加产品规格
    addGoodsType() {
      // goodsStandard
      let json = {
        code: '',
        standard: "",
        purchasing: "",
        price: "",
        salePrice: "",
        num: "",
        minimum: "",
        limit: "",
        // 隐藏积分相关功能
        // pointNum: "",
        // point: "",
        standardImg: [],
      };
      this.goodsStandard.unshift(json);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
    },
    // 产品上架状态新增规格
    addStandard(row) {
      // let _this = this;
      // this.standardId = "";
      // this.goodsStandard = [];
      // this.addEditForm = {};
      // let id = row.goodsMallDTOList[0].id;
      // let json = {
      //   code:'',
      //   standard: "",
      //   purchasing: "",
      //   price: "",
      //   salePrice: "",
      //   num: "",
      //   minimum: "",
      //   limit: "",
      //   // 隐藏积分相关功能
      //   // pointNum: "",
      //   // point: "",
      //   standardImg: [],
      // };
      // this.standardId = json;
      // this.dialogTitle = "添加规格信息";
      // this.goodsStandard.push(json);
      // this.$set(this.addEditForm, "goodsId", id);
      // this.$set(this.addEditForm, "warehouseId", row.warehouseId);
      // this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
      // this.dialogFormVisible = true;
      this.$router.push({ name: 'salesGoodsAdd', params: { type: 'goodsStandard', id: row.id, isActivity: row.isActivity, warehouseId: row.warehouseId, goodsId: row.goodsMallDTOList[0].id } })
    },
    // 修改起订量
    changeMinimum(e) {
      if (Number(e) < 0) {
        this.$message({
          message: "起订量不能小于0",
          type: "warning",
        });
        this.addEditForm.minimum = 0;
      } else if (
        Number(this.addEditForm.num) &&
        Number(e) > Number(this.addEditForm.num)
      ) {
        this.$message({
          message: "起订量不能大于库存量",
          type: "warning",
        });
        this.addEditForm.minimum = 0;
      }
    },
    // 批量排序
    handleSort() {
      let _this = this;
      this.warehouseList.forEach(item => {
        if (_this.adcode == item.adcode) {
          _this.sortWarehouseId = item.id;
          _this.getListByUser()
        }
      })
      this.sortShow = true;
    },
    // 排序前选择所属仓库
    sortChangeWarehouse(e) {
      let _this = this;
      this.warehouseList.forEach(item => {
        if (e == item.id) {
          _this.adcode = item.adcode
          _this.sortWarehouseId = item.id;
          _this.getListByUser()
        }
      })
    },
    // 获取上架的销售产品
    getListByUser() {
      let _this = this;
      this.loading = true;
      this.$axios
        .get(this.$axios.defaults.basePath + '/goodsMall/getEditSortList?warehouseId=' + _this.sortWarehouseId)
        .then(res => {
          console.log(res)
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.forEach(item => {
              if (item.imgs.indexOf(',') != -1) {
                item.imgs = item.imgs.split(',')
              } else {
                item.imgs = [item.imgs]
              }
            })
            _this.goodsUpList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        })
    },
    // 拖拽排序事件
    dragstart(index) {
      this.dragIndex = index;
    },
    dragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (this.dragIndex !== index) {
        const source = this.goodsUpList[this.dragIndex];
        this.goodsUpList.splice(this.dragIndex, 1);
        this.goodsUpList.splice(index, 0, source);
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex = index;
      }
    },
    dragover(e, index) {
      e.preventDefault();
    },

    // 排序提交
    sortSubmit() {
      let _this = this;
      let data = [];
      this.submitBtnLoad = true;
      this.goodsUpList.forEach((item, index) => {
        let json = {
          goodsId: item.id,
          sort: index,
          warehouseId: item.warehouseId
        }
        data.push(json)
      });
      this.$axios({
        url: this.$axios.defaults.basePath + '/goodsMall/editSort',
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        data: JSON.stringify(data)
      }).then(res => {
        console.log(res)
        _this.submitBtnLoad = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: '操作成功',
            type: 'success'
          });
          _this.sortShow = false;
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }
      });
    },

    handleEditNum(row) {
      this.editNumShow = true;
      this.formData = row;
    },

    handleSubmit() {
      var _this = this;
      if (_this.formData.num == '') {
        _this.$message.error('请输入库存数量');
        return false;
      } else {
        this.$axios.get(_this.$axios.defaults.basePath + '/goodsMall/updateNum', {
          params: {
            id: _this.formData.id,
            num: _this.formData.num,
          }
        }).then(function (res) {
          if (res.data.errcode == 0) {
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.editNumShow = false;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        })
      }
    },

    cancelAdd(s) {
      this.editNumShow = false
      this[s] = false
    }

  },
};
</script>
<style>
/* .rowStyle >>>.el-table--enable-row-transition .el-table__body td.el-table__cell{
  background-color: #f5f5f5;
} */
.el-table__row.expanded + tr,
.el-table__row.expanded + tr td,
.el-table__row.expanded + tr td .el-table,
.el-table__row.expanded + tr td .el-table tr,
.el-table__row.expanded + tr td .el-table tr th {
  background-color: #f5f5f5 !important;
}
</style>
<style scoped>
.buys_total >>> .el-button {
  white-space: normal;
  text-align: left;
  margin: 8px 20px 8px 0;
}
.buys_total >>> .el-button span {
  display: inline-block;
  line-height: 20px;
}
.boxMain >>> .el-image img {
  height: auto !important;
}
.goods_buys {
  padding: 0 30px;
  margin-top: 15px;
  box-sizing: border-box;
}
.goods_buys .buys_title {
  line-height: 1.5rem;
  font-size: 1.125rem;
  color: #303133;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.buys_total p {
  margin-right: 20px;
}
.titleBox {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.titleBox p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 24px;
  border-left: 4px solid #409eff;
}
.standard_box .standard_list {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px dashed #333;
}
.standard_box > div:first-child .standard_list {
  border-top: none !important;
}
.sort_goods .list_title {
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
}
.sort_goods .list_title div {
  width: 11%;
}
.sort_goods .list li {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
}
.sort_goods .list li div {
  width: 11%;
}
</style>
