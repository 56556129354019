<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="所属乡镇">
          <area-cascader :areaValue="searchTowns" :isStatus="false" :allLevel="allLevel" @areaChangeAll="areaChange">
          </area-cascader>
        </el-form-item>
        <el-form-item label="用户" prop="userId">
          <!-- @change="changeUser" -->
          <el-select v-model="searchForm.userid" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品">
          <el-select filterable v-model="goodsIds" multiple clearable :reserve-keyword="true" placeholder="请选择商品（可多选）" style="width: 250px" @change="searchGoodsMore">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-cascader ref="typeCascader" style="width: 150px" clearable :options="goodsType" v-model="searchForm.categoryId" @change="changeGoodsType" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }"></el-cascader>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 100px" v-model="searchForm.status" clearable placeholder="请选择状态">
            <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="商品单位"> -->
        <!-- @change="searchUnitChange" -->
        <!-- <el-select v-model="searchForm.unit" style="width: 150px" clearable>
            <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="颜色">
          <el-input v-model="searchForm.color" clearable placeholder="请输入颜色" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="searchForm.model" clearable placeholder="请输入型号" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="searchForm.standard" clearable placeholder="请输入规格" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input v-model="searchForm.mfrs" clearable placeholder="请输入制造商" style="width: 150px"></el-input>
        </el-form-item> -->
        <el-form-item label="选择路线" prop="wayId">
          <el-select v-model="wayIds" multiple filterable clearable style="width: 200px">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-cascader ref="tree" style="width: 300px" :options="regionalRouteData" v-model="searchRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="是否为预售单">
          <el-select style="width: 200px" v-model="searchForm.isPresell" clearable placeholder="请选择是否为预售单">
            <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为现称单">
          <el-select style="width: 200px" v-model="searchForm.isCurrentWeighing" clearable placeholder="请选择是否为现称单">
            <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为赠品单">
          <el-select style="width: 200px" v-model="searchForm.isGift" clearable placeholder="请选择是否为赠品单">
            <el-option v-for="(item, index) in isPresellList" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">销售单列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="primary" @click="pointPush" v-if="transferButton == '1'" :disabled="ids.length == 0">批量完成</el-button> <!-- 隐藏发放积分文字 -->
          <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="uploadOrder" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl2" :before-upload="beforeAvatarUpload2" :limit="1" :on-success="handleAvatarSuccess2" :on-error="handleAvatarErr2" :on-remove="handleRemove" :headers="importHeaders" v-if="updateButton == '1'">
            <el-button type="primary" class="el-button--mini" v-loading="loading3">导入订单</el-button>
          </el-upload>
          <el-popover placement="top" trigger="hover">
            <el-button type="text" @click="exportEvent(0)">订单导出</el-button>
            <el-button type="text" @click="exportEvent(1)">订单汇总导出</el-button>
            <el-button slot="reference" style="margin:0 10px" size="mini" type="primary" v-if="exportButton == '1'">订单导出</el-button>
          </el-popover>
          <el-button size="mini" type="danger" @click="handleCloseOrder" :disabled="ids.length == 0" v-if="updateButton == '1'">热销品截单</el-button>
          <el-button size="mini" type="primary" @click="printShow=true" v-if="exportButton == '1'">打印</el-button>
          <el-button size="mini" type="primary" @click="selfPrintShow=true" v-if="exportButton == '1'">自提打印</el-button>
          <el-button size="mini" type="primary" @click="exportPrint" v-if="exportButton == '1'">打印记录</el-button>
          <el-button size="mini" type="primary" @click="updateOrderStatus" v-if="transferButton == '1'" :disabled="ids.length == 0">批量修改订单状态</el-button>
          <el-button size="mini" type="danger" @click="batchReturnShow=true;" v-if="updateButton == '1'">批量取消</el-button>
          <el-button size="mini" type="primary" @click="exportPrintTotal" v-if="updateButton == '1'">导出打印订单汇总表</el-button>
          <el-button size="mini" type="primary" @click="oneClickCompletedShow = true;getDeliveryDate(1)" v-if="updateButton == '1'">一键完成</el-button>
          <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl" :before-upload="beforeAvatarUpload" :limit="1" :on-success="handleAvatarSuccess" :on-error="handleAvatarErr" :on-remove="handleRemove" :headers="importHeaders" v-if="updateButton == '1'">
            <el-button type="primary" class="el-button--mini" v-loading="loading2">导入修改订单</el-button>
          </el-upload>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectStatus">
            </el-table-column>
            <el-table-column prop="icons" label="商品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px;height:60px;" :src="scope.row.icons">
                  </el-image>
                </div>
                <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column prop="describe" label="商品描述" width="130px" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="wechat" label="用户昵称" show-overflow-tooltip> </el-table-column>
            <!-- <el-table-column prop="categoryName" label="类型">
            </el-table-column> -->
            <el-table-column prop="standard" label="规格"> </el-table-column>
            <!-- <el-table-column prop="model" label="型号"> </el-table-column> -->
            <!-- <el-table-column prop="unit" label="单位"> </el-table-column> -->
            <!-- <el-table-column prop="mfrs" label="制造商"> </el-table-column> -->
            <el-table-column prop="num" label="商品数量" :filter-multiple="false" :filters="[{ text: '小于10', value: '10' }, { text: '小于20', value: '20' }]" :filter-method="filterNum" width="90"> </el-table-column>
            <el-table-column prop="price" label="价格">
              <template slot-scope="scope">
                {{ scope.row.price }} 元
              </template>
            </el-table-column>
            <!-- 隐藏积分功能 -->
            <!-- <el-table-column prop="point" label="积分"> </el-table-column> -->
            <el-table-column prop="totalPrice" label="订单总价">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="type" label="订单类型">
              <template slot-scope="scope">
                {{ scope.row.type == 1 ? "限时订单" : scope.row.type == 2 ? "限量订单" : scope.row.type == 3 ? "限购订单" : "热销现货" }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为预售单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isPresell == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为现称单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isCurrentWeighing == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="type" label="是否为赠品单" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.isGift == 1 ? "是" : "否"  }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <!-- <el-table-column prop="startTime" label="开始时间" min-width="150">
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间" min-width="150">
            </el-table-column>
            <el-table-column
              prop="deliveryTime"
              label="配送时间"
              min-width="150"
            >
            </el-table-column> -->
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  已下单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
                  退货
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #333">
                  取消
                </p>
                <p v-else-if="scope.row.status == 3" style="color: #67c23a">
                  已完成
                </p>
                <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
                  已截单
                </p>
                <p v-else-if="scope.row.status == 5" style="color: red">
                  已出库
                </p>
                <p v-else-if="scope.row.status == 6" style="color: #67c23a">
                  已配送
                </p>
                <p v-else-if="scope.row.status == 9" style="color: red">
                  已结账
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="way" width="80" label="路线" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="created" width="160" label="下单时间">
            </el-table-column>
            <el-table-column prop="outboundDeliveryTime" width="160" label="出库时间">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 4"></p>
                <p v-else>{{ scope.row.outboundDeliveryTime }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="completeTime" width="160" label="完成时间">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 4 || scope.row.status == 5"></p>
                <p v-else>{{ scope.row.completeTime }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" width="450" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="editPrice(scope.row,1)" :disabled="scope.row.status == 9||scope.row.status == 2||scope.row.status == 1" v-if="searchButton == '1'">编辑总价</el-button>
                <el-button size="mini" type="primary" @click="handleDetails(scope.row)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" :disabled="scope.row.status != 4||scope.row.isGift==1 || scope.row.status == 9">编辑</el-button>
                <el-popover placement="right" trigger="hover">
                  <el-button type="text" @click="handleRefound(scope.row,0)">退货退款</el-button>
                  <el-button type="text" @click="handleRefound(scope.row,1)">退货无需退款</el-button>
                  <el-button type="text" @click="handleRefound(scope.row,2)">仅退款</el-button>
                  <el-button slot="reference" size="mini" style="margin:0 10px" type="primary" v-if="searchButton == '1'" :disabled="scope.row.isOutbound==0||(scope.row.status == 1 || scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 9)||scope.row.isGift==1">退货</el-button>
                </el-popover>

                <el-button size="mini" type="danger" @click="handleCancelEvent(scope.row)" v-if="searchButton == '1'" :disabled="scope.row.status != 4 || scope.row.status == 9 ||scope.row.isGift==1">取消</el-button>
                <el-button size="mini" type="primary" @click="currentWeighingOrder(scope.row)" v-if="searchButton == '1'" :disabled="!(scope.row.isCurrentWeighing==1&&scope.row.status == 5)||scope.row.isGift==1 || scope.row.status == 9">现称</el-button>
                <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleCloseOrder(scope.row.id)"
                  v-if="scope.row.type == 4"
                  :disabled="scope.row.status == 4"
                  >截单</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false" :close="addEditCancel">
      <el-form :model="addEditForm" ref="addEditForm" :inline="true" label-width="120px" v-if="addEditForm.isCurrentWeighing==1&&dialogTitle=='编辑'">
        <el-form-item label="备注：">
          <el-input style="width: 300px" type="text" v-model="addEditForm.remark" @input="changeNum($event,'addEditForm')"></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" v-else label-width="120px">
        <el-form-item label="选择仓库" prop="adcode" v-if="dialogTitle=='新增'">
          <el-select v-model="addEditForm.warehouseId" filterable style="width: 300px" :disabled="detailsShow || dialogTitle == '编辑'" placeholder="可搜索选项" @change="changeWarehouse">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" prop="id" v-if="addEditForm.warehouseId">
          <el-select v-model="addEditForm.id" filterable style="width: 300px" placeholder="可搜索选项" :disabled="detailsShow || dialogTitle == '编辑'" @change="changeGoodsEvents">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="addEditForm.userId" multiple filterable style="width: 300px" :disabled="detailsShow || dialogTitle == '编辑'" placeholder="可搜索选项" @change="changeUser">
            <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" style="width:100%">
          <el-table :data="addEditForm.userNumList" style="width: 100%">
            <el-table-column label="用户" width="400">
              <template slot-scope="scope">
                <el-select v-model="scope.row.userId" style="width: 300px" disabled placeholder="可搜索选项">
                  <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
                  </el-option>
                </el-select>
              </template>

            </el-table-column>
            <el-table-column prop="name" label="商品数量" width="400">
              <template slot-scope="scope">
                <el-input style="width: 300px" type="text" :disabled="detailsShow" v-model="scope.row.num" :placeholder="
                  addEditForm.id ? '剩余库存' + saleGoodsInfo.num : '请输入商品数量'
                " @input="changeGoodsNum($event)"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="是否是预售单" style="width:100%">
          <el-switch style="display: block;margin-top:8px" v-model="addEditForm.isPresell" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" active-text="是" inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否是现称单" style="width:100%">
          <el-switch style="display: block;margin-top:8px" v-model="addEditForm.isCurrentWeighing" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" active-text="是" inactive-text="否">
          </el-switch>
        </el-form-item>
        <!-- <el-form-item label="商品数量" prop="num">
          <el-input style="width: 300px" type="text" :disabled="detailsShow" v-model="addEditForm.num" :placeholder="
            addEditForm.id ? '剩余库存' + saleGoodsInfo.num : '请输入商品数量'
          " @input="changeGoodsNum"></el-input>
        </el-form-item> -->
        <el-form-item label="订单类型" v-if="addEditForm.id">
          <el-select v-model="saleGoodsInfo.type" placeholder="请选择订单类型" style="width: 300px" :disabled="true">
            <el-option v-for="(item, index) in orderType" :key="index" :label="item.label" :value="item.id" :disabled="true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起订量" v-if="saleGoodsInfo.type !=3">
          <el-input style="width: 300px" type="text" :disabled="true" v-model="saleGoodsInfo.minimum"></el-input>
        </el-form-item>
        <el-form-item label="限购量" v-if="saleGoodsInfo.type ==3">
          <el-input style="width: 300px" type="text" :disabled="true" v-model="saleGoodsInfo.limit"></el-input>
        </el-form-item>
        <el-form-item label="商品类型" v-if="addEditForm.id">
          <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" :disabled="true" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="商品单位" v-if="addEditForm.id">
          <el-select v-model="saleGoodsInfo.unitId" style="width: 300px" @change="unitChange" :disabled="true">
            <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.color" placeholder="请输入颜色" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="型号" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.model" placeholder="请输入型号" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="规格" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.standard" placeholder="请输入规格" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="制造商" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="进货价(元)" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.purchasing" placeholder="请输入进货价" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="单价(元)" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.price" placeholder="请输入单价" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="总价(元)" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.totalPrice" placeholder="请输入总价" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <!-- 隐藏积分功能 -->
        <!-- <el-form-item label="积分(参考值)" v-if="addEditForm.id">
          <el-input
            v-model="saleGoodsInfo.point"
            placeholder="请输入积分倍数"
            style="width: 300px"
            :disabled="true"
          ></el-input>
        </el-form-item> -->

        <!-- <el-form-item
          label="开始时间"
          v-if="addEditForm.id && saleGoodsInfo.type == 1"
        >
          <el-date-picker
            v-model="saleGoodsInfo.startTime"
            type="datetime"
            style="width: 300px"
            :disabled="true"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="结束时间"
          v-if="addEditForm.id && saleGoodsInfo.type == 1"
        >
          <el-date-picker
            v-model="saleGoodsInfo.endTime"
            type="datetime"
            style="width: 300px"
            :disabled="true"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="配送时间" v-if="addEditForm.id">
          <el-date-picker v-model="saleGoodsInfo.deliveryTime" type="date" style="width: 300px" :disabled="true" value-format="yyyy-MM-dd" placeholder="选择配送时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" v-if="addEditForm.id">
          <el-input type="textarea" v-model="saleGoodsInfo.remark" placeholder="请输入备注" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <!-- <el-form-item label="描述" v-if="addEditForm.id">
          <el-input type="textarea" v-model="saleGoodsInfo.describe" placeholder="请输入描述" style="width: 300px" :rows="6" :disabled="true"></el-input>
        </el-form-item> -->
        <el-form-item label="活动规则" v-if="saleGoodsInfo.rules && saleGoodsInfo.rules.length>0">
          <p v-for="(item,i) in saleGoodsInfo.rules" :key="i">{{ item }}</p>
        </el-form-item>
        <el-form-item label="主订单信息" style="width:100%" v-if="saleGoodsInfo.isGift==1">
          <el-table :data="saleGoodsInfo.mainOrderList" style="width: 100%;margin-top:50px">
            <el-table-column prop="title" label="商品名称" width="200"></el-table-column>
            <el-table-column prop="standard" label="规格" width="200"></el-table-column>
            <el-table-column prop="num" label="数量" width="200"></el-table-column>
            <el-table-column prop="price" label="价格" width="200">
              <template slot-scope="scope">
                {{scope.row.price}}元
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" :loading="submitBtnLoad" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 订单导出 -->
    <el-dialog title="订单导出" :visible.sync="exportShow" width="65%" :close-on-click-modal="false" :close="hanleCancel(exportShow)">
      <el-form :model="exportForm" ref="exportForm" :inline="true" label-width="150px">
        <el-form-item label="所属乡镇" v-if="exportForm.type!=1">
          <area-cascader :isStatus="false" :allLevel="allLevel" @areaChangeAll="areaChange"></area-cascader>
        </el-form-item>
        <el-form-item label="选择用户" prop="userId">
          <!-- @change="changeUser" -->
          <el-select v-model="exportForm.userids" filterable multiple clearable style="width: 365px" placeholder="可搜索选项" @change="exportUserChange">
            <el-option v-for="item in userList" :key="item.id" :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- status -->
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="exportForm.statuss" multiple clearable @change="changeOrderStatus" style="width: 300px">
            <el-option v-for="(item, index)  in orderStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品">
          <el-select v-model="exportForm.goodsIds" multiple filterable clearable style="width: 300px" placeholder="可搜索选项" @change="exportGoodsChange">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" size="mini" @click="selectAll" round style="margin-left:10px">全选</el-button>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-cascader ref="exportTree" style="width: 300px" :options="regionalRouteData" v-model="exportRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="选择路线：" prop="wayId">
          <el-select v-model="exportForm.wayIds" multiple filterable clearable style="width: 300px">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型" v-if="exportForm.type!=1">
          <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="exportCategoryId" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="商品单位" v-if="exportForm.type!=1">
          <el-select v-model="exportForm.unit" style="width: 300px" @change="unitChange" clearable>
            <el-option v-for="(item, index)  in goodsUnit" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" v-if="exportForm.type!=1">
          <el-input v-model="exportForm.color" placeholder="请输入颜色" style="width: 300px" clearable></el-input>
        </el-form-item>
        <el-form-item label="型号" v-if="exportForm.type!=1">
          <el-input v-model="exportForm.model" placeholder="请输入型号" style="width: 300px" clearable></el-input>
        </el-form-item>
        <el-form-item label="规格" v-if="exportForm.type!=1">
          <el-input v-model="exportForm.standard" placeholder="请输入规格" style="width: 300px" clearable></el-input>
        </el-form-item>
        <el-form-item label="制造商" v-if="exportForm.type!=1">
          <el-input v-model="exportForm.mfrs" placeholder="请输入制造商" style="width: 300px" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <div style="width:300px"></div>
        </el-form-item>
        <el-form-item label="订单创建开始时间" v-if="exportForm.type!=1">
          <el-date-picker v-model="exportForm.startTime" type="datetime" style="width: 300px" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单创建结束时间" v-if="exportForm.type!=1">
          <el-date-picker v-model="exportForm.endTime" type="datetime" style="width: 300px" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单完成开始时间" v-if="exportForm.type!=1">
          <el-date-picker v-model="exportForm.completeStartTime" type="datetime" style="width: 300px" value-format="yyyy-MM-dd HH:mm:ss" placeholder="只有订单状态是完成状态才可生效" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单完成结束时间" v-if="exportForm.type!=1">
          <el-date-picker v-model="exportForm.completeEndTime" type="datetime" style="width: 300px" value-format="yyyy-MM-dd HH:mm:ss" placeholder="只有订单状态是完成状态才可生效" clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false; exportForm = {};
        exportCategoryId = []; exportGoodsId = []; ">取 消</el-button>
        <el-button @click="exportQK">清 空</el-button>
        <el-button type="primary" @click="orderExport">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 退货 -->
    <el-dialog :title="returnTlt" :visible.sync="returnShow" :close-on-click-modal="false" width="35%">
      <el-form :model="returnForm" ref="returnForm" :rules="returnRules" :inline="true" label-width="120px">
        <el-form-item label="用户昵称">
          <el-input style="width: 300px" v-model="returnForm.wechat" placeholder="请输入商品名称" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input style="width: 300px" v-model="returnForm.standard" placeholder="请输入商品名称" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input style="width: 300px" v-model="returnForm.title" placeholder="请输入商品名称" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="购买数量">
          <el-input style="width: 300px" v-model="returnForm.num" placeholder="请输入购买数量" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="退款金额">
          <!-- <el-input></el-input> -->
          <el-input style="width: 300px" v-model="returnForm.totalSalePrice" :disabled='returnForm.refundType==0||returnForm.refundType==1' placeholder="请输入退款金额"></el-input>
        </el-form-item>
        <el-form-item label="退货数量" prop="returnGoodsNum">
          <el-input style="width: 300px" type="text" min="0" :max="returnForm.num" @input="goodsNumChange" :disabled="returnForm.refundType==2" v-model="returnForm.returnGoodsNum" placeholder="请输入商品数量"></el-input>
        </el-form-item>
        <el-form-item label="退货原因">
          <el-input style="width: 300px" type="textarea" v-model="returnForm.reason" placeholder="请输入退货原因"></el-input>
        </el-form-item>
        <el-form-item label="选择日期">
          <el-date-picker style="width: 300px" v-model="returnForm.created" type="datetime" clearable value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否需要带回" v-if="returnForm.status == 3 || returnForm.status==4||returnForm.status==5||returnForm.status==6">
          <el-switch v-model="returnForm.needBack" :disabled="returnForm.refundType==2" active-text="是" inactive-text="否">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hanleCancel('returnShow')">取 消</el-button>
        <el-button type="primary" @click="returnSubmit('returnForm')">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 打印 -->
    <el-dialog title="打印" v-if="printShow" :visible.sync="printShow" width="80%" :close-on-click-modal="false">
      <selectPrintOrder @over="over" @driver="driver"></selectPrintOrder>
      <!-- 打印确认弹框 -->
      <el-dialog title="打印" v-if="printFixShow" :visible.sync="printFixShow" width="80%" append-to-body :close-on-click-modal="false">
        <printFix ref="printFix" :driverInfo="driverInfo" :printData="printData" @over="over" @overPrintShow="overPrintShow"></printFix>
      </el-dialog>
    </el-dialog>
    <!-- 自提打印 -->
    <el-dialog title="自提" v-if="selfPrintShow" :visible.sync="selfPrintShow" width="80%" :close-on-click-modal="false">
      <self-print @addOver="addOver2"></self-print>
    </el-dialog>
    <div style="display:none">
      <print ref="print" :printOrderData="printOrderData"></print>
    </div>
    <div style="display:none">
      <print-region ref="printRegion" :printOrderData="printRegionOrderData"></print-region>
    </div>
    <!-- 导出打印记录 -->
    <el-dialog title="打印记录" v-if="exportPrintShow" :visible.sync="exportPrintShow" width="30%" :close-on-click-modal="false">
      <el-form :model="exportPrintForm" ref="exportPrintForm" :inline="true" label-width="120px">
        <el-form-item label="选择记录">
          <el-select v-model="exportPrintForm.id" @change="changeExportPrint" multiple filterable style="width: 300px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in printRecords" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <!-- <el-button type="primary" @click="printExport">导出</el-button> -->
        <el-button type="primary" @click="printRecord" v-if="exportPrintData.length">打印</el-button>

      </div>
    </el-dialog>

    <!-- 创建订单
    <el-dialog title="创建订单" v-if="addOrderShow" :visible.sync="addOrderShow" width="65%" :close-on-click-modal="false">
      <add-create @addOver="addOver"></add-create>
    </el-dialog> -->
    <!-- 批量取消 -->
    <el-dialog title="批量取消" v-if="batchReturnShow" :visible.sync="batchReturnShow" width="80%" :close-on-click-modal="false">
      <batch-return :regionalRouteData="regionalRouteData" :userList="userList" @cancelReturn="batchReturnShow = false"></batch-return>
    </el-dialog>
    <!-- 导出打印记录汇总表 -->
    <el-dialog title="打印记录汇总表" :visible.sync="exportPrintTotalShow" width="30%" @close="exportPrintTotalShow = false;">
      <el-form :model="exportPrintTotalForm" ref="exportPrintTotalForm" :inline="true" label-width="120px">
        <el-form-item label="选择汇总日期">
          <el-date-picker v-model="exportPrintTotalForm.totalDate" @change="changeExportPrintTotal" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="exportSumData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="selectExportSumData">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="created" label="时间" min-width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="way" label="路线" min-width="140" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="text-align:center;padding-top:20px">
        <el-button type="primary" :disabled="exportSumIds.length==0" @click="printExportTotal">导出</el-button>
      </div>
    </el-dialog>
    <!-- 一键完成 -->
    <el-dialog title="一键完成" v-if="oneClickCompletedShow" :visible.sync="oneClickCompletedShow" width="30%" @close="oneClickCompletedCancel">
      <el-form :model="oneClickCompletedForm" ref="oneClickCompletedForm" :inline="true" label-width="120px">
        <el-form-item label="出库日期">
          <el-date-picker v-model="oneClickCompletedForm.date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <div style="color:red;font-size:12px;">注：请检查是否存在未配送订单，是否已将其状态改为已截单！</div>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <el-button @click="oneClickCompletedCancel">取 消</el-button>
        <el-button type="primary" @click="oneClickCompletedEvent">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 现称 -->
    <el-dialog title="现称" v-if="currentWeighingOrderShow" :visible.sync="currentWeighingOrderShow" width="50%">
      <el-form :model="currentWeighingOrderForm" ref="currentWeighingOrderForm" :inline="true" label-width="120px">
        <el-form-item label="用户">
          <el-input style="width: 300px" type="text" disabled v-model="currentWeighingOrderForm.wechat"></el-input>
        </el-form-item>
        <el-form-item label="商品">
          <el-input style="width: 300px" type="text" disabled v-model="currentWeighingOrderForm.title"></el-input>
        </el-form-item>
        <el-form-item label="商品数量">
          <el-input style="width: 100px" type="text" @input="changeNum($event,'currentWeighingOrderForm')" v-model="currentWeighingOrderForm.num"></el-input>
        </el-form-item>
        <el-form-item label="单价（元）">
          <el-input style="width: 100px" type="text" disabled v-model="currentWeighingOrderForm.price"></el-input>
        </el-form-item>
        <el-form-item label="总价（元）">
          <el-input style="width: 100px" type="text" disabled v-model="currentWeighingOrderForm.totalPrice"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width: 100px" type="text" v-model="currentWeighingOrderForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <el-button @click="currentWeighingOrderShow=false">取 消</el-button>
        <el-button type="primary" @click="currentWeighingOrdeUpdata">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改总价格 -->
    <el-dialog title="修改总价格" v-if="editPriceData.show" :visible.sync="editPriceData.show" width="50%">
      <el-form :model="editPriceData" ref="currentWeighingOrderForm" :inline="true" label-width="120px">
        <el-form-item label="原总价">
          <el-input style="width: 300px" type="text" disabled v-model="editPriceData.oldTotalPrice"></el-input>
        </el-form-item>
        <el-form-item label="修改后总价">
          <el-input style="width: 300px" type="text" v-model="editPriceData.totalPrice" @input="chengeEditPrice" placeholder="请输入修改后总价"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <el-button @click="editPriceData.show=false">取 消</el-button>
        <el-button type="primary" @click="editPrice">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导出异常订单 -->
    <el-dialog title="提示" :visible.sync="exportErrOrderData.show" width="30%">
      <span>{{exportErrOrderData.data.msg}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportErrOrderData.show = false">取 消</el-button>
        <el-button type="primary" @click="exportErrOrder">导出异常订单</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "../../components/areaCascader.vue"
import selectPrintOrder from "@/components/selectPrintOrder.vue"
import print from "@/components/print.vue"
import printRegion from "@/components/printRegion.vue"
import printFix from "@/components/printFix.vue"
import selfPrint from "@/components/selfPrint.vue"
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
// 批量添加订单
// import addCreate from "@/components/addCreate.vue"
import batchReturn from "@/components/batchReturn.vue"
export default {
  name: "goodsIndex",
  data() {
    let _this = this
    return {
      exportErrOrderData: {
        show: false,
        data: {}
      },
      exportRegionalList: [],
      searchRegionalList: [],
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      regionalRouteData: [],
      editPriceData: {
        show: false,
        id: "",
        totalPrice: "",
        oldTotalPrice: "",
      },
      currentWeighingOrderShow: false,
      currentWeighingOrderForm: {},
      deliveryDate: [],
      pickerOptions: {
        disabledDate(time) {
          let tem = time.getTime()
          return !_this.deliveryDate.includes(tem);
        },
      },
      exportSumIds: [],//打印记录汇总选择的打印记录id
      exportSumData: [],//打印记录汇总
      exportPrintForm: {
        id: []
      },
      exportPrintData: [],
      exportPrintShow: false,
      printRecords: [],//打印记录
      printSearchForm: {},//打印数据搜索参数
      printData: {},//所有打印数据
      printOrderData: {},//二次确认选择打印数据
      printRegionOrderData: {},//二次确认选择打印数据 //地区打印
      printShow: false,
      printFixShow: false,
      selfPrintShow: false,//自提打印弹框
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/saleChildOrder/importOrderUpdate",
      uploadUrl2: this.$axios.defaults.basePath + "/orderImportRecord/importOrder",
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      loading: false,
      loading2: false,
      loading3: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      transferButton: "",
      exportButton: "",
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",

      },
      wayIds: [],
      // 搜索商品多选
      goodsIds: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
        userNumList: []
      },
      addEditRules: {
        id: [{ required: true, message: "请选择销售商品", trigger: "change" }],
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        num: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 商品类型
      goodsType: [],
      // 商品单位
      goodsUnit: [],
      // 商品状态
      goodsStatus: [
        {
          label: "已下单",
          id: 0,
        },
        {
          label: "退货",
          id: 1,
        },
        {
          label: "取消",
          id: 2,
        },
        {
          label: "已截单",
          id: 4,
        },
        {
          label: "已完成",
          id: 3,
        }, {
          label: "已出库",
          id: 5,
        }, {
          label: "已配送",
          id: 6,
        },
        {
          label: "已结账",
          id: '9',
        },
      ],
      // 是否是预售单
      isPresellList: [
        {
          label: "否",
          id: 0,
        },
        {
          label: "是",
          id: 1,
        },

      ],
      // 订单类型
      orderType: [
        {
          label: "限时订单",
          id: "1",
        },
        {
          label: "限量订单",
          id: "2",
        }, {
          label: "限购订单",
          id: "3",
        }, {
          label: "热销现货",
          id: "4",
        }, {
          label: "满减活动",
          id: "5",
        },
      ],
      orderStatus: [
        {
          label: "已下单",
          id: "0",
        },
        {
          label: "退货",
          id: "1",
        },
        {
          label: "取消",
          id: "2",
        },
        {
          label: "已完成",
          id: "3",
        },
        {
          label: "已截单",
          id: "4",
        }, {
          label: "已出库",
          id: "5",
        }, {
          label: "已配送",
          id: '6',
        },
        {
          label: "已结账",
          id: '9',
        },
      ],
      submitBtnLoad: false,
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉商品
      selectGoods: [],
      userList: [],
      saleGoodsInfo: {},
      warehouseList: [],
      // 选中用户的adcode
      userAdcode: "",
      exportShow: false,
      exportForm: { wayIds: [], userid: "", statuss: [], goodsIds: [], userids: [] },
      exportCategoryId: [],
      returnForm: {
        needBack: false,
        totalSalePrice: null
      },
      returnShow: false,
      returnTlt: "退货",
      returnRules: {
        returnGoodsNum: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
        ],
      },
      adcode: sessionStorage.getItem('adcode'),
      // 导出订单选择商品下拉数据
      exportGoods: [],
      exportGoodsId: [],
      // 地区练级选择器是否展示全层级
      allLevel: false,
      searchTowns: '',
      wayList: [], //路线列表
      // 新增订单显示
      // addOrderShow: false,
      driverInfo: {},
      // 批量退货
      batchReturnShow: false,
      // 导出打印汇总表
      exportPrintTotalShow: false,
      exportPrintTotalForm: {
        totalDate: ''
      },

      batchReturnShow: false,
      // 一键完成
      oneClickCompletedShow: false,
      oneClickCompletedForm: {
        date: ''
      },
      // 订单导出选择订单状态
      orderStatusList: [],
      useridList: []
    };
  },
  components: {
    areaCascader,
    selectPrintOrder,
    print,
    printFix,
    // addCreate,
    batchReturn,
    selfPrint,
    printRegion
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    this.getUserList();
    this.getWayList();
    this.getSelectGoods()
    this.getRegionalRouteList()
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "sale_child_order_add") {
        this.addButton = "1";
      }
      if (item.authority == "sale_child_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "sale_child_order_cancel") {
        this.delButton = "1";
      } else if (item.authority == "sale_child_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "sale_child_order_transfer") {
        this.transferButton = "1";
      } else if (item.authority == "sale_child_order_export") {
        this.exportButton = "1";
      }
    });
  },
  watch: {
    dialogFormVisible: {
      handler(n) {
        if (n) {
          this.getWarehouseList()
        } else {
          // this.getUserList()
          // this.getSelectGoods()
        }
      },
      immediate: true
    },
    returnShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.returnForm).forEach((key) => {
            this.returnForm[key] = "";
          });
          this.returnForm.needBack = false
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    //导出异常订单
    exportErrOrder() {
      let _this = this
      this.$axios
        .get(this.$axios.defaults.basePath + "/orderImportRecord/exportErrOrder", {
          params: {
            id: this.exportErrOrderData.data.id
          },
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = "异常订单.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          _this.exportErrOrderData.show = false
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })
    },
    exportQK() {
      let type = JSON.parse(JSON.stringify(this.exportForm.type))
      this.exportForm = {}
      this.exportCategoryId = []
      this.exportGoodsId = []
      this.exportRegionalList = []
      this.exportForm.type = type
    },
    selectAll() {
      let ids = this.selectGoods.map((item) => item.id)
      console.log(ids)
      this.$set(this.exportForm, "goodsIds", ids)
      // this.exportForm.goodsIds = ids
    },
    // 处理数据方法
    changeKey(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].value = arr[i].id
        arr[i].label = arr[i].name
        if (arr[i].childs.length) {
          this.changeKey(arr[i].childs)
        } else {
          delete arr[i].childs
        }
      }
      return arr
    },
    // 区域列表
    async getRegionalRouteList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/regionalRoute/selectRegionalTree", {
          params: {
            current: 1,
            size: 1000
          }
        })
        .then(function (res) {
          if (res.status == 200) {
            if (res.data.length > 0) {
              // 递归 如果当前层级没有下级 则删除childs
              var newTree = _this.changeKey(res.data)
              _this.$nextTick(() => {
                _this.regionalRouteData = newTree
              })
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    chengeEditPrice(e) {
      this.editPriceData.totalPrice = this.$cjs.getNum(e)
    },
    //修改价格
    editPrice(row, flag) {
      let _this = this
      if (flag == 1) {
        this.editPriceData.id = row.id
        this.editPriceData.oldTotalPrice = row.totalPrice
        this.editPriceData.show = true
      } else {

        let { id, totalPrice, oldTotalPrice } = this.editPriceData
        if (!totalPrice) {
          _this.$message({
            message: "请输入修改总价",
            type: "warning",
          });
          return
        }
        this.$axios({
          url: _this.$axios.defaults.basePath + "/saleChildOrder/updateTotalPrice",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id, totalPrice, oldTotalPrice
          },
        }).then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.editPriceData.totalPrice = ""
            _this.editPriceData.show = false;
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
      }
    },
    //现称确定
    currentWeighingOrdeUpdata() {
      let _this = this;
      if (!this.currentWeighingOrderForm.num) {
        _this.$message({
          message: "数量不能为空！",
          type: "warning",
        });
        return
      }
      let obj = {
        goodsMallId: this.currentWeighingOrderForm.goodsId,
        id: this.currentWeighingOrderForm.id,
        num: this.currentWeighingOrderForm.num,
        remark: this.currentWeighingOrderForm.remark,
      }
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/saleChildOrder/updateCurrentWeighingOrder"
          , {
            params: obj
          })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.currentWeighingOrderShow = false;
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 现称
    currentWeighingOrder(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/info", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.currentWeighingOrderForm = res.data.data;
            that.currentWeighingOrderShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //编辑备注输入框
    changeNum(e, name) {
      let num = this.$cjs.getNum(e)
      if (name == 'currentWeighingOrderForm') {
        this[name].num = num
        this.currentWeighingOrderForm.totalPrice = (Number(this.currentWeighingOrderForm.num) * Number(this.currentWeighingOrderForm.price)).toFixed(2)
      } else {
        this[name].remark = num
      }

    },
    exportUserChange(e) {
      this.useridList = e
      this.getSelectGoods()
    },
    // 订单导出选择订单状态
    changeOrderStatus(e) {
      this.orderStatusList = e
      this.getSelectGoods()
    },
    // 获取有出库记录的日期
    getDeliveryDate(type) { // 0打印订单汇总 1 一键完成
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/printRecords/selectDeliveryDate"
          , {
            params: {
              type
            }
          })
        .then((res) => {
          if (res.data.errcode == 0) {
            let data = []
            for (let i in res.data.data) {
              let time = new Date(res.data.data[i] + ' 00:00:00')
              data.push(time.valueOf())
            }
            _this.deliveryDate = data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeExportPrintTotal(e) {
      this.getPrintRecords(e)
    },
    // 搜索商品多选
    searchGoodsMore(e) {
      console.log(e)
      this.searchForm.goodsId = e.join(',')
    },
    //打印 打印记录
    printRecord() {
      let _this = this;
      let id = JSON.parse(JSON.stringify(this.exportPrintData))
      this.$axios
        .get(this.$axios.defaults.basePath + "/printRecords/orderRecordPrinting", {
          params: {
            id: id.join(",")
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.printOrderData = res.data.data
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //选择打印记录
    changeExportPrint(e) {
      // var obj = this.printRecords.find(function (obj) {
      //   return obj.id === e
      // })
      this.exportPrintData = e
    },
    handleAvatarErr() {
      this.loading2 = false;
    },
    handleAvatarErr2(res, file) {
      this.loading3 = false;
      console.log(res)
    },
    //导入修改订单状态数据
    handleAvatarSuccess(res, file) {
      this.loading2 = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
        // const mainFile = this.$refs.upload;
        //   if (mainFile && mainFile.length) {
        //       mainFile.forEach(item => {
        //       item.clearFiles();
        //       });
        //   }
      } else {
        this.listEvent()
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
      //location. reload()
    },
    handleAvatarSuccess2(res, file) {
      this.loading3 = false;
      this.$refs.uploadOrder.clearFiles()
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        if (!res.data) {
          this.$message({
            message: res.errmsg,
            type: "error",
          });
        } else {
          this.exportErrOrderData.data = res.data
          this.exportErrOrderData.show = true
          this.listEvent()
        }

      }
    },
    handleRemove(file) {
      console.log(file);
    },
    beforeAvatarUpload2(file) {
      console.log(file, "adasd")
      this.loading3 = true;
    },
    beforeAvatarUpload(file) {
      console.log(file, 'asdasd')
      // if (flag == 1) {
      //   this.loading3 = true;
      // } else {
      this.loading2 = true;
      // }

    },

    // 一键完成
    oneClickCompletedEvent() {
      let _this = this;
      if (!this.oneClickCompletedForm.date) {
        this.$message({
          message: '请选择出库日期',
          type: 'warning'
        })
        return;
      }
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/oneClickCompleted", {
          params: {
            date: _this.oneClickCompletedForm.date,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.oneClickCompletedShow = false;
            _this.oneClickCompletedForm = {};
            _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    oneClickCompletedCancel() {
      this.oneClickCompletedShow = false;
      this.oneClickCompletedForm = {};
    },

    // 导出订单打印汇总表
    exportPrintTotal() {
      this.getDeliveryDate(0)
      this.exportPrintTotalShow = true;
    },
    printExportTotal() {
      let ids = this.exportSumIds.join(",")
      window.open(this.$axios.defaults.basePath + "/printRecords/exportSum?printRecordIds=" + ids)
    },

    driver(e) {

      this.driverInfo = e
    },
    addOver2(data) {
      this.printOrderData = data
      this.selfPrintShow = false
    },
    // addOver(e) {
    //   if (e == "ok") {
    //     this.pagesData.currentPage = 1;
    //     this.listEvent();
    //   }
    //   this.addOrderShow = false
    // },
    filterNum(value, row, column) {
      return row.num < value;
    },
    changeWarehouse(e) {
      // this.addEditForm.adcode = e
      // this.userAdcode = e
      this.getSelectGoods(e)
      this.getUserList(e)
    },
    //修改订单状态
    updateOrderStatus(id) {
      let _this = this;
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get(this.$axios.defaults.basePath + "/saleChildOrder/updateOrder", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    overPrintShow() {
      this.printFixShow = false
    },
    // 打印记录导出
    printExport() {
      window.open(this.$axios.defaults.basePath + "/printRecords/export?id=" + this.exportPrintForm.id)
      // let _this = this;
      // this.$axios
      //   .get(this.$axios.defaults.basePath + "/printRecords/export", {
      //     params: _this.exportPrintForm,
      //   })
      //   .then((res) => {
      //     var eleLink = document.createElement("a");
      //     eleLink.download = "销售单.csv";
      //     eleLink.style.display = "none";

      //     var blob = new Blob([res.data]);
      //     eleLink.href = URL.createObjectURL(blob);
      //     // 触发点击
      //     document.body.appendChild(eleLink);
      //     eleLink.click();
      //     _this.exportShow = false;
      //     _this.$message({
      //       message: "导出成功",
      //       type: "success",
      //     });
      //     _this.exportForm = {};
      //     _this.exportCategoryId = [];
      //     // 然后移除
      //     document.body.removeChild(eleLink);
      //   });
    },
    exportPrint() {
      this.getPrintRecords("")
      this.exportPrintForm.id = []
      this.exportPrintShow = true
    },
    // 获取打印记录
    getPrintRecords(selectTime) {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/printRecords", {
          params: {
            selectTime
          }
        }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            if (selectTime) {
              if (res.data.data.length) {
                _this.exportSumData = res.data.data
              } else {
                _this.exportSumData = []
                _this.$message({
                  message: selectTime + "日无打印记录",
                  type: "warning",
                });
              }
            } else {
              for (let i in res.data.data) {
                res.data.data[0].id = Number(res.data.data[0].id)
                res.data.data[i].name = res.data.data[i].created + ' ' + res.data.data[i].way
              }
              _this.exportPrintForm.id.push(res.data.data[0].id)
              _this.exportPrintData.push(res.data.data[0].id)
              _this.printRecords = res.data.data
            }

          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    async over(params, callback) {
      if (this.printSearchForm.userIds) {
        delete this.printSearchForm.userIds
        delete this.printSearchForm.isAdd
        delete this.printSearchForm.saleChildOrderId
      }
      if (params) {
        if (params.userIds) {
          this.printSearchForm.userIds = params.userIds
          this.printSearchForm.saleChildOrderId = params.saleChildOrderId
          this.printSearchForm.isAdd = params.isAdd
        } else {
          let p = JSON.parse(JSON.stringify(params))
          p.wayId = p.wayId.join(',')
          this.printSearchForm = p
        }
        let res = await this.getUserOrderPrinting(this.printSearchForm)
        callback(res) //告诉子组件接口调用成功 关闭loading
      } else {
        this.printShow = false
      }
    },
    // 查询打印订单数据
    async getUserOrderPrinting(params) {
      let _this = this
      return new Promise(resolve => {
        if (params.wayOrRegion == '0') { //路线
          this.$axios
            .get(_this.$axios.defaults.basePath + "/saleChildOrder/userOrderPrinting", {
              params: params || {},
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                if (res.data.data.multipleRoutes.length) {
                  res.data.data.wayOrRegion = params.wayOrRegion //0路线 1区域
                  if (params.userIds) {
                    _this.printOrderData = res.data.data
                  } else {
                    for (let n in res.data.data.multipleRoutes) {
                      for (let i in res.data.data.multipleRoutes[n].userOrderList) {
                        let num = 0
                        for (let j in res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList) {
                          res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList[j].checked = true
                          num += res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList[j].num
                        }
                        res.data.data.multipleRoutes[n].userOrderList[i].totalNum = num
                      }
                    }
                    _this.printData = res.data.data
                    _this.printFixShow = true
                  }
                  resolve("1")
                  if (params.isAdd) {
                    _this.printFixShow = false
                    if (params.isAdd == 1) {
                      _this.$refs.printFix.add(res.data.data)
                    }
                  }

                } else {
                  resolve("0")
                  _this.$message({
                    message: "暂无数据,请刷新后再试！",
                    type: "warning",
                  });
                }

              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        } else if (params.wayOrRegion == '1') {//区域
          this.$axios({
            url: _this.$axios.defaults.basePath + "/saleChildOrder/regionUserOrderPrinting",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(params),
          }).then((res) => {
            // this.$axios
            //   .get(_this.$axios.defaults.basePath + "/saleChildOrder/regionUserOrderPrinting", {
            //     params: params || {},
            //   })
            //   .then((res) => {
            if (res.data.errcode == 0) {
              if (res.data.data.multipleRoutes.length) {
                res.data.data.wayOrRegion = params.wayOrRegion //0路线 1区域
                if (params.userIds) {
                  _this.printRegionOrderData = res.data.data
                } else {
                  for (let i in res.data.data.multipleRoutes) {
                    for (let j in res.data.data.multipleRoutes[i].multipleRoutes) {
                      for (let n in res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList) {
                        for (let k in res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList) {
                          res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList[k].checked = true
                        }
                      }
                    }
                  }
                  _this.printData = res.data.data
                  _this.printFixShow = true
                }
                resolve("1")
                if (params.isAdd) {
                  _this.printFixShow = false
                  if (params.isAdd == 1) {
                    _this.$refs.printFix.add(res.data.data)
                  }
                }

              } else {
                resolve("0")
                _this.$message({
                  message: "暂无数据,请刷新后再试！",
                  type: "warning",
                });
              }

            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        }
        // let url = ""
        // if (params.wayOrRegion == '0') {//路线
        //   url = "/saleChildOrder/userOrderPrinting"
        // } else if (params.wayOrRegion == '1') {//区域
        //   url = "/saleChildOrder/regionUserOrderPrinting"
        // }
        // this.$axios
        //   .get(_this.$axios.defaults.basePath + url, {
        //     params: params || {},
        //   })
        //   .then((res) => {
        //     if (res.data.errcode == 0) {
        //       if (res.data.data.multipleRoutes.length) {
        //         res.data.data.wayOrRegion = params.wayOrRegion //0路线 1区域
        //         if (params.userIds) {
        //           if (params.wayOrRegion == '0') {//路线
        //             _this.printOrderData = res.data.data
        //           } else if (params.wayOrRegion == '1') {//地区
        //             _this.printRegionOrderData = res.data.data
        //           }

        //         } else {
        //           if (params.wayOrRegion == "0") {//路线
        //             for (let n in res.data.data.multipleRoutes) {
        //               for (let i in res.data.data.multipleRoutes[n].userOrderList) {
        //                 let num = 0
        //                 for (let j in res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList) {
        //                   res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList[j].checked = true
        //                   num += res.data.data.multipleRoutes[n].userOrderList[i].saleChildOrderList[j].num
        //                 }
        //                 res.data.data.multipleRoutes[n].userOrderList[i].totalNum = num
        //               }
        //             }
        //           } else if (params.wayOrRegion == "1") {//区域
        //             for (let i in res.data.data.multipleRoutes) {
        //               for (let j in res.data.data.multipleRoutes[i].multipleRoutes) {
        //                 for (let n in res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList) {
        //                   for (let k in res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList) {
        //                     res.data.data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList[k].checked = true
        //                   }
        //                 }
        //               }
        //             }
        //           }
        //           _this.printData = res.data.data
        //           _this.printFixShow = true
        //         }
        //         resolve("1")
        //         if (params.isAdd == 1) {
        //           _this.$refs.printFix.add(res.data.data)
        //         }
        //       } else {
        //         resolve("0")
        //         _this.$message({
        //           message: "暂无数据,请刷新后再试！",
        //           type: "warning",
        //         });
        //       }

        //     } else {
        //       _this.$message({
        //         message: res.data.errmsg,
        //         type: "warning",
        //       });
        //     }
        //   });
      })
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      _this.searchForm.wayId = _this.wayIds.length ? _this.wayIds.join(',') : '';
      this.$axios
        .get(_this.$axios.defaults.basePath + "/saleChildOrder", {
          params: _this.searchForm,
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.goodsImg) {
                if (item.goodsImg.indexOf(",") != -1)
                  item.icons = item.goodsImg.split(",")[0];
                else item.icons = item.goodsImg;
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 连级选择器
    areaChange(e) {
      let p = CodeToText[e[0]]
      let c = CodeToText[e[1]]
      let a = CodeToText[e[2]]
      this.searchTowns = e[2];
      if (this.exportShow) {
        this.$set(this.exportForm, 'towns', p + '-' + c + '-' + a);
      } else {
        this.$set(this.searchForm, 'towns', p + '-' + c + '-' + a);
      }
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显商品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取销售商品下拉列表 --先选用户获取adcode 根据adcode获取商品
    //导出订单和检索时adcode为当前登录用户的adcode
    // 订单导出是传递订单状态
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId: warehouseId || '',
            orderStatus: _this.orderStatusList.join(','),
            userid: _this.useridList.join(',')
          },
        })
        .then((res) => {
          if (res.status == 200) {

            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
              // if(item.isTopCarriage == 0){
              //   item.name = item.name+'（未上架）'
              // }else if(item.isTopCarriage == 2){
              //   item.name = item.name+'（已下架）'
              // }
              // item.name =item.type==1?item.name+'（限时商品）':item.type==2?item.name+'（限量商品）':item.type==3?item.name+'（限购商品）':item.name+'（长期热销）'

            });
            _this.exportGoods = list;
            _this.selectGoods = list;
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.status == 200) {
            _this.warehouseList = res.data.data;
            _this.addEditForm.adcode = _this.warehouseList[0].adcode
            _this.userAdcode = _this.warehouseList[0].adcode
            _this.addEditForm.warehouseId = _this.warehouseList[0].id
            // _this.getSelectGoods()
            _this.getUserList(_this.warehouseList[0].id)
          }
        });
    },
    // 获取用户下拉列表
    getUserList(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser", {
          params: {
            warehouseId: warehouseId || ""
          }
        })
        .then((res) => {
          if (res.status == 200) {
            res.data.data.forEach(item => {
              item.wechat = `${item.wechat}（${item.shopName}）`
            })
            _this.userList = res.data.data;
          }
        });
    },
    // 检索
    searchSubmit() {
      var treedata = this.$refs.tree.getCheckedNodes();
      let ids = []
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      this.searchForm.regionalId = ids.join(",")
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.searchForm.categoryId = this.searchForm.categoryId
        ? this.searchForm.categoryId.join(",")
        : "";
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.searchRegionalList = []
      this.searchTowns = '';
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
      this.$set(this.searchForm, 'towns', '');
      this.wayIds = []
      this.goodsIds = [];
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //导出打印订单汇总 选择事件
    selectExportSumData(e) {
      this.exportSumIds = []
      e.forEach((item) => {
        this.exportSumIds.push(item.id);
      });
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.$router.push({ name: 'saleOrder_add' })
      // this.dialogTitle = "新增";
      // this.addOrderShow = true;
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.userAdcode = row.adcode;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/info", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = false;
            that.dialogTitle = "编辑";
            // that.getSelectGoods();
            let userIdArr = [data.userId]
            let obj = {
              userId: data.userId,
              num: data.num
            }
            that.$set(that.addEditForm, "remark", data.remark);
            that.$set(that.addEditForm, "isCurrentWeighing", String(data.isCurrentWeighing));
            that.$set(that.addEditForm, "userNumList", [obj]);
            that.$set(that.addEditForm, "userId", userIdArr);
            that.$set(that.addEditForm, "id", data.goodsId);
            that.$set(that.addEditForm, "orderId", data.id);
            that.$set(that.addEditForm, "isPresell", String(data.isPresell));
            // that.$set(that.addEditForm, "userId", data.userId);
            that.$set(that.addEditForm, "num", data.num);
            that.saleGoodsInfo = data;
            that.$set(that.goodsTypeId2, 0, data.goodsId);
            that.saleGoodsInfo.type = data.type.toString();
            that.$set(that.goodsTypeId2, 0, data.categoryId);

            // 连级选择器回显
            that.goodsTypeId2 = that
              .familyTree(that.goodsType, data.categoryId)
              .reverse();
            if (data.imgs) {
              let imgArr = data.imgs.split(",");
              that.imgData = imgArr;
              imgArr.forEach((item) => {
                let json = {
                  url: item,
                };
                that.fileList.push(json);
              });
            }

            // 商品单位回显
            that.goodsUnit.forEach((item) => {
              if (item.name == data.unit) {
                that.saleGoodsInfo.unitId = item.id;
              }
            });

            // 活动规则
            if (that.saleGoodsInfo.goodsMallActivityRuleList.length > 0) {
              let rules = [];
              that.saleGoodsInfo.goodsMallActivityRuleList.forEach(item => {
                if (that.saleGoodsInfo.goodsMallActivityRuleList[0].type == 1) {
                  rules.push('满' + item.threshold + '份，赠送' + item.discounts + '份' + item.giftName)
                } else {
                  rules.push('满' + item.threshold + '份，单价' + item.discounts + '元')
                }

              })
              that.$set(that.saleGoodsInfo, 'rules', rules)
            }

            console.log(that.saleGoodsInfo)
            that.dialogFormVisible = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
      this.$refs.addEditForm.clearValidate();
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitBtnLoad = true;
          // if (this.addEditForm.num <= 0) {
          //   this.$message({
          //     message: "商品数量不能小于1",
          //     type: "warning",
          //   });
          //   this.submitBtnLoad = false;
          //   return;
          // }
          for (let i in _this.addEditForm.userNumList) {
            if (!_this.addEditForm.userNumList[i].num) {
              this.$message({
                message: "商品数量不能小于1",
                type: "warning",
              });
              this.submitBtnLoad = false
              return
            }
            _this.addEditForm.userNumList[i].id = _this.saleGoodsInfo.id
          }
          if (this.dialogTitle == "新增") {

            this.$axios({
              url: _this.$axios.defaults.basePath + "/saleChildOrder/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                orderFormList: _this.addEditForm.userNumList
              },
            }).then((res) => {
              _this.submitBtnLoad = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.dialogFormVisible = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            let url = ""
            let obj = {}
            if (_this.addEditForm.isCurrentWeighing == 1) {
              if (!_this.addEditForm.remark) {
                _this.$message({
                  message: "请填写备注",
                  type: "warning",
                });
                return
              }
              url = "/saleChildOrder/updateRemark"
              obj = {
                id: _this.addEditForm.orderId,
                remark: _this.addEditForm.remark
              }
            } else {
              url = "/saleChildOrder/update"
              obj = {
                id: _this.addEditForm.orderId,
                num: _this.addEditForm.userNumList[0].num,
                isPresell: _this.addEditForm.isPresell,
                price: _this.saleGoodsInfo.price
              }
            }
            this.$axios
              .get(this.$axios.defaults.basePath + url, {
                params: obj
              })
              .then((res) => {
                _this.submitBtnLoad = false;
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  _this.dialogFormVisible = false;
                  _this.listEvent();
                } else {
                  _this.$message({
                    message: res.data.errmsg,
                    type: "warning",
                  });
                }
              });
          }
        } else {
          _this.submitBtnLoad = false;
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/goodsMall/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.saleGoodsInfo.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    searchUnitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.searchForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      let that = this;
      that.goodsTypeId2 = [];
      // this.getGoodsType();
      this.userList.forEach((item) => {
        if (item.id == row.userId) {
          this.userAdcode = item.adcode;
        }
      });
      // this.getSelectGoods();
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/info", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = true;
            that.dialogTitle = "详情";
            // that.getSelectGoods();
            let userIdArr = [data.userId]
            let obj = {
              userId: data.userId,
              num: data.num
            }
            that.$set(that.addEditForm, "userNumList", [obj]);
            that.$set(that.addEditForm, "userId", userIdArr);
            that.$set(that.addEditForm, "id", data.goodsId);
            that.$set(that.addEditForm, "orderId", data.id);
            that.$set(that.addEditForm, "isPresell", String(data.isPresell));
            that.$set(that.addEditForm, "isCurrentWeighing", String(data.isCurrentWeighing));
            // that.$set(that.addEditForm, "userId", data.userId);
            that.$set(that.addEditForm, "num", data.num);
            that.saleGoodsInfo = data;
            that.$set(that.goodsTypeId2, 0, data.goodsId);
            that.saleGoodsInfo.type = data.type.toString();
            that.$set(that.goodsTypeId2, 0, data.categoryId);

            // 连级选择器回显
            that.goodsTypeId2 = that
              .familyTree(that.goodsType, data.categoryId)
              .reverse();
            if (data.imgs) {
              let imgArr = data.imgs.split(",");
              that.imgData = imgArr;
              imgArr.forEach((item) => {
                let json = {
                  url: item,
                };
                that.fileList.push(json);
              });
            }

            // 商品单位回显
            that.goodsUnit.forEach((item) => {
              if (item.name == data.unit) {
                that.saleGoodsInfo.unitId = item.id;
              }
            });
            // 活动规则
            if (that.saleGoodsInfo.goodsMallActivityRuleList.length > 0) {
              let rules = [];
              that.saleGoodsInfo.goodsMallActivityRuleList.forEach(item => {
                if (that.saleGoodsInfo.goodsMallActivityRuleList[0].type == 1) {
                  rules.push('满' + item.threshold + '份，赠送' + item.discounts + '份' + item.giftName)
                } else {
                  rules.push('满' + item.threshold + '份，单价' + item.discounts + '元')
                }
              })
              that.$set(that.saleGoodsInfo, 'rules', rules)
            }
            that.dialogFormVisible = true;
            // ------------------------------------
            // let data = res.data.data;
            // that.detailsShow = true;
            // that.dialogTitle = "详情";
            // that.dialogFormVisible = true;
            // that.addEditForm.id = data.goodsId;
            // that.addEditForm.userId = data.userId;
            // that.addEditForm.num = data.num;
            // that.saleGoodsInfo = data;
            // that.$set(that.goodsTypeId2, 0, data.goodsId);
            // that.saleGoodsInfo.type = data.type.toString();
            // that.$set(that.goodsTypeId2, 0, data.categoryId);

            // // 连级选择器回显
            // that.goodsTypeId2 = that
            //   .familyTree(that.goodsType, data.categoryId)
            //   .reverse();
            // if (data.imgs) {
            //   let imgArr = data.imgs.split(",");
            //   that.imgData = imgArr;
            //   imgArr.forEach((item) => {
            //     let json = {
            //       url: item,
            //     };
            //     that.fileList.push(json);
            //   });
            // }

            // // 商品单位回显
            // that.goodsUnit.forEach((item) => {
            //   if (item.name == data.unit) {
            //     that.saleGoodsInfo.unitId = item.id;
            //   }
            // });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 下拉选择商品
    changeGoodsEvents(e) {
      let _this = this;
      _this.goodsTypeId2 = [];
      if (e) {
        var goods = {};
        _this.selectGoods.forEach((item) => {
          if (item.id == e) {
            goods = item;
          }
        });
        _this.saleGoodsInfo = goods;
        _this.saleGoodsInfo.type = goods.type.toString();
        _this.goodsTypeId2.push(goods.categoryId);
        if (e.length != 1) {
          return
        }
        // 判断当前用户是否有该商品的有效订单
        this.$axios
          .get(this.$axios.defaults.basePath + "/saleChildOrder/checkOrder", {
            params: {
              userId: _this.addEditForm.userId,
              goodsId: e,
            },
          })
          .then((res) => {
            if (res.data.errcode == 0) {
              // 如果存在该商品的订单且未过期则弹出该订单详情进行编辑
              if (res.data.data) {
                _this
                  .$confirm("当前用户已有此商品订单，是否编辑该订单?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                  .then(() => {
                    _this.dialogFormVisible = false;
                    // 根据ID调用编辑事件显示弹框
                    _this.handleEdit({ id: res.data.data });
                  })
                  .catch(() => {
                    _this.$message({
                      type: "info",
                      message: "已取消",
                    });
                    _this.dialogFormVisible = false;
                  });
              }
              // else {
              //   _this.$nextTick(() => {
              //     var newDate = new Date().getTime();
              //     if (goods.type == 1) {
              //       //判断限时时间段是否过期
              //       var startTime = new Date(goods.startTime).getTime();
              //       var endTime = new Date(goods.endTime).getTime();
              //       if (newDate < startTime) {
              //         _this.$message({
              //           message: "当前销售商品未开始",
              //           type: "warning",
              //         });
              //         _this.addEditForm.id = "";
              //       } else if (newDate > endTime) {
              //         _this.$message({
              //           message: "当前销售商品已过期",
              //           type: "warning",
              //         });
              //         _this.addEditForm.id = "";
              //       }
              //     } else {
              //       //判断限量
              //       if (goods.num <= 0) {
              //         _this.$message({
              //           message: "当前销售商品已售完",
              //           type: "warning",
              //         });
              //         _this.addEditForm.id = "";
              //       }
              //     }
              //   });
              // }
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
              _this.addEditForm.id = "";
              _this.$set(_this.saleGoodsInfo, {});
              _this.goodsTypeId2 = [];
            }
          });
      }
    },
    // 取消
    handleCancelEvent(row) {
      let _this = this;
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios
          .get(this.$axios.defaults.basePath + "/saleChildOrder/cancel", {
            params: {
              id: row.id,
              warehouseId: row.warehouseId
            },
          })
          .then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
      }).catch(() => {
      });
    },
    // 选择用户
    changeUser(e, isTopCarriage) {
      console.log(e, isTopCarriage, "e, isTopCarriage")
      let _this = this;
      if (typeof e == "object") {
        if (!_this.saleGoodsInfo.id) {
          _this.addEditForm.userId = []
          _this.$message({
            message: "请先选择商品",
            type: "warning",
          });
          return
        }
        let userNumList = []
        for (let i in e) {
          let obj = {}
          obj.userId = e[i]
          if (_this.saleGoodsInfo.minimum) {
            obj.num = _this.saleGoodsInfo.minimum
          } else {
            obj.num = ""
          }

          userNumList.push(obj)
        }
        _this.addEditForm.userNumList = userNumList
      } else {
        let datas = {
          adcode: ''
        }
        if (isTopCarriage) {
          datas.isTopCarriage = isTopCarriage
        }
        this.userList.forEach((item) => {
          if (item.id == e) {
            if (item.adcode) {
              datas.adcode = item.adcode,
                this.userAdcode = item.adcode;
              if (this.dialogTitle == '新增') {
                let _this = this;
                this.$axios
                  .get(this.$axios.defaults.basePath + "/goodsMall/getListByUser")
                  .then((res) => {
                    if (res.data.errcode == 0) {

                      let list = JSON.parse(JSON.stringify(res.data.data))
                      list.forEach(item => {
                        item.name = `${item.name}（${item.standard}）`
                      });
                      _this.selectGoods = list;
                    } else {
                      _this.$message({
                        message: res.data.errmsg,
                        type: 'warning'
                      });
                    }
                  });
              } else {
                // this.getSelectGoods();
              }
            } else {
              this.$message({
                message: "此用户不是团长，只有团长才能下单",
                type: "warning",
              });
              this.addEditForm.userId = "";
            }
          }
        });
        // 选择用户后和选择的商品联动判断是否已存在订单
        if (this.dialogFormVisible && this.saleGoodsInfo.id) {
          this.changeGoodsEvents(this.saleGoodsInfo.id)
        }
      }
    },
    // 发放积分
    pointPush() {
      let _this = this;
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get(this.$axios.defaults.basePath + "/saleChildOrder/transfer", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.isGift || row.status == 1 || row.status == 2 || row.status == 3 || row.status == 4 || row.status == 9) {
        //已完成、取消、退货、9结账状态不可选中
        return false;
      } else {
        return true;
      }
    },
    changeGoodsType(e) {
    },
    exportEvent(flag) {
      // this.getSelectGoods()
      this.getWayList()
      this.exportForm.type = flag
      this.exportShow = true;
    },
    // 订单导出
    orderExport() {

      let _this = this;
      if (this.exportCategoryId && this.exportCategoryId.length > 0) {
        this.exportForm.categoryId = this.exportCategoryId.join(",");
      } else {
        this.exportForm.categoryId = ""
      }
      if (this.exportForm.goodsIds && this.exportForm.goodsIds.length > 0) {
        this.exportForm.goodsId = this.exportForm.goodsIds.join(",");
      } else {
        this.exportForm.goodsId = ""
      }
      if (this.exportForm.statuss && this.exportForm.statuss.length > 0) {
        this.exportForm.status = this.exportForm.statuss.join(",");
      } else {
        this.exportForm.status = ""
      }
      if (this.exportForm.wayIds && this.exportForm.wayIds.length > 0) {
        this.exportForm.wayId = this.exportForm.wayIds.join(",");
      } else {
        this.exportForm.wayId = ""
      }
      if (this.exportForm.userids && this.exportForm.userids.length > 0) {
        this.exportForm.userid = this.exportForm.userids.join(",");
      } else {
        this.exportForm.userid = ""
      }
      var treedata = this.$refs.exportTree.getCheckedNodes();
      let ids = []
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      this.exportForm.regionalId = ids.join(",")
      // this.exportForm.wayId = this.exportForm.wayIds.length ? this.exportForm.wayIds.join(',') : ''

      if (this.exportForm.type == 0) {
        delete this.exportForm.statuss
        delete this.exportForm.goodsIds
        delete this.exportForm.wayIds
        delete this.exportForm.userids
        this.$axios
          .get(this.$axios.defaults.basePath + "/saleChildOrder/export", {
            params: _this.exportForm,
          })
          .then((res) => {
            var eleLink = document.createElement("a");
            eleLink.download = "销售单.csv";
            eleLink.style.display = "none";

            var blob = new Blob([res.data]);
            eleLink.href = URL.createObjectURL(blob);
            // 触发点击
            document.body.appendChild(eleLink);
            eleLink.click();
            _this.exportShow = false;
            _this.$message({
              message: "导出成功",
              type: "success",
            });
            // 然后移除
            document.body.removeChild(eleLink);
          });
      } else {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
        let { userid, status, goodsId, wayId, regionalId } = this.exportForm
        window.open(this.$axios.defaults.basePath + "/saleChildOrder/exportOrderSum?userid=" + userid + "&status=" + status + "&goodsId=" + goodsId + "&wayId=" + wayId + "&warehouseId=" + userInfo.warehouseId + "&regionalId=" + regionalId)
        _this.exportShow = false;
      }
      let type = JSON.parse(JSON.stringify(this.exportForm.type))
      _this.exportForm = {}
      _this.exportCategoryId = []
      _this.exportGoodsId = []
      _this.exportRegionalList = []
      _this.exportForm.type = type
    },
    changeGoods(e) {
    },
    // 订单退货
    handleRefound(row, refundType) {
      this.returnTlt = refundType == 0 ? '退货退款' : refundType == 1 ? '退货无需退款' : refundType == 2 ? '仅退款' : ""
      this.returnForm = JSON.parse(JSON.stringify(row));
      this.returnForm.refundType = refundType
      // this.$set(this.returnForm, 'totalSalePrice2', (this.returnForm.price * this.returnForm.num).toFixed(2))
      if (refundType == 0 || refundType == 1) {
        this.$set(this.returnForm, 'totalSalePrice', null)
      } else {
        this.$set(this.returnForm, 'totalSalePrice', null)
        this.$set(this.returnForm, 'returnGoodsNum', 0)
      }
      this.returnShow = true;
    },
    returnSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.returnForm.returnGoodsNum > this.returnForm.num) {
            this.$message({
              message: "退货数量不能大于购买数量",
              type: "warning",
            });
            return
          }
          let needBack = _this.returnForm.needBack ? 1 : 0
          this.$axios({
            url: _this.$axios.defaults.basePath + "/saleChildOrder/orderReturn",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              id: _this.returnForm.id,
              goodsId: _this.returnForm.goodsId,
              returnGoodsNum: _this.returnForm.returnGoodsNum,
              reason: _this.returnForm.reason,
              created: _this.returnForm.created,
              needBack,
              refundType: _this.returnForm.refundType,
              totalSalePrice: _this.returnForm.totalSalePrice
            },
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.returnShow = false;
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    hanleCancel(s) {
      this[s] = false;
    },
    changeGoodsNum(e) {
      console.log(this.saleGoodsInfo)
      if (e <= 0) {
        this.$message({
          message: "商品数量不能小于0",
          type: "warning",
        });
        scope.row.num = 1;
      } else {
        if (this.saleGoodsInfo.goodsMallActivityRuleList[0].type == 0) {
          if (this.addEditForm.level == 2) {
            this.$set(this.saleGoodsInfo, 'price', this.saleGoodsInfo.price)
            this.$set(this.saleGoodsInfo, 'totalPrice', (this.saleGoodsInfo.price * e).toFixed(2))
          } else if (this.saleGoodsInfo.goodsMallActivityRuleList && this.saleGoodsInfo.goodsMallActivityRuleList.length > 0) {
            let nums = [];
            this.saleGoodsInfo.goodsMallActivityRuleList.forEach((item, index) => {
              if (e >= Number(item.threshold)) {
                this.$set(this.saleGoodsInfo, 'price', Number(item.discounts))
                this.$set(this.saleGoodsInfo, 'totalPrice', (Number(item.discounts) * e).toFixed(2))
              } else {
                nums.push(index)
              }
            })
            if (nums.length == this.saleGoodsInfo.goodsMallActivityRuleList.length) {
              this.$set(this.saleGoodsInfo, 'price', this.saleGoodsInfo.salePrice)
              this.$set(this.saleGoodsInfo, 'totalPrice', (this.saleGoodsInfo.salePrice * e).toFixed(2))
            }
          }
        } else {
          this.$set(this.saleGoodsInfo, 'price', this.saleGoodsInfo.salePrice)
          this.$set(this.saleGoodsInfo, 'totalPrice', (this.saleGoodsInfo.salePrice * e).toFixed(2))
        }

      }


      // addEditForm.num
    },
    goodsNumChange(e) {
      let price = (e * this.returnForm.price).toFixed(2)
      this.$set(this.returnForm, 'totalSalePrice', price)
      console.log(price)
      if (e > this.returnForm.num) {
        // this.$message({
        //   message: "退货数量不能大于购买数量",
        //   type: "warning",
        // });
        // this.$set(this.returnForm, "returnGoodsNum", "");
        // return;
      }
    },
    // 热销现货手动截单
    handleCloseOrder() {
      var _this = this;
      // 先判断勾选订单是否只为热销现货且已下单
      for (var i = 0; i < _this.multipleSelection.length; i++) {
        if (_this.multipleSelection[i].type != 4 || _this.multipleSelection[i].status != 0) {
          _this.$message({
            message: '只有热销现货订单且为已下单状态才可截单',
            type: "warning",
          });
          return;
        }
      }
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.get(this.$axios.defaults.basePath + '/saleChildOrder/closeOrder?orderId=' + _this.ids.join(","))
            .then(res => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });

    },
    // 导出下拉选择商品
    exportGoodsChange(e) {
      // this.$set(this.exportForm, 'goodsId', e.join(','))
    },
    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.boxMain >>> .el-image img {
  height: auto !important;
}
</style>
