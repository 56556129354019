<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="商品名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-cascader
            :options="goodsType"
            :show-all-levels="false"
            placeholder="请选择商品类型"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }"
            clearable
            @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品单位">
          <el-select v-model="searchForm.unit">
            <el-option
              v-for="item in goodsUnit"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input
            v-model="searchForm.mfrs"
            placeholder="请输入制造商"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.enabled" placeholder="请选择状态">
            <el-option
              v-for="item in goodsStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">商品列表</p>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :selectable="selectable"
            >
            </el-table-column>
            <el-table-column prop="icons" label="商品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image
                    style="width: 60px"
                    :src="scope.row.icons"
                    :preview-src-list="scope.row.imgs.split(',')"
                  >
                  </el-image>
                </div>
                <img
                  v-else
                  src="../assets/img/no_img.png"
                  style="width: 60px"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称"> </el-table-column>
            <el-table-column
              prop="describe"
              label="商品描述"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="color" label="颜色" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="category" label="类型" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="standard" label="规格" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="model" label="型号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="unit" label="单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="num" label="库存" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="price"
              label="采购单价(元)"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="sale"
              label="销售单价(元)"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="mfrs" label="制造商" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="enabled" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                <p v-else style="color: #67c23a">启用</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchaseRmark"
              label="备注"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
      <el-form
        :model="addEditForm"
        ref="addEditForm"
        :rules="addEditRules"
        :inline="true"
        label-width="120px"
      >
        <el-form-item label="商品名称" prop="name">
          <el-input
            v-model="addEditForm.name"
            placeholder="请输入商品名称"
            autocomplete="off"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-cascader
            style="width: 300px"
            :options="goodsType"
            :show-all-levels="false"
            v-model="goodsTypeId"
            placeholder="请选择商品类型"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }"
            :disabled="detailsShow"
            clearable
            @change="cascaderChange2"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品单位">
          <el-select
            v-model="addEditForm.unitId"
            style="width: 300px"
            @change="unitChange"
            :disabled="detailsShow"
          >
            <el-option
              v-for="item in goodsUnit"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色">
          <el-input
            v-model="addEditForm.color"
            placeholder="请输入颜色"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input
            v-model="addEditForm.model"
            placeholder="请输入型号"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input
            v-model="addEditForm.standard"
            placeholder="请输入规格"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存" v-if="detailsShow == true">
          <el-input
            v-model="addEditForm.num"
            placeholder="请输入规格"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input
            v-model="addEditForm.mfrs"
            placeholder="请输入制造商"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="addEditForm.enabled"
            placeholder="请选择状态"
            style="width: 300px"
            :disabled="detailsShow"
          >
            <el-option
              v-for="item in goodsStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
              :disabled="detailsShow"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            v-model="addEditForm.describe"
            placeholder="请输入描述"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="addEditForm.remark"
            placeholder="请输入备注"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图片">
          <el-upload
            :action="uploadUrl"
            :file-list="fileList"
            :headers="headers"
            name="files"
            :disabled="detailsShow"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')"
          >确 定</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsIndex",
  props: {
    warehouseName: {
      type: String,
      default: () => "",
    },
    isPresell: {
      type: [Number, String],
      default: () => 0,
    },
  },
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 商品类型
      goodsType: [],
      // 商品单位
      goodsUnit: [],
      // 商品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      // 查看详情表单禁止输入
      detailsShow: false,
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_unit_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_unit_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_unit_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_unit_query") {
        this.searchButton = "1";
      }
    });
  },
  methods: {
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goods", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            name: _this.searchForm.name,
            categoryId: _this.searchForm.categoryId,
            unit: _this.searchForm.unit,
            mfrs: _this.searchForm.mfrs,
            enabled: _this.searchForm.enabled,
            limit: this.isPresell ? (this.isPresell == 2 ? 2 : 0) : 1,
            warehouseName: this.warehouseName,
          },
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      this.$emit("update:sections", val);
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
      this.imgData = [];
      this.fileList = [];
    },
    // 修改
    handleEdit(row) {
      this.fileList = [];
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      row.enabled = row.enabled.toString();
      this.$nextTick(() => {
        this.addEditForm = row;
        this.goodsTypeId = [row.categoryId];
        if (row.imgs) {
          let imgArr = row.imgs.split(",");
          this.imgData = imgArr;
          imgArr.forEach((item) => {
            let json = {
              url: item,
            };
            this.fileList.push(json);
          });
        }
      });
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/goods/add";
          } else {
            url = "/goods/update";
          }
          if (this.imgData.length > 0) {
            _this.addEditForm.imgs = this.imgData.join(",");
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/goods/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      this.handleEdit(row);
      this.detailsShow = true;
    },
    // 启用
    handleUp(row) {
      let _this = this;
      row.enabled = "1";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/goods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 禁用
    handleDown(row) {
      let _this = this;
      row.enabled = "0";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/goods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      this.imgData.push(file.response);
    },
    // 上传图片删除
    handleRemove(file) {
      let imgArr = [];
      this.imgData.forEach((item) => {
        if (file.url != item) {
          imgArr.push(item);
        }
      });
      this.imgData = imgArr;
      let ind = "";
      this.fileList.forEach((item, index) => {
        if (file.url == item.url) {
          ind = index;
        }
      });
      this.fileList.splice(ind, 1);
    },
    //如果商品状态为禁用 0 表格复选框禁用
    selectable(row, index) {
      if (row.enabled == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped></style>
