<template>
  <div class="homePage">
    <!-- echarts -->
    <div class="home_drawTotal">
      <div class="home_echarts clearfix">
        <!-- 各仓库销售统计及查询明细 -->
        <div class="echarts_box clearfix">
          <div class="table_box">
            <p class="sort_title">仓库销售统计</p>
            <el-date-picker
              v-model="warehouseTime"
              type="datetimerange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="warehouseChange"
              style="top: 30px"
            >
            </el-date-picker>
            <template>
              <el-table
                ref="warehouseTable"
                :data="warehouseData"
                :row-class-name="handleRowClassName"
                @sort-change="sortChange"
                style="width: 100%"
                height="420"
              >
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="name" label="仓库名称">
                </el-table-column>
                <el-table-column prop="saleSum" label="销售总额（元）">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="lookWarehouseSaleInfo(scope.row)"
                      >查看明细</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!-- <el-button
              v-if="userSortData.length > 8"
              type="primary"
              style="display: block; margin: 20px auto"
              @click="dialogGoodsVisible = true"
              >查看更多</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
    <!-- 仓库销售明细 -->
    <el-dialog
      title="仓库销售明细"
      :visible.sync="warehouseVisible"
      width="80%"
    >
      <el-table
        ref="warehouseSaleTable"
        :data="warehouseTableData"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="name" label="商品名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="surplus" label="剩余库存" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="saleCnt" label="销售量" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="saleSum"
          label="销售总额（元）"
          sortable="custom"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div class="block" style="padding: 10px 15px">
        <el-pagination
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="pagesData3.currentPage"
          :page-sizes="pagesData3.rows"
          :page-size="pagesData3.currentRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagesData3.total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="warehouseVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "data-backups",
  data() {
    return {
      priceNum: {},
      goodsList: [],
      orderList: [],
      detailShow: false,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //分页数据
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      searchForm: {
        endTime: "",
        startTime: "",
        userid: "",
      },
      bannerSrc: require("../../assets/img/home_banner.png"),
      srcs: require("../../assets/home/user_lan.png"),
      // 用户总数（人）
      userTotal: 0,
      //
      myEcharts_line2: null,

      // 用户adcode ---------------------
      adcode: "",
      adcodeList: "",
      username: sessionStorage.getItem("userName"),
      // 用户所在地区
      userArea: "",
      // 下拉商品
      selectGoods: [],
      goodsId: "",
      // 仓库下拉
      // 仓库销售数据
      warehouseData: [],
      warehouseTime: [],
      warehouseVisible: false,
      warehouseTableData: [],
      warehouseId: "",
      //分页数据
      pagesData3: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
    };
  },
  created() {
    this.adcode = sessionStorage.getItem("adcode");
    this.userArea = CodeToText[this.adcode];
  },
  mounted() {
    this.getWarehouseList();
    this.initData();
  },
  methods: {
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeDatePicker(e) {
      this.searchForm.startTime = e[0];
      this.searchForm.endTime = e[1];
    },
    // 检索
    searchSubmit() {
      this.pagesData3.currentPage = 1;
      this.pagesData3.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this.searchForm.startTime = "";
      this.searchForm.endTime = "";
      this.pagesData3.currentPage = 1;
      this.pagesData3.currentRows = 10;
      this.listEvent();
    },
    //单个团长的详细数据-售后信息
    getUserReturnGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData2.currentPage;
      params.size = _this.pagesData2.currentRows;
      params.warehouseId = _this.warehouseId;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsStatistics/userReturnGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.orderList = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单个团长的详细数据-商品列表
    getUserGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData.currentPage;
      params.size = _this.pagesData.currentRows;
      params.warehouseId = _this.warehouseId;
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/goodsStatistics/userGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsList = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    handleRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "el-bg-gray" : "";
    },
    // 排名表格升序降序事件 传參：字段名+type
    sortChange(e) {
      console.log(e);
    },
    initData() {
      this.getwarehouseSaleTotal();
    },
    // 各仓库销售统计
    getwarehouseSaleTotal() {
      let _this = this;
      this.$axios
        .get(
          this.$axios.defaults.basePath + "/goodsStatistics/warehouseSaleSum",
          {
            params: {
              startTime:
                _this.warehouseTime && _this.warehouseTime.length > 0
                  ? _this.warehouseTime[0]
                  : "",
              endTime:
                _this.warehouseTime && _this.warehouseTime.length > 0
                  ? _this.warehouseTime[1]
                  : "",
            },
          }
        )
        .then((res) => {
          console.log(res);
          _this.warehouseData = res.data.data;
        });
    },
    warehouseChange(e) {
      this.getwarehouseSaleTotal();
    },
    lookWarehouseSaleInfo(row) {
      let _this = this;
      _this.warehouseId = row.id;
      this.$axios
        .get(
          this.$axios.defaults.basePath +
            "/goodsStatistics/warehouseSaleDetail",
          {
            params: {
              size: _this.pagesData3.currentRows,
              current: _this.pagesData3.currentPage,
              startTime:
                _this.warehouseTime && _this.warehouseTime.length > 0
                  ? _this.warehouseTime[0]
                  : "",
              endTime:
                _this.warehouseTime && _this.warehouseTime.length > 0
                  ? _this.warehouseTime[1]
                  : "",
              id: row.id,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.data.records.length == 0) {
            _this.$message({
              message: "暂无销售明细",
              type: "warning",
            });
            return;
          }
          _this.warehouseTableData = res.data.data.records;
          _this.pagesData3.total = res.data.data.total;
          _this.warehouseVisible = true;
        });
    },
    //分页--每页条数切换
    handleSizeChange3(val) {
      var _this = this;
      _this.pagesData3.currentRows = val;
      this.lookWarehouseSaleInfo({ id: _this.warehouseId });
    },
    //第几页
    handleCurrentChange3(val) {
      var _this = this;
      _this.pagesData3.currentPage = val;
      this.lookWarehouseSaleInfo({ id: _this.warehouseId });
    },
  },
};
</script>

<style scoped>
.homePage {
  margin-top: 56px;
  padding: 20px;
  box-sizing: border-box;
}
.home_banner {
  width: 100%;
  height: 336px;
  padding: 60px 70px 40px 70px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: url("../../assets/img/home_banner.png") no-repeat;
  background-size: 100% 100%;
}
.home_banner .home_title {
  color: #fff;
  font-size: 16px;
}
.home_title p:first-child {
  font-size: 30px;
  margin-bottom: 10px;
}
.home_total {
  margin-top: 0;
}
.home_total_title {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  margin-top: 15px;
}
.home_total ul li {
  float: left;
  min-width: 176px;
  padding: 25px;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  list-style: none;
  margin-right: 15px;
  background-color: rgba(243, 246, 253, 0.2);
}
.home_total ul li p:first-child + p {
  font-size: 30px;
  margin-top: 10px;
}
.home_drawTotal {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.home_drawTotal .total_title {
  font-size: 16px;
  margin-bottom: 10px;
}
.home_drawTotal .total_title .total_change span {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
}
.home_drawTotal .total_title .total_change span.active {
  color: #246eff;
}
.home_echarts {
  width: 100%;
}
.home_echarts > div {
  width: 100%;
  height: 600px;
  float: left;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div {
  border-top: 0;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div + div {
  border-right: 1px solid #e5e5e5;
}
.echarts_box {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.echarts_box >>> .el-date-editor {
  width: 380px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
.goodsSelect {
  position: absolute;
  top: 10px;
  right: 400px;
  z-index: 1000;
}
@media screen and (max-width: 1700px) {
  .goodsSelect {
    left: 24% !important;
  }
}
.echarts_box .echarts_item {
  width: 100%;
  height: 600px;
}

.weclone {
  color: #333;
  text-align: center;
  font-size: 50px;
  padding-top: 300px;
}
.pageMain .item {
  width: 100%;
  height: 180px;
  position: relative;
  cursor: pointer;
}
.pageMain .item .item_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: auto;
  height: 180px;
}
.pageMain .item .icon_zhuan {
  position: inherit;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  -webkit-animation: changeright 6s linear infinite;
}
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pageMain .item .item_main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.pageMain .item .item_main img {
  display: block;
  /*padding-left: 15px;*/
  margin: 0 auto 10px auto;
}
.home_sort {
  width: 100%;
  margin-top: 20px;
}
.table_box {
  width: 100%;
  height: 500px;
  float: left;
  padding: 20px 0;
  margin-left: 1.4%;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}
.table_box:first-child {
  margin-left: 0;
}
.table_box .sort_title {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-table {
  border: 1px solid #e3e3e3;
}

::v-deep .el-table thead {
  background: #e7efff;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-bg-gray,
::v-deep .el-table__row:hover {
  background: #f1f2f7 !important;
}
.nums {
  font-size: 20px;
  margin-left: 50px;
}
.nums span {
  font-size: 24px;
  font-weight: 600;
}
</style>
