<template>
  <div>
    <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
      <el-form-item label="商品名称">
        <el-input v-model="searchForm.name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="商品类型">
        <el-cascader :options="goodsType" v-model="goodsTypeId" :show-all-levels="false" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
      </el-form-item>
      <el-form-item label="商品单位">
        <el-select v-model="searchForm.unit">
          <el-option v-for="item in goodsUnit" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="制造商">
        <el-input v-model="searchForm.mfrs" placeholder="请输入制造商"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.enabled" placeholder="请选择状态">
          <el-option v-for="item in goodsStatus" :key="item.id" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
        <el-button @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="userTable boxMain">
      <p class="boxTitle">商品列表</p>
      <template>
        <el-table ref="multipleTable" :data="tableData" :stripe="true" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="icons" label="商品图片">
            <template slot-scope="scope">
              <div class="demo-image__preview" v-if="scope.row.icons">
                <el-image style="width: 60px" :src="scope.row.icons" :preview-src-list="scope.row.imgs.split(',')">
                </el-image>
              </div>
              <img v-else src="../assets/img/no_img.png" style="width: 60px" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="describe" show-overflow-tooltip label="商品描述">
          </el-table-column>
          <el-table-column prop="color" label="颜色"> </el-table-column>
          <el-table-column prop="category" label="类型"> </el-table-column>
          <el-table-column prop="standard" label="规格"> </el-table-column>
          <el-table-column prop="model" label="型号"> </el-table-column>
          <el-table-column prop="unit" label="单位"> </el-table-column>
          <el-table-column prop="num" label="库存"> </el-table-column>
          <el-table-column prop="mfrs" label="制造商"> </el-table-column>
          <el-table-column prop="enabled" label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
              <p v-else style="color: #67c23a">启用</p>
            </template>
          </el-table-column>
          <el-table-column prop="remark" show-overflow-tooltip label="备注">
          </el-table-column>
        </el-table>
        <div class="block" style="padding: 10px 15px">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
          </el-pagination>
        </div>
      </template>
    </div>
    <div class="btns">
      <el-button type="primary" :disabled="goods.length == 0" @click="handleSubmit">确定</el-button>
      <el-button @click="cancelAdd">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      goodsTypeId: [], //选择的商品类型id
      //搜索
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
        limit: "2", //1：销售单 2：采购单
      },
      tableData: [],
      goodsUnit: [], //商品单位
      goodsType: [], //商品类型
      // 商品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      goods: [],
      pageName: this.fromPage,
    };
  },
  props: ["fromPage", "warehouseId", 'type'],
  created() {
    this.listEvent();
    this.getGoodsUnit();
    this.getGoodsType();
  },
  methods: {
    //点击确定后 取消表格的选择
    handleSubmit() {
      this.$emit("goodsSelected", this.goods);
      this.$refs.multipleTable.clearSelection();
    },
    cancelAdd() {
      this.$emit("cancelDialog");
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      if (this.pageName == "warehouse") {
        _this.searchForm.limit = "";
      }
      let data = _this.searchForm;
      if (this.warehouseId) {
        data = Object.assign({}, data, {
          warehouseId: this.warehouseId,
        })
      }
      // 入库只查询主商品，出库查询全部商品
      // if(this.$props.type == 1){
      // data.mainGoods = 1
      // }
      let url = '';
      if (this.$props.type == 3) { //清库查询主产品列表
        url = '/warehouseDetailed/selectByPage'
      } else { //出入库查询产品列表
        url = '/goodsMall/selectWarehouseGoodsByPage'
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + url, {
          params: data,
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.loading = false;
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = key == "limit" ? 2 : "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.goodsTypeId = [];
      this.listEvent();
    },
    handleSelectionChange(val) {
      this.goods = val;
    },
  },
};
</script>

<style  scoped>
.btns {
  padding-top: 30px;
  text-align: center;
}
</style>
