<template>
  <div>
    <div class="pageMain">
      <el-form
          :model="searchForm"
          :inline="true"
          ref="searchForm"
          label-position="left"
          class="demo-form-inline"
          v-if="searchButton == '1'"
        >
          <el-form-item label="菜单ID">
            <el-input
              v-model="searchForm.id"
              placeholder="请输入菜单id"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="searchSubmit('searchForm')"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button @click="resetForm('searchForm')">重置</el-button>
          </el-form-item>
        </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">权限列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="handleAdd" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i>添加新权限</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length==0" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="menuId" label="绑定菜单ID">
            </el-table-column>
            <el-table-column prop="description" label="描述" width="200">
            </el-table-column>
            <el-table-column width="200" prop="created" label="创建时间">
            </el-table-column>
            <el-table-column align="center" width="160" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.current" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增、编辑权限-->
    <!-- <div class="alertEvent addPost" v-show="addShow">
      <div class="alertMsg" @click="cancelAdd('addShow')"></div>
      <div class="alertMain" style="width: 35%">
        <div class="alertTitle clearfix">
          <p class="float_lf">{{dialogTitle}}</p>
          <img class="float_rt" src="../../assets/img/del_icon.png" alt="" @click="cancelAdd('addShow')">
        </div>
        <div class="postForm">
          <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm">
            <el-form-item label="名称：" prop="name">
              <el-input v-model="formData.name" placeholder="请输入权限名称" style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item label="menuid：" prop="menuId">
              <el-input v-model="formData.menuId" style="width: 300px;" placeholder="请输入menuId（只允许输入数字）" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="description">
              <el-input v-model="formData.description" placeholder="请输入权限描述" style="width: 300px;" type="textarea"></el-input>
            </el-form-item>
            <el-form-item class="postBtn" style="display: block;text-align: center;">
              <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交
              </el-button>
              <el-button @click="cancelAdd('addShow')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div> -->
    <!--新增、编辑权限-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" width="60%">
      <div class="postForm">
        <el-form :model="formData" :rules="addPrivilege" ref="formData" :inline="true" label-width="140px" class="demo-ruleForm">
          <el-form-item label="名称：" prop="name">
            <el-input v-model="formData.name" placeholder="请输入权限名称" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="menuid：" prop="menuId">
            <el-input v-model="formData.menuId" style="width: 300px;" placeholder="请输入menuId（只允许输入数字）" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description">
            <el-input v-model="formData.description" placeholder="请输入权限描述" style="width: 300px;" type="textarea"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block;text-align: center;">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {

  data() {
    return {
      submitBtnLoad: false,
      index: '1',
      addShow: false,
      tableData: [],
      dialogTitle: '',
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //form表单数据
      formData: {
        name: '',
        menuId: '',
        description: '',
      },
      multipleTable: [],
      ids: [],
      id: '',
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      addPrivilege: {
        name: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }],
        menuId: [{
          required: true,
          message: '请输入menuId',
          trigger: 'blur'
        }],
      },
      searchForm:{
        id:''
      },
    }
  },

  created() {
    this.listEvent();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'sys_privilege_update') {
        this.updateButton = '1'
      } else if (item.authority == 'sys_privilege_query') {
        this.searchButton = '1'
      } else if (item.authority == 'sys_privilege_create') {
        this.addButton = '1'
      } else if (item.authority == 'sys_privilege_delete') {
        this.delButton = '1'
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'listEvent'
  },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      this.$axios.get(_this.$axios.defaults.basePath + '/sysPrivilege', {
        params: {
          id:_this.searchForm.id,
          current: _this.pagesData.currentPage,
          size: _this.pagesData.currentRows,
        }
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }
      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.pagesEvent(_this.pagesData.currentPage, val);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.pagesEvent(val, _this.pagesData.currentRows);
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios.get(_this.$axios.defaults.basePath + '/sysPrivilege', {
        params: {
          current: page,
          size: rows,
        }
      }).then(function (res) {
        _this.tableData = res.data.data.records;
        _this.pagesData.total = res.data.data.total;
      })
    },
    searchSubmit(){
      if(this.searchForm.id){
        this.listEvent()
      }else{
        this.$message({
          message:'请输入菜单ID',
          type:'warning'
        })
      }
    },
    resetForm(){
      this.$set(this.searchForm,'id','')
      this.listEvent()
    },
    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = '添加新权限';
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });

    },
    //删除操作
    handleDel() {
      var _this = this;
      this.roleId = this.ids.join(',');
      if (_this.ids.length == 0) {
        _this.$message.error('请选择要删除的内容');
        return false;
      } else {
        _this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var _this = this;
          _this.$axios({
            url: _this.$axios.defaults.basePath + '/sysPrivilege/delete',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(_this.ids)
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.listEvent();
            }
          })
        }).catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    //点击编辑按钮操作
    handleEdit(value) {
      this.addShow = true;
      this.dialogTitle = '编辑权限';
      this.formData = JSON.parse(JSON.stringify(value));
      this.id = value.id;
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true
          if (this.dialogTitle == '添加新权限') {
            this.$axios({
              url: _this.$axios.defaults.basePath + '/sysPrivilege/add',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                name: _this.formData.name,
                menuId: parseInt(_this.formData.menuId),
                description: _this.formData.description,
              })
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false
                _this.$message({
                  message: res.data.data,
                  type: 'success'
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false
              }
            })
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + '/sysPrivilege/update',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                id: _this.id,
                name: _this.formData.name,
                menuId: parseInt(_this.formData.menuId),
                description: _this.formData.description,
              })
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false
                _this.$message({
                  message: res.data.data,
                  type: 'success'
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false
              }
            })
          }
        } else {
          return false;
        }
      });
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
  }
}
</script>
