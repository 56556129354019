<template>
  <div class="goods">
    <div class="pageMain">
      <div class="boxMain">
        <p class="boxTitle">商品类型列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <!-- <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl3" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess" :on-error="handleAvatarErr" :headers="importHeaders" v-if="updateButton == '1'">
            <el-button type="primary" class="el-button--mini" v-loading="uploadLoading">商品类型数据导入</el-button>
          </el-upload> -->
        </div>
        <template>
          <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" row-key="id" default-expand-all :tree-props="{ children: 'childs', hasChildren: 'hasChildren' }">
            <!-- <el-table-column prop="id" label="ID"> </el-table-column> -->
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="categoryLevel" label="级别">
            </el-table-column>
            <el-table-column prop="parentId" label="父级ID">
              <template slot-scope="scope">{{
                scope.row.parentId ? scope.row.parentId : "暂无"
              }}</template>
            </el-table-column>
            <el-table-column prop="created" label="创建时间"> </el-table-column>
            <el-table-column align="center" width="280" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)" class="el-icon-delete" v-if="delButton == '1'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true">
        <el-form-item label="类型名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="addEditForm.name" placeholder="请输入类型名称" autocomplete="off" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="类型排序" :label-width="formLabelWidth">
          <el-input v-model="addEditForm.sort" autocomplete="off" style="width: 300px" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="所属上级" :label-width="formLabelWidth">
          <el-cascader :options="tableData" :show-all-levels="false" v-model="parentId" placeholder="请选择所属上级" style="width: 300px" :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsType",
  data() {
    return {
      uploadLoading: false,
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      uploadUrl3: this.$axios.defaults.basePath + "/import/importGoodsType",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      hasChildren: true,
      // 弹框标题
      dialogTitle: "",
      dialogFormVisible: false,
      // 表单数据
      addEditForm: {
        name: "",
        sort: "",
        parentId: "",
        categoryLevel: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入类型名称", trigger: "blur" }],
      },
      // label宽度
      formLabelWidth: "100px",
      // 所属父级回显
      parentId: "",
    };
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_category_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_category_create") {
        this.addButton = "1";
      } else if (item.authority == "goods_category_delete") {
        this.delButton = "1";
      }
    });
  },
  methods: {
    handleAvatarErr() {
      this.uploadLoading = false;
    },
    //导入修改订单状态数据
    uploadSuccess(res, file) {
      this.uploadLoading = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    beforeAvatarUpload(file) {
      this.uploadLoading = true;
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 类型新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
      this.parentId = "";
    },
    // 类型修改
    handleEdit(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsCategory/getGoodsCategoryDetail", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
            that.addEditForm = data;
            that.$nextTick(() => {
              that.parentId = data.parentId;
            });
          }
        });

    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!_this.addEditForm.parentId) {
            _this.addEditForm.categoryLevel = "1级";
          }
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/goodsCategory/add";
          } else {
            url = "/goodsCategory/update";
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 类型删除
    handleDelete(id) {
      let _this = this;
      _this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsCategory/delete", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.addEditForm.parentId = e[e.length - 1];
      this.addEditForm.categoryLevel = e.length + 1 + "级";
    },
  },
};
</script>

<style scoped>
.goods >>> .el-table__row.expanded .el-table_1_column_1 {
  padding-left: 10px;
}
</style>