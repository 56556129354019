<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="产品名称：">
          <el-input v-model="searchForm.goodsName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="所属仓库">
          <el-select v-model="searchForm.warehouseId" placeholder="请选择所属仓库">
            <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期范围：">
          <el-date-picker @change="changedatepicker($event)" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">清库管理</p>
        <div class="tableTopBtn">
          <el-button type="primary" v-if="addButton == '1'" @click="handleAdd" size="mini">新增</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column prop="goodsName" label="产品名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="standard" label="规格名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="goodsNum" label="数量" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="totalNum" label="总量" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="warehouseName" label="所属仓库" show-overflow-tooltip></el-table-column>
            <el-table-column prop="created" label="创建时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip>
            </el-table-column>

          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/修改 -->
    <el-dialog :title="addEditTitle" :visible.sync="addEditShow" width="85%">
      <div>
        <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true">
          <el-form-item prop="warehouseId" label="选择仓库">
            <el-select v-model="addEditForm.warehouseId" @change="warehouseChange">
              <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item prop="warehouseId" label="选择出库类型">
            <el-select v-model="addEditForm.returnType">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input type="textarea" placeholder="请输入备注" style="width: 300px" v-model="addEditForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="changeInputGoods" type="primary" style="margin-bottom: 20px">选择商品</el-button>
        <el-table ref="goodsFormTable" :data="addEditForm.warehouseDetailedList" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
          <el-table-column prop="standard" label="规格"> </el-table-column>
          <el-table-column prop="num" label="库存"> </el-table-column>
          <el-table-column prop="totalNum" label="总量"> </el-table-column>
          <!-- <el-table-column prop="goodsNum" label="数量" width="210px"></el-table-column> -->
          <!-- <el-table-column prop="price" label="出库单位成本"></el-table-column>
          <el-table-column prop="sale" label="出库成本"></el-table-column>
          <el-table-column prop="enabled" label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
              <p v-else-if="scope.row.enabled == 1" style="color: #67c23a">
                启用
              </p>
              <p v-else></p>
            </template>
          </el-table-column> -->
          <el-table-column prop="remark" label="备注">
            <template slot-scope="scope">
              <el-input v-if="addEditForm.remark" placeholder="备注" v-model="addEditForm.remark"></el-input>
              <el-input v-else placeholder="备注" v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button size="medium" type="danger" class="el-icon-minus" @click="chengeOrderItem(scope)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addEditShow = false">关 闭</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 商品列表 -->
    <el-dialog :title="addEditTitle" :visible.sync="changeGoodsShow" width="85%">
      <div>
        <goods v-if="changeGoodsShow" :type="3" :fromPage="pageName" :warehouseId="addEditForm.warehouseId" @goodsSelected="goodschange" @cancelDialog="cancelDialog"></goods>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goods from "../components/purchaseGoods2.vue";
export default {
  name: "goodsIndex",
  components: {
    goods,
  },
  data() {
    return {
      warehouseName: "",
      loading: false,
      tableData: [],
      addButton: "",
      searchButton: "",
      searchForm: {
        goodsName: "",
        name: "",
        warehouseId: "",
        status: "",
        type: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 50, 100, 200, 500, 1000],
      },
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      // 仓库数据
      warehouseData: [],
      // 新增编辑
      addEditForm: {
        // 1入库，2出库
        form: "",
        status: 0,
        warehouseName: "",
        warehouseId: "",
        type: "",
        // 商品列表
        warehouseDetailedList: [],
      },
      addEditShow: false,
      addEditRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ],
      },
      addEditTitle: "",
      // 选择商品
      changeGoodsShow: false,
      addEditGoodsList: [],
      // 选择商品回參
      sections: [],
      // 商品组件传參
      pageName: "warehouse",
      // 出库类型
      typeList: [],
      sumTotal: '',
      datePicker: [],
    };
  },
  watch: {
    addEditShow: {
      handler(n, o) {
        if (!n) {
          Object.keys(this.addEditForm).forEach((key) => {
            this.addEditForm[key] = "";
          });
          this.addEditForm.warehouseDetailedList = []
        }
      }
    }
  },
  created() {
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "warehouse_detailed_query") {
        this.searchButton = "1";
      } else if (item.authority == "warehouse_detailed_add") {
        this.addButton = "1";
      }
    });
    this.warehouse()
    this.listEvent()
  },
  methods: {
    chengeOrderItem(scope) {
      this.addEditForm.warehouseDetailedList.splice(scope.$index, 1)
    },
    changedatepicker(e) {
      console.log(e)
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    cancelDialog() {
      this.changeGoodsShow = false;
    },
    // 获取出库类型列表
    getOuterList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectByList?type=4")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.typeList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 清库列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      let data = {
        current: _this.pagesData.currentPage,
        size: _this.pagesData.currentRows,
        status: _this.searchForm.status,
        warehouseId: _this.searchForm.warehouseId,
        form: _this.searchForm.type,
        goodsName: _this.searchForm.goodsName,
        startTime: _this.searchForm.startTime,
        endTime: _this.searchForm.endTime,
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouseDetailed", {
          params: data,
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            console.log(res.data.data)
            _this.sumTotal = res.data.data.sumTotalPrice
            _this.tableData = res.data.data.warehouseDetailedPage.records;
            _this.pagesData.total = res.data.data.warehouseDetailedPage.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 仓库列表
    warehouse() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res, "asdasdd")
          if (res.data.errcode == 0) {
            _this.warehouseData = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 商品列表
    getGoodsList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouseDetailed/selectByPage", {
          params: {
            current: _this.pagesData2.currentPage,
            size: _this.pagesData2.currentRows,
            name: _this.addEditForm.warehouseName,
            warehouseId: _this.addEditForm.warehouseId,
          },
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.addEditGoodsList = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this.datePicker = []
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this[formName] = {};
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      this.listEvent();
    },
    // 新增
    handleAdd() {
      this.addEditTitle = "新增清库信息";
      this.addEditShow = true;
    },
    // 选择仓库
    warehouseChange() {
      var id = this.addEditForm.warehouseId;
      var name = "";
      this.warehouseData.forEach((item) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      this.$set(this.addEditForm, "warehouseName", name);
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addEditForm.warehouseDetailedList.length > 0) {
            let data = []
            this.addEditForm.warehouseDetailedList.forEach(item => {
              let json = {
                goodsId: item.id,
                remark: item.remark,
                warehouseId: this.addEditForm.warehouseId
              }
              data.push(json)
            })
            this.$axios({
              url: _this.$axios.defaults.basePath + '/warehouseDetailed/add',
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(data),
            }).then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.addEditShow = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            _this.$message({
              message: "请选择商品",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 选择商品显示商品列表
    changeInputGoods() {
      console.log(this.addEditForm)
      if (this.addEditForm.warehouseName) {
        this.warehouseName = this.addEditForm.warehouseName;
        this.getGoodsList();
        this.changeGoodsShow = true;
      } else {
        this.$message({
          message: "请选择仓库",
          type: "warning",
        });
      }
    },
    // 商品组件选中商品回參
    goodschange(e) {
      e.forEach((item) => {
        item.goodsNum = 1;
        item.goodsId = item.id;
        item.goodsName = item.name;
        item.remark = item.purchaseRmark;
      });
      this.changeGoodsShow = false;
      this.addEditForm.warehouseDetailedList =
        this.addEditForm.warehouseDetailedList.concat(
          JSON.parse(JSON.stringify(e))
        );
    },
    //合计
    getSummaries(param) {
      let _this = this;
      const { columns, data } = param;
      const sums = [];
      let ml = null //销售毛利
      let sr = null //销售收入
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "totalPrice") {
          sums[index] = Number(_this.sumTotal).toFixed(2) + ' 元'

        }
      });

      return sums;
    },
  },
};
</script>
