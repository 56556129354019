<template>
  <div class="page">
    <div class="pageMain">
      <div class="postForm" v-loading="loading">
        <div class="tableTopBtn">
          <el-button size="mini" type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')"
            :disabled="fromType != 2">保存
          </el-button>
          <el-button size="mini" type="primary" :loading="submitBtnLoad" :disabled="fromType != 1"
            @click="handleSubmit('formData')">保存并新增
          </el-button>
        </div>
        <el-form
            :model="formData"
            :inline="true"
            :rules="addRules"
            ref="formData"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item label="名称：" prop="name">
              <el-input
                v-model="formData.name"
                style="width: 220px"
                placeholder="请输入名称"
                :disabled="fromType == 3"
              ></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="desc">
              <el-input
                v-model="formData.desc"
                style="width: 220px"
                :disabled="fromType == 3"
                placeholder="请输入描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型：" prop="type">
              <el-select
                v-model="formData.type"
                :disabled="fromType == 3"
                placeholder="请选择菜单类型"
                style="width: 220px"
                @change="changeMenu()"
              >
                <el-option
                  v-for="item in type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input
                v-model="formData.sort"
                :disabled="fromType == 3"
                style="width: 220px"
                placeholder="只允许输入数字"
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              ></el-input>
            </el-form-item>
            <el-form-item label="URL：" prop="targetUrl">
              <el-input
                v-model="formData.targetUrl"
                :disabled="fromType == 3"
                style="width: 220px"
                placeholder="请输入URL"
              ></el-input>
            </el-form-item>
            <el-form-item label="父级ID：" prop="parentId">
              <el-input
                v-model="formData.parentId"
                :disabled="fromType == 3"
                style="width: 220px"
                placeholder="只允许输入数字"
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              ></el-input>
            </el-form-item>
            <el-form-item label="parentKey：" prop="parentKey">
              <el-input
                v-model="formData.parentKey"
                :disabled="fromType == 3"
                style="width: 220px"
                placeholder="只允许输入数字"
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
              <el-radio-group v-model="formData.status" style="width: 220px" :disabled="fromType == 3">
                <el-radio :label="0">禁用</el-radio>
                <el-radio :label="1">启用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="未选中图标：" v-if="formData.type == 1" style="display: block;">
              <el-upload
                :action="uploadUrl"
                :disabled="fromType == 3"
                name="files"
                :limit="1"
                accept=".jpg,.png,.jpeg"
                :file-list="fileList"
                :headers="headers"
                list-type="picture-card"
                :on-success="handleAvatarSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="选中图标：" v-if="formData.type == 1" style="display: block;">
              <el-upload
                :action="uploadUrl"
                :disabled="fromType == 3"
                name="files"
                :limit="1"
                accept=".jpg,.png,.jpeg"
                :file-list="hoverFileList"
                :headers="headers"
                list-type="picture-card"
                :on-success="handleAvatarSuccessHover"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            
          </el-form>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
  </div>
</template>
<script>
export default ({
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.imgPath + "/image/AliYunImgUploadList",
      loading: false,
      formData: {
        name: "",
        desc: "",
        sort: "",
        status: "",
        targetUrl: "",
        type: "",
        icon: "",
        iconHover: "",
        parentId: "",
        parentKey: "",
        iconPath: "",
        iconHoverPath: "",
      },
      addRules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择菜单类型", trigger: "change" },
        ],
      },
      submitBtnLoad: false,
      id: '',
      dialogImageUrl: "",
      dialogVisible: false,
      type: [
        {
          value: 1,
          label: "一级菜单",
        },
        {
          value: 2,
          label: "二级菜单",
        },
        {
          value: 3,
          label: "三级菜单",
        },
      ],
      fromType:'',
      fileList:[],
      hoverFileList: [],
    }
  },
  created() {

  },  
  mounted() {
    this.fromType = this.$route.params.type || 1
    if (this.fromType != 1) {
      this.id = this.$route.params.id
      this.handleDetails()
    }
  },
  methods: {
    
    //选择菜单类型触发方法
    changeMenu() {
      var type = this.formData.type;
      if (type == 1) {
        this.iconShow = true;
      } else {
        this.iconShow = false;
      }
    },
    handleSubmit(formName) {
      var _this = this;
      _this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.fromType == 1) {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysMenu/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                name: _this.formData.name,
                desc: _this.formData.desc,
                icon: _this.formData.icon,
                iconHover: _this.formData.iconHover,
                sort: _this.formData.sort,
                targetUrl: _this.formData.targetUrl,
                status: _this.formData.status,
                type: _this.formData.type,
                parentId: _this.formData.parentId,
                parentKey: _this.formData.parentKey,
                iconPath: _this.formData.iconPath,
                iconHoverPath: _this.formData.iconHoverPath,
                // quick: _this.formData.quick,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.$router.push({ name: 'menu_list' })
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysMenu/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                id: _this.id,
                name: _this.formData.name,
                desc: _this.formData.desc,
                icon: _this.formData.icon,
                iconHover: _this.formData.iconHover,
                sort: _this.formData.sort,
                targetUrl: _this.formData.targetUrl,
                status: _this.formData.status,
                type: _this.formData.type,
                parentId: _this.formData.parentId,
                parentKey: _this.formData.parentKey,
                iconPath: _this.formData.iconPath,
                iconHoverPath: _this.formData.iconHoverPath,
                // quick: _this.formData.quick,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.$router.push({name:'menu_list'})
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleDetails() {
      var _this = this;
      this.loading = true
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysMenu/info", {
          params: {
            menuId: _this.id,
          },
        })
        .then(function (res) {
          _this.loading = false
          var resData = res.data;
          if (resData.errcode == 0) {
            _this.formData = resData.data;
            _this.fileList = [];
            _this.hoverFileList = [];
            let obj = {
              url: resData.data.icon,
            };
            let obj1 = {
              url: resData.data.iconHover,
            };
            _this.fileList.push(obj);
            _this.hoverFileList.push(obj1);
          }
        });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      this.formData.icon = file.response;
    },
    handleAvatarSuccessHover(res, file) {
      this.formData.iconHover = file.response;
    },

    beforeAvatarUpload(file) { },
    handleRemove(file, fileList) { },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  }
})
</script>
<style>
.postForm .el-form-item__label {
  width: 140px !important;
}
</style>