<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="用户姓名：">
          <el-input v-model="searchForm.userName" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker @change="changedatepicker($event)" :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">操作日志列表</p>
        <template>

          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="type" label="日志类型" width="100">
              <template slot-scope="scope">
                {{scope.row.type==1?'查询':scope.row.type==2?'修改':scope.row.type==3?'新增':scope.row.type==4?'删除':scope.row.type==5?'导出':scope.row.type==6?'审核':scope.row.type==7?'上传':scope.row.type==8?'下载':scope.row.type==9?'登录':scope.row.type==10?'异常':''}}
              </template>
            </el-table-column>
            <el-table-column prop="username" label="操作用户" width="200">
            </el-table-column>
            <el-table-column prop="created" label="操作时间" width="200">
            </el-table-column>
            <el-table-column prop="ip" label="IP" width="150">
            </el-table-column>
            <el-table-column prop="method" show-overflow-tooltip label="请求方法名">
            </el-table-column>
            <el-table-column prop="params" show-overflow-tooltip label="请求参数">
            </el-table-column>
            <el-table-column prop="time" label="执行时长" width="150">
              <template slot-scope="scope">
                {{scope.row.time+'ms'}}
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      datePicker: [],
      //搜索
      searchForm: {
        userName: "", //用户姓名
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      loading: true,
      multipleTable: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      infoButton: '',

    }
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'sysUserLog_query') {
        this.searchButton = '1'
      }
    });
  },
  methods: {
    changedatepicker(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      _this.searchForm.type = 1
      this.$axios.get(_this.$axios.defaults.basePath + '/sysUserLog', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => {
        this.searchForm[key] = ''
      })
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.datePicker = []
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },


  }
}
</script>

<style scoped>
</style>
