<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button type="primary" :loading="btnLoading" @click="addEditSubmit('addChildEditForm')">提 交</el-button>
      </div>
      <div class="goods">
        <el-form :model="addChildEditForm" ref="addChildEditForm" :rules="addEditRules" :inline="true" label-width="120px"
          style="padding: 20px">
          <!-- 规格信息 -->
          <div class="standard_box">
            <div v-for="(child, i) in addChildEditForm.goodsStandard" :key="i">
              <div class="standard_list">
                <el-button v-if="dialogTitle != '编辑规格信息' && childIndex == null" class="float_rt" size="mini" type="danger" @click="delGoodsType(i, child.id, addEditForm.warehouseId)">删除</el-button>
                <el-button v-if="dialogTitle != '编辑规格信息' && childIndex==null" class="float_rt" size="mini" type="primary" style="margin-right:20px;" @click="addChildGoods(i, addEditForm)">添加子产品</el-button>
                <el-form-item label="产品规格" :prop="'goodsStandard[' + i + '].standard'">
                  <el-input v-model="child.standard" placeholder="请输入产品规格" style="width: 300px"
                    :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="采购价(元)">
                  <el-input v-model="child.purchasing" placeholder="请输入采购价" style="width: 300px"
                    :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="原价(元)">
                  <el-input v-model="child.price" placeholder="请输入原价" style="width: 300px"
                    :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="乡兴供货价(元)">
                  <el-input v-model="child.salePrice" placeholder="请输入乡兴供货价" style="width: 300px"
                    :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="自提价(元)">
                  <el-input v-model="child.takeSale" placeholder="请输入自提价格" style="width: 300px"
                    :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="批发商价(元)">
                  <el-input v-model="child.wholesalePrice" placeholder="请输入批发商价格"
                    style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="起订量" v-if="addEditForm.type != 3" :prop="'goodsStandard[' + i + '].minimum'">
                  <el-input v-model="child.minimum" placeholder="请输入起订量" style="width: 300px"
                    :disabled="minimumchange" @input="changeMinimum"></el-input>
                </el-form-item>
                <el-form-item label="限购份数" v-if="addEditForm.type == 3" :prop="'goodsStandard[' + i + '].limit'">
                  <el-input :min="1" v-model="child.limit" placeholder="请输入限购份数"
                    style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="规格比" :prop="'goodsStandard[' + i + '].scaledValue'">
                  1 : <el-input :min="1" v-model="child.scaledValue" placeholder="请输入规格比"
                    style="width: 280px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="是否为预售产品">
                  <el-switch v-model="child.isPresell" active-color="#13ce66" active-text="是"
                    inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                  </el-switch>
                </el-form-item>
                <el-form-item label="是否为现称产品">
                  <el-switch v-model="child.isCurrentWeighing" active-color="#13ce66"
                    active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                  </el-switch>
                </el-form-item>
                <el-form-item label="" style="margin-left:80px">
                  <el-button type="primary" v-if="dialogTitle == '销售产品编辑'"
                    @click="synchronizePrice(child)">同步</el-button>
                </el-form-item>
                <el-form-item v-if="addEditForm.id && addEditForm.isActivity == 1" label="活动规则"
                  style="display: block;">
                  <div v-if="child.goodsMallActivityRuleList && child.goodsMallActivityRuleList.length > 0">
                    <div v-if="activityType == 0">
                      <p v-for="(sItem, standI) in child.goodsMallActivityRuleList" :key="standI">{{ '满' +
                        sItem.threshold + '份，单价 ' + sItem.discounts + '元' }}</p>
                    </div>
                    <div v-if="activityType == 1">
                      <p v-for="(sItem, standI) in child.goodsMallActivityRuleList" :key="standI">{{ '满' +
                        sItem.threshold + '份，赠送 ' + sItem.giftName + ' ' + sItem.discounts + '份' }}</p>
                    </div>
                  </div>
                  <el-button type="primary" size="mini" @click="settingRules(i)"
                    :disabled="detailsShow">设置活动规则</el-button>
                </el-form-item>
                <el-form-item label="产品规格图片" prop="imgs" style="display: block">
                  <cropperAndUploadVue :disabled="detailsShow" :list="child.standardImg" :size="[11, 11]"
                    :limit="1" :sortable="false" @change="(list) => handleChange(list, i)"
                    @success="(list) => handleSuccess(list, i)"></cropperAndUploadVue>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 设置活动规则 -->
    <active-rule-setting v-if="rulesShow" :dialogShow="rulesShow" :ruleList="ruleList" :guiGeList="guiGeList" :giftGoodsList="giftGoodsList" :activityType="activityType" :goodsIds="goodsIds" @pushRules="pullRules" @closeClick="closeDialog"></active-rule-setting>

  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
export default {
  name: "salesGoodsAdd",
  components: {
    vuedraggable,
    cropperAndUploadVue: () => import("@/components/cropperAndUpload.vue"),
    activeRuleSetting: () => import("@/components/activeRulesSetting.vue"),
  },
  data() {
    return {
      detailsShow:false,
      btnLoading:false,
      addEditForm:{},
      addChildEditForm:{
        goodsStandard: [
          {
            code: '',
            standard: "",
            purchasing: "",
            price: "",
            salePrice: "",
            takeSale: "",//自提价格
            num: "",
            minimum: "",
            limit: "",
            isPresell: "0",//是否为预售商品 0为否1为是
            isCurrentWeighing: "0",//是否为现称商品 0为否1为是
            // 隐藏积分相关功能
            // pointNum: "",
            // point: "",
            standardImg: [],
            isActivity: 0,
            goodsMallActivityRuleList: [],
            wholesalePrice: '', // 批发商价
            scaledValue: '', //规格比
            type: 2, // 0正常商品、1主产品、2子产品
          },
        ],
      },
      
      // 起订量是否可修改 判断已上架的产品在下架后不可修改起订量
      minimumchange: false,
      dialogTitle:'',
      rulesShow:'',
      ruleList:[
        {
          discounts: '',
          threshold: '',
        },
      ],
      nowIndex:'',
      parentIndex:null,
      guiGeList:[],
      goodsIds:[],
      standardIndex:'',
      activityType:0,
      giftGoodsList:[],
      childIndex:'',
      // 来源标识 判断是从哪个页面进入 1销售产品列表页 2销售产品编辑页
      flag:'',
      type:'',
      imgData:[],
    };
  },
  computed: {
    // tabOrderType() {
    //   return [
    //     {
    //       label: "全部",
    //       id: "",
    //     },
    //     ...this.orderType,
    //   ];
    // },
    addEditRules() {
      // 结束时间不能小于开始时间验证
      var timeRules = (rule, value, callback) => {
        var start = new Date(this.addEditForm.startTime).getTime();
        var end = new Date(value).getTime();
        if (end < start) {
          return callback(new Error("结束时间不能小于开始时间！"));
        } else {
          callback();
        }
      };
      let data = {
        goodsId: [{ required: true, message: "请选择产品", trigger: "change" }],
        standard: [
          { required: true, message: "请输入产品规格", trigger: "blur" },
        ],
        purchasing: [
          { required: true, message: "请输入产品采购价", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入产品原价", trigger: "blur" }],
        salePrice: [
          { required: true, message: "请输入产品原价", trigger: "blur" },
        ],
        // num: [{ required: true, message: "请输入产品库存", trigger: "blur" }],
        pointNum: [
          { required: true, message: "请输入积分倍数", trigger: "blur" },
        ],
        point: [{ required: true, message: "请输入所需积分", trigger: "blur" }],
        type: [
          { required: true, message: "请选择订单类型", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
          { validator: timeRules, trigger: "blur" },
        ],
        warehouseId: [
          { required: true, message: "请选择所属仓库", trigger: "change" },
        ],
      };
      this.addChildEditForm.goodsStandard.forEach((standard, index) => {
        data = Object.assign({}, data, {
          // ["goodsStandard[" + index + "].code"]: [
          //   { required: true, message: "请输入商品编码", trigger: "blur" },
          // ],
          ["goodsStandard[" + index + "].standard"]: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].purchasing"]: [
            { required: true, message: "请输入采购价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].price"]: [
            { required: true, message: "请输入原价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].salePrice"]: [
            { required: true, message: "请输入乡兴供货价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].num"]: [
            { required: true, message: "请输入库存", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].minimum"]: [
            { required: true, message: "请输入起订量", trigger: "blur" },
          ],
          // scaledValue
          ["goodsStandard[" + index + "].scaledValue"]: [
            { required: true, message: "请输入规格比", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].limit"]: [
            { required: true, message: "请输入限购份数", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].pointNum"]: [
            { required: true, message: "请输入积分倍数", trigger: "blur" },
          ],
        });
      });

      return data;
    },
  },
  created() {
    if(this.$route.params.data){
      
      this.flag = this.$route.params.flag ? this.$route.params.flag :''

      this.addEditForm = JSON.parse(this.$route.params.data)
      console.log(this.$route.params,this.addEditForm)
      this.dialogTitle = this.$route.params.title
      this.parentIndex = this.$route.params.index
      // && this.$route.params.type == 'editStandard'
      this.childIndex = this.$route.params.childIndex
      
      if(this.$route.params.type){
        this.type = this.$route.params.type
        this.handleGoodsInfo();
      }else{
        let child = this.addChildEditForm.goodsStandard
        child.forEach(item => {
          item.isActivity = this.addEditForm.isActivity
          item.standardImg = Array.isArray(item.standardImg) ? item.standardImg : item.standardImg.split(',')
        })
        this.activityType = child.activityType
        this.$set(this.addChildEditForm, 'goodsStandard', child)
      }
    }
    
  },
  mounted(){
    
  },
  methods: {
    // 修改起订量
    changeMinimum(){},
    // 删除
    delGoodsType(i){
      let goodsStandard = this.addChildEditForm.goodsStandard
      goodsStandard.splice(i,1)
    },
    // 添加子产品
    addChildGoods(){
      let goodsStandard = this.addChildEditForm.goodsStandard
      let json = {
        code: '',
        standard: "",
        purchasing: "",
        price: "",
        salePrice: "",
        takeSale: "",//自提价格
        num: "",
        minimum: "",
        limit: "",
        isPresell: "0",//是否为预售商品 0为否1为是
        isCurrentWeighing: "0",//是否为现称商品 0为否1为是
        // 隐藏积分相关功能
        // pointNum: "",
        // point: "",
        standardImg: [],
        isActivity: this.addEditForm.isActivity,
        goodsMallActivityRuleList: [],
        wholesalePrice: '', // 批发商价
        scaledValue: '', //规格比
        type: 2, // 0正常商品、1主产品、2子产品
      };
      goodsStandard.push(json)
      this.$set(this.addChildEditForm,'goodsStandard', goodsStandard)
    },

    addRules(index) {
      let json = {
        discounts: '',
        threshold: '',
      }
      this.ruleList.push(json)
    },
    delRules(index) {
      this.ruleList.splice(index, 1)
    },
    // 设置活动规格
    settingRules(i) {
      console.log(i, this.addChildEditForm.goodsStandard)
      this.nowIndex = i;
      this.guiGeList = [];
      this.goodsIds = [];
      this.standardIndex = i
      if (this.addChildEditForm.goodsStandard[i].goodsMallActivityRuleList && this.addChildEditForm.goodsStandard[i].goodsMallActivityRuleList.length > 0) {
        this.ruleList = this.addChildEditForm.goodsStandard[i].goodsMallActivityRuleList
      }

      if (this.addChildEditForm.goodsStandard[i].id) {
        this.guiGeList = this.addChildEditForm.goodsStandard
        this.goodsIds.push(this.addChildEditForm.goodsStandard[i].id)
        this.activityType = this.addChildEditForm.goodsStandard[i].activityType
      }
      this.getGiftList();
      this.rulesShow = true
    },
    // 提交规则
    submitRules() {
      let _this = this;
      if (this.ruleList.length == 0) {
        this.$message({
          message: '请至少设置一条规则',
          type: 'warning'
        })
      } else {
        let flag = true
        for (let i = 0; i < this.ruleList.length; i++) {
          if (this.ruleList[i].discounts == '' || this.ruleList[i].threshold == '') {
            this.$message({
              message: '购买数量和单价不能为空',
              type: 'warning'
            })
            flag = false
            break;
          } else {
            flag = true
          }
        }

        if (flag) {
          // 如果当前规格已存在
          if (this.addChildEditForm.goodsStandard.length > 0) {
            // this.goodsStandard[this.standardIndex].id
            this.ruleList.forEach((item, index) => {
              item.goodsId = this.addChildEditForm.goodsStandard[this.standardIndex].id
              item.level = index + 1
            })
            this.addChildEditForm.goodsStandard.forEach((item, index) => {
              if (index == this.standardIndex) {
                item.goodsMallActivityRuleList = this.ruleList
              }
            })
            this.rulesShow = false
            // this.$axios({
            //   url: _this.$axios.defaults.basePath + '/goodsMallActivityRule/update',
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   data: _this.ruleList,
            // }).then((res) => {
            //   if (res.data.errcode == 0) {
            //     _this.$message({
            //       message: '操作成功',
            //       type: "success",
            //     });
            //     _this.$set(_this.addEditForm, "goodsStandard", _this.goodsStandard);
            //     _this.rulesShow = false
            //     _this.$router.push({ name: 'salesGoods', params: { type: 'back' } })
            //   } else {
            //     _this.$message({
            //       message: res.data.errmsg,
            //       type: "warning",
            //     });
            //   }
            // });
          } else {
            this.ruleList.forEach((item, index) => {
              item.level = index + 1
            })
            this.addChildEditForm.goodsStandard.forEach((item, index) => {
              if (index == this.standardIndex) {
                item.goodsMallActivityRuleList = this.ruleList
              }
            })
            this.rulesShow = false
          }

          return
          let url = ''
          if (this.dialogTitle == "销售产品新增" || this.dialogTitle == "添加规格信息") {
            url = '/goodsMallActivityRule/add'
          } else {
            url = '/goodsMallActivityRule/update'
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: _this.ruleList,
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: '操作成功',
                type: "success",
              });
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        }

      }

    },
    closeDialog() {
      this.rulesShow = false
    },
    // 接收活动规则组件传值
    pullRules(data, type) {
      console.log(data)
      this.ruleList = data
      this.activityType = data[0].type
      this.$set(this.addChildEditForm.goodsStandard[this.nowIndex], 'goodsMallActivityRuleList', data)
      this.$set(this.addChildEditForm.goodsStandard[this.nowIndex], 'activityType', data[0].type)
    },
    // 获取赠品列表
    getGiftList() {
      let _this = this;
      // 获取赠品列表
      this.$axios
        .get(this.$axios.defaults.basePath + '/goodsMall/getList?isGift=1')
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            if (res.data.data.length == 0) {
              _this.$message({
                message: '暂无赠品数据',
                type: 'warning'
              })
            } else {
              res.data.data.forEach(item => {
                item.giftName = item.name + '（' + item.standard + '）'
              })
              _this.giftGoodsList = res.data.data
            }
          }
        })
    },
    // 提交子规格
    addEditSubmit(){
      let _this = this;
      console.log(this.addChildEditForm)
      if(!this.flag){
        this.$router.push({ name: 'salesGoodsAdd', params: { type: 'childGoods', title: this.dialogTitle, index: this.parentIndex, childIndex: this.childIndex, childs: this.addChildEditForm.goodsStandard } })
      }else{
        _this.btnLoading = true
        let url = '/goodsMall/update'
        let json = this.addChildEditForm.goodsStandard[0]
        json.standardImg = json.standardImg.join(',')
        json.warehouseId = this.addEditForm.warehouseId
        this.$confirm('是否同步已截单订单价格和规格?', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '是',
          cancelButtonText: '否',

          type: 'warning'
        }).then(() => {
          json.isSyncOrderPrice = 1
          this.changeGoods(json, url)
        }).catch((action) => {
          if (action == "cancel") {
            json.isSyncOrderPrice = 0
            this.changeGoods(json, url)
          }

        });
        // this.$axios({
        //   url: _this.$axios.defaults.basePath + '/goodsMall/update',
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   data: JSON.stringify(json),
        // }).then((res) => {
        //   _this.btnLoading = false;
        //   if (res.data.errcode == 0) {
        //     _this.$message({
        //       message: "操作成功",
        //       type: "success",
        //     });
        //     _this.$router.push({ path: '/goods/sales_goods' })
        //   } else {
        //     _this.$message({
        //       message: res.data.errmsg,
        //       type: "warning",
        //     });
        //   }
        // });
      }
    },
    //上传图片事件
    
    handleChange(list, index) {
      // this.addChildEditForm.goodsStandard[index].standardImg = []
      // list.forEach(item => {
      //   this.addChildEditForm.goodsStandard[index].standardImg.push(item)
      // })
      // this.$set(this.goodsStandard[index],'standardImg', list);
    },
    handleSuccess(imgData, i) {
      let goodsStandard = this.addChildEditForm.goodsStandard
      goodsStandard[i].standardImg = imgData
      this.addChildEditForm.goodsStandard[i].standardImg = imgData

    },

    // 上传图片删除
    handleRemove(file) {
      let imgArr = [];
      this.fileList = [];
      let url = file.response ? file.response : file.url;
      this.imgData.forEach((item) => {
        if (url != item) {
          imgArr.push(item);
          this.fileList.push({ url: item });
        }
      });
      this.imgData = imgArr;
      if (this.imgData.length > 0) {
        this.addEditForm.imgs = this.imgData.join(",");
      } else {
        this.addEditForm.imgs = "";
      }
      // let ind = "";
      // this.fileList.forEach((item, index) => {
      //   if (file.response == item.url) {
      //     ind = index;
      //   }
      // });
      // this.fileList.splice(ind, 1);
    },
    //产品编辑 同步价格
    async synchronizePrice(item) {
      let _this = this
      var obj = JSON.parse(JSON.stringify(item))
      if (obj.standardImg.some((img) => img.raw ? !img.response : false)) {
        await this.$confirm("当前规格图片尚未上传, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
      obj.standardImg = obj.standardImg.join(",")
      var url = "/goodsMall/update"
      this.$confirm('是否同步已截单订单价格和规格?', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        obj.isSyncOrderPrice = 1
        this.changeGoods(obj, url)
      }).catch((action) => {
        if (action == "cancel") {
          obj.isSyncOrderPrice = 0
          this.changeGoods(obj, url)
        }
      });
    },
    changeGoods(json, url) {
      json.warehouseId = this.addEditForm.warehouseId
      let _this = this
      this.$axios({
        url: _this.$axios.defaults.basePath + url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(json),
      }).then((res) => {
        _this.loading = false;
        _this.submitBtnLoad = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          // _this.standardId = "";
          _this.dialogFormVisible = false;
          _this.$router.push({ path: '/goods/sales_goods' })
          // _this.listEvent();
        } else {
          // _this.dialogFormVisible = false;
          // _this.standardId = "";
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 获取商品信息
    handleGoodsInfo() {
      let that = this;
      that.loading = true;
      // this.minimumchange = true;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/details", {
          params: { id: that.addEditForm.id, warehouseId: that.addEditForm.warehouseId },
        })
        .then(function (res) {
          that.loading = false;
          if (res.data.errcode == 0) {
            let data = res.data.data;
            data.type = data.type.toString();
            if (!data.pointNum) {
              data.pointNum = 0;
            }
            for (let i in data.goodsMallDTOList) {
              data.goodsMallDTOList[i].isPresell = String(data.goodsMallDTOList[i].isPresell)
              data.goodsMallDTOList[i].isCurrentWeighing = String(data.goodsMallDTOList[i].isCurrentWeighing)
            }
            data.point = Math.trunc(data.salePrice * data.pointNum);
            that.$nextTick(() => {
              let goodsStandard = [];
              let childList = data.goodsMallDTOList[that.parentIndex].childGoodsMallFormList
              let child = childList[that.childIndex]
              child.standardImg = Array.isArray(child.standardImg) ? child.standardImg : child.standardImg.split(',')
              child.isPresell = String(child.isPresell)
              child.isCurrentWeighing = String(child.isCurrentWeighing)
              goodsStandard.push(child)
              that.activityType = child.activityType
              that.$set(that.addChildEditForm, 'goodsStandard', goodsStandard)
            });

          } else {
            that.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style scoped>.goods {
  height: 70vh;
  overflow: auto;
}

.boxMain>>>.el-image img {
  height: auto !important;
}

.titleBox {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.titleBox p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 24px;
  border-left: 4px solid #409eff;
}

.standard_box .standard_list {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px dashed #333;
}

.standard_box>div:first-child .standard_list {
  border-top: none !important;
}

.sort_goods .list_title {
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
}

.sort_goods .list_title div {
  width: 11%;
}

.sort_goods .list li {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
}

.sort_goods .list li div {
  width: 11%;
}

.tableTopBtn {
  display: flex;
  align-items: center;
}

.tableTopBtn p {
  margin-left: 20px;
}</style>
