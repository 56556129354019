<template>
  <div>
    <!-- 检索 -->
    <el-form :model="driverData" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
      <el-form-item label="" prop="wayId" v-for="(dItem,i) in driverInfo" :key="i" v-show="printData.wayOrRegion == '0'">
        <span>{{ dItem.way }}：</span>
        <el-select v-model="dItem.driverId" filterable style="width: 150px" placeholder="请选择司机">
          <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="wayId" v-show="printData.wayOrRegion == '1'">
        <span>司机：</span>
        <el-select v-model="driverId" filterable style="width: 150px" placeholder="请选择司机">
          <el-option v-for="(item, index) in driverList" :key="index" :label="item.username" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库时间:">
        <el-date-picker v-model="driverData.created" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择出库时间">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="userTable boxMain">
      <p class="boxTitle">商品列表</p>
      <template>
        <el-table ref="multipleTables" class="userTable" :data="printData.userOrderList" tooltip-effect="dark" style="width: 100%;" @selection-change="handleSelectionChange" :cell-class-name="cell">
          <el-table-column type="selection" width="55" :selectable="selectable">
          </el-table-column>
          <el-table-column prop="userName" label="姓名">
          </el-table-column>
          <el-table-column prop="wechat" label="微信名称">
          </el-table-column>
          <el-table-column prop="phone" label="手机号">
          </el-table-column>
          <el-table-column prop="towns" label="地址">
          </el-table-column>
          <el-table-column prop="address" label="具体住址">
          </el-table-column>
          <el-table-column prop="shopName" label="店铺名称">
          </el-table-column>
          <el-table-column prop="totalNum" label="购买数量" :filter-multiple="false" :filters="[{ text: '小于10', value: '10' }, { text: '小于20', value: '20' }]" :filter-method="filterNum">
          </el-table-column>
          <el-table-column type="expand" label="订单详情">
            <template slot-scope="props">
              <h5 v-if="props.row.saleChildOrderList&&props.row.saleChildOrderList.length" style="margin:0">配送列表</h5>
              <table class="orderTable" v-if="props.row.saleChildOrderList&&props.row.saleChildOrderList.length">
                <thead>
                  <tr>
                    <th></th>
                    <th>名称</th>
                    <th>规格</th>
                    <th>单价</th>
                    <th>数量</th>
                    <th>小计</th>
                    <th>订单状态</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(it,ind) in props.row.saleChildOrderList" :key="ind">
                    <td>
                      <el-checkbox v-model="it.checked" @change="changeCheckbox($event,props.$index,ind)"></el-checkbox>
                    </td>
                    <td>{{it.title}}</td>
                    <td>{{it.standard}}</td>
                    <td>{{it.price}}</td>
                    <td>{{it.num}}</td>
                    <td>{{it.totalPrice}}</td>
                    <td :style="{color:it.status==4?'green':it.status==5?'red':''}">{{it.status==4?'已截单':it.status==5?'已出库':""}}</td>
                  </tr>
                </tbody>
              </table>
              <h5 v-if="props.row.backOrderList&&props.row.backOrderList.length">退货列表</h5>
              <table class="orderTable" v-if="props.row.backOrderList&&props.row.backOrderList.length">
                <thead>
                  <tr>
                    <th></th>
                    <th>名称</th>
                    <th>规格</th>
                    <th>单价</th>
                    <th>数量</th>
                    <th>小计</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(it,ind) in props.row.backOrderList" :key="ind">
                    <td>
                      <!-- <el-checkbox v-model="it.checked" @change="changeCheckbox($event,props.$index,ind)"></el-checkbox> -->
                    </td>
                    <td>{{it.title}}</td>
                    <td>{{it.standard}}</td>
                    <td>{{it.price}}</td>
                    <td>{{it.num}}</td>
                    <td>{{it.totalPrice}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div style="text-align:center;padding-top:20px">
      <el-button type="primary" @click="over">打印</el-button>
      <el-button @click="$emit('overPrintShow')">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      driverId: "",
      userIds: "",
      allSaleChildOrderId: [],
      formData: {
        saleChildOrderId: "",
        way: "",
        wayId: '',
        undeliveredOrders: "",
        refundIds: ""
      },
      changeFlag: 0,//判断点的是订单复选框 还是 外层表格 0是表格 1是订单复选框
      driverList: [],
      checkedUserList: [],//选择打印的数据
      // 选择的路线数据
      driverData: {
        created: '',
        driverId: []
      }
    }
  },
  props: {
    printData: {},
    driverInfo: []
  },
  mounted() {
    console.log(this.driverInfo, this.printData, '----------')
    this.$set(this.driverData, 'created', this.$cjs.getDayTime())
    this.$set(this.driverData, 'driverId', this.driverInfo.map(item => { return item.driverId }))

    this.getDriverList()
    let saleChildOrderId = []
    // let refundIds = []
    // 整合数据
    this.printData.userOrderList = []
    if (this.printData.wayOrRegion == '0') {//路线
      for (let i in this.printData.multipleRoutes) {
        if (this.printData.multipleRoutes[i].userOrderList.length > 0 && this.printData.multipleRoutes[i].userOrderList[0].way) {
          this.printData.userOrderList.push({
            userName: this.printData.multipleRoutes[i].userOrderList[0].way + '（路线）',
            userId: -1
          })
          // 将路线的driverId放入对应的multipleRoutes内
          for (let n = 0; n < this.driverInfo.length; n++) {
            if (this.driverInfo[n].way == this.printData.multipleRoutes[i].userOrderList[0].way) {
              this.printData.multipleRoutes[i].driverId = this.driverInfo[n].driverId
            }
          }
          for (let j in this.printData.multipleRoutes[i].userOrderList) {
            this.printData.userOrderList.push(this.printData.multipleRoutes[i].userOrderList[j])
          }
        }
      }
    } else if (this.printData.wayOrRegion == '1') {//区域
      let data = this.printData
      for (let i in data.multipleRoutes) {
        for (let j in data.multipleRoutes[i].multipleRoutes) {
          if (data.multipleRoutes[i].multipleRoutes[j].userOrderList.length > 0 && data.multipleRoutes[i].multipleRoutes[j].userOrderList[0].way) {
            this.printData.userOrderList.push({
              userName: data.regionalName + '-' + data.multipleRoutes[i].regionalName + '-' + data.multipleRoutes[i].multipleRoutes[j].userOrderList[0].way,
              userId: -1
            })
            for (let n in data.multipleRoutes[i].multipleRoutes[j].userOrderList) {
              // for (let k in data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList) {
              //   data.multipleRoutes[i].multipleRoutes[j].userOrderList[n].saleChildOrderList[k].checked = true
              // }
              this.printData.userOrderList.push(data.multipleRoutes[i].multipleRoutes[j].userOrderList[n])
            }
          }

        }
      }
    }

    for (let i in this.printData.userOrderList) {
      //拿到所有的配送订单id
      for (let j in this.printData.userOrderList[i].saleChildOrderList) {
        if (this.printData.userOrderList[i].saleChildOrderList[j].checked) {
          saleChildOrderId.push(this.printData.userOrderList[i].saleChildOrderList[j].id)
        }
      }
      // //拿到所有的退货订单id
      // for (let k in this.printData.userOrderList[i].backOrderList) {
      //   refundIds.push(this.printData.userOrderList[i].backOrderList[k].refundId)
      // }
    }
    // this.formData.refundIds = refundIds
    this.allSaleChildOrderId = saleChildOrderId
    this.$nextTick(() => {
      for (let i in this.printData.userOrderList) {
        this.$refs.multipleTables.toggleRowSelection(this.printData.userOrderList[i])
      }
    })
  },
  methods: {
    cell({ row, column, rowIndex, columnIndex }) {
      if (row.userId == -1) {
        return "fontSizeWeight"
      }
    },
    selectable(row, index) {
      if (row.userId == -1) {
        return false
      } else {
        return true
      }
    },
    // 获取司机列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.status == 200) {
            _this.driverList = res.data.data;
          }
        });
    },
    filterNum(value, row, column) {
      return row.totalNum < value;
    },
    changeCheckbox(e, index, ind) {
      for (let i in this.printData.userOrderList) {
        if (i == index) {
          for (let j in this.printData.userOrderList[i].saleChildOrderList) {
            if (j == ind) {
              this.printData.userOrderList[i].saleChildOrderList[j].checked = e
            }
          }
        }
      }
      for (let i in this.printData.userOrderList[index].saleChildOrderList) {
        if (this.printData.userOrderList[index].saleChildOrderList[i].checked) {
          this.changeFlag = 1
          this.$refs.multipleTables.toggleRowSelection(this.printData.userOrderList[index], true)
          this.changeId()
          return
        } else {
          if (i == this.printData.userOrderList[index].saleChildOrderList.length - 1) {
            this.$refs.multipleTables.toggleRowSelection(this.printData.userOrderList[index], false)
          }
        }
      }
      this.changeId()
    },
    handleSelectionChange(e) {
      if (e.length) {
        this.formData.way = e[0].way
        this.formData.wayId = e[0].wayId
        if (this.changeFlag == 0) {
          if (e.length > this.checkedUserList.length) {
            let del = e.filter(item => !this.checkedUserList.some(ele => ele.userId === item.userId))
            for (let i in this.printData.userOrderList) {
              for (let j in del) {
                if (this.printData.userOrderList[i].userId == del[j].userId) {
                  for (let k in this.printData.userOrderList[i].saleChildOrderList) {
                    this.printData.userOrderList[i].saleChildOrderList[k].checked = true
                  }
                }
              }
            }

          } else {
            let del = this.checkedUserList.filter(item => !e.some(ele => ele.userId === item.userId))
            for (let i in this.printData.userOrderList) {
              for (let j in del) {
                if (this.printData.userOrderList[i].userId == del[j].userId) {
                  for (let k in this.printData.userOrderList[i].saleChildOrderList) {
                    this.printData.userOrderList[i].saleChildOrderList[k].checked = false
                  }
                }
              }
            }

            console.log(del, "del")
          }
        }
        this.checkedUserList = e
        this.changeId()
      } else {
        for (let i in this.printData.userOrderList) {
          for (let j in this.printData.userOrderList[i].saleChildOrderList) {
            this.printData.userOrderList[i].saleChildOrderList[j].checked = false
          }
        }
        this.checkedUserList = []
        this.userIds = ""
        this.formData.saleChildOrderId = ''
      }
      this.changeFlag = 0
      //----------------------------------------------------------------
    },
    changeId() {
      let data = this.checkedUserList
      let data2 = this.printData.userOrderList
      let userIds = []
      let saleChildOrderId = []
      for (let i in data) {
        userIds.push(data[i].userId)
        for (let j in data2) {
          if (data[i].userId == data2[j].userId) {
            for (let k in data2[j].saleChildOrderList) {
              if (data2[j].saleChildOrderList[k].checked) {
                saleChildOrderId.push(data2[j].saleChildOrderList[k].id)
              }
            }
          }
        }
      }
      this.userIds = userIds.join(",")
      this.formData.saleChildOrderId = saleChildOrderId
      this.changeFlag = 0
    },
    over() {
      if (this.printData.wayOrRegion == '0') {
        for (let i in this.driverInfo) {
          if (!this.driverInfo[i].driverId) {
            this.$message({
              message: "请选择" + this.driverInfo[i].way + "司机！",
              type: "warning",
            });
            return
          }
        }
      } else if (this.printData.wayOrRegion == '1') {
        if (!this.driverId) {
          this.$message({
            message: "请选择司机",
            type: "warning",
          });
          return
        }
      }

      if (this.userIds) {
        let obj = {
          userIds: this.userIds,
          saleChildOrderId: this.formData.saleChildOrderId.join(","),
          wayOrRegion: this.printData.wayOrRegion
        }
        this.$msgbox({
          title: '提示',
          message: "此操作将生成打印记录，请确认需要打印的数据, 是否保存?",
          showCancelButton: true,
          closeOnClickModal: false,
          showClose: false,
          confirmButtonText: '保存',
          cancelButtonText: '不保存',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm' || action === 'cancel') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '加载中...';
              instance.cancelButtonLoading = true;
              instance.cancelButtonText = '加载中...';
              if (action === 'confirm') {
                obj.isAdd = 1
              } else {
                obj.isAdd = 2
              }
              this.$emit("over", obj, (res) => {
                if (res == 1) {
                  instance.confirmButtonLoading = false;
                  instance.cancelButtonLoading = false;
                  done();
                } else {
                  instance.confirmButtonLoading = false;
                  instance.cancelButtonLoading = false;
                  done();
                }
              })
            } else {
              done();
            }
          }
        }).then(action => {
          // this.$message({
          //   type: 'info',
          //   message: 'action: ' + action
          // });
        });
      } else {
        this.$message({
          message: "请选择要打印的数据",
          type: "warning",
        });
      }
    },
    //添加打印记录
    add(data) {
      let _this = this;
      let formData = {}
      let allSaleChildOrderId = JSON.parse(JSON.stringify(this.allSaleChildOrderId))//所有的id
      let saleChildOrderId = JSON.parse(JSON.stringify(this.formData.saleChildOrderId))//选中的id

      let dataArr = []
      if (this.printData.wayOrRegion == '0') {//路线
        for (let i in this.printData.multipleRoutes) {
          if (this.printData.multipleRoutes[i].userOrderList.length > 0) {
            let obj = {
              totalNum: this.printData.multipleRoutes[i].totalNum,
              totalPrice: this.printData.multipleRoutes[i].totalPrice,
              way: this.printData.multipleRoutes[i].userOrderList[0].way,
              wayId: this.printData.multipleRoutes[i].userOrderList[0].wayId,
              driverId: "",
              saleChildOrderId: [],
              undeliveredOrders: [],
              refundIds: [],
              created: this.driverData.created
            }
            for (let j in this.driverInfo) {
              if (this.driverInfo[j].id == this.printData.multipleRoutes[i].userOrderList[0].wayId) {
                obj.driverId = this.driverInfo[j].driverId
              }
            }
            dataArr.push(obj)
          }
        }
      } else if (this.printData.wayOrRegion == '1') {//区域

        for (let i in this.printData.multipleRoutes) {
          for (let j in this.printData.multipleRoutes[i].multipleRoutes) {
            if (this.printData.multipleRoutes[i].multipleRoutes[j].userOrderList.length > 0) {
              let obj = {
                totalNum: this.printData.multipleRoutes[i].multipleRoutes[j].totalNum,
                totalPrice: this.printData.multipleRoutes[i].multipleRoutes[j].totalPrice,
                way: this.printData.multipleRoutes[i].multipleRoutes[j].userOrderList[0].way,
                wayId: this.printData.multipleRoutes[i].multipleRoutes[j].userOrderList[0].wayId,
                driverId: this.driverId,
                saleChildOrderId: [],
                undeliveredOrders: [],
                refundIds: [],
                created: this.driverData.created
              }
              dataArr.push(obj)
            }
          }
        }
      }

      //循环找出需要传参的id
      // let saleChildOrderId  //选中的数组
      // let undeliveredOrders  //为选中的数组
      // let refundIds //需要带回的数组
      for (let j in this.formData.saleChildOrderId) {
        for (let i in allSaleChildOrderId) {
          if (allSaleChildOrderId[i] == this.formData.saleChildOrderId[j]) {
            allSaleChildOrderId.splice(i, 1);//计算不需要配送的id
          }
        }
      }
      let list = this.printData.userOrderList
      for (let i in list) {
        for (let j in dataArr) {
          if (list[i].wayId == dataArr[j].wayId) {
            for (let n in list[i].saleChildOrderList) {
              for (let k in saleChildOrderId) {
                if (list[i].saleChildOrderList[n].id == saleChildOrderId[k]) {
                  dataArr[j].saleChildOrderId.push(list[i].saleChildOrderList[n].id)
                }
              }
              for (let h in allSaleChildOrderId) {
                if (list[i].saleChildOrderList[n].id == allSaleChildOrderId[h]) {
                  dataArr[j].undeliveredOrders.push(list[i].saleChildOrderList[n].id)
                }
              }
            }
            if (list[i].backOrderList && list[i].backOrderList.length) {
              for (let c in list[i].backOrderList) {
                dataArr[j].refundIds.push(list[i].backOrderList[c].refundId)
              }
            }
          }
        }
      }
      for (let i in dataArr) {
        dataArr[i].saleChildOrderId = dataArr[i].saleChildOrderId.length ? dataArr[i].saleChildOrderId.join(",") : ""
        dataArr[i].undeliveredOrders = dataArr[i].undeliveredOrders.length ? dataArr[i].undeliveredOrders.join(",") : ""
        dataArr[i].refundIds = dataArr[i].refundIds.length ? dataArr[i].refundIds.join(",") : ""
      }
      dataArr.forEach((item, index, array) => {
        if (item.saleChildOrderId == '') {
          array.splice(index, 1)
        }
      })
      // console.log(dataArr, "dataArrdataArrdataArr")

      // return
      this.$axios({
        url: _this.$axios.defaults.basePath + "/printRecords/add",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: dataArr,
      }).then((res) => {
        if (res.data.errcode == 0) {
          // _this.$message({
          //   message: "操作成功",
          //   type: "success",
          // });
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.userTable >>> .el-table .fontSizeWeight .cell {
  font-size: 16px;
  font-weight: 600;
}
.orderTable {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  border-collapse: collapse;
}
.orderTable tr th {
  width: 17%;
  background: none;
}
.orderTable tr th:nth-of-type(1) {
  width: 5%;
}
h5 {
  font-size: 20px;
  color: #000;
  padding: 10px 0;
  text-align: center;
  margin-top: 10px;
}
</style>