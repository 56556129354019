<template>
  <div class="bigBox">
    <div class="login_app">
      <div class="login_mask">
        <div class="login">
          <div class="login_form">
            <el-form :model="loginForm" ref="loginForm" :rules="loginRules" status-icon class="demo-ruleForm" autocomplete="on">
              <div class="login_title">
                您好！<br />欢迎来到<span>团购后台系统</span>
              </div>
              <div class="form_input_box">
                <div class="">
                  <el-form-item prop="userName" class="clearfix" :style="{
                      borderColor: focusFlag == 1 ? '#246EFF' : '#f7f7f7',
                    }">
                    <img src="../assets/img/user_icon.png" alt="" class="float_lf" />
                    <el-input class="float_lf" type="text" @blur="loginBlur" @focus="loginFocus(1)" v-model="loginForm.userName" autocomplete="on" placeholder="请输入账号"></el-input>
                  </el-form-item>
                  <el-form-item prop="password" class="clearfix" :style="{
                      borderColor: focusFlag == 2 ? '#246EFF' : '#f7f7f7',
                    }">
                    <img src="../assets/img/psd_icon.png" alt="" class="float_lf" />
                    <el-input class="float_lf" type="password" @blur="loginBlur" @focus="loginFocus(2)" v-model="loginForm.password" autocomplete="on" placeholder="请输入密码"></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-form-item class="submitBtn" style="margin: 0">
                <el-button size="medium" v-on:keyup.enter.native="console.log(123)" @click="submitForm('loginForm')" :loading="isClick">登 录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="beiAn">河北金乌科技有限公司 ©2023&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 冀ICP备18022801号-4</div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "login",
  data() {
    return {
      loginFlag: true,
      loginForm: {
        userName: "",
        password: "",
      },
      loginRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      power: [],
      newName: "",
      oneNav: [],
      twoNav: [],
      threeNav: [],
      threeId: [],
      noType: {
        id: 0,
        ids: "0",
        name: "暂无",
      },
      token: "",
      isClick: false,
      focusFlag: 0,
    };
  },
  mounted() {
    //监听键盘抬起事件
    window.addEventListener("keyup", this.handleEnter);
  },

  methods: {
    ...mapMutations(["changeLogin"]),
    loginBlur() {
      this.focusFlag = 0;
    },
    loginFocus(flag) {
      this.focusFlag = flag;
    },
    submitForm() {
      var _this = this;
      if (this.loginForm.userName == "") {
        this.$message({
          message: "请输入账户名称",
          type: "warning",
        });
      } else if (this.loginForm.password == "") {
        this.$message({
          message: "请输入账户密码",
          type: "warning",
        });
      } else {
        _this.isClick = true;
        _this.login();
      }
    },
    login() {
      let _this = this;
      this.$axios({
        url: _this.$axios.defaults.basePath + "/login",
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: {
          username: _this.loginForm.userName,
          password: _this.$md5(_this.loginForm.password),
        },
      })
        .then((res) => {
          if (res.data.errcode == 0) {
            sessionStorage.clear();
            _this.$message({
              message: "登录成功！",
              type: "success",
            });
            _this.changeLogin({ Authorization: res.data.data.token });
            var authorities = _this.uniqueList(res.data.data.authorities);
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
            sessionStorage.setItem("authority", JSON.stringify(authorities));
            sessionStorage.setItem(
              "myMenu",
              JSON.stringify(res.data.data.menus)
            );
            sessionStorage.setItem("userName", res.data.data.username);
            sessionStorage.setItem("userIds", res.data.data.ids);
            sessionStorage.setItem("adcode", res.data.data.adcode);
            sessionStorage.setItem(
              "departmentName",
              res.data.data.departmentName
            );
            sessionStorage.setItem("role_strings", res.data.data.role_strings);
            var newArr = _this.uniqueList(res.data.data.menus);
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/sysPrivilege/all")
              .then(function (rea) {
                if (rea.data.errcode == 0) {
                  var powerAll = rea.data.data;
                  //将所有权限添加到对应菜单内 powers
                  newArr.forEach((item) => {
                    item.powers = new Array();
                    powerAll.forEach((child) => {
                      if (child.menuId == item.id) {
                        var arr = {
                          id: child.id,
                          name: child.name,
                          description: child.description,
                        };
                        item.powers.push(arr);
                      }
                    });
                  });
                  //对比菜单权限与用户权限 筛选出用户可见菜单
                  var menus = new Array();
                  newArr.forEach((item) => {
                    if (item.powers.length != 0) {
                      item.powers.forEach((twoItem) => {
                        authorities.forEach((child) => {
                          if (twoItem.name == child.authority) {
                            menus.push(item);
                          }
                        });
                      });
                    } else {
                      menus.push(item);
                    }
                  });
                  // console.log(menus,"menus")
                  // return
                  //数组去重
                  menus = _this.uniqueList(menus);
                  //遍历菜单初始数据 分类整理
                  menus.forEach((item) => {
                    item.child = new Array();
                    if (item.parentId) {
                      var index = item.menuKey;
                      item.tab = index.replace(/\./g, "-");
                      if (item.parentKey) {
                        if (item.parentKey.indexOf(".") != -1) {
                          var is = item.parentKey.indexOf(".");
                          var ids = item.parentKey.substr(is + 1);
                          //三级菜单
                          _this.threeNav.push(item);
                          _this.threeId.push(ids);
                        } else {
                          //二级菜单
                          _this.twoNav.push(item);
                        }
                      }
                    } else {
                      //一级菜单
                      item.tab = item.menuKey;
                      _this.oneNav.push(item);
                    }
                  });
                  // this.menus.forEach((item)=>{
                  //     this.icon.forEach((child)=>{
                  //         if(item.name == child.title){
                  //             item.icon = child.icon
                  //         }
                  //     });
                  // });
                  //将整理好的分类按层级管理合并
                  _this.oneNav.forEach((item) => {
                    _this.twoNav.forEach((twoItem) => {
                      if (twoItem.parentId == item.id) {
                        item.child.push(twoItem);
                      }
                      _this.threeNav.forEach((threeItem) => {
                        if (threeItem.parentId == twoItem.id) {
                          twoItem.child.push(threeItem);
                          twoItem.child = [...new Set(twoItem.child)];
                        }
                      });
                    });
                  });
                  sessionStorage.setItem("menus", JSON.stringify(_this.oneNav));
                  setTimeout(function () {
                    _this.isClick = false;
                    _this.$router.push({ path: "/home" });
                  }, 500);
                  //判断登录成功后跳转的页面
                  // for (let i in _this.oneNav) {
                  //   //先判断一级目录里有没有首页的地址
                  //   if (_this.oneNav[i].targetUrl == "/home") {
                  //     //如果有首页地址  那么直接跳转
                  //     sessionStorage.setItem("activeTab", _this.oneNav[i].id)//菜单选中的配置
                  //     setTimeout(function () {
                  //       _this.$router.push({ path: _this.oneNav[i].targetUrl });
                  //     }, 500);
                  //     return
                  //   } else {
                  //     //如果没有首页地址的话，等循环到最后一项  跳转到第0项的页面
                  //     if (i == _this.oneNav.length - 1) {
                  //       //先判断第0项的地址是否为空，如果不为空的话是一级菜单,那么直接跳转
                  //       if (_this.oneNav[0].targetUrl) {
                  //         sessionStorage.setItem("activeTab", _this.oneNav[0].id)//菜单选中的配置
                  //         setTimeout(function () {
                  //           _this.$router.push({ path: _this.oneNav[0].targetUrl });
                  //         }, 500);
                  //       } else { // 如果第0项的地址为空的话，说明当前一级菜单下存在二级菜单,那么直接跳转到二级菜单的第0项的地址
                  //         sessionStorage.setItem("activeTab", _this.oneNav[0].child[0].id)//菜单选中的配置
                  //         setTimeout(function () {
                  //           _this.$router.push({ path: _this.oneNav[0].child[0].targetUrl });
                  //         }, 500);
                  //       }
                  //     }
                  //   }
                  // }
                } else {
                  _this.isClick = false;
                }
              })
              .catch(() => {
                _this.isClick = false;
              });
          } else if (res.data.errcode == 41002) {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
            _this.isClick = false;
          } else if (res.data.errcode == 403) {
            sessionStorage.setItem('Authorization', '');
            _this.login();
          } else {
            _this.isClick = false;
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          _this.isClick = false;
        });
    },
    //键盘事件 按回车登录
    handleEnter(event) {
      const e = event || window.event || arguments.callee.caller.arguments[0];
      if (!e) return;
      const { keyCode } = e;
      if (keyCode == 13) {
        this.submitForm();
      }
    },

    //json数组去重
    uniqueList(array) {
      var r = [];
      for (var i = 0, l = array.length; i < l; i++) {
        for (var j = i + 1; j < l; j++)
          //关键在这里
          if (JSON.stringify(array[i]) == JSON.stringify(array[j])) j = ++i;
        r.push(array[i]);
      }
      return r;
    },
  },
};
</script>


<style lang="css" scoped>
.bigBox {
  position: relative;
  width: 100%;
  height: 100vh;
}
.login_app {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("../assets/img/bg.png") center no-repeat;
  background-size: 100% 100%;
}
.login_mask {
  width: 100%;
  height: 100%;
}
.login_top img {
  width: 330px;
  display: block;
  margin: 0 auto 20px auto;
}
.login {
  padding: 30px 70px 40px 70px;
  background: #fff;
  position: absolute;
  top: 289px;
  left: 60%;
  z-index: 100;
  border-radius: 20px;
}
.login .login_form {
  width: 100%;
  text-align: center;
  border-radius: 5px;
  padding-bottom: 40px;
}
.login_title {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin: 20px 0 50px 0;
  letter-spacing: 15px;
  line-height: 40px;
}
.login_title span {
  color: #246eff;
}
.login .login_form .form_input_box {
  box-sizing: border-box;
}
.login .login_form .form_input_box .form_title {
  color: #333;
}
.login .login_form .form_input_box .el-form-item {
  margin-bottom: 0;
  margin-top: 30px;
  width: 387px;
  height: 46px;
  background-color: rgba(247, 247, 247, 1);
  border-radius: 25px;
  border: 2px solid rgba(247, 247, 247, 1);
}
.login .login_form .form_input_box .el-form-item:hover {
  border-color: #246eff !important;
}
.login .login_form >>> form input {
  background-color: transparent;
}
.login .login_form >>> .el-form-item__error {
  padding-left: 10px;
}
#app .login .login_form >>> form input.el-input__inner {
  color: #333;
  height: 46px;
  width: 312px;
  font-size: 16px;
  border: 0px;
  padding-left: 10px;
}
#app .login .login_form >>> input::-webkit-input-placeholder {
  color: #999;
}
#app .login .login_form >>> input::-moz-input-placeholder {
  color: #999;
}
#app .login .login_form >>> input::-ms-input-placeholder {
  color: #999;
}

.login .el-form-item__content {
  position: relative;
}
.login .el-form-item__content img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
  width: 20px;
  padding: 2px 0 0 5px;
}
.el-message {
  min-width: 200px !important;
}
.login .submitBtn .el-form-item__content {
  margin-left: 0 !important;
}
.login .submitBtn .el-form-item__content button {
  width: 100%;
  height: 50px;
  color: #fff;
  border: 0;
  font-size: 20px;
  font-weight: normal;
  background-color: #246eff;
  border-radius: 25px;
  margin-top: 60px;
}
.login .submitBtn .chooseBtn {
  width: 100px;
  font-size: 14px;
  color: #5a79ff;
  cursor: pointer;
  margin: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #72b5f9;
}
.beiAn {
  width: auto;
  color: #fff;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
