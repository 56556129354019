<template>
  <div>
    <el-form :model="addEditForm" ref="addEditForm" class="orderTable" :rules="addEditRules" :inline="true" label-width="90px">
      <el-form-item label="选择仓库" prop="warehouseId">
        <el-select v-model="addEditForm.warehouseId" filterable style="width: 200px" placeholder="可搜索选项" @change="changeWarehouse">
          <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="选择商品" prop="goodsId">
        <el-select v-model="addEditForm.goodsId" clearable filterable style="width: 300px" placeholder="可搜索选项" @change="changeGood">
          <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="选择用户" prop="userId">
        <el-select v-model="addEditForm.userId" filterable style="width: 200px" placeholder="可搜索选项" @change="changeUser" clearable>
          <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库时间:">
        <el-date-picker v-model="addEditForm.deliveryTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择出库时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" style="width:100%">
        <el-table :data="addOrderList" style="width: 100%">
          <el-table-column label="用户">
            <template slot-scope="scope">
              <el-select v-model="scope.row.userId" disabled placeholder="可搜索选项">
                <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="商品">
            <template slot-scope="scope">
              <el-select v-model="scope.row.id" filterable placeholder="可搜索选项" @change="changeItemGood(scope,$event)">
                <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品数量">
            <template slot-scope="scope">
              <el-input type="Number" v-model="scope.row.num" placeholder="请输入商品数量" @input="changeNum(scope, $event)"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="单价(元)">
            <template slot-scope="scope">
              <el-input type="text" :disabled="true" v-model="scope.row.price" @input="changeNum(scope, $event)"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="总价(元)">
            <template slot-scope="scope">
              <p>{{ scope.row.totalPrice }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="isCurrentWeighing" label="isCurrentWeighing">
            </el-table-column> -->
          <el-table-column prop="remark" label="备注">
            <template slot-scope="scope">
              <el-input type="text" v-model="scope.row.remark" :disabled="scope.row.isCurrentWeighing==0"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="活动规则">
            <template slot-scope="scope">
              <div v-if="scope.row.goodsMallActivityRuleList && scope.row.goodsMallActivityRuleList.length > 0">
                <div v-if="scope.row.goodsMallActivityRuleList[0].type==0">
                  <p v-for="(sItem, standI) in scope.row.goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，单价 ' + sItem.discounts + '元' }}</p>
                </div>
                <div v-if="scope.row.goodsMallActivityRuleList[0].type==1">
                  <p v-for="(sItem, standI) in scope.row.goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，赠送 ' + sItem.discounts + '份'+ sItem.giftName }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="name" label="商品数量">
            <template slot-scope="scope">
              <el-input type="text" v-model="scope.row.num" placeholder="请输入商品数量"></el-input>
            </template>
          </el-table-column> -->

          <!-- <el-table-column prop="name" label="是否为预售单">
            <template slot-scope="scope">
              <el-switch style="display: block" v-model="scope.row.isPresell" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" active-text="是" inactive-text="否">
              </el-switch>
            </template>
          </el-table-column> -->
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button size="medium" type="primary" class="el-icon-plus" @click="chengeOrderItem(scope,1)"></el-button>
              <el-button size="medium" type="danger" class="el-icon-minus" @click="chengeOrderItem(scope,2)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

    </el-form>
    <div style="text-align:center;padding-top:20px">
      <el-button type="primary" @click="over()" :loading="loading">确定</el-button>
      <el-button @click="$emit('addOver')">取消</el-button>
    </div>
  </div>
</template>

<script>
// 解决js运算精度丢失问题
import * as math from "mathjs"
export default {
  name: 'add-create',
  data() {
    return {
      loading: false,
      userAdcode: '',//仓库adcode
      warehouseList: [],//仓库列表
      userList: [],//人员列表
      selectGoods: [],//商品列表
      selectGoodsInfo: {},//选择的商品信息
      addEditForm: {
        warehouseId: "",//仓库id
        adcode: "",
        goodsId: "",
        userId: ''
      },
      // 批量添加数组
      addOrderList: [],
      addEditRules: {
        warehouseId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        id: [{ required: true, message: "请选择销售商品", trigger: "change" }],
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        num: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
      },
      userInfo: [],
    }
  },
  mounted() {
    this.$set(this.addEditForm, 'deliveryTime', this.$cjs.getDayTime())
    this.getWarehouseList()
  },
  methods: {
    changeNum(scope, e) {
      if (!Number(e)) {
        this.$message({
          message: '请输入正确的数量',
          type: 'warning'
        })
        scope.row.num = ''
        return
      } else if (Number(e) <= 0) {
        this.$message({
          message: '数量不能小于0',
          type: 'warning'
        })
        scope.row.num = ''
        return
      }
      scope.row.isUpdate = 1
      //如果用户是批发商 直接取批发价
      if (scope.row.level == 2) {
        let value = math.multiply(scope.row.wholesalePrice, e)
        // scope.row.totalPrice = Number(value).toFixed(2)
        scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
      } else {
        if (scope.row.isActivity == 1 && scope.row.goodsMallActivityRuleList.length > 0 && scope.row.goodsMallActivityRuleList[0].type == 0) {
          let nums = []
          scope.row.goodsMallActivityRuleList.forEach((item, index) => {
            if (e >= Number(item.threshold)) {
              scope.row.price = Number(item.discounts)
              let value = math.multiply(item.discounts, e)
              // scope.row.totalPrice = Number(value).toFixed(2)
              scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
            } else {
              nums.push(index)
            }
          })
          if (nums.length == scope.row.goodsMallActivityRuleList.length) {
            scope.row.price = Number(scope.row.oldPrice)
            let value = math.multiply(scope.row.oldPrice, e)
            // scope.row.totalPrice = Number(value).toFixed(2)
            scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
          }
        } else {
          if (scope.row.isActivity == '0' && Number(scope.row.takeSale) > 0 && scope.row.takeSale) {
            let value = math.multiply(scope.row.takeSale, e)
            // scope.row.totalPrice = Number(value).toFixed(2)
            scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
          } else {
            console.log(scope.row, "scope.row")
            let value = math.multiply(scope.row.oldPrice, e)
            scope.row.price = scope.row.oldPrice
            // scope.row.totalPrice = Number(value).toFixed(2)
            scope.row.totalPrice = this.$cjs.preciseNum(value, 2)
          }
        }
      }

    },
    over() {
      let _this = this
      for (let i in this.addOrderList) {
        if (!this.addOrderList[i].id) {
          _this.$message({
            message: "请检查商品是否全部选择",
            type: "warning",
          });
          return
        }
        if (!this.addOrderList[i].num) {
          _this.$message({
            message: "请检查商品数量是否全部填写",
            type: "warning",
          });
          return
        }
      }
      _this.loading = true
      _this.$axios({
        url: _this.$axios.defaults.basePath + "/saleChildOrder/userSelfDeliveryOrderPrinting",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          orderFormList: _this.addOrderList,
          orderSource: 1,
          deliveryTime: _this.addEditForm.deliveryTime
        },
      }).then((res) => {
        _this.loading = false
        if (res.data.errcode == 0) {
          let data = res.data.data
          data.type = "zt" //用来在打印组件判断是不是自提
          _this.$emit("addOver", data)
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //表格选商品
    async changeItemGood(scope, e) {
      console.log(scope)
      var obj = this.selectGoods.find(function (obj) {
        return obj.id === e
      })
      var price = scope.row.level == 2 ? obj.wholesalePrice : obj.salePrice
      if (this.userInfo.level == 2) {
        if (!price) {
          this.$message({
            message: '当前用户等级为批发商，该商品暂未设置批发价。',
            type: 'warning'
          })
        }
        scope.row.price = price
        scope.row.isSelfPickup = 1
      } else {
        if (obj.isActivity == '0' && Number(obj.takeSale) > 0 && obj.takeSale) {
          scope.row.price = obj.takeSale
          scope.row.isSelfPickup = 1
        } else {
          scope.row.price = obj.salePrice
          scope.row.isSelfPickup = 0
        }
      }

      scope.row.wholesalePrice = obj.wholesalePrice || 0
      scope.row.oldPrice = price
      scope.row.totalPrice = 0
      scope.row.num = ''
      scope.row.isActivity = obj.isActivity
      scope.row.isCurrentWeighing = obj.isCurrentWeighing
      scope.row.takeSale = obj.takeSale
      scope.row.goodsMallActivityRuleList = this.userInfo.level == 2 ? [] : obj.goodsMallActivityRuleList
      console.log(scope.row, "scope.rowscope.row")
      //判断商品有没有买过 买过把数量改了
      // if (scope.row.id) {
      //   console.log(1)
      //   let result = await this.selectUserOrder(scope.row.id, scope.row.userId)
      //   if (result.errcode == 0) {
      //     console.log(2)
      //     scope.row.num = result.data.num
      //     scope.row.isPresell = 0
      //     scope.row.isCurrentWeighing = result.data.isCurrentWeighing
      //     this.changeNum(scope, Number(result.data.num))
      //   } else {
      //     scope.row.num = ''
      //     scope.row.isPresell = 0
      //     console.log()
      //     this.changeNum(scope, 1)
      //   }
      // }
    },
    //表格加减
    async chengeOrderItem(scope, flag) { //1 新增 2 删除
      if (flag == 1) {
        let obj = {
          id: "",
          num: "",
          userId: scope.row.userId,
          level: scope.row.level,
          warehouseId: this.addEditForm.warehouseId,
          isPresell: 0,//是否为预售单 0为否 1为是
          isActivity: 0,
          isCurrentWeighing: 0,
          isUpdate: 1,//0.不更新订单（不校验价格），1.更新订单
        }
        this.addOrderList.splice(scope.$index + 1, 0, obj)
      } else {
        this.addOrderList.splice(scope.$index, 1)
        let num = 0
        for (let i in this.addOrderList) {
          if (this.addOrderList[i].userId == scope.row.userId) {
            num += 1
          }
        }
        if (num == 0) {
          this.addEditForm.userId = this.addEditForm.userId.filter(item => item != scope.row.userId)
        }

      }
    },
    // 选择用户
    async changeUser(e) {
      this.addOrderList = []
      this.userInfo = this.userList.find(function (obj) {
        return obj.id == e
      })
      console.log(this.userInfo, 'this.userInfo')
      let result = await this.selectUserOrder(this.addEditForm.userId, this.addEditForm.warehouseId)
      if (result.errcode == 0) {
        if (result.data.length > 0) {
          result.data.forEach(item => {
            let obj = {
              id: item.goodsId,
              num: item.num,
              userId: this.userInfo.id,
              level: this.userInfo.level,
              oldPrice: item.salePrice,
              price: item.price,
              isActivity: item.isActivity,
              takeSale: item.takeSale,
              warehouseId: this.addEditForm.warehouseId,
              wholesalePrice: item.wholesalePrice,
              totalPrice: item.totalPrice,
              isPresell: item.isPresell,
              isCurrentWeighing: item.isCurrentWeighing,
              goodsMallActivityRuleList: item.goodsMallActivityRuleList,
              remark: item.remark || '',
              isUpdate: 0,//0.不更新订单（不校验价格），1.更新订单
            }
            if (item.isGift != 1) {
              if (this.userInfo.level == 2) {
                obj.price = item.wholesalePrice
                // obj.totalPrice = (Number(item.wholesalePrice) * Number(item.num)).toFixed(2)
                var value = Number(item.wholesalePrice) * Number(item.num)
                obj.totalPrice = this.$cjs.preciseNum(value, 2)

              } else {
                obj.isPresell = item.isPresell || '0'
                obj.isActivity = item.goodsMallActivityRuleList.length && item.goodsMallActivityRuleList ? 1 : 0
                if (obj.isActivity == '0' && Number(item.takeSale) > 0 && item.takeSale) {
                  obj.price = item.takeSale
                  // obj.totalPrice = (Number(item.takeSale) * Number(item.num)).toFixed(2)
                  var value = Number(item.takeSale) * Number(item.num)
                  obj.totalPrice = this.$cjs.preciseNum(value, 2)

                  obj.isSelfPickup = 1
                } else {
                  // item.price = item.salePrice
                  obj.isSelfPickup = 0
                }
              }
            }

            this.addOrderList.push(obj)
          })
        } else {
          let obj = {
            id: "",
            num: '',
            userId: this.userInfo.id,
            level: this.userInfo.level,
            oldPrice: "",
            price: "",
            isActivity: "0",
            warehouseId: this.addEditForm.warehouseId,
            totalPrice: 0,
            isPresell: 0,
            isCurrentWeighing: 0,
            goodsMallActivityRuleList: [],
            remark: ''
          }
          this.addOrderList.push(obj)
        }
      }
    },
    // async changeUser(e) {
    //   let addOrderList = []
    //   this.addOrderList = [];
    //   let arr = typeof e == 'string' ? [e] : e
    //   if (!e) {
    //     this.addOrderList = [];
    //     return
    //   }
    //   if (!this.addOrderList.length) {
    //     for (let i in arr) {
    //       var userItem = this.userList.find(function (obj) {
    //         return obj.id == arr[i]
    //       })
    //       var price = userItem.level == 2 ? this.selectGoodsInfo.wholesalePrice : this.selectGoodsInfo.salePrice
    //       let obj = {
    //         id: this.addEditForm.goodsId,
    //         // num: this.selectGoodsInfo.minimum || '',
    //         num: '',
    //         userId: arr[i],
    //         level: userItem.level,
    //         oldPrice: price,
    //         price: price,
    //         isActivity: "0",
    //         warehouseId: this.addEditForm.warehouseId,
    //         totalPrice: 0,
    //         isPresell: 0,
    //         isCurrentWeighing: 0,
    //         goodsMallActivityRuleList: [],
    //         // isPresell: this.selectGoodsInfo.isPresell || "0",//是否为预售单 0为否 1为是
    //       }
    //       //判断商品有没有买过 买过把数量改了
    //       if (this.addEditForm.userId && this.addEditForm.warehouseId) {
    //         let result = await this.selectUserOrder(this.addEditForm.userId, this.addEditForm.warehouseId)
    //         if (result.errcode == 0) {
    //           console.log(result, "resultresultresultresultresult")
    //           if (result.data.length > 0) {
    //             result.data.forEach(item => {
    //               item.isPresell = item.isPresell || '0'
    //               // item.isCurrentWeighing = '0'
    //               item.oldPrice = item.salePrice
    //               item.isActivity = item.goodsMallActivityRuleList.length && item.goodsMallActivityRuleList ? 1 : 0
    //               if (item.isActivity == '0' && Number(item.takeSale) > 0 && item.takeSale) {
    //                 item.price = item.takeSale
    //                 item.totalPrice = (Number(item.takeSale) * Number(item.num)).toFixed(2)
    //                 item.isSelfPickup = 1
    //               } else {
    //                 // item.price = item.salePrice
    //                 item.isSelfPickup = 0
    //               }
    //             })
    //             addOrderList = result.data
    //           } else {
    //             obj.num = result.data.num
    //             obj.isPresell = 0
    //             obj.isActivity = result.data.isActivity
    //             let data = {
    //               row: obj
    //             }
    //             this.changeNum(data, Number(result.data.num))
    //             addOrderList.push(obj)
    //           }
    //         }
    //       } else {
    //         obj.isPresell = this.selectGoodsInfo.isPresell || "0" //是否为预售单 0为否 1为是
    //         addOrderList.push(obj)
    //       }
    //       this.addOrderList = addOrderList
    //     }

    //   } else {
    //     //过滤表格里选择的，多选框没选择的  把过滤的数据从表格里删除
    //     console.log(this.addOrderList)
    //     let addOrderListNo = this.addOrderList.filter((item) => !e.some((item2) => item2 === item.userId));

    //     for (let i in this.addOrderList) {
    //       for (let j in addOrderListNo) {
    //         if (this.addOrderList[i].userId == addOrderListNo[j].userId) {
    //           this.addOrderList.splice(i, 1)
    //         }
    //       }
    //     }
    //     //过滤多选框里选择的， 表格里没有的  把过滤的数据添加到表格里
    //     let no = e.filter((item) => !this.addOrderList.some((item2) => item2.userId === item));
    //     for (let i in no) {
    //       let obj = {
    //         id: this.addEditForm.goodsId,
    //         // num: this.selectGoodsInfo.minimum || '',
    //         num: '',
    //         userId: no[i],
    //         oldPrice: this.selectGoodsInfo.salePrice,
    //         price: this.selectGoodsInfo.salePrice,
    //         goodsMallActivityRuleList: this.selectGoodsInfo.goodsMallActivityRuleList || [],
    //         warehouseId: this.addEditForm.warehouseId,
    //         isPresell: 0,//是否为预售单 0为否 1为是
    //       }
    //       if (this.addEditForm.userId && this.addEditForm.warehouseId) {
    //         let result = await this.selectUserOrder(this.addEditForm.userId, this.addEditForm.warehouseId)
    //         if (result.errcode == 0) {
    //           obj.num = result.data.num
    //         }
    //       }
    //       this.addOrderList.push(obj)
    //     }
    //   }
    //   console.log(this.addOrderList, "this.addOrderList")
    // },
    changeGood(e) {
      this.selectGoodsInfo = this.selectGoods.filter(item => item.id === e)
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.status == 200) {
            _this.warehouseList = res.data.data;
            _this.addEditForm.adcode = _this.warehouseList[0].adcode
            _this.addEditForm.warehouseId = _this.warehouseList[0].id
            _this.getListByUser()
            _this.getUserList()
          }
        });
    },
    // 获取下拉商品
    getListByUser() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/saleChildOrder/getGoodsMallList", {
          params: {
            // adcode: _this.addEditForm.adcode,
            warehouseId: _this.addEditForm.warehouseId
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = `${item.name}（${item.standard}）`
            });
            _this.$nextTick(() => {
              _this.selectGoods = list;
            })
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getWayUser", {
          params: {
            warehouseId: _this.addEditForm.warehouseId
          }
        })
        .then((res) => {
          if (res.status == 200) {
            res.data.data.forEach(item => {
              item.wechat = `${item.wechat}（${item.shopName}）`
            })
            _this.userList = res.data.data;
          }
        });
    },
    // 选择仓库
    changeWarehouse(e) {
      for (let i in this.warehouseList) {
        if (this.warehouseList[i].id == e) {
          this.addEditForm.adcode = this.warehouseList[i].adcode
        }
      }
      this.addEditForm.warehouseId = e
      this.addEditForm.userId = ''
      this.addEditForm.goodsId = ''
      this.addOrderList = []
      this.getListByUser()
      this.getUserList()
    },
    // 查询用户是否存在自提订单
    async selectUserOrder() {
      let _this = this;
      return new Promise((resolve, reject) => {
        _this.$axios
          .get(_this.$axios.defaults.basePath + "/saleChildOrder/selectUserOrder", {
            params: {
              userId: _this.addEditForm.userId,
              warehouseId: _this.addEditForm.warehouseId
            }
          })
          .then((res) => {
            resolve(res.data)
          }).catch(reject);;
      });
    },
  }
}
</script>

<style scoped>
.tables {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tables .btns {
  height: 200px;
  display: flex;
  align-items: center;
}
.orderTable >>> .el-form-item:last-child .el-form-item__content {
  width: 100%;
}
.orderTable >>> .el-table .cell {
  overflow: initial;
}
</style>