<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <!-- <el-button @click="addEditCancel">取 消</el-button> -->
        <el-button type="primary" :disabled="dialogTitle != '销售产品编辑' && dialogTitle != '编辑规格信息'" :loading="btnLoading" @click="addEditSubmit('addEditForm')">保 存</el-button>
        <el-button type="primary" :disabled="dialogTitle == '销售产品新增'?false: dialogTitle == '添加规格信息'?false:true" :loading="btnLoading" @click="addEditSubmit('addEditForm')">保存并新增</el-button>
        <p style="color:red" v-if="this.changeType == 'edit'&&!addEditForm.warehouseId">* 当前产品为旧数据，请检查是否关联仓库及售价是否填写！</p>
      </div>
      <div class="goods">
        <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px" style="padding: 20px">
          <div v-if="
  dialogTitle == '销售产品详情' ||
  dialogTitle == '销售产品新增' ||
  (dialogTitle == '销售产品编辑' && !standardId)
          ">
            <div class="titleBox">
              <p>产品基础信息</p>
            </div>
            <el-form-item label="选择产品" prop="goodsId">
              <el-select v-model="addEditForm.goodsId" filterable style="width: 300px" :disabled="detailsShow" placeholder="可搜索选项" @change="changeGoodsEvents">
                <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品类型" v-if="addEditForm.goodsId">
              <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2" placeholder="请选择产品类型" :props="{
                  checkStrictly: true,
                  label: 'name',
                  value: 'id',
                  children: 'childs',
                }" :disabled="true" clearable @change="cascaderChange2"></el-cascader>
            </el-form-item>
            <el-form-item label="产品单位" v-if="addEditForm.goodsId">
              <el-select v-model="addEditForm.unitId" style="width: 300px" @change="unitChange" :disabled="true">
                <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="颜色" v-if="addEditForm.goodsId">
              <el-input v-model="addEditForm.color" placeholder="请输入颜色" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="型号" v-if="addEditForm.goodsId">
              <el-input v-model="addEditForm.model" placeholder="请输入型号" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="制造商" v-if="addEditForm.goodsId">
              <el-input v-model="addEditForm.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="描述" v-if="addEditForm.goodsId">
              <el-input type="textarea" v-model="addEditForm.describe" placeholder="请输入描述" style="width: 300px" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="产品图片" style="display: block" v-if="addEditForm.goodsId">
              <template>
                <img v-for="(items, i) in imgData" :key="i" :src="items" alt="" width="100px" style="margin-right: 15px" />
              </template>
            </el-form-item>
            <el-form-item label="产品视频" v-if="addEditForm.goodsId && addEditForm.videos" style="display: block">
              <video :src="addEditForm.videos" width="200px" controls></video>
            </el-form-item>
          </div>
          <div v-if="changeType == 'details'||changeType == 'add'||changeType =='edit' || changeType == 'childGoods'">
            <!-- v-if="
  dialogTitle == '销售产品详情' ||
  dialogTitle == '销售产品新增' ||
  (dialogTitle == '销售产品编辑' && !standardId)" -->

            <div class="titleBox">
              <p>产品销售信息</p>
            </div>
            <!-- <el-button size="mini" type="primary" @click="addGoodsType">添加产品规格</el-button> -->
            <!-- goodsStandard -->
            <!-- <el-form-item label="商品编码" prop="code">
                    <el-input type="text" v-model="addEditForm.code" placeholder="请输入商品编码" style="width: 300px"></el-input>
                  </el-form-item> -->
            <el-form-item label="订单类型" prop="type">
              <el-select v-model="addEditForm.type" placeholder="请选择订单类型" style="width: 300px" :disabled="minimumchange" @change="changeOrderType">
                <el-option v-for="(item, index) in orderType" :key="index" :label="item.label" :value="item.id" :disabled="detailsShow"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime" v-if="addEditForm.type !=4 ">
              <el-date-picker v-model="addEditForm.startTime" type="datetime" style="width: 300px" :disabled="detailsShow" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <!-- addEditForm.type == 1 || addEditForm.type == 3  -->
            <el-form-item label="结束时间" prop="endTime" v-if="addEditForm.type !=4 ">
              <el-date-picker v-model="addEditForm.endTime" type="datetime" style="width: 300px" :disabled="detailsShow" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="配送时间" v-if="addEditForm.type != 4">
              <!-- 限时限量限购显示 -->
              <el-input v-model="addEditForm.deliveryTime" placeholder="请输入配送时间" style="width: 300px" :disabled="detailsShow"></el-input>
              <!-- <el-date-picker
                      v-model="addEditForm.deliveryTime"
                      type="date"
                      style="width: 300px"
                      :disabled="detailsShow"
                      value-format="yyyy-MM-dd"
                      placeholder="选择配送时间"
                    >
                    </el-date-picker> -->
            </el-form-item>
            <!-- 新增时仓库为多选、编辑时为单选 -->
            <el-form-item label="所属仓库" prop="warehouseId">
              <el-select v-if="dialogTitle == '销售产品新增'" v-model="warehouseId" placeholder="请选择所属仓库" style="width: 300px" multiple :disabled="detailsShow" @change="changeWarehouse">
                <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id" :disabled="detailsShow"></el-option>
              </el-select>
              <!-- :disabled="detailsShow || dialogTitle == '编辑'" -->
              <el-select v-else v-model="addEditForm.warehouseId" placeholder="请选择所属仓库" style="width: 300px" :disabled="detailsShow">
                <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id" :disabled="detailsShow"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序">
              <el-input type="text" v-model="addEditForm.sort" placeholder="请输入排序" style="width: 300px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="addEditForm.remark" placeholder="请输入备注" style="width: 300px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="是否为活动" v-if="addEditForm.type != 3">
              <el-switch v-model="addEditForm.isActivity" active-color="#13ce66" style="width: 300px" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="detailsShow">
              </el-switch>
              <!-- <p style="color:red">注：若设置为活动，则必须有多个规格，且都有起订量</p> -->
            </el-form-item>
            <el-form-item label="是否为组合规格">
              <el-switch v-model="addEditForm.isCombination" active-color="#13ce66" active-text="是" inactive-text="否" :disabled="detailsShow">
              </el-switch>
            </el-form-item>
          </div>

          <div v-if="
            dialogTitle == '销售产品详情' ||
            dialogTitle == '销售产品新增' ||
            dialogTitle == '销售产品编辑' ||
            dialogTitle == '添加规格信息' ||
            dialogTitle == '编辑规格信息'
          " class="titleBox clearfix">
            <p class="float_lf">产品规格信息</p>
            <el-button v-if="!addEditForm.isCombination&&
              (dialogTitle == '销售产品新增' ||
              dialogTitle == '添加规格信息')
            " class="float_rt" size="mini" type="primary" @click="addGoodsType">添加规格信息</el-button>
          </div>
          <!-- 组合规格信息 -->
          <div class="standard_box" v-if="addEditForm.isCombination">
            <el-form-item label="尺码" style="width:100%">
              <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
              <el-checkbox-group v-model="checkedSize" @change="handleCheckedChange($event,'size')">
                <el-checkbox v-for="item in sizeList" :label="item" :key="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="颜色" style="width:100%">
              <el-checkbox-group v-model="checkedColor" @change="handleCheckedChange($event,'color')">
                <el-checkbox v-for="item in colorList" :label="item" :key="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="采购价(元)" label-width="100px">
              <el-input v-model="addEditForm.purchasing" placeholder="请输入采购价" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="原价(元)" label-width="80px">
              <el-input v-model="addEditForm.price" placeholder="请输入原价" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="乡兴供货价(元)">
              <el-input v-model="addEditForm.salePrice" placeholder="请输入乡兴供货价" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="自提价(元)" label-width="80px">
              <el-input v-model="addEditForm.takeSale" placeholder="请输入自提价格" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="批发商价(元)">
              <el-input v-model="addEditForm.wholesalePrice" placeholder="请输入批发商价格" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="起订量" v-if="addEditForm.type != 3" label-width="70px">
              <el-input v-model="addEditForm.minimum" placeholder="请输入起订量" style="width: 150px" :disabled="minimumchange" @input="changeMinimum"></el-input>
            </el-form-item>
            <el-form-item label="限购份数" v-if="addEditForm.type == 3">
              <el-input :min="1" v-model="addEditForm.limit" placeholder="请输入限购份数" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="规格比">
              1 : <el-input :min="1" v-model="addEditForm.scaledValue" placeholder="请输入规格比" style="width: 150px" :disabled="detailsShow"></el-input>
            </el-form-item>
            <el-form-item label="是否为预售产品">
              <el-switch v-model="addEditForm.isPresell" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
              </el-switch>
            </el-form-item>
            <el-form-item label="是否为现称产品">
              <el-switch v-model="addEditForm.isCurrentWeighing" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
              </el-switch>
            </el-form-item>
            <!-- <el-form-item label="是否为主产品">
              <el-switch v-model="addEditForm.type" active-color="#13ce66" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="detailsShow" @change="isMainGoods($event, i)">
              </el-switch>
            </el-form-item> -->
            <el-form-item v-if="addEditForm.goodsId && addEditForm.isActivity == 1" label="活动规则">
              <el-button type="primary" size="mini" @click="settingRules(null)" :disabled="detailsShow">设置活动规则</el-button>
              <div v-if="addEditForm.goodsMallActivityRuleList && addEditForm.goodsMallActivityRuleList.length > 0">
                <p v-for="(sItem, standI) in addEditForm.goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，单价 ' + sItem.discounts + '元' }}</p>
              </div>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" @click="composeStandard">确定</el-button>
            </el-form-item>

            <el-table ref="multipleTable" :data="addEditForm.goodsStandard" tooltip-effect="dark" style="width: 100%" v-loading="loading">
              <el-table-column label="产品规格" align="center">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'goodsStandard[' + scope.$index + '].standard'">
                    <el-input v-model="scope.row.standard" placeholder="产品规格" style="width: 150px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="采购价(元)" align="center">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.purchasing" placeholder="采购价" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="原价(元)" align="center">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.price" placeholder="原价" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="乡兴供货价(元)" align="center">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.salePrice" placeholder="乡兴供货价" style="width: 100px" :disabled="detailsShow" @input="pointNumInput(child, i)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="自提价(元)" align="center">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.takeSale" placeholder="自提价" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="批发商价(元)" align="center">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-input v-model="scope.row.wholesalePrice" placeholder="请输入批发商价格" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="起订量" align="center" v-if="addEditForm.type != 3">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'goodsStandard[' + scope.$index + '].minimum'">
                    <el-input v-model="scope.row.minimum" placeholder="起订量" style="width: 100px" :disabled="minimumchange" @input="changeMinimum"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="限购份数" align="center" v-if="addEditForm.type == 3">
                <template slot-scope="scope">
                  <el-form-item label="" :prop="'goodsStandard[' + scope.$index + '].limit'">
                    <el-input :min="1" v-model="scope.row.limit" placeholder="限购份数" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="规格比" align="center" v-if="addEditForm.type == 3">
                <template slot-scope="scope">
                  <el-form-item label="">
                    1 : <el-input :min="1" v-model="scope.row.scaledValue" placeholder="请输入规格比" style="width: 100px" :disabled="detailsShow"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="是否为预售产品" align="center" width="120">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-switch v-model="scope.row.isPresell" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                    </el-switch>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="是否为现称产品" align="center" width="120">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-switch v-model="scope.row.isCurrentWeighing" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                    </el-switch>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="是否为主产品" align="center" width="120">
                <template slot-scope="scope">
                  <el-form-item label="">
                    <el-switch v-model="scope.row.type" active-color="#13ce66" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="detailsShow" @change="isMainGoods($event, i)">
                    </el-switch>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="产品规格图片" align="center" width="340">
                <template slot-scope="scope">
                  <el-form-item label="" prop="imgs" style="display: block">
                    <cropperAndUploadVue :disabled="detailsShow" :list="goodsStandard[scope.$index].standardImg" :size="[5, 5]" :limit="1" :sortable="false" @change="(list) => handleChange(list, scope.$index)" @success="(list) => handleSuccess(list, scope.$index)"></cropperAndUploadVue>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" v-if="dialogTitle == '销售产品编辑'">
                <template slot-scope="scope">
                  <el-button type="primary" @click="synchronizePrice(scope.row)">同步</el-button>
                </template>
              </el-table-column>
              <el-table-column label="" align="center" width="100">
                <template slot-scope="scope">
                  <el-button v-if="
                  dialogTitle == '销售产品新增' ||
                  dialogTitle == '添加规格信息'
                " class="float_rt" size="mini" type="danger" @click="delGoodsType(scope.$index, scope.row.id, addEditForm.warehouseId)">
                    删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 规格信息 -->
          <div class="standard_box" v-if="!addEditForm.isCombination">
            <div v-for="(child, i) in addEditForm.goodsStandard" :key="i">
              <div class="standard_list">
                <el-button v-if="
                  dialogTitle == '销售产品新增' ||
                  dialogTitle == '添加规格信息'
                " class="float_rt" size="mini" type="danger" @click="delGoodsType(i, child.id, addEditForm.warehouseId)">
                  删除</el-button>
                <el-button v-if="child.type == 1 && changeType != 'editStandard'" class="float_rt" size="mini" type="primary" style="margin-right:20px;" :disabled="detailsShow" @click="addChildGoods(i, addEditForm)">
                  添加子产品</el-button>
                <!-- :prop="'goodsStandard[' + i + '].code'" -->
                <!-- <el-form-item label="产品编码">
                  <el-input type="text" v-model="addEditForm.goodsStandard[i].code" placeholder="请输入商品编码" style="width: 300px"></el-input>
                </el-form-item> -->
                <el-form-item label="产品规格" :prop="'goodsStandard[' + i + '].standard'">
                  <el-input v-model="addEditForm.goodsStandard[i].standard" placeholder="请输入产品规格" style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <!-- :prop="'goodsStandard[' + i + '].purchasing'" -->
                <el-form-item label="采购价(元)">
                  <el-input v-model="addEditForm.goodsStandard[i].purchasing" placeholder="请输入采购价" style="width: 300px" :disabled="true"></el-input>
                </el-form-item>

                <el-form-item label="原价(元)" :prop="'goodsStandard[' + i + '].price'">
                  <el-input v-model="addEditForm.goodsStandard[i].price" placeholder="请输入原价" style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>

                <el-form-item label="乡兴供货价(元)" :prop="'goodsStandard[' + i + '].salePrice'">
                  <el-input v-model="addEditForm.goodsStandard[i].salePrice" placeholder="请输入乡兴供货价" style="width: 300px" :disabled="detailsShow" @input="pointNumInput(child, i)"></el-input>
                </el-form-item>
                <el-form-item label="自提价(元)">
                  <el-input v-model="addEditForm.goodsStandard[i].takeSale" placeholder="请输入自提价格" style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="批发商价(元)">
                  <el-input v-model="addEditForm.goodsStandard[i].wholesalePrice" placeholder="请输入批发商价格" style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="起订量" v-if="addEditForm.type != 3" :prop="'goodsStandard[' + i + '].minimum'">
                  <el-input v-model="addEditForm.goodsStandard[i].minimum" placeholder="请输入起订量" style="width: 300px" :disabled="minimumchange || detailsShow" @input="changeMinimum"></el-input>
                </el-form-item>
                <el-form-item label="限购份数" v-if="addEditForm.type == 3" :prop="'goodsStandard[' + i + '].limit'">
                  <el-input :min="1" v-model="addEditForm.goodsStandard[i].limit" placeholder="请输入限购份数" style="width: 300px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="规格比">
                  1 : <el-input :min="1" v-model="addEditForm.goodsStandard[i].scaledValue" placeholder="请输入规格比" style="width: 280px" :disabled="detailsShow"></el-input>
                </el-form-item>
                <el-form-item label="是否为预售产品">
                  <el-switch v-model="addEditForm.goodsStandard[i].isPresell" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                  </el-switch>
                </el-form-item>
                <el-form-item label="是否为现称产品">
                  <el-switch v-model="addEditForm.goodsStandard[i].isCurrentWeighing" active-color="#13ce66" active-text="是" inactive-text="否" active-value="1" inactive-value="0" :disabled="detailsShow">
                  </el-switch>
                </el-form-item>
                <el-form-item label="是否为主产品">
                  <el-switch v-model="addEditForm.goodsStandard[i].type" active-color="#13ce66" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="detailsShow" @change="isMainGoods($event, i)">
                  </el-switch>
                </el-form-item>
                <el-form-item label="" style="margin-left:80px">
                  <el-button type="primary" v-if="dialogTitle == '销售产品编辑'" @click="synchronizePrice(addEditForm.goodsStandard[i])">同步</el-button>
                </el-form-item>
                <!-- 隐藏积分相关功能 -->
                <!-- <el-form-item label="积分倍数" :prop="'goodsStandard[' + i + '].pointNum'">
                  <el-input v-model="addEditForm.goodsStandard[i].pointNum" placeholder="请输入积分倍数" style="width: 300px"
                    :disabled="detailsShow" type="text" @input="pointNumInput(child, i)"></el-input>
                </el-form-item>
                <el-form-item label="积分(参考值)">
                  <el-input v-model="addEditForm.goodsStandard[i].point" placeholder="请输入积分倍数" style="width: 300px"
                    :disabled="true"></el-input>
                </el-form-item> -->
                <el-form-item v-if="addEditForm.goodsId && addEditForm.isActivity == 1" label="活动规则">
                  <div v-if="goodsStandard[i].goodsMallActivityRuleList.length>0" style="width:300px">
                    <div v-if="goodsStandard[i].activityType == 0">
                      <p v-for="(sItem, standI) in goodsStandard[i].goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，单价 ' + sItem.discounts + '元' }}</p>
                    </div>
                    <div v-if="goodsStandard[i].activityType == 1">
                      <p v-for="(sItem, standI) in goodsStandard[i].goodsMallActivityRuleList" :key="standI">{{ '满' + sItem.threshold + '份，赠送 '+ sItem.giftName +' '+sItem.discounts + '份' }}</p>
                    </div>
                  </div>
                  <el-button type="primary" size="mini" @click="settingRules(i)" :disabled="detailsShow">设置活动规则</el-button>
                </el-form-item>
                <el-form-item label="产品规格图片" prop="imgs">
                  <cropperAndUploadVue :disabled="detailsShow" :list="goodsStandard[i].standardImg" :size="[11, 11]" :limit="1" :sortable="false" @change="(list) => handleChange(list, i)" @success="(list) => handleSuccess(list, i)"></cropperAndUploadVue>
                </el-form-item>
              </div>
              <!-- 子产品列表 -->
              <div class="childTable" v-if="child.type == 1 && changeType != 'editStandard'">
                <p class="boxTitle">子产品列表</p>
                <template>
                  <el-table :data="child.childGoodsMallFormList" border style="width: 100%">
                    <el-table-column prop="standard" label="产品规格"></el-table-column>
                    <el-table-column prop="isPresell" label="是否是预售产品">
                      <template slot-scope="scope">
                        {{ scope.row.isPresell == 1 ? '是' : '否' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="isCurrentWeighing" label="是否是现称产品">
                      <template slot-scope="scope">
                        {{ scope.row.isCurrentWeighing == 1 ? '是' : '否' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="isGift" label="是否是赠品">
                      <template slot-scope="scope">
                        {{ scope.row.isGift == 1 ? '是' : '否' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="num" label="库存"></el-table-column>
                    <el-table-column prop="saleTotalNum" label="商品总量">
                      <template slot-scope="scope">
                        {{ scope.row.type == 0 ? 0 : scope.row.saleTotalNum }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="purchasing" label="进货价"></el-table-column>
                    <el-table-column prop="price" label="原价">
                      <template slot-scope="scope">
                        {{ scope.row.price }} 元
                      </template>
                    </el-table-column>
                    <el-table-column prop="salePrice" label="乡兴供货价">
                      <template slot-scope="scope">
                        {{ scope.row.salePrice }} 元
                      </template>
                    </el-table-column>
                    <el-table-column prop="salePrice" label="自提价">
                      <template slot-scope="scope">
                        {{ scope.row.takeSale }} 元
                      </template>
                    </el-table-column>
                    <el-table-column prop="wholesalePrice" label="批发商价">
                      <template slot-scope="scope">
                        {{ scope.row.wholesalePrice ? scope.row.wholesalePrice : 0 }} 元
                      </template>
                    </el-table-column>
                    <el-table-column prop="scaledValue" label="规格比">
                      <template slot-scope="scope">
                        <p v-if="scope.row.scaledValue">1 : {{ scope.row.scaledValue }}</p>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column prop="limit" label="限购量" v-if="scope.row.type == 3"></el-table-column> -->
                    <el-table-column prop="minimum" label="起订量"></el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="editChildEvent(scope.row, i,scope.$index)" v-if="updateButton == '1'" :disabled="scope.row.isTopCarriage == 1 || detailsShow">编辑</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 设置活动规则 -->
    <active-rule-setting v-if="rulesShow" :dialogShow="rulesShow" :dialogTitle="dialogTitle" :ruleList="ruleList" :guiGeList="guiGeList" :giftGoodsList="giftGoodsList" :activityType="activityType" :goodsIds="goodsIds" :salesGoodsId="salesGoodsId" @pushRules="pullRules" @closeClick="closeDialog"></active-rule-setting>

    <!-- <el-dialog title="设置活动规则" :visible.sync="rulesShow" width="50%" @close="rulesShow = false">
      <template>
        <el-table :data="ruleList" style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="threshold" label="购买数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.threshold" placeholder="请输入购买数量" style="width: 200px"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="discounts" label="单价(元)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.discounts" placeholder="请输入对应单价" style="width: 200px"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="addRules">添 加</el-button>
              <el-button size="mini" type="danger" @click="delRules(scope.$index)">删 除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRules">确 定</el-button>
        <el-button @click="rulesShow = false;">取 消</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { isArray } from 'mathjs';
import vuedraggable from "vuedraggable";
export default {
  name: "salesGoodsAdd",
  components: {
    vuedraggable,
    cropperAndUploadVue: () => import("@/components/cropperAndUpload.vue"),
    activeRuleSetting: () => import("@/components/activeRulesSetting.vue"),
  },
  data() {
    return {
      changeType: null,//操作类型
      typeParams: null,//数据
      checkedSize: [],//选中的尺寸
      checkedColor: [],//选中的颜色
      sizeList: [],//尺寸
      colorList: [],//颜色
      isMinimumList: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "符合起订量"
        },
        {
          id: 2,
          name: "不符合起订量"
        }
      ],
      orderNumShow: false,
      updateOrderNumData: {
        id: "",//订单id
        num: "",//订单数量
      },
      seeBuyOrderUserId: "",
      returnRules: {
        returnGoodsNum: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
        ],
      },
      returnShow: false,
      returnForm: {},
      userBuyOrderShow: false,
      userBuyOrderList: [],//用户订单列表
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      upDownNumButton: '',
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
        isOrder: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      // 购买记录分页
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
        isCombination: false,//是否是组合规格
        goodsMallActivityRuleList: [], //活动规则
        goodsStandard: [],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 产品类型
      goodsType: [],
      // 产品单位
      goodsUnit: [],
      // 产品状态
      goodsStatus: [
        {
          label: "未上架",
          id: "0",
        },
        {
          label: "已上架",
          id: "1",
        }, {
          label: "已下架",
          id: "2",
        }, {
          label: "已截单",
          id: "3",
        },
      ],
      isOrder: [
        {
          label: "查全部",
          id: "0",
        },
        {
          label: "查有订单的",
          id: "1",
        }
      ],
      activeName: "",
      // 订单类型
      orderType: [
        {
          label: "限时订单",
          id: "1",
        },
        {
          label: "限量订单",
          id: "2",
        },
        {
          label: "限购订单",
          id: "3",
        },
        {
          label: "热销现货订单",
          id: "4",
        },
        {
          label: "满减活动",
          id: "5",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉产品
      selectGoods: [],
      warehouseList: [],
      // 产品购买列表
      buysListData: [],
      // 仓库多选
      warehouseId: [],
      // 已下单人数
      buyNum: "",
      // 下单量
      orderNum: "",
      submitBtnLoad: false,
      // 起订量是否可修改 判断已上架的产品在下架后不可修改起订量
      minimumchange: false,
      // 产品规格数组
      goodsStandard: [
      ],
      standardId: "",
      upDownShow: false,
      downRowData: {},
      downType: "", //一键下架/规格下架
      downWarehouseId: "", //下架仓库ID
      sortShow: false,
      goodsUpList: [],
      adcode: sessionStorage.getItem('adcode'),
      // 排序仓库ID
      sortWarehouseId: '',
      dragIndex: '',
      enterIndex: '',
      userName: sessionStorage.getItem('userName'),
      // 购买记录
      buyListShow: false,
      buyGoodsId: '',
      buyWarehouseId: '',
      buyStandardList: [],
      buyListForm: {
        standard: '',
        userName: '',
        isMinimum: '',
      },
      userList: [],
      editNumShow: false,
      formData: {
        num: '',
      },
      editNum: {
        num: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }]
      },
      btnLoading: false,
      goodsId: '',
      // 路由返回的ID
      warehouseId_r: '',
      rulesShow: false,
      ruleList: [
        {
          discounts: '',
          threshold: '',
        },
      ],
      // 规格下标 --设置活动规则
      standardIndex: '',
      // 设置活动规则 查询产品下的规格
      guiGeList: [],
      salesGoodsId: '',
      goodsIds: [],
      // 活动类型 0满减 1满赠
      activityType: 0,
      giftGoodsList: [],
      // 设置活动时选中的销售产品信息
      nowGoodsData: {},
      nowIndex: '', //当前设置活动规则的规格下标
    };
  },
  computed: {
    tabOrderType() {
      return [
        {
          label: "全部",
          id: "",
        },
        ...this.orderType,
      ];
    },
    addEditRules() {
      // 结束时间不能小于开始时间验证
      var timeRules = (rule, value, callback) => {
        var start = new Date(this.addEditForm.startTime).getTime();
        var end = new Date(value).getTime();
        if (end < start) {
          return callback(new Error("结束时间不能小于开始时间！"));
        } else {
          callback();
        }
      };
      let data = {
        goodsId: [{ required: true, message: "请选择产品", trigger: "change" }],
        standard: [
          { required: true, message: "请输入产品规格", trigger: "blur" },
        ],
        purchasing: [
          { required: true, message: "请输入产品采购价", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入产品原价", trigger: "blur" }],
        salePrice: [
          { required: true, message: "请输入产品原价", trigger: "blur" },
        ],
        // num: [{ required: true, message: "请输入产品库存", trigger: "blur" }],
        pointNum: [
          { required: true, message: "请输入积分倍数", trigger: "blur" },
        ],
        point: [{ required: true, message: "请输入所需积分", trigger: "blur" }],
        type: [
          { required: true, message: "请选择订单类型", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
          { validator: timeRules, trigger: "blur" },
        ],
        warehouseId: [
          { required: true, message: "请选择所属仓库", trigger: "change" },
        ],
      };

      this.goodsStandard.forEach((standard, index) => {
        data = Object.assign({}, data, {
          // ["goodsStandard[" + index + "].code"]: [
          //   { required: true, message: "请输入商品编码", trigger: "blur" },
          // ],
          ["goodsStandard[" + index + "].standard"]: [
            { required: true, message: "请输入产品规格", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].purchasing"]: [
            { required: true, message: "请输入采购价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].price"]: [
            { required: true, message: "请输入原价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].salePrice"]: [
            { required: true, message: "请输入乡兴供货价", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].num"]: [
            { required: true, message: "请输入库存", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].minimum"]: [
            { required: true, message: "请输入起订量", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].limit"]: [
            { required: true, message: "请输入限购份数", trigger: "blur" },
          ],
          ["goodsStandard[" + index + "].pointNum"]: [
            { required: true, message: "请输入积分倍数", trigger: "blur" },
          ],
        });
      });

      return data;
    },
  },
  created() {
    this.getGoodsType();
    this.getGoodsUnit();
    this.getSelectGoods();
    this.getWarehouseList();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_query") {
        this.searchButton = "1";
      }
    });
  },
  activated() {

    let datas = {}
    if (this.$route.params.type == undefined || this.$route.params.type == "undefined") {
      if (!sessionStorage.getItem("changeType") || (sessionStorage.getItem("changeType") == "undefined" || sessionStorage.getItem("changeType") == undefined)) {
        this.changeType = "add"
        this.typeParams = null
        sessionStorage.setItem("changeType", "add");
      } else {
        this.changeType = sessionStorage.getItem("changeType")
        this.typeParams = JSON.parse(sessionStorage.getItem("typeParams"))
      }
    } else {
      this.changeType = this.$route.params.type
      this.typeParams = this.$route.params
      sessionStorage.setItem("changeType", this.$route.params.type);
      sessionStorage.setItem("typeParams", JSON.stringify(this.$route.params));
    }
    this.getSysDictionaries(3)
    this.getSysDictionaries(2)
    console.log(this.changeType, this.typeParams, "datasdatas")
    if (this.changeType == 'details') {
      console.log('details')
      this.dialogTitle = '销售产品详情'
      this.detailsShow = true
      this.standardId = ''
      this.goodsId = this.typeParams.id
      // datas = JSON.parse(this.typeParams.data)
      this.warehouseId_r = this.typeParams.warehouseId

      this.handleGoodsInfo()
    } else if (this.changeType == 'edit') {
      console.log('edit')
      console.log(JSON.parse(this.typeParams.data))
      this.dialogTitle = '销售产品编辑'
      this.detailsShow = false
      this.goodsId = this.typeParams.id
      datas = JSON.parse(this.typeParams.data)
      this.warehouseId_r = this.typeParams.warehouseId
      this.minimumchange = this.typeParams.minimumchange
      this.salesGoodsId = datas.id
      this.standardId = ''
      this.$set(this.addEditForm, "isActivity", datas.isActivity);
      this.handleGoodsInfo()
    } else if (this.changeType == 'goodsStandard') {
      console.log('goodsStandard')
      this.dialogTitle = '添加规格信息'
      this.standardId = "";
      // datas = JSON.parse(this.typeParams.data)
      this.goodsStandard = [];
      this.addEditForm = {};
      let json = {
        code: '',
        standard: "",
        purchasing: "",
        price: "",
        salePrice: "",
        num: "",
        minimum: "",
        limit: "",
        // 隐藏积分相关功能
        // pointNum: "",
        // point: "",
        standardImg: [],
        goodsMallActivityRuleList: [],
        isCurrentWeighing: '0',
        isPresell: "0",
        type: "0",
        childGoodsMallFormList: [],
      };
      //  return
      this.standardId = json;
      this.goodsStandard.push(json);
      this.$set(this.addEditForm, "goodsId", this.typeParams.goodsId);
      this.$set(this.addEditForm, "isActivity", this.typeParams.isActivity);
      this.$set(this.addEditForm, "warehouseId", this.typeParams.warehouseId);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
      // this.dialogFormVisible = true;
    } else if (this.changeType == 'editStandard') {
      console.log('editStandard')
      this.dialogTitle = '编辑规格信息'
      this.goodsStandard = [];
      this.addEditForm = {};
      

      datas = JSON.parse(this.typeParams.data)
      console.log(datas)
      let goodsId = datas.id
      this.goodsId = datas.id
      this.warehouseId_r = datas.warehouseId
      this.detailsShow = false
      let standardId = this.typeParams.standardId
      if (standardId) {
        // datas.goodsMallDTOList.forEach(item => {
        //   if (item.id == standardId) {
        //     item.isPresell = String(item.isPresell)
        //     item.isCurrentWeighing = String(item.isCurrentWeighing)
        //     if (item.standardImg.indexOf(',') == -1) {
        //       item.standardImg = [item.standardImg]
        //     } else {
        //       item.standardImg = item.standardImg.join(',')
        //     }

        //     this.goodsStandard.push(item);
        //     goodsId = item.id
        //   }
        // })
        this.handleGoodsInfo()
        this.standardId = standardId
        this.$set(this.addEditForm, "goodsId", goodsId);
        this.$set(this.addEditForm, "isActivity", datas.isActivity);
        this.$set(this.addEditForm, "warehouseId", datas.warehouseId);
        // this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);

      }
    } else if (this.changeType == "add") {
      console.log('add')
      this.detailsShow = false
      this.dialogTitle = '销售产品新增'
      this.addEditForm = {}
      this.warehouseId = []
      this.standardId = ''
    } else if (this.changeType == 'childGoods') {
      // 子产品返回数据
      this.dialogTitle = this.typeParams.title
      if (this.dialogTitle == '销售产品新增') {
        this.changeType = 'add'
      } else if (this.dialogTitle == '销售产品编辑') {
        this.changeType = 'edit'
      } else if (this.dialogTitle == '编辑规格信息') {
        this.changeType = 'editStandard'
      }
      let parentIndex = this.typeParams.index
      console.log(this.addEditForm)
      //childIndex有值表示从编辑页面点击子产品列表的编辑进入编辑子产品 返回数据
      let childList = this.addEditForm.goodsStandard[parentIndex].childGoodsMallFormList

      if (this.typeParams.childIndex != null) {
        childList[this.typeParams.childIndex] = this.typeParams.childs[0]
      } else { //从编辑页面点击添加子产品进入编辑子产品 返回数据
        this.typeParams.childs.forEach(item => {
          childList.push(item)
        })
      }
      console.log(childList)
      // this.goodsStandard[parentIndex].childGoodsMallFormList = childList
      this.$set(this.goodsStandard[parentIndex], "childGoodsMallFormList", childList);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
      console.log(this.goodsStandard)
    }


  },
  methods: {
    // 添加子产品
    addChildGoods(i, addEditForm) {
      this.$router.push(
        {
          name: 'salesChildGoodsAdd',
          params: {
            index: i,
            childIndex: null,
            title: this.dialogTitle,
            data: JSON.stringify(addEditForm)
          }
        }
      )
    },
    // 编辑子产品
    editChildEvent(child, parentIndex, childIndex) {
      this.$router.push(
        {
          name: 'salesChildGoodsAdd',
          params: {
            index: parentIndex,
            type: this.changeType,
            childIndex: childIndex,
            title: this.dialogTitle,
            data: JSON.stringify(this.addEditForm),
            childData: JSON.stringify(child)
          }
        }
      )
    },

    // 接收活动规则组件传值
    pullRules(data, type) {
      console.log(data)
      this.ruleList = data
      this.activityType = data[0].type
      this.$set(this.goodsStandard[this.nowIndex], 'goodsMallActivityRuleList', data)
      this.$set(this.goodsStandard[this.nowIndex], 'activityType', data[0].type)

    },
    // 获取赠品列表
    getGiftList() {
      let _this = this;
      // 获取赠品列表
      this.$axios
        .get(this.$axios.defaults.basePath + '/goodsMall/getList?isGift=1')
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            if (res.data.data.length == 0) {
              _this.$message({
                message: '暂无赠品数据',
                type: 'warning'
              })
            } else {
              res.data.data.forEach(item => {
                item.giftName = item.name + '（' + item.standard + '）'
              })
              _this.giftGoodsList = res.data.data
            }
          }
        })
    },
    // 规格是否为主产品
    isMainGoods(e, index) {
      console.log(e, index)
      console.log(this.addEditForm)
      let goodsStandard = JSON.parse(JSON.stringify(this.addEditForm.goodsStandard));
      goodsStandard.forEach((item, i) => {
        if (index != i) {
          item.type = 0
        }
      })
      this.goodsStandard = goodsStandard
      this.$set(this.addEditForm, 'goodsStandard', goodsStandard)
    },

    addRules(index) {
      let json = {
        discounts: '',
        threshold: '',
      }
      this.ruleList.push(json)
    },
    delRules(index) {
      this.ruleList.splice(index, 1)
    },
    // 设置活动规格
    settingRules(i) {
      console.log(i, this.goodsStandard)
      this.nowIndex = i;
      this.guiGeList = [];
      this.goodsIds = [];
      // 如果i没有值则为组合商品设置活动规则
      if (i == null) {
        if (this.addEditForm.goodsMallActivityRuleList && this.addEditForm.goodsMallActivityRuleList.length > 0) {
          this.ruleList = this.addEditForm.goodsMallActivityRuleList ? this.addEditForm.goodsMallActivityRuleList : '[{}]'
        } else {
          this.ruleList = [{}]
        }
      } else {
        this.standardIndex = i
        if (this.goodsStandard[i].goodsMallActivityRuleList && this.goodsStandard[i].goodsMallActivityRuleList.length > 0) {
          this.ruleList = this.goodsStandard[i].goodsMallActivityRuleList
        }
      }

      if (this.goodsStandard[i].id) {
        this.guiGeList = this.goodsStandard
        this.goodsIds.push(this.goodsStandard[i].id)
        this.activityType = this.goodsStandard[i].activityType
      }


      this.getGiftList();
      this.rulesShow = true
    },
    // 提交规则
    submitRules() {
      let _this = this;
      if (this.ruleList.length == 0) {
        this.$message({
          message: '请至少设置一条规则',
          type: 'warning'
        })
      } else {
        let flag = true
        for (let i = 0; i < this.ruleList.length; i++) {
          if (this.ruleList[i].discounts == '' || this.ruleList[i].threshold == '') {
            this.$message({
              message: '购买数量和单价不能为空',
              type: 'warning'
            })
            flag = false
            break;
          } else {
            flag = true
          }
        }

        if (flag) {
          // 如果当前规格已存在
          console.log(this.addEditForm)
          if (this.goodsStandard.length > 0) {
            // this.goodsStandard[this.standardIndex].id
            // 组合单
            if (this.addEditForm.isCombination) {
              // this.standardIndex
            } else {
              this.ruleList.forEach((item, index) => {
                item.goodsId = this.goodsStandard[this.standardIndex].id
                item.level = index + 1
              })
              this.goodsStandard.forEach((item, index) => {
                if (index == this.standardIndex) {
                  item.goodsMallActivityRuleList = this.ruleList
                }
              })
            }
            this.rulesShow = false
            // this.$axios({
            //   url: _this.$axios.defaults.basePath + '/goodsMallActivityRule/update',
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   data: _this.ruleList,
            // }).then((res) => {
            //   if (res.data.errcode == 0) {
            //     _this.$message({
            //       message: '操作成功',
            //       type: "success",
            //     });
            //     _this.$set(_this.addEditForm, "goodsStandard", _this.goodsStandard);
            //     _this.rulesShow = false
            //     _this.$router.push({ name: 'salesGoods', params: { type: 'back' } })
            //   } else {
            //     _this.$message({
            //       message: res.data.errmsg,
            //       type: "warning",
            //     });
            //   }
            // });
          } else {
            this.ruleList.forEach((item, index) => {
              item.level = index + 1
            })
            if (this.addEditForm.isCombination) {
              this.addEditForm.goodsMallActivityRuleList = this.ruleList
            } else {
              this.addEditForm.goodsStandard.forEach((item, index) => {
                if (index == this.standardIndex) {
                  item.goodsMallActivityRuleList = this.ruleList
                }
              })
            }
            this.rulesShow = false
          }

          return
          let url = ''
          if (this.dialogTitle == "销售产品新增" || this.dialogTitle == "添加规格信息") {
            url = '/goodsMallActivityRule/add'
          } else {
            url = '/goodsMallActivityRule/update'
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: _this.ruleList,
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: '操作成功',
                type: "success",
              });
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        }

      }

    },
    closeDialog() {
      this.rulesShow = false
    },
    //销售商品规格组合
    composeStandard() {
      this.loading = true
      let _this = this
      let colourIds = []
      let sizeIds = []
      for (let i in _this.checkedColor) {
        colourIds.push(_this.checkedColor[i].id)
      }
      for (let i in _this.checkedSize) {
        sizeIds.push(_this.checkedSize[i].id)
      }
      let json = {
        goodsId: _this.addEditForm.goodsId,
        purchasing: _this.addEditForm.purchasing,
        price: _this.addEditForm.price,
        salePrice: _this.addEditForm.salePrice,
        takeSale: _this.addEditForm.takeSale,
        minimum: _this.addEditForm.minimum,
        limit: _this.addEditForm.limit,
        colourIds: colourIds.join(','),
        sizeIds: sizeIds.join(','),
        isPresell: _this.addEditForm.isPresell,
        isCurrentWeighing: _this.addEditForm.isCurrentWeighing,
        goodsMallActivityRuleList: _this.addEditForm.goodsMallActivityRuleList
      }
      this.$axios({
        url: _this.$axios.defaults.basePath + "/goodsMall/composeStandard",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(json),
      }).then((res) => {
        _this.loading = false;
        if (res.data.errcode == 0) {
          for (let i in res.data.data) {
            res.data.data[i].standardImg = []
            res.data.data[i].isPresell = String(res.data.data[i].isPresell)
            res.data.data[i].isCurrentWeighing = String(res.data.data[i].isCurrentWeighing)
            _this.goodsStandard.unshift(res.data.data[i]);
          }
          _this.$set(_this.addEditForm, "goodsStandard", _this.goodsStandard);
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //多规格复选框
    handleCheckedChange(val, flag) {
      if (flag == 'size') {
        this.checkedSize = val
      } else if (flag == 'color') {
        this.checkedColor = val
      }
    },
    // 获取字典尺寸和颜色列表
    getSysDictionaries(type) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/sysDictionaries/selectByList", {
          params: {
            type
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            if (type == 3) {
              _this.sizeList = res.data.data
            } else if (type == 2) {
              _this.colorList = res.data.data
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品信息
    async handleGoodsInfo() {
      this.loading = true;
      const res = await this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/details", {
          params: { id: this.goodsId, warehouseId: this.warehouseId_r },
        })
        if (res.data.errcode !== 0) {
          this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
          return
        }

        let data = res.data.data;
        this.fileList = [];
        data.type = data.type.toString();
        if (!data.pointNum) {
          data.pointNum = 0;
        }
        for (let i in data.goodsMallDTOList) {
          data.goodsMallDTOList[i].isPresell = String(data.goodsMallDTOList[i].isPresell)
          data.goodsMallDTOList[i].isCurrentWeighing = String(data.goodsMallDTOList[i].isCurrentWeighing)
        }
        data.point = Math.trunc(data.salePrice * data.pointNum);
        await this.$nextTick()
        this.addEditForm = Object.assign({}, data, {
        goodsId: data.id,
        goodsStandard: data.goodsMallDTOList,
      });
        // 连级选择器回显
        this.goodsTypeId2 = this
          .familyTree(this.goodsType, data.categoryId)
          .reverse();
        if (data.imgs) {
          this.imgData = data.imgs.split(",");
          this.fileList = this.imgData.map(item => ({ url: item }))
        }else{
          this.imgData = [];
        }
        if (this.standardId) {
          this.goodsStandard = []
          data.goodsMallDTOList.forEach(standard => {
            if (standard.standardImg)
              standard.standardImg = standard.standardImg.split(",")
                .map((url) => ({
                  url,
                }));
            else standard.standardImg = [];
            standard.point = Math.trunc(
              standard.salePrice * standard.pointNum
            );
            if (this.standardId == standard.id) {
              this.goodsStandard.push(standard);
            }
          })

        } else {
          console.log(data.goodsMallDTOList)
          this.goodsStandard = data.goodsMallDTOList.map((standard) => {
            if (standard.standardImg)
              standard.standardImg = standard.standardImg
                .split(",")
                .map((url) => ({
                  url,
                }));
            else standard.standardImg = [];
            standard.point = Math.trunc(
              standard.salePrice * standard.pointNum
            );
            return standard;
          });
        }
        this.$set(this.addEditForm, 'goodsStandard', this.goodsStandard)
    },
    // 获取产品下拉列表
    getSelectGoods() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goods/selectGoodsList")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.selectGoods = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 修改起订量
    changeMinimum(e) {
      if (Number(e) < 0) {
        this.$message({
          message: "起订量不能小于0",
          type: "warning",
        });
        this.addEditForm.minimum = 0;
      } else if (
        Number(this.addEditForm.num) &&
        Number(e) > Number(this.addEditForm.num)
      ) {
        this.$message({
          message: "起订量不能大于库存量",
          type: "warning",
        });
        this.addEditForm.minimum = 0;
      }
    },
    // 修改库存
    changeNum(e) {
      if (e <= 0) {
        this.$message({
          message: "库存量不能小于1",
          type: "warning",
        });
        this.addEditForm.num = "";
      } else if (this.addEditForm.minimum && e < this.addEditForm.minimum) {
        this.$message({
          message: "库存量不能小于起订量",
          type: "warning",
        });
        this.addEditForm.num = "";
      }
    },
    // 删除规格
    async delGoodsType(index, id, warehouseId) {
      const _warehouseId =
        typeof warehouseId == "object" ? warehouseId[0] : warehouseId;
      if (id) {
        this.ids = [{ id: id, warehouseId: _warehouseId }];
        await this.handleDel();
      }
      this.goodsStandard.splice(index, 1);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
    },


    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显产品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取产品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      window.location.reload();
    },
    //产品编辑 同步价格
    async synchronizePrice(item) {
      let _this = this
      var obj = JSON.parse(JSON.stringify(item))
      if (obj.standardImg.some((img) => img.raw ? !img.response : false)) {
        await this.$confirm("当前规格图片尚未上传, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
      obj.standardImg = obj.standardImg.join(",")
      var url = "/goodsMall/update"
      this.$confirm('是否同步已截单订单价格和规格?', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        obj.isSyncOrderPrice = 1
        this.changeGoods(obj, url)
      }).catch((action) => {
        if (action == "cancel") {
          obj.isSyncOrderPrice = 0
          this.changeGoods(obj, url)
        }
      });
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          _this.loading = true;
          const goodsStandard = JSON.parse(JSON.stringify(_this.goodsStandard));
          console.log(goodsStandard)
          if (goodsStandard.some((standard) => standard.standardImg.some((img) => img.raw ? !img.response : false))) {
            await this.$confirm("有规格图片尚未上传, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            });
          }
          let flag = true;
          goodsStandard.forEach(item => {
            if (item.type == 1 && !item.scaledValue) {
              this.$message({
                message: '主产品规格比不能为空',
                type: 'warning'
              })
              flag = false
            }
          })
          if (!flag) { return }
          let url = "";
          this.submitBtnLoad = true;
          var json = {
            goodsId: _this.addEditForm.goodsId,
            remark: _this.addEditForm.remark,
            deliveryTime: _this.addEditForm.deliveryTime,
            startTime: _this.addEditForm.startTime,
            endTime: _this.addEditForm.endTime,
            type: _this.addEditForm.type,
            warehouseId: _this.addEditForm.warehouseId,
            sort: _this.addEditForm.sort,
            isActivity: _this.addEditForm.isActivity,
            goodsMallFormList: goodsStandard.map((standard) => {
              standard.standardImg = standard.standardImg
                .map((img) => (img.raw ? img.response : img.url))
              if (standard.childGoodsMallFormList && standard.childGoodsMallFormList.length > 0) {
                standard.childGoodsMallFormList.map((child) => {
                  child.standardImg = Array.isArray(child.standardImg) > 0 ? child.standardImg.join(',') : child.standardImg
                })
              }

              // standard.point = Math.trunc(
              //   standard.salePrice * standard.pointNum
              // );
              return standard;
            }),
          };
          if (this.changeType == "add") {
            url = "/goodsMall/add";
            json.warehouseId = _this.warehouseId.join(",");
          } else if (this.changeType == "editStandard") {
            url = "/goodsMall/update";
            // json.id = _this.addEditForm.id;
            json.warehouseId = _this.warehouseId.join(",");
          } else if (this.changeType == "goodsStandard") {
            url = "/goodsMall/addStandard";
            json = {
              goodsId: _this.addEditForm.goodsId,
              warehouseId: _this.addEditForm.warehouseId,
              goodsMallFormList: _this.addEditForm.goodsStandard.map((standard) => {
                standard.standardImg = standard.standardImg
                  .map((img) => (img.raw ? img.response : img.url))
                  ;
                return standard;
              }),
            };
          } else if (this.changeType == "edit") {
            url = "/goodsMall/editAll";
            json.id = _this.addEditForm.id;
            // json.warehouseId = _this.warehouseId.join(",");
          }
          if (this.addEditForm.type == 3 && this.addEditForm.limit == "") {
            this.$message({
              message: "请输入限购份数",
              type: "warning",
            });
            return;
          }
          for (let i in json.goodsMallFormList) {
            json.goodsMallFormList[i].standardImg = json.goodsMallFormList[i].standardImg.join(",")
          }
          if (this.changeType == "editStandard") {
            json = json.goodsMallFormList.find(
              (standard) => standard.id == this.standardId
            );
            this.$confirm('是否同步已截单订单价格和规格?', '提示', {
              distinguishCancelAndClose: true,
              confirmButtonText: '是',
              cancelButtonText: '否',

              type: 'warning'
            }).then(() => {
              json.isSyncOrderPrice = 1
              this.changeGoods(json, url)
            }).catch((action) => {
              if (action == "cancel") {
                json.isSyncOrderPrice = 0
                this.changeGoods(json, url)
              }

            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + url,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(json),
            }).then((res) => {
              _this.loading = false;
              _this.submitBtnLoad = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.standardId = "";
                _this.$router.push({ path: '/goods/sales_goods' })
              } else {
                // _this.standardId = "";
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          }
        } else {
          this.submitBtnLoad = false;
          return false;
        }
      });
    },
    changeGoods(json, url) {
      let _this = this
      json.warehouseId = this.addEditForm.warehouseId
      json.standardImg = this.addEditForm.imgs
      this.$axios({
        url: _this.$axios.defaults.basePath + url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(json),
      }).then((res) => {
        _this.loading = false;
        _this.submitBtnLoad = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          // _this.standardId = "";
          _this.dialogFormVisible = false;
          _this.$router.push({ path: '/goods/sales_goods' })
          // _this.listEvent();
        } else {
          // _this.dialogFormVisible = false;
          // _this.standardId = "";
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.imgData.push(res);
      if (this.imgData.length > 0) {
        this.$set(this.addEditForm, "imgs", this.imgData.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },
    handleChange(list, index) {
      this.goodsStandard[index].standardImg = []
      list.forEach(item => {
        this.goodsStandard[index].standardImg.push(item)
      })
      // this.$set(this.goodsStandard[index],'standardImg', list);
    },
    handleSuccess(imgData, removeIndex) {
      console.log(imgData, removeIndex, this.goodsStandard)
      if (imgData.length > 0) {
        let imgArr = []
        imgData.forEach(item=>{
          let newJson = {
            raw:item,
            response:item,
            url:item
          }
          imgArr.push(newJson)
        })
        this.goodsStandard[removeIndex].standardImg = imgArr
        this.$set(this.addEditForm, "imgs", imgData.join(","));
      }
    },

    // 上传图片删除
    handleRemove(file) {
      let imgArr = [];
      this.fileList = [];
      let url = file.response ? file.response : file.url;
      this.imgData.forEach((item) => {
        if (url != item) {
          imgArr.push(item);
          this.fileList.push({ url: item });
        }
      });
      this.imgData = imgArr;
      if (this.imgData.length > 0) {
        this.addEditForm.imgs = this.imgData.join(",");
      } else {
        this.addEditForm.imgs = "";
      }
      // let ind = "";
      // this.fileList.forEach((item, index) => {
      //   if (file.response == item.url) {
      //     ind = index;
      //   }
      // });
      // this.fileList.splice(ind, 1);
    },
    // 上传详情图片删除
    // handleRemove2(file) {
    //   console.log(file);
    //   let imgArr = [];
    //   this.fileList = [];
    //   let url = file.response ? file.response : file.url;
    //   this.imgData.forEach((item) => {
    //     if (url != item) {
    //       imgArr.push(item);
    //       this.fileList.push({ url: item });
    //     }
    //   });
    //   this.imgData = imgArr;
    //   if (this.imgData.length > 0) {
    //     this.addEditForm.imgs = this.imgData.join(",");
    //   } else {
    //     this.addEditForm.imgs = "";
    //   }

    //   // let ind = "";
    //   // this.fileList.forEach((item, index) => {
    //   //   if (file.response == item.url) {
    //   //     ind = index;
    //   //   }
    //   // });
    //   // this.fileList.splice(ind, 1);
    // },
    //上传视频事件
    handleAvatarSuccess2(res, file) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.videoData = [];
      this.fileList2 = [];
      this.videoData.push(res);
      this.fileList2.push({ url: res.data });
      this.$set(this.addEditForm, "videos", res.data);
      this.videoFileShow = false;
    },
    // 上传视频删除
    handleRemove2(file) {
      let videoArr = [];
      let url = file.url ? file.url : file.response;
      this.videoData.forEach((item) => {
        if (url != item) {
          videoArr.push(item);
        }
      });
      this.videoData = videoArr;
      let ind = "";
      this.fileList2.forEach((item, index) => {
        if (url == item.url) {
          ind = index;
        }
      });
      this.fileList2.splice(ind, 1);
    },
    // 限制视频大小
    handleFileChange(file) {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 <= 50;
        if (!isLt5M) {
          this.$message.error("上传视频大小不能超过 50MB!");
          return reject(false);
        } else {
          return resolve(true);
        }
      });
    },
    inputGoodsName(e) {
      if (e.length >= 50) {
        this.$message({
          message: "产品名称不能大于50个字符",
          type: "warning",
        });
      }
    },
    // 视频删除
    videoDelete() {
      this.$confirm("是否确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.videoData = [];
          this.$set(this.addEditForm, "videos", "");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 下拉选择产品
    changeGoodsEvents(e) {
      if (e) {
        var goods = {};
        this.selectGoods.forEach((item) => {
          if (item.id == e) {
            goods = item;
          }
        });
        this.$set(this.addEditForm, "id", e);
        // this.addEditForm.categoryId = goods.categoryId;
        // this.$set(this.addEditForm, "categoryId", goods.categoryId);
        this.goodsTypeId2 = this.familyTree(
          this.goodsType,
          goods.categoryId
        ).reverse();
        this.addEditForm.unitId = goods.unitId;
        this.addEditForm.color = goods.color;
        this.addEditForm.model = goods.model;

        this.addEditForm.mfrs = goods.mfrs;
        this.addEditForm.enabled = goods.enabled.toString();
        this.$set(this.addEditForm, "standard", goods.standard);
        this.fileList = [];
        if (goods.imgs) {
          let imgArr = goods.imgs.split(",");
          this.imgData = imgArr;
          imgArr.forEach((item) => {
            let json = {
              url: item,
            };
            this.fileList.push(json);
          });
        }
        if (this.dialogTitle == "销售产品新增") this.checkExist();
      }
    },
    // 选择订单类型
    changeOrderType(e) {
      if (e == 1 || e == 2) {
        //限时、限量
        this.$set(this.addEditForm, "limit", 1);
      } else if (e == 3) {
        //限购订单
        this.$set(this.addEditForm, "minimum", 1);
      } else if (e == 4) {
        //热销现货
        this.$set(this.addEditForm, "limit", 1);
        this.$set(this.addEditForm, "minimum", 1);
        this.$set(this.addEditForm, "startTime", "");
        this.$set(this.addEditForm, "endTime", "");
      }
    },
    // 添加产品规格
    addGoodsType() {
      // goodsStandard
      let json = {
        code: '',
        standard: "",
        purchasing: "",
        price: "",
        salePrice: "",
        takeSale: "",//自提价格
        num: "",
        minimum: "",
        limit: "",
        isPresell: "0",//是否为预售商品 0为否1为是
        isCurrentWeighing: "0",//是否为现称商品 0为否1为是
        // 隐藏积分相关功能
        // pointNum: "",
        // point: "",
        standardImg: [],
        isActivity: this.addEditForm.isActivity,
        goodsMallActivityRuleList: [],
        childGoodsMallFormList: [], //子规格
        wholesalePrice: '', // 批发商价
        scaledValue: '', //规格比
        type: 0, // 0正常商品、1主产品、2子产品
      };
      this.goodsStandard.unshift(json);
      this.$set(this.addEditForm, "goodsStandard", this.goodsStandard);
    },
    // 新增时仓库多选
    changeWarehouse(e) {
      this.warehouseId = e;
      this.$set(this.addEditForm, "warehouseId", e.join(","));
      if (this.dialogTitle == "新增") this.checkExist();
    },
    // 修改乡兴供货价
    changeSalePrice(e) {
      if (!this.addEditForm.pointNum) return;
      this.addEditForm.point = Math.trunc(e * this.addEditForm.pointNum);
    },
    // 积分倍数
    pointNumInput(data, index) {
      const e = data.pointNum;
      // 价格取整
      if (e < 0) this.$set(this.goodsStandard[index], "pointNum", 0);
      if (!data.salePrice) return;
      const point = Math.trunc(data.salePrice * e);
      this.$set(this.goodsStandard[index], "point", point);
    },
    checkExist() {
      if (!(this.addEditForm.warehouseId && this.addEditForm.id)) return;
      let _this = this;
      // 判断当前用户是否有该商品的有效订单
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/checkGoodsMall", {
          params: {
            goodsId: this.addEditForm.id,
            warehouseId: this.addEditForm.warehouseId,
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            // 如果存在该商品的订单且未过期则弹出该订单详情进行编辑
            if (res.data.data) {
              _this
                .$confirm("当前列表已有此商品，请前往该商品进行编辑", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(() => {
                  _this.dialogFormVisible = false;
                  // 根据ID调用编辑事件显示弹框
                  // _this.handleEdit({ id: res.data.data });
                })
                .catch(() => {
                  _this.$message({
                    type: "info",
                    message: "已取消",
                  });
                  _this.dialogFormVisible = false;
                });
            } else {
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

  },
};
</script>

<style scoped>
.goods {
  height: 70vh;
  overflow: auto;
}
.boxMain >>> .el-image img {
  height: auto !important;
}
.titleBox {
  text-align: left;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.titleBox p {
  font-size: 18px;
  padding-left: 15px;
  line-height: 24px;
  border-left: 4px solid #409eff;
}
.standard_box .standard_list {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px dashed #333;
}
.standard_box > div:first-child .standard_list {
  border-top: none !important;
}
.sort_goods .list_title {
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
}
.sort_goods .list_title div {
  width: 11%;
}
.sort_goods .list li {
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #e5e5e5;
}
.sort_goods .list li div {
  width: 11%;
}
.tableTopBtn {
  display: flex;
  align-items: center;
}
.tableTopBtn p {
  margin-left: 20px;
}
</style>
