<template>
  <!-- 区域联级选择器 -->
  <!-- :id="allLevels == true ? '' :'areaCascader'" -->
  <div class="areaCascader">
    <el-cascader ref="cascader" v-model="value" :show-all-levels="allLevels" :options="options" @change="handleChange"
      :disabled="isStatus"></el-cascader>
  </div>
</template>
<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
//vue实例
export default {
  data() {
    return {
      value: this.areaValue,
      options: regionData,
      isStatus: this.status,
      // 是否显示所有层级
      allLevels: '',
    };
  },
  props: ["areaValue", "status", "allLevel"],
  watch: {
    areaValue: function (newVal, oldVal) {
      this.value = newVal;
    },
    status: function (newVal, oldVal) {
      this.isStatus = newVal;
    },
    allLevel: function (newVal, oldVal) {
      if (newVal == '' || newVal == true) {
        this.allLevels = true;
      } else {
        this.allLevels = false;
      }
      console.log(this.allLevels)
    },
  },
  created() { },
  methods: {
    handleChange(e) {
      this.$emit("areaChange", e[e.length - 1]);
      this.$emit("areaChangeAll", e);
    },
  },
};
</script>
<style scoped>
.areaCascader>>>.el-input__inner {
  width: 300px;
}

#areaCascader>>>.el-input__inner {
  width: 200px;
}
</style>