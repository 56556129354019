<template>
  <div>
    <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
      <el-form-item label="用户">
        <el-select v-model="searchForm.userid" clearable filterable style="width: 150px" placeholder="可搜索选项">
          <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select style="width: 150px" v-model="searchForm.status" clearable placeholder="请选择状态" @change="changeOrderStatus">
          <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品">
        <el-select v-model="searchForm.goodsId" filterable placeholder="请选择商品" style="width: 150px">
          <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择区域:">
        <el-cascader ref="tree" style="width: 300px" :options="regionalRouteData" v-model="searchRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
      </el-form-item>
      <el-form-item label="选择路线" prop="wayId">
        <el-select v-model="wayIds" multiple filterable clearable style="width: 200px">
          <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
        <el-button @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>

      <!-- <el-form-item label="是否需要带回" style="margin-left:100px;">
        <el-switch v-model="needBack" active-text="是" inactive-text="否">
        </el-switch>
      </el-form-item>
      <el-form-item label="退货原因" style="margin-left:100px;">
        <el-input type="textarea" v-model="reason"></el-input>
      </el-form-item> -->
    </el-form>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="icons" label="商品图片">
        <template slot-scope="scope">
          <div class="demo-image__preview" v-if="scope.row.icons">
            <el-image style="width: 60px;height:60px;" :src="scope.row.icons">
            </el-image>
          </div>
          <img v-else src="../assets/img/no_img.png" style="width: 60px" alt="" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="商品名称" min-width="140" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="describe" label="商品描述" width="130px" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="wechat" label="用户昵称" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="standard" label="规格"> </el-table-column>
      <el-table-column prop="num" label="商品数量" :filter-multiple="false" :filters="[{ text: '小于10', value: '10' }, { text: '小于20', value: '20' }]" :filter-method="filterNum">
      </el-table-column>
      <el-table-column prop="price" label="价格"> </el-table-column>
      <el-table-column prop="totalPrice" label="订单总价">
      </el-table-column>
      <el-table-column prop="type" label="订单类型">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? "限时订单" : scope.row.type == 2 ? "限量订单" : scope.row.type == 3 ? "限购订单" : "热销现货" }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <p v-if="scope.row.status == 0" style="color: #409eff">
            已下单
          </p>
          <p v-else-if="scope.row.status == 1" style="color: #e6a23c">
            退货
          </p>
          <p v-else-if="scope.row.status == 2" style="color: #333">
            取消
          </p>
          <p v-else-if="scope.row.status == 3" style="color: #67c23a">
            已完成
          </p>
          <p v-else-if="scope.row.status == 4" style="color: rgb(245, 108, 108)">
            已截单
          </p>
          <p v-else-if="scope.row.status == 5" style="color: red">
            已出库
          </p>
          <p v-else-if="scope.row.status == 6" style="color: #67c23a">
            已配送
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="way" width="80" label="路线" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="created" width="160" label="下单时间">
      </el-table-column>
    </el-table>
    <div class="block" style="padding: 10px 15px">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="rPagesData.currentPage" :page-sizes="rPagesData.rows" :page-size="rPagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="rPagesData.total">
      </el-pagination>
    </div>
    <div style="text-align:center;padding-top:20px">
      <el-button type="primary" :loading="submitBtnLoad" @click="returnSubmit">确定</el-button>
      <el-button @click="cancelEvent">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'add-create',
  data() {
    return {
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      searchRegionalList: [],
      wayIds: [],
      wayList: [],
      loading: false,
      searchForm: {
        status: 4,
        userid: ""
      },
      tableData: [],
      needBack: 0, //1:需要带回；0:不需要
      reason: '',
      // 商品状态
      goodsStatus: [
        {
          label: "已截单",
          id: 4,
        },
        {
          label: "已完成",
          id: 3,
        }
      ],
      //分页数据
      rPagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      ids: [],
      multipleSelection: [],
      submitBtnLoad: false,
      selectGoods: [],
    }
  },
  props: ['userList', "regionalRouteData"],
  mounted() {
    this.listEvent();
    this.getWayList()
    this.getSelectGoods()
  },
  methods: {
    // 
    changeOrderStatus(e) {
      this.getSelectGoods()
    },
    getSelectGoods() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId: '',
            orderStatus: this.searchForm.status,
            userid: ""
          },
        })
        .then((res) => {
          if (res.status == 200) {

            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
              // if(item.isTopCarriage == 0){
              //   item.name = item.name+'（未上架）'
              // }else if(item.isTopCarriage == 2){
              //   item.name = item.name+'（已下架）'
              // }
              // item.name =item.type==1?item.name+'（限时商品）':item.type==2?item.name+'（限量商品）':item.type==3?item.name+'（限购商品）':item.name+'（长期热销）'

            });
            _this.selectGoods = list;
          }
        });
    },
    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.rPagesData.currentPage = 1;
      this.rPagesData.currentRows = 10;
      this.searchForm.categoryId = this.searchForm.categoryId
        ? this.searchForm.categoryId.join(",")
        : "";
      var treedata = this.$refs.tree.getCheckedNodes();
      let ids = []
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      this.searchForm.regionalId = ids.join(",")
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.searchForm.status = 4;
      this.searchRegionalList = []
      this.rPagesData.currentPage = 1;
      this.rPagesData.currentRows = 10;
      this.wayIds = []
      this.getSelectGoods()
      this.listEvent();
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      _this.searchForm.current = _this.rPagesData.currentPage;
      _this.searchForm.size = _this.rPagesData.currentRows;
      _this.searchForm.wayId = _this.wayIds.length ? _this.wayIds.join(',') : '';
      this.$axios
        .get(_this.$axios.defaults.basePath + "/saleChildOrder", {
          params: _this.searchForm,
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.goodsImg) {
                if (item.goodsImg.indexOf(",") != -1)
                  item.icons = item.goodsImg.split(",")[0];
                else item.icons = item.goodsImg;
              }
            });
            _this.tableData = res.data.data.records;
            _this.rPagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    filterNum(value, row, column) {
      return row.num < value;
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.rPagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.rPagesData.currentPage = val;
      this.listEvent();
    },
    returnSubmit() {
      let _this = this;
      let needBack = this.needBack ? 1 : 0;
      this.submitBtnLoad = true;
      console.log({
        ids: _this.ids.join(','),
        needBack: needBack,
        reason: _this.reason
      })
      this.$axios({
        url: _this.$axios.defaults.basePath + "/saleChildOrder/batchReturn",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: _this.ids.join(','),
          needBack: needBack,
          reason: _this.reason
        },
      }).then((res) => {
        _this.submitBtnLoad = false;
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    cancelEvent() {
      this.ids = [];
      this.needBack = false;
      this.reason = '';
      this.$emit('cancelReturn')
    },
  }
}
</script>

<style scoped>
.tables {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tables .btns {
  height: 200px;
  display: flex;
  align-items: center;
}

.orderTable >>> .el-form-item:last-child .el-form-item__content {
  width: 100%;
}
</style>