<template>
  <div>
    <div class="pageMain">
      <div class="userTable boxMain">
        <p class="boxTitle">路线列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="handleAdd" type="primary" class="el-button--mini" v-if="addButton == '1'"><i
                class="el-icon-plus"></i>添加路线</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length == 0"
              v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="way" label="路线名称"> </el-table-column>
            <el-table-column align="center" width="160" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'"
                  class="el-icon-edit">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
    <!--新增、编辑仓库-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" width="40%" :close-on-click-modal="false">
      <div class="postForm">
        <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="100px" class="demo-ruleForm">
          <el-form-item label="路线名称：" prop="way">
            <el-input v-model="formData.way" placeholder="请输入路线名称" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      tableData: [],
      dialogTitle: "",
      loading: true,
      //form表单数据
      formData: {
        way: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      addPrivilege: {
        way: [
          {
            required: true,
            message: "请输入路线名称",
            trigger: "blur",
          },
        ]
      },
    };
  },

  created() {
    this.listEvent();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "driver_way_update") {
        this.updateButton = "1";
      } else if (item.authority == "driver_way_query") {
        this.searchButton = "1";
      } else if (item.authority == "driver_way_add") {
        this.addButton = "1";
      } else if (item.authority == "driver_way_delete") {
        this.delButton = "1";
      }
    });
  },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val, flag) {
      this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val, flag) {
      this.pagesData.currentPage = val;
      this.listEvent();
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = "添加路线";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(row) {
      this.formData = row;
      this.addShow = true;
      this.dialogTitle = "编辑路线";
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.dialogTitle == "添加路线") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/driverWay/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/driverWay/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.formData),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    //删除操作
    handleDel() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该路线, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            var _this = this;
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/driverWay/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }else{
                  _this.$message({
                    message: res.data.errmsg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 区域选择
    areaEvent(e) {
      this.$set(this.formData, "adcode", e);
    },
  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 30px;
  margin-top: 15px;
}
</style>

<style lang="scss" scoped>
// ::v-deep .postForm .el-input {
//   width: auto !important;
// }
</style>
