<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="角色名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色编码">
          <el-input
            v-model="searchForm.code"
            placeholder="请输入角色编码"
          ></el-input>
        </el-form-item>
        <!--<el-form-item label="状态">-->
        <!--<el-select v-model="searchForm.status" placeholder="请选择状态">-->
        <!--<el-option v-for="(item,index) in status" :key="index" :label="item.text" :value="item.id"></el-option>-->
        <!--</el-select>-->
        <!--</el-form-item>-->
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">角色列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button
              @click="(addShow = true), (alertTlt = '新增角色')"
              type="primary"
              class="el-button--mini"
              v-if="addButton == '1'"
              ><i class="el-icon-plus"></i> 新增角色</el-button
            >
            <el-button
              size="mini"
              type="danger"
              :disabled="ids.length == 0"
              @click="handleDelAll()"
              v-if="delButton == '1'"
              class="el-icon-delete"
              >批量删除</el-button
            >
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="角色名称"> </el-table-column>
            <el-table-column prop="code" label="角色编码"> </el-table-column>
            <el-table-column prop="description" label="描述"> </el-table-column>
            <el-table-column min-width="170" prop="created" label="创建时间">
            </el-table-column>
            <el-table-column
              min-width="170"
              prop="lastUpdateTime"
              label="最后更新时间"
            >
            </el-table-column>
            <el-table-column align="center" label="操作" width="350">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handlePrivileges(scope.row.id)"
                  >权限设置
                </el-button>
                <!-- <el-button size="mini" type="primary" @click="userSite(scope.row.id)">用户设置</el-button> -->
                <el-button
                  size="mini"
                  type="primary"
                  v-if="updateButton == '1'"
                  @click="edit(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  v-if="delButton == '1'"
                  @click="handleDel(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增角色-->
    <el-dialog :title="alertTlt" :visible.sync="addShow" width="60%">
      <div class="postForm">
        <el-form
          :model="formData"
          :inline="true"
          :rules="addRules"
          ref="formData"
          label-width="100px"
          class="demo-ruleForm text-center"
        >
          <el-form-item label="角色名称：" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入角色名称"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色编码：" prop="code">
            <el-input
              v-model="formData.code"
              placeholder="请输入角色代码"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：" prop="description">
            <el-input
              v-model="formData.description"
              placeholder="请输入角色描述"
              style="width: 300px"
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="postBtn"
            style="display: block; text-align: center"
          >
            <el-button
              type="primary"
              :loading="submitBtnLoad"
              @click="handleSubmit('formData')"
              >提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--权限分配管理-->
    <el-dialog title="权限分配" :visible.sync="rolesShow">
      <div
        class="postForm"
        style="overflow: auto; height: 630px; box-sizing: border-box"
      >
        <el-tree
          ref="tree"
          :data="rolesData"
          show-checkbox
          node-key="s_id"
          :default-expanded-keys="checkedData"
          :default-checked-keys="checkedData"
          :props="defaultProps"
          @check-change="handleCheckChange"
        >
        </el-tree>
        <div style="text-align: center; margin: 30px 0">
          <el-button type="primary" @click="submitPriviles" :loading="btnLoad"
            >提交</el-button
          >
          <el-button @click="cancelAdd('rolesShow')">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="用户设置" :visible.sync="userSiteShow">
      <el-table
        ref="multipleTable2"
        :data="userList"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        @selection-change="selectUserChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="departmentName" label="所属机构">
        </el-table-column>
        <el-table-column prop="fullname" label="姓名"> </el-table-column>
        <el-table-column prop="role_strings" label="角色"> </el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 30px">
        <el-button type="primary" @click="siteUser">提交</el-button>
        <el-button @click="cancelAdd('userSiteShow')">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitBtnLoad: false,
      btnLoad: false,
      userSiteShow: false,
      userList: [],
      index: "1",
      dialogConfirm: false,
      addShow: false,
      loading: true,
      //搜索
      searchForm: {
        name: "",
        code: "",
      },
      alertTlt: "",
      tableData: [],
      tabWidth: 200,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      userid: "",
      status: [
        {
          id: 0,
          text: "禁用",
        },
        {
          id: 1,
          text: "启用",
        },
      ],
      formData: {
        name: "",
        code: "",
        description: "",
        status: "",
      },
      formLabelWidth: "100px",
      multipleTable: [],
      ids: [],
      delroleId: [],
      roleId: "",
      rolesData: [],
      value: [],
      rolesShow: false,
      rolesList: [],
      children: [],
      checkedData: [],
      privilegesIds: [],

      defaultProps: {
        children: "children",
        label: "label",
      },
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      addRules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入角色编码",
            trigger: "blur",
          },
        ],
      },
      menus: [],
      userIds: [],
      nowRoleId: [],
    };
  },
  created() {
    this.listEvent();
    // this.getUserList()
    this.menus = JSON.parse(sessionStorage.getItem("menus"));

    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "sys_role_update") {
        this.updateButton = "1";
      } else if (item.authority == "sys_role_query") {
        this.searchButton = "1";
      } else if (item.authority == "sys_role_create") {
        this.addButton = "1";
      } else if (item.authority == "sys_role_delete") {
        this.delButton = "1";
      }
    });

    this.generateData();
    if (sessionStorage.getItem("role_strings").indexOf(",") != -1) {
      this.nowRoleId = sessionStorage.getItem("role_strings").split(",");
    } else {
      this.nowRoleId = [sessionStorage.getItem("role_strings")];
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach((key) => {
            this.formData[key] = "";
          });
        }
      },
    },
    userSiteShow: {
      handler(n) {
        if (!n) {
          this.$refs.multipleTable2.clearSelection();
          // this.getUserList()
        }
      },
    },
  },
  methods: {
    //获取所有权限列表
    generateData() {
      var _this = this;
      this.menus.forEach((item) => {
        _this.children = [];
        var aa = item["child"];
        aa.forEach((val) => {
          var powers = [];
          if (val["child"].length == 0) {
            powers = [];
            var bb = val["powers"];

            bb.forEach((v) => {
              powers.push({
                id: v["id"],
                s_id: v["id"],
                label: v["description"],
                //privileges: v['privileges']
              });
            });
          } else {
            powers = [];
            var cc = val["child"];
            cc.forEach((v) => {
              var powers1 = [];
              var dd = v["powers"];
              dd.forEach((i) => {
                powers1.push({
                  id: i["id"],
                  s_id: i["id"],
                  label: i["description"],
                  // privileges: i['privileges']
                });
              });
              powers.push({
                id: v["id"],
                sys_id: v["id"],
                label: v["name"],
                children: powers1,
                // privileges: v['privileges']
              });
            });
          }
          _this.children.push({
            id: val["id"],
            sys_id: val["id"],
            label: val["name"],
            children: powers,
          });
        });
        if (item["child"].length == 0) {
          item["powers"].forEach((v) => {
            _this.children.push({
              id: v["id"],
              s_id: v["id"],
              label: v["description"],
              //privileges: v['privileges']
            });
          });
        }
        _this.rolesData.push({
          id: item["id"],
          label: item["name"],
          children: _this.children,
        });
      });
    },
    //权限管理
    handlePrivileges(id) {
      var _this = this;
      _this.roleId = id;
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/sysRolePrivilege/role_privileges",
          {
            params: {
              roleId: id,
            },
          }
        )
        .then(function (res) {
          console.log(res)
          // var menueid = '';
          if (res.data.errcode == 0) {
            // let menus = JSON.parse(JSON.stringify(res.data.data))
            // let oneNav= []
            // let twoNav= []
            // let threeNav= []
            // menus.forEach((item) => {
            // 	item.child = new Array();
            // 	if (item.type == 1) {
            // 		oneNav.push(item)
            // 	} else if (item.type == 2) {
            // 		twoNav.push(item)
            // 	} else if (item.type == 3) {
            // 		threeNav.push(item)
            // 	}
            // })
            // return
            var dataJson = res.data.data;
            var pid = "";
            dataJson.forEach((item) => {
              item["privileges"].forEach((item1) => {
                if (item1["own"] == 1) {
                  pid += item1.id + ",";
                }
              });
            });
            var aa = pid.substr(0, pid.length - 1);
            var arr = aa.split(",");
            // var array = arr.map(Number);
            _this.checkedData = arr;
            _this.rolesShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    siteUser() {
      let _this = this;
      _this
        .$axios({
          url: _this.$axios.defaults.basePath + "/sysRole/grant_role_users",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            roleId: _this.roleId,
            userIds: _this.userIds,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            _this.userSiteShow = false;
            // _this.listEvent();
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "success",
            });
          }
        });
    },
    //用户设置
    userSite(id) {
      let _this = this;
      _this.roleId = id;
      this.userSiteShow = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysRole/roleUserList", {
          params: {
            id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            for (let i in _this.userList) {
              for (let j in res.data.data) {
                if (_this.userList[i].id == res.data.data[j].userId) {
                  _this.$refs.multipleTable2.toggleRowSelection(
                    _this.userList[i]
                  );
                }
              }
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    getUserList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/selectOptionList", {
          params: {
            fullname: "",
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            for (let i in res.data.data) {
              let roles = [];
              for (let j in res.data.data[i].roles) {
                roles.push(res.data.data[i].roles[j].name);
              }
              res.data.data[i].role_strings = roles.join(",");
            }
            _this.userList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    selectUserChange(e) {
      this.userIds = [];
      for (let i in e) {
        this.userIds.push(e[i].id);
      }
    },
    edit(id) {
      let that = this;
      this.$axios
        .get(that.$axios.defaults.basePath + "/sysRole/roleId", {
          params: {
            roleId: id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.formData = res.data.data;
            that.alertTlt = "编辑角色";
            that.addShow = true;
          } else {
            that.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysRole", {
          params: _this.searchForm,
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },

    handleSubmit(formData) {
      var _this = this;
      _this.$refs[formData].validate((val) => {
        if (val) {
          _this.submitBtnLoad = true;
          let api = "";
          if (_this.alertTlt == "新增角色") {
            api = "/sysRole/insert";
          } else {
            api = "/sysRole/update";
          }
          _this
            .$axios({
              url: _this.$axios.defaults.basePath + api,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: _this.formData,
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.addShow = false;
                _this.listEvent();
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "success",
                });
              }
            });
        } else {
          return false;
        }
      });
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
      this.checkedData = [];
      window.location.reload();
    },
    //批量删除
    handleDelAll() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的角色");
        return false;
      } else {
        _this
          .$confirm("是否确认删除该角色, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            _this
              .$axios({
                url: _this.$axios.defaults.basePath + "/sysRole/delete",
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                data: JSON.stringify(_this.ids),
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    //删除
    handleDel(row) {
      var _this = this;

      _this
        .$confirm("是否确认删除该角色, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var roleAdd = [row];
          _this
            .$axios({
              url: _this.$axios.defaults.basePath + "/sysRole/delete",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(roleAdd),
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listEvent();
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //权限选择
    handleCheckChange(data) {},
    //权限提交
    submitPriviles() {
      var _this = this;
      var treedata = this.$refs.tree.getCheckedNodes();
      var menueid = "";
      treedata.forEach((item) => {
        if (item["s_id"]) {
          menueid += item.s_id + ",";
        } else {
          menueid += item.id + ",";
        }
      });
      var aa = menueid.substr(0, menueid.length - 1);
      var arr = aa.split(",");
      console.log(arr, "arr");
      // var array = [...new Set(arr.map(Number))];
      // console.log(array, "array")
      this.btnLoad = true;
      this.$axios({
        url:
          _this.$axios.defaults.basePath + "/sysRolePrivilege/grant_privileges",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          privilegeIds: arr,
          roleId: _this.roleId,
        }),
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.btnLoad = false;
          _this.$message({
            message: "保存成功",
            type: "success",
          });
          _this.rolesShow = false;
          _this.checkedData = [];
          if (_this.nowRoleId.indexOf(_this.roleId) != -1) {
            sessionStorage.clear();
            _this.$message({
              message: "请重新登录",
              type: "warning",
            });
            setTimeout(function () {
              _this.$router.push({ path: "/login" });
              window.location.reload();
            }, 500);
          }
        } else {
          _this.btnLoad = false;
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
