<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单位名称">
          <el-input v-model="searchForm.name" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">商品单位列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="danger" :disabled="ids.length == 0" @click="handleDel" v-if="delButton == '1'" class="el-icon-delete">删除</el-button>
          <!-- <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl3" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess" :on-error="handleAvatarErr" :headers="importHeaders" v-if="updateButton == '1'">
            <el-button type="primary" class="el-button--mini" v-loading="uploadLoading">商品单位数据导入</el-button>
          </el-upload> -->
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="ID"> </el-table-column>
            <el-table-column prop="name" label="单位名称"> </el-table-column>
            <el-table-column align="center" width="280" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                <!-- <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row.id)"
                  class="el-icon-delete"
                  v-if="delButton == '1'"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true">
        <el-form-item label="单位名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="addEditForm.name" placeholder="请输入单位名称" autocomplete="off" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsUnit",
  data() {
    return {
      uploadLoading: false,
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      uploadUrl3: this.$axios.defaults.basePath + "/import/importUnitInfo",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      searchForm: {
        name: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
      },
      formLabelWidth: "100px",
      ids: [],
      multipleSelection: [],
    };
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_unit_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_unit_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_unit_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_unit_query") {
        this.searchButton = "1";
      }
    });
  },
  methods: {
    handleAvatarErr() {
      this.uploadLoading = false;
    },
    //导入修改订单状态数据
    uploadSuccess(res, file) {
      this.uploadLoading = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    beforeAvatarUpload(file) {
      this.uploadLoading = true;
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            name: _this.searchForm.name,
          },
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this[formName] = {};
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsUnit/getGoodsUnitDetail", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data
            that.addEditForm = data;
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
          }
        });

    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/goodsUnit/add";
          } else {
            url = "/goodsUnit/update";
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      _this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/delete", {
          params: {
            ids: _this.ids.join(","),
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.$message({
              message: "操作成功",
              type: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
</style>