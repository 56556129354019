<template>
  <div>
    <div class="pageMain">
      <el-radio-group v-model="type" size="small" @input="changeRadio" style="margin-bottom:10px">
        <el-radio-button label="0">按路线</el-radio-button>
        <el-radio-button label="1">按区域</el-radio-button>
      </el-radio-group>
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" :rules="rules" ref="searchForm" label-position="left" class="demo-form-inline">
        <el-form-item label="路线:" prop="wayId" v-show="type==0">
          <el-select v-model="searchForm.wayId" filterable style="width: 300px"  multiple placeholder="请选择路线" @change="chengeWay">
            <el-option v-for="(item, index) in wayList" :key="index" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域:" v-if="type==1">
          <el-cascader ref="tree" style="width: 300px" :options="regionalRouteData" v-model="arrr" placeholder="请选择区域" :props="cascaderProps" clearable @change="cascaderChange"></el-cascader>
          <el-button type="primary" @click="changeRegional" style="margin-left:10px">查询</el-button>
        </el-form-item>
        <!-- <el-form-item label="日期:">
          <el-date-picker :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-popover placement="top-start" title="提示" width="200" trigger="hover" content="此操作将默认打印昨天全部已截单状态的订单！">
            <el-button type="primary" slot="reference" @click="otherPrintShow">一键打印</el-button>
          </el-popover>
        </el-form-item> -->
      </el-form>
      <div class="tables">
        <div class="userTable boxMain" style="width:40%">
          <p class="boxTitle">商品列表</p>
          <el-form :model="searchGoods" :inline="true" :rules="rules" style="padding:20px 20px 0 20px" ref="searchGoods" label-position="left" class="demo-form-inline">
            <el-form-item label="产品名称：">
              <el-input style="width: 240px;margin-right:10px" v-model="searchGoods.name" placeholder="请输入商品名称"></el-input>
              <el-button type="primary" @click="searchGoodsSubmit" icon="el-icon-search">搜索</el-button>
              <el-button @click="searchGoods = {};pagesData.currentPage=1;searchGoodsSubmit()">重置</el-button>
            </el-form-item>
          </el-form>
          <template>
            <el-table ref="multipleTables" class="userTable" :data="tableData" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" :selectable="selectable">
              </el-table-column>
              <el-table-column prop="name" label="商品名称">
              </el-table-column>
              <el-table-column prop="standard" label="规格">
              </el-table-column>
            </el-table>
            <div class="block" style="padding: 10px 15px">
              <!-- :pager-count="pagesData.pager" -->
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
              </el-pagination>
            </div>
          </template>
        </div>
        <div class="btns">
          <el-button type="primary" icon="el-icon-caret-left" @click="move(1)">移出</el-button>
          <el-button type="primary" icon="el-icon-caret-right" @click="move(2)">移入</el-button>
        </div>
        <div class="userTable boxMain" style="width:40%">
          <p class="boxTitle">已选择人员列表</p>
          <template>
            <el-table ref="multipleTable" :data="addGoodsList" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange2">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column prop="name" label="商品名称">
              </el-table-column>
              <el-table-column prop="standard" label="规格">
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
      <div style="text-align:center;padding-top:20px">
        <el-button type="primary" :loading="overLoading" @click="over('searchForm')">确定</el-button>
        <el-button @click="$emit('over')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { resolve } from 'mathjs';

export default {
  data() {
    return {
      arrr: [],
      props: { multiple: true },
      options: [{
        value: 1,
        label: '东南',
        children: [{
          value: 2,
          label: '上海',
          children: [
            { value: 3, label: '普陀' },
            { value: 4, label: '黄埔' },
            { value: 5, label: '徐汇' }
          ]
        }, {
          value: 7,
          label: '江苏',
          children: [
            { value: 8, label: '南京' },
            { value: 9, label: '苏州' },
            { value: 10, label: '无锡' }
          ]
        }, {
          value: 12,
          label: '浙江',
          children: [
            { value: 13, label: '杭州' },
            { value: 14, label: '宁波' },
            { value: 15, label: '嘉兴' }
          ]
        }]
      }, {
        value: 17,
        label: '西北',
        children: [{
          value: 18,
          label: '陕西',
          children: [
            { value: 19, label: '西安' },
            { value: 20, label: '延安' }
          ]
        }, {
          value: 21,
          label: '新疆维吾尔族自治区',
          children: [
            { value: 22, label: '乌鲁木齐' },
            { value: 23, label: '克拉玛依' }
          ]
        }]
      }],
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      regionalRouteData: [],
      type: '0',//0 按路线 1按区域
      pager: 4,
      datePicker: [],
      searchForm: {
        startTime: "",
        endTime: "",
        goodsIds: "",
        wayId: []
      },
      rules: {
        wayId: [{ required: true, message: "请选择路线", trigger: "change" }],
      },
      loading: false,
      lsGoodsList: [],
      addGoodsList: [],
      lsaddGoodsList: [],
      tableData: [],
      wayList: [],
      searchGoods: {},
      pagesData: {
        pager: 4,
        total: 0,
        currentPage: 1,
        currentRows: 15,
        rows: [15, 30, 45, 60],
      },
      selectGoods: [],
      driverInfo: [],
      overLoading: false
    }
  },
  watch: {
  },
  props: {
    // selectGoods: [],
    // total: '',
  },
  mounted() {
    this.getWayList()
  },
  methods: {
    // 处理数据方法
    changeKey(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].value = arr[i].id
        arr[i].label = arr[i].name
        if (arr[i].childs.length) {
          this.changeKey(arr[i].childs)
        } else {
          delete arr[i].childs
        }
      }
      return arr
    },
    cascaderChange(e){
      console.log(e)
      this.arrr = e
    },
    //选区域
    changeRegional() {
      var treedata = this.$refs.tree.getCheckedNodes();
      let ids = []
      console.log(treedata)
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      this.searchForm.regionalId = ids.join(",")
      this.goodslistEvent(1, this.pagesData.currentRows, this.searchGoods.name)
    },
    //单选
    changeRadio(e) {//0 按路线 1按区域
      this.tableData = []
      this.addGoodsList = []

      if (e == 0) {
        this.goodslistEvent(1, this.pagesData.currentRows, this.searchGoods.name)
      } else {
        this.getRegionalRouteList()
      }
    },
    // 区域列表
    async getRegionalRouteList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/regionalRoute/selectRegionalTree", {
          params: {
            current: 1,
            size: 1000
          }
        })
        .then(function (res) {
          if (res.status == 200) {
            if(res.data.length>0){
              // 递归 如果当前层级没有下级 则删除childs
              var newTree = _this.changeKey(res.data)
              _this.$nextTick(()=>{
                _this.regionalRouteData = newTree
              })
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //路线选择
    chengeWay(e) {
      this.searchForm.wayId = e
      this.pagesData.currentPage = 1
      this.goodslistEvent(this.pagesData.currentPage, this.pagesData.currentRows, this.searchGoods.name)
      // 多条线路获取对应司机数据
      let driverArr = []
      if (e.length > 0) {
        e.forEach(item => {
          this.wayList.forEach(dItem => {
            if (item == dItem.id) {
              if (dItem.driverId == '0') {
                dItem.driverId = ""
              }
              driverArr.push(dItem)
            }
          })
        })
      }
      this.driverInfo = driverArr

      // if (e.length == 1) {
      //   this.driverInfo = this.wayList.filter(item => item.id === e[0])
      // }else{
      //   this.driverInfo = [{}]
      // }
      this.$emit("driver", this.driverInfo)
      this.addGoodsList = []
    },
    // 请求列表
    goodslistEvent(current, size, name) {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/printRecords/getGoodsMallPage", {
          params: {
            current: current,
            size: size,
            goodsName: name,
            wayId: _this.searchForm.wayId.join(','),
            type: _this.type,
            regionalId: _this.searchForm.regionalId,//区域id
          },
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.loading = false;
            for (let i in res.data.data.records) {
              res.data.data.records[i].flag == 0//flag 使用来标注商品是否被选择 0 是未选择 1是选择
            }
            _this.selectGoods = JSON.parse(JSON.stringify(res.data.data.records))
            _this.changeTableData()
            _this.pagesData.total = res.data.data.total;
            _this.printShow = true;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeTableData() {
      for (let i in this.selectGoods) {
        this.selectGoods[i].flag = 0
        for (let j in this.addGoodsList) {
          if (this.selectGoods[i].id == this.addGoodsList[j].id) {
            this.selectGoods[i].flag = 1
          }
        }
      }
      this.tableData = JSON.parse(JSON.stringify(this.selectGoods));
    },
    move(flag) {
      if (flag == 1) {
        this.addGoodsList = this.addGoodsList.filter(item => !this.lsaddGoodsList.some(it => it.id === item.id));
      } else {
        this.addGoodsList = this.addGoodsList.concat(this.lsGoodsList)
        this.lsGoodsList = []
      }
      this.changeTableData()
    },
    handleSelectionChange(val) {
      this.lsGoodsList = val
    },
    handleSelectionChange2(val) {
      this.lsaddGoodsList = val
    },
    over(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.addGoodsList.length) {
            this.$message.error("请选择商品")
            return
          }
          this.overLoading = true
          if (this.datePicker.length) {
            this.searchForm.startTime = this.datePicker[0]
            this.searchForm.endTime = this.datePicker[1]
          }
          let ids = []
          for (let i in this.addGoodsList) {
            ids.push(this.addGoodsList[i].id)
          }
          this.searchForm.goodsIds = ids.join(",")
          this.searchForm.type = 1
          this.searchForm.wayOrRegion = this.type // 0 按路线 1按区域
          this.$emit("over", this.searchForm, (res) => {
            this.overLoading = false
          })
        } else {
          return false;
        }
      });



    },
    otherPrintShow() {
      let obj = {
        wayId: this.searchForm.wayId,
        type: 0
      }
      this.$emit("over", obj)
    },
    selectable(row, index) {
      if (row.flag == 1) {
        return false
      } else {
        return true
      }
    },
    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/printRecords/wayList")
        .then(function (res) {
          if (res.data.errcode == 0) {

            let data = res.data.data
            //把有关无极路线的都改成一致的id和name
            let wjDriverId = ''
            let wjDriverName = ''
            for (let i in data) {
              if (data[i].way.indexOf("无极") != -1 && data[i].driverId != '0') {
                wjDriverId = data[i].driverId
                wjDriverName = data[i].driverName
              }
            }
            for (let i in data) {
              if (data[i].way.indexOf("无极") != -1 && data[i].driverId == '0') {
                console.log(data[i].driverName, "data[i].driverName")
                data[i].driverId = wjDriverId
                data[i].driverName = wjDriverName
              }
            }
            _this.wayList = data;
            _this.driverInfo.push(_this.wayList[0])
            _this.searchForm.wayId.push(_this.wayList[0].id)
            _this.$emit("driver", _this.driverInfo)
            _this.goodslistEvent(_this.pagesData.currentPage, _this.pagesData.currentRows, _this.searchGoods.name)
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 产品名称搜索
    searchGoodsSubmit() {
      this.loading = true;
      this.pagesData.currentPage = 1
      this.goodslistEvent(this.pagesData.currentPage, this.pagesData.currentRows, this.searchGoods.name)
      // this.$emit('changePageEvent', this.pagesData.currentPage, this.pagesData.currentRows, this.searchGoods.name);
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.loading = true;
      this.goodslistEvent(this.pagesData.currentPage, this.pagesData.currentRows, this.searchGoods.name)

      // this.$emit('changePageEvent', _this.pagesData.currentPage, _this.pagesData.currentRows, '');
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.loading = true;
      this.goodslistEvent(this.pagesData.currentPage, this.pagesData.currentRows, this.searchGoods.name)
      // this.$emit('changePageEvent', _this.pagesData.currentPage, _this.pagesData.currentRows, '');
    },
  }
}
</script>

<style scoped>
.tables {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tables .btns {
  height: 200px;
  display: flex;
  align-items: center;
}
</style>