import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import Axios from 'axios';
import ElementUI from 'element-ui';
import {
    Message
} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/index.css';
import md5 from 'js-md5';
import './assets/js/rem';
import cjs from './assets/js/common';
Vue.prototype.$cjs = cjs;
Vue.use(ElementUI);
import Print from 'vue-print-nb'
Vue.use(Print)
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = Axios;
// Axios.defaults.basePath = 'http://18233125549.gnway.cc:8000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://18233125549.gnway.cc:8000/admin'; //查看图片

// Axios.defaults.basePath = 'http://192.168.110.183:9000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://192.168.110.183:9000/admin'; //查看图片
// Axios.defaults.basePath = 'http://192.168.110.113:9000/admin'; //访问接口  
// Axios.defaults.imgPath = 'http://192.168.110.113:9000/admin'; //查看图片
// Axios.defaults.basePath = 'http://192.168.110.96:9000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://192.168.110.96:9000/admin'; //查看图片

// Axios.defaults.basePath = 'http://192.168.110.127:9000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://192.168.110.127:9000/admin'; //查看图片

// Axios.defaults.basePath = 'http://zyttest.gnway.cc:8000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://zyttest.gnway.cc:8000/admin'; //查看图片

// Axios.defaults.basePath = 'http://192.168.110.104:9000/admin'; //访问接口
// Axios.defaults.imgPath = 'http://192.168.110.104:9000/admin'; //查看图片
// Axios.defaults.WEB_SOCKET_URL = 'ws://192.168.110.104:9000/admin/wss/';
// ---------------------------------------------------------------------------------------------------
Axios.defaults.basePath = 'https://system.xiangxinglianmeng.com/admin'; //访问接口
Axios.defaults.imgPath = 'https://system.xiangxinglianmeng.com/admin'; //查看图片
// Axios.defaults.WEB_SOCKET_URL = 'ws://39.99.216.227:9000/admin/wss/';
// 123
//请求拦截器
Axios.interceptors.request.use(
    config => {
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        let token = sessionStorage.getItem('Authorization');
        config.headers.Authorization = token;
        return config;
    },
    error => {
        return Promise.reject(error);
    });

// 响应拦截器
Axios.interceptors.response.use((response) => {
    if (response.data.errcode == '40001' || response.data.errcode == '41001' || response.data.errcode == '403') {
        Message({
            showClose: true,
            message: "登录状态信息过期,请重新登录",
            type: "error"
        });
        sessionStorage.clear()
        router.push({
            path: "/login"
        });
        return
    } else {
        return response;
    }
}, (err) => {
    // Message.error('请求失败');
    return Promise.reject(err);
});

Vue.prototype.resetSetItem = function (key, newVal) {
    if (key == 'tabList' || key == 'activeTab' || key == "tabsValue") {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

Vue.config.productionTip = false;
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')