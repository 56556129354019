<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="商品名称">
          <el-input v-model="searchForm.name" clearable placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态">
          <el-select v-model="searchForm.isTopCarriage" clearable placeholder="请选择状态">
            <el-option v-for="item in goodsStatus" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">积分订单列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="primary" @click="exportEvent" v-if="exportButton == '1'">订单导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <!-- @selection-change="handleSelectionChange" -->
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column type="index" label="#" width="55">
            </el-table-column>
            <el-table-column prop="icons" label="商品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px" :src="scope.row.icons">
                  </el-image>
                </div>
                <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="100" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="username" label="用户"> </el-table-column>
            <el-table-column prop="num" label="商品数量"> </el-table-column>
            <el-table-column prop="price" label="原价"> </el-table-column>
            <el-table-column prop="salePrice" label="开团价"></el-table-column>
            <el-table-column prop="totalPrice" label="订单总价">
            </el-table-column>
            <el-table-column prop="point" label="所需积分"> </el-table-column>
            <el-table-column prop="totalPoint" label="总积分">
            </el-table-column>
            <el-table-column prop="created" label="时间" min-width="150px">
            </el-table-column>
            <el-table-column prop="refundInfo" label="退货信息" min-width="150px">
              <template slot-scope="scope">
                <el-tooltip v-if="scope.row.refundInfo" :content="
                    '商品：' +
                    scope.row.refundInfo.name +
                    '，数量：' +
                    scope.row.refundInfo.returnGoodsNum
                  ">
                  <div>
                    {{ "商品：" + scope.row.refundInfo.name }}<br />
                    {{ "数量：" + scope.row.refundInfo.returnGoodsNum }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="isTopCarriage" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: #409eff">
                  已下单
                </p>
                <p v-else-if="scope.row.status == 1" style="color: #67c23a">
                  已收货
                </p>
                <p v-else-if="scope.row.status == 2" style="color: #e6a23c">
                  售后/退货
                </p>
                <!-- <p v-else-if="scope.row.status == 3" style="color: #e6a23c">
                  取消
                </p> -->
              </template>
            </el-table-column>
            <el-table-column align="center" width="260" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleOrderReturn(scope.row)" v-if="searchButton == '1'"
                  :disabled="scope.row.num == 0 || scope.row.status != 0">退货</el-button>
                <el-button size="mini" type="primary" @click="handleOrderOk(scope.row)" v-if="searchButton == '1'"
                  :disabled="scope.row.status != 0">完成</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px">
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="addEditForm.userId" filterable style="width: 300px" :disabled="detailsShow"
            placeholder="可搜索选项" @change="changeUser">
            <el-option v-for="(item,index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" prop="id">
          <el-select v-model="addEditForm.id" filterable style="width: 300px" :disabled="detailsShow"
            placeholder="可搜索选项" @change="changeGoodsEvents">
            <el-option v-for="(item,index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型" v-if="addEditForm.id">
          <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2"
            placeholder="请选择商品类型" :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }" :disabled="true" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="商品单位" v-if="addEditForm.id">
          <el-select v-model="saleGoodsInfo.unitId" style="width: 300px" @change="unitChange" :disabled="true">
            <el-option v-for="(item,index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.color" placeholder="请输入颜色" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="型号" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.model" placeholder="请输入型号" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="规格" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.standard" placeholder="请输入规格" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="制造商" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="进货价" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.purchasing" placeholder="请输入进货价" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="单价" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.price" placeholder="请输入单价" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="总价" v-if="addEditForm.id && dialogTitle == '详情'">
          <el-input v-model="saleGoodsInfo.totalPrice" placeholder="请输入总价" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="库存" v-if="addEditForm.id">
          <el-input
            v-model="saleGoodsInfo.num"
            placeholder="请输入库存"
            style="width: 300px"
            :disabled="true"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="积分倍数" v-if="addEditForm.id">
          <el-input
            v-model="saleGoodsInfo.pointNum"
            placeholder="请输入积分倍数"
            style="width: 300px"
            :disabled="true"
            type="text"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="积分(参考值)" v-if="addEditForm.id">
          <el-input v-model="saleGoodsInfo.point" placeholder="请输入积分倍数" style="width: 300px" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="订单类型" v-if="addEditForm.id">
          <el-select v-model="saleGoodsInfo.type" placeholder="请选择订单类型" style="width: 300px" :disabled="true">
            <el-option v-for="(item,index) in orderType" :key="index" :label="item.label" :value="item.id"
              :disabled="true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" v-if="addEditForm.id && saleGoodsInfo.type == 1">
          <el-date-picker v-model="saleGoodsInfo.startTime" type="datetime" style="width: 300px" :disabled="true"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" v-if="addEditForm.id && saleGoodsInfo.type == 1">
          <el-date-picker v-model="saleGoodsInfo.endTime" type="datetime" style="width: 300px" :disabled="true"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送时间" v-if="addEditForm.id">
          <el-date-picker v-model="saleGoodsInfo.deliveryTime" type="date" style="width: 300px" :disabled="true"
            value-format="yyyy-MM-dd" placeholder="选择配送时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" v-if="addEditForm.id">
          <el-input type="textarea" v-model="saleGoodsInfo.remark" placeholder="请输入备注" style="width: 300px"
            :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="描述" v-if="addEditForm.id">
          <el-input type="textarea" v-model="saleGoodsInfo.describe" placeholder="请输入描述" style="width: 300px" :rows="6"
            :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="商品数量" prop="num">
          <el-input style="width: 300px" type="text" :disabled="detailsShow" v-model="addEditForm.num" :placeholder="
              addEditForm.id ? '剩余库存' + saleGoodsInfo.num : '请输入商品数量'
            "></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 退货 -->
    <el-dialog title="退货" :visible.sync="returnShow" width="35%" :close-on-click-modal="false">
      <el-form :model="returnForm" ref="returnForm" :rules="returnRules" :inline="true" label-width="120px">
        <el-form-item label="商品名称">
          <el-input style="width: 300px" v-model="returnForm.name" placeholder="请输入商品名称" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="商品数量" prop="returnGoodsNum">
          <el-input style="width: 300px" type="text" min="0" :max="returnForm.num" v-model="returnForm.returnGoodsNum"
            placeholder="请输入商品数量"></el-input>
        </el-form-item>
        <el-form-item label="退货原因">
          <el-input style="width: 300px" type="textarea" v-model="returnForm.reason" placeholder="请输入退货原因"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="returnSubmit('returnForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="hanleCancel('returnShow')">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 订单导出 -->
    <el-dialog title="订单导出" :visible.sync="exportShow" width="40%" :close-on-click-modal="false">
      <el-form :model="exportForm" ref="exportForm" :inline="true" label-width="120px">
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="exportForm.userid" filterable clearable style="width: 300px" placeholder="可搜索选项"
            @change="changeUser">
            <el-option v-for="(item,index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="exportForm.status" clearable style="width: 300px">
            <el-option v-for="(item,index) in orderStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" v-if="exportForm.userid">
          <el-select v-model="exportForm.title" filterable clearable style="width: 300px" placeholder="可搜索选项">
            <el-option v-for="(item,index) in selectGoods" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker v-model="exportForm.startTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker v-model="exportForm.endTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false">取 消</el-button>
        <el-button @click="exportForm = {}">清 空</el-button>
        <el-button type="primary" @click="orderExport">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsIndex",
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      exportButton: "",
      searchForm: {
        name: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        id: [{ required: true, message: "请选择销售商品", trigger: "change" }],
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        num: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 商品类型
      goodsType: [],
      // 商品单位
      goodsUnit: [],
      // 商品状态
      goodsStatus: [
        {
          label: "未上架",
          id: "0",
        },
        {
          label: "已上架",
          id: "1",
        },
      ],
      // 订单类型
      orderType: [
        {
          label: "限时订单",
          id: "1",
        },
        {
          label: "限量订单",
          id: "2",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉商品
      selectGoods: [],
      userList: [],
      saleGoodsInfo: {},
      // 选中用户的adcode
      userAdcode: "",
      // 退货
      returnForm: {},
      returnShow: false,
      returnRules: {
        returnGoodsNum: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
        ],
      },
      exportShow: false,
      exportForm: {},
      orderStatus: [
        {
          label: "已下单",
          id: "0",
        },
        {
          label: "已收货",
          id: "1",
        },
        {
          label: "售后/退货",
          id: "2",
        },
        {
          label: "取消",
          id: "3",
        },
      ],
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    this.getSelectGoods();
    this.getUserList();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "points_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "points_order_add") {
        this.addButton = "1";
      } else if (item.authority == "points_order_delete") {
        this.delButton = "1";
      } else if (item.authority == "points_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "points_order_upDown") {
        this.upDownButton = "1";
      } else if (item.authority == "points_order_export") {
        this.exportButton = "1";
      }
    });
  },
  methods: {
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/pointOrder", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            title: _this.searchForm.name,
          },
        })
        .then((res) => {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                if (item.imgs.indexOf(",") != -1)
                  item.icons = item.imgs.split(",")[0];
                else item.icons = item.imgs;
              }
              if (item.refundInfo) {
                var json = {
                  name: "多行测试",
                  num: 1,
                };
                item.refundInfo = JSON.parse(item.refundInfo);
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显商品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取销售商品下拉列表 --先选用户获取adcode 根据adcode获取商品
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId: warehouseId || '',
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.selectGoods = res.data.data;
          }
        });
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.userList = res.data.data;
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
      this.imgData = [];
      this.fileList = [];
      this.goodsTypeId2 = [];
      this.detailsShow = false;
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/details", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = false;
            that.fileList = [];
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
            data.isTopCarriage = data.isTopCarriage.toString();
            data.type = data.type.toString();
            if (!data.pointNum) {
              data.pointNum = 1;
            }
            data.point = Math.trunc(data.salePrice * data.pointNum);
            that.$nextTick(() => {
              that.addEditForm = data;
              // 连级选择器回显
              that.goodsTypeId2 = that
                .familyTree(that.goodsType, data.categoryId)
                .reverse();
              if (data.imgs) {
                let imgArr = data.imgs.split(",");
                that.imgData = imgArr;
                imgArr.forEach((item) => {
                  let json = {
                    url: item,
                  };
                  that.fileList.push(json);
                });
              }
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/saleChildOrder/add";
          } else {
            url = "/goodsMall/update";
          }
          console.log(_this.addEditForm);
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            console.log(res);
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.listEvent();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/goodsMall/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.saleGoodsInfo.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      let that = this;
      that.goodsTypeId2 = [];
      this.$axios
        .get(this.$axios.defaults.basePath + "/pointOrder/info", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = true;
            that.dialogTitle = "详情";
            that.dialogFormVisible = true;
            that.addEditForm.id = data.goodsId;
            that.addEditForm.userId = data.userId;
            that.addEditForm.num = data.num;
            that.saleGoodsInfo = data;
            that.saleGoodsInfo.type = data.type.toString();
            that.$set(that.goodsTypeId2, 0, data.categoryId);
          }
        });
    },
    // 下拉选择商品
    changeGoodsEvents(e) {
      if (e) {
        var goods = {};
        this.selectGoods.forEach((item) => {
          if (item.id == e) {
            goods = item;
          }
        });
        this.saleGoodsInfo = goods;
        this.saleGoodsInfo.type = goods.type.toString();
        // this.goodsTypeId2.push(goods.categoryId);
        this.$set(this.goodsTypeId2, 0, goods.categoryId);
        console.log(goods);
        var newDate = new Date().getTime();
        if (goods.type == 1) {
          //判断限时时间段是否过期
          var startTime = new Date(goods.startTime).getTime();
          var endTime = new Date(goods.endTime).getTime();
          console.log(startTime, endTime);
          if (newDate < startTime) {
            this.$message({
              message: "当前销售商品未开始",
              type: "warning",
            });
            this.addEditForm.id = "";
          } else if (newDate > endTime) {
            this.$message({
              message: "当前销售商品已过期",
              type: "warning",
            });
            this.addEditForm.id = "";
          }
        } else {
          //判断限量
          if (goods.num <= 0) {
            this.$message({
              message: "当前销售商品已售完",
              type: "warning",
            });
            this.addEditForm.id = "";
          }
        }
      }
    },
    // 选择用户
    changeUser(e) {
      this.userList.forEach((item) => {
        if (item.id == e) {
          if (item.adcode) {
            this.userAdcode = item.adcode;
          } else {
            this.$message({
              message: "此用户不是团长，只有团长才能下单",
              type: "warning",
            });
            this.addEditForm.userId = "";
          }
        }
      });
    },
    // 退货
    handleOrderReturn(row) {
      this.returnForm = row;
      console.log(row);
      this.returnShow = true;
    },
    returnSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            url:
              _this.$axios.defaults.basePath + "/pointOrder/pointOrderReturn",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              id: _this.returnForm.id,
              goodsId: _this.returnForm.goodsId,
              returnGoodsNum: _this.returnForm.returnGoodsNum,
              reason: _this.returnForm.reason,
            },
          }).then((res) => {
            console.log(res);
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.returnShow = false;
              _this.listEvent();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    hanleCancel(s) {
      this[s] = false;
    },
    handleOrderOk(row) {
      let _this = this;
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(
              _this.$axios.defaults.basePath + "/pointOrder/pointOrderComplete",
              {
                params: {
                  ids: row.id,
                },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    exportEvent() {
      this.exportShow = true;
    },
    // 订单导出
    orderExport() {
      let _this = this;
      console.log(this.exportForm);
      this.$axios
        .get(this.$axios.defaults.basePath + "/pointOrder/export", {
          params: _this.exportForm,
        })
        .then((res) => {
          console.log(res);
          var eleLink = document.createElement("a");
          eleLink.download = "积分订单.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });

          // 然后移除
          document.body.removeChild(eleLink);
        });
    },
  },
};
</script>

<style scoped>
</style>
