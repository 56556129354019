<template>
  <div>
    <div id="userMap"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      // 地图点标记测试数据
      markerList: [
        {
          wechat: '张旭彤',
          username: '张旭彤',
          shopName: '综合超市',
          location: '114.85,38.02',
          towns: "河北省-石家庄市-藁城区",
          address: "廉北路东段",
          way: "藁城",
          place: "石家庄仓库",
          phone: "18230410440",
        }, {
          wechat: '测试2',
          username: '张旭彤',
          shopName: '第九中学',
          location: '114.84,38.01',
          towns: "河北省-石家庄市-藁城区",
          address: "廉新街218号",
          way: "藁城",
          place: "石家庄仓库",
          phone: "18230410440",
        }, {
          wechat: '测试3',
          username: '张旭彤',
          shopName: '信誉楼',
          location: '114.86,38.03',
          towns: "河北省-石家庄市-藁城区",
          address: "廉州西路372号",
          way: "藁城",
          place: "石家庄仓库",
          phone: "18230410440",
        }, {
          wechat: '测试4',
          username: '张旭彤',
          shopName: '大有容园',
          location: '114.83,38.02',
          towns: "河北省-石家庄市-藁城区",
          address: "岗上镇东邑村",
          way: "藁城",
          place: "石家庄仓库",
          phone: "18230410440",
        },
      ],
    }
  },
  watch: {
    
  },
  props: {
    
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    })
  },
  methods: {
    // 初始化地图
    initMap() {

      AMapLoader.load({
        key: "b8696b7a51c31412f91d4576c7e18b53", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        AMapUI: {             // 是否加载 AMapUI，缺省不加载
          "version": '1.1',   // AMapUI 缺省 1.1
          "plugins": [],       // 需要加载的 AMapUI ui插件
        },
      }).then((AMap) => {
        //创建地图
        this.map = new AMap.Map('userMap', {
          cursor: 'default',
          zoom: 4
        });
        // this.createMarkers();
        //just some colors
        var colors = [
          "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00",
          "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707",
          "#651067", "#329262", "#5574a6", "#3b3eac"
        ];

        // AMapUI.loadUI(['geo/DistrictExplorer'], function (DistrictExplorer) {

        //   //创建一个实例
        //   var districtExplorer = new DistrictExplorer({
        //     map: this.map
        //   });


        //   function renderAreaNode(areaNode) {

        //     //绘制子区域
        //     districtExplorer.renderSubFeatures(areaNode, function (feature, i) {

        //       var fillColor = colors[i % colors.length];
        //       var strokeColor = colors[colors.length - 1 - i % colors.length];

        //       return {
        //         cursor: 'default',
        //         bubble: true,
        //         strokeColor: strokeColor, //线颜色
        //         strokeOpacity: 1, //线透明度
        //         strokeWeight: 1, //线宽
        //         fillColor: fillColor, //填充色
        //         fillOpacity: 0.35, //填充透明度
        //       };
        //     });

        //     //绘制父区域
        //     districtExplorer.renderParentFeature(areaNode, {
        //       cursor: 'default',
        //       bubble: true,
        //       strokeColor: 'black', //线颜色
        //       strokeOpacity: 1, //线透明度
        //       strokeWeight: 1, //线宽
        //       fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
        //       fillOpacity: 0.35, //填充透明度
        //     });
        //   }

        //   var adcodes = [130109, 130183, 130181];

        //   districtExplorer.loadMultiAreaNodes(adcodes, function (error, areaNodes) {

        //     //清除已有的绘制内容
        //     districtExplorer.clearFeaturePolygons();


        //     for (var i = 0, len = areaNodes.length; i < len; i++) {
        //       renderAreaNode(areaNodes[i]);
        //     }

        //     //更新地图视野
        //     this.map.setFitView(districtExplorer.getAllFeaturePolygons());
        //   });
        // });





        // this.map = new AMap.Map("userMap", {  //设置地图容器id
        //   zoom: 14,           //初始化地图级别
        //   center: [114.83, 38.01], //初始化地图中心点位置
        //   viewMode: '3D',
        //   pitch: 30,
        //   expandZoomRange: true,
        //   mapStyle: 'amap://styles/whitesmoke',
        // });

        

      }).catch(e => {
        console.log(e);
      })
    },
    // 创建点标记
    createMarkers(){
      let _this = this;
      // 创建 AMap.LabelsLayer 图层
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 111,
        // 关闭标注避让，默认为开启，v1.4.15 新增属性
        animation: false,
        // 关闭标注淡入动画，默认为开启，v1.4.15 新增属性
        collision: false
      });

      // 将图层添加到地图
      this.map.add(layer);

      let markers = [];
      let icon = {
        type: 'image',
        image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
        size: [12, 18],
        anchor: 'bottom-center',
        angel: 0,
        retina: true
      };
      this.markerList.forEach(item => {
        var curPosition = item.location.split(',');
        var curData = {
          position: curPosition,
          icon
        };
        var labelMarker = new AMap.LabelMarker(curData);
        labelMarker.on('mouseover', function (e) {
          var position = e.data.data && e.data.data.position;

          if (position) {
            normalMarker.setContent(
              '<div class="amap-info-window">'
              + position +
              '<div class="amap-info-sharp"></div>' +
              '</div>');
            normalMarker.setPosition(position);
            _this.map.add(normalMarker);
          }
        });

        labelMarker.on('mouseout', function () {
          _this.map.remove(normalMarker);
        });

        markers.push(labelMarker);
      })
      // 一次性将海量点添加到图层
      layer.add(markers)
      // 普通点
      var normalMarker = new AMap.Marker({
        offset: new AMap.Pixel(0, -20),
      });
    },
  }
}
</script>

<style scope>
#userMap {
  width: 100%;
  height: 600px;
}

/* 地图信息窗体 */
.custom-input-card {
  width: 18rem;
}

.custom-input-card .btn:last-child {
  margin-left: 1rem;
}

.content-window-card {
  position: relative;
  width: 23rem;
  padding: 0.75rem 0 0 1.25rem;
  box-shadow: none;
  bottom: 0;
  left: 0;
}

.content-window-card p {
  height: 2rem;
}
.amap-info-window {
  width: 150px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  padding: 3px 7px;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
  position: relative;
}

.amap-info-sharp {
  position: absolute;
  top: 21px;
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}
</style>