<template>
  <div class="homePage">
    <!-- banner -->
    <div class="home_banner">
      <!-- <div class="home_title">
        <p>欢迎来到团购后台管理系统！</p>
        <p>{{ date }}</p>
      </div> -->
      <div class="home_total">
        <p class="home_total_title">
          {{
          username == "admin" ? "各地区团长统计：" : userArea + "团长统计："
          }}
        </p>
        <ul class="clearfix">
          <li v-for="(item, index) in adcodeList" :key="index">
            <p>{{ item.name }}（人）</p>
            <p>{{ item.num }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- echarts -->
    <div class="home_drawTotal">
      <el-form style="margin-bottom: 20px">
        <el-form-item label="所属仓库">
          <el-select clearable v-model="warehouseId" style="width: 200px" :disabled="username != 'admin'"
            @change="changeWarehouse">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="home_echarts clearfix">
        <!-- 团长销售排行榜 -->
        <div class="echarts_box clearfix">
          <div class="table_box">
            <p class="sort_title">团长销售排行榜</p>
            <template>
              <el-button type="primary" @click="exportEvent(1)" size="mini" class="float_rt"
                style="margin:0 0 15px 0;position: relative;z-index: 999;">导出销售排行</el-button>
              <el-table ref="singleTable" :data="userSortData" :row-class-name="handleRowClassName"
                @sort-change="sortChange" style="width: 100%" height="600">
                <el-table-column type="index" label="排名" width="80">
                </el-table-column>
                <el-table-column prop="wechat" label="昵称"> </el-table-column>
                <el-table-column prop="username" label="用户名" sortable="custom">
                </el-table-column>
                <el-table-column prop="towns" label="所属乡镇">
                </el-table-column>
                <el-table-column prop="salePrice" label="销售额">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="lookSaleInfo(scope.row)">查看明细</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!-- <el-button
              v-if="userSortData.length > 8"
              type="primary"
              style="display: block; margin: 20px auto"
              @click="dialogGoodsVisible = true"
              >查看更多</el-button
            > -->
          </div>
        </div>
        <!-- 团长活跃情况统计 -->
        <!-- <div class="echarts_box clearfix" style="padding-top: 40px">
          <el-date-picker v-model="userTotalTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" @change="userNumChange"
            style="top: 30px">
          </el-date-picker>
          <div class="echarts_item" id="lineEcharts2"></div>
        </div> -->
      </div>
    </div>
    <!-- 团长销售排行明细 -->
    <el-dialog title="团长销售明细" :visible.sync="detailShow" width="60%">
      <div class="pageMain">
        <!-- 检索 -->
        <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
          <el-form-item label="日期">
            <el-date-picker v-model="userDetailDate" @change="changeDatePicker" type="datetimerange" range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetForm('searchForm')">重置</el-button>
          </el-form-item>
          <el-form-item label="">
            <div class="nums">
              下单次数：<span>{{ priceNum.orderCnt }}</span> 总金额：<span>{{
                priceNum.totalPrice
                }}</span>
            </div>
          </el-form-item>
        </el-form>

        <div class="clearfix">
          <div class="boxMain fl" style="width: 100%; box-sizing: border-box">
            <p class="boxTitle">商品列表</p>
            <template>
              <el-table ref="multipleTable" :data="goodsList" style="width: 100%">
                <el-table-column prop="title" width="300" label="商品名称">
                </el-table-column>
                <el-table-column prop="num" label="下单次数"></el-table-column>
                <el-table-column prop="goodsNum" label="商品数量"></el-table-column>
                <el-table-column prop="price" label="商品单价"></el-table-column>
                <el-table-column prop="totalPrice" label="商品总价">
                </el-table-column>
              </el-table>
              <div class="block" style="padding: 10px 15px">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
                  layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
                </el-pagination>
              </div>
            </template>
          </div>
          <div class="boxMain" style="width: 100%; margin-top: 20px; box-sizing: border-box">
            <p class="boxTitle">售后列表</p>
            <template>
              <el-table ref="multipleTable" :data="orderList" style="width: 100%">
                <el-table-column prop="title" width="300" label="商品名称">
                </el-table-column>
                <el-table-column prop="cnt" label="退货次数"> </el-table-column>
                <el-table-column prop="num" label="商品数量"></el-table-column>
                <el-table-column prop="price" label="商品单价"></el-table-column>
                <el-table-column prop="amount" label="退货金额">
                </el-table-column>
              </el-table>
              <div class="block" style="padding: 10px 15px">
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                  :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows"
                  :page-size="pagesData2.currentRows" layout="total, sizes, prev, pager, next, jumper"
                  :total="pagesData2.total">
                </el-pagination>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog title="统计分析导出" :visible.sync="exportShow" width="40%" @close="exportShow = false;exportForm = {};">
      <el-form :model="exportForm" ref="exportForm" :inline="true" label-width="120px">
        <el-form-item label="所属仓库">
          <el-select clearable multiple v-model="exportForm.warehouseId" style="width: 300px"
            :disabled="username != 'admin'">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false;exportForm = {};">取 消</el-button>
        <el-button @click="
            exportForm = {};
          ">清 空</el-button>
        <el-button type="primary" @click="orderExport">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "data-backups",
  data() {
    return {
      priceNum: {},
      goodsList: [],
      orderList: [],
      detailShow: false,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //分页数据
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      searchForm: {
        endTime: "",
        startTime: "",
        userid: "",
      },
      userDetailDate: [],
      bannerSrc: require("../../assets/img/home_banner.png"),
      srcs: require("../../assets/home/user_lan.png"),
      // 用户总数（人）
      userTotal: 0,
      //
      myEcharts_line2: null,

      // 用户adcode ---------------------
      adcode: "",
      adcodeList: "",
      username: sessionStorage.getItem("userName"),
      // 用户所在地区
      userArea: "",
      // 下拉商品
      selectGoods: [],
      goodsId: "",
      // 团长销量排行
      userSortData: [],
      userTotalTime: [],
      // 团长活跃
      UserNumData: [],
      allnum: "",
      // 仓库下拉
      warehouseList: [],
      warehouseId: "",
      //订单导出
      exportShow:false,
      exportForm:{},
      exportPath:'',
      exportType:null,
    };
  },
  created() {
    this.adcode = sessionStorage.getItem("adcode");
    this.userArea = CodeToText[this.adcode];
    this.getWarehouseList();
  },
  mounted() {
    // this.drawEcharts();
    this.initData();
    this.getSelectGoods();
  },
  methods: {
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
            // 通过用户adcode获取所属仓库ID
            res.data.data.forEach((item) => {
              if (_this.adcode == item.adcode) {
                _this.warehouseId = item.id;
              }
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeDatePicker(e) {
      this.searchForm.startTime = e[0];
      this.searchForm.endTime = e[1];
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.pagesData2.currentPage = 1;
      this.pagesData2.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this.searchForm.startTime = "";
      this.searchForm.endTime = "";
      this.userDetailDate = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.pagesData2.currentPage = 1;
      this.pagesData2.currentRows = 10;
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.getUserGoodsPage();
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      this.getUserReturnGoodsPage();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.getUserGoodsPage();
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      this.getUserReturnGoodsPage();
    },
    //单个团长的详细数据-售后信息
    getUserReturnGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData2.currentPage;
      params.size = _this.pagesData2.currentRows;
      params.warehouseId = _this.warehouseId;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsStatistics/userReturnGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.orderList = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单个团长的详细数据-商品列表
    getUserGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData.currentPage;
      params.size = _this.pagesData.currentRows;
      params.warehouseId = _this.warehouseId;
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/goodsStatistics/userGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsList = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单个团长的详细数据-下单次数/下单金额
    listEvent() {
      let _this = this;
      this.searchForm.warehouseId = this.warehouseId;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsStatistics/userDetail", {
          params: this.searchForm,
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.getUserGoodsPage();
            _this.getUserReturnGoodsPage();
            _this.priceNum = res.data.data;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    handleRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "el-bg-gray" : "";
    },
    // 绘制echarts图表事件
    drawEcharts() {
      let _this = this;
      let myEcharts_line2 = echarts.init(
        document.getElementById("lineEcharts2")
      );
      let option3 = {
        title: {
          text: "团长活跃情况统计",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: function (params) {
            var i = params.dataIndex;
            console.log(_this.UserNumData)
            var name = _this.UserNumData[i].wechatStr?_this.UserNumData[i].wechatStr:_this.UserNumData[i].userStr
            return (
              "日期：" +
              params.name +
              "<br />开团次数：" +
              params.value +
              "<br />开团团长：" +
              name
            );
          },
          // formatter: "日期：{b0}<br />开团次数：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      myEcharts_line2.setOption(option3);
      this.myEcharts_line2 = myEcharts_line2;
    },
    // 排名表格升序降序事件 传參：字段名+type
    sortChange(e) {
      console.log(e);
    },
    initData() {
      this.getTotal();
      this.getGoodsDayStatistics();
      this.getUserNum();
    },
    // 获取地区团长总数 admin查看全部地区 用户查看本地区
    async getTotal() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/userSum",
        {
          params: {
            warehouseId: _this.warehouseId,
          },
        }
      );
      this.adcodeList = [];
      res.data.data.forEach((item) => {
        var areaName = CodeToText[item.adcode];
        var json = {
          name: areaName,
          num: item.num,
        };
        this.adcodeList.push(json);
      });
      console.log(this.adcodeList);
    },
    // 查询团长销售排行
    async getGoodsDayStatistics() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/rankingList",
        {
          params: {
            warehouseId: _this.warehouseId,
          },
        }
      );
      this.userSortData = res.data.data;
      console.log(res);
    },
    // 查询团长活跃情况统计
    async getUserNum() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/userActiveSituation",
        {
          params: {
            startTime:
              _this.userTotalTime && _this.userTotalTime.length > 0
                ? _this.userTotalTime[0]
                : "",
            endTime:
              _this.userTotalTime && _this.userTotalTime.length > 0
                ? _this.userTotalTime[1]
                : "",
            warehouseId: _this.warehouseId,
          },
        }
      );
      console.log(res);
      const xAxisData = [];
      const seriesData = [];
      this.allnum = res.data.data.allnum;
      this.UserNumData = res.data.data.list;
      res.data.data.list.forEach((item) => {
        xAxisData.push(item.dateStr);
        seriesData.push(item.num);
      });

      // this.myEcharts_line2.setOption({
      //   xAxis: {
      //     data: xAxisData,
      //   },
      //   series: [
      //     {
      //       data: seriesData,
      //     },
      //   ],
      // });
    },
    // 获取商品下拉列表
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId: warehouseId || '',
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.selectGoods = res.data.data;
            _this.oneGoodsId = res.data.data.goodsId;
          }
        });
    },
    // 商品下拉选s
    changeGoodsEvents(e) {
      this.getGoodsStatistics();
    },
    // 查看团长明细
    lookSaleInfo(row) {
      this.searchForm.userid = row.userid;
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.detailShow = true;
    },
    // 团长活跃度时间统计
    userNumChange(e) {
      this.getUserNum();
    },
    changeWarehouse(e) {
      this.getSelectGoods(e);
      this.initData();
    },
    // 导出事件
    exportEvent(type){
      if(type == 1){ // 团长销售排行榜 
        this.exportPath = '/export/rankingList';
      }
      this.exportType = type;
      this.$set(this.exportForm,'warehouseId',this.warehouseId);
      this.exportShow = true;
    },
    orderExport() {
      let _this = this;
      console.log(this.exportForm);
      _this.exportForm.warehouseId = _this.exportForm.warehouseId.join(',');
      this.$axios
        .get(this.$axios.defaults.basePath + this.exportPath, {
          params: _this.exportForm,
        })
        .then((res) => {
          console.log(res);
          var eleLink = document.createElement("a");
          eleLink.download = _this.exportType == 1?"团长销售排行.csv":"";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          _this.exportForm = {};
            _this.exportCategoryId = [];
          // 然后移除
          document.body.removeChild(eleLink);
        });
    },
  },
};
</script>

<style scoped>
.homePage {
  margin-top: 56px;
  padding: 20px;
  box-sizing: border-box;
}
.home_banner {
  width: 100%;
  height: 336px;
  padding: 60px 70px 40px 70px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: url("../../assets/img/home_banner.png") no-repeat;
  background-size: 100% 100%;
}
.home_banner .home_title {
  color: #fff;
  font-size: 16px;
}
.home_title p:first-child {
  font-size: 30px;
  margin-bottom: 10px;
}
.home_total {
  margin-top: 0;
}
.home_total_title {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  margin-top: 15px;
}
.home_total ul li {
  float: left;
  min-width: 176px;
  padding: 25px;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  list-style: none;
  margin-right: 15px;
  background-color: rgba(243, 246, 253, 0.2);
}
.home_total ul li p:first-child + p {
  font-size: 30px;
  margin-top: 10px;
}
.home_drawTotal {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.home_drawTotal .total_title {
  font-size: 16px;
  margin-bottom: 10px;
}
.home_drawTotal .total_title .total_change span {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
}
.home_drawTotal .total_title .total_change span.active {
  color: #246eff;
}
.home_echarts {
  width: 100%;
}
.home_echarts > div {
  width: 100%;
  height: 800px;
  float: left;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div {
  border-top: 0;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div + div {
  border-right: 1px solid #e5e5e5;
}
.echarts_box {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.echarts_box >>> .el-date-editor {
  width: 380px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
.goodsSelect {
  position: absolute;
  top: 10px;
  right: 400px;
  z-index: 1000;
}
@media screen and (max-width: 1700px) {
  .goodsSelect {
    left: 24% !important;
  }
}
.echarts_box .echarts_item {
  width: 100%;
  height:500px;
}

.weclone {
  color: #333;
  text-align: center;
  font-size: 50px;
  padding-top: 300px;
}
.pageMain .item {
  width: 100%;
  height: 180px;
  position: relative;
  cursor: pointer;
}
.pageMain .item .item_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: auto;
  height: 180px;
}
.pageMain .item .icon_zhuan {
  position: inherit;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  -webkit-animation: changeright 6s linear infinite;
}
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pageMain .item .item_main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.pageMain .item .item_main img {
  display: block;
  /*padding-left: 15px;*/
  margin: 0 auto 10px auto;
}
.home_sort {
  width: 100%;
  margin-top: 20px;
}
.table_box {
  width: 100%;
  height: 500px;
  float: left;
  padding: 20px 0;
  margin-left: 1.4%;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}
.table_box:first-child {
  margin-left: 0;
}
.table_box .sort_title {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-table {
  border: 1px solid #e3e3e3;
}

::v-deep .el-table thead {
  background: #e7efff;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-bg-gray,
::v-deep .el-table__row:hover {
  background: #f1f2f7 !important;
}
.nums {
  font-size: 20px;
  margin-left: 50px;
}
.nums span {
  font-size: 24px;
  font-weight: 600;
}
</style>
