<template>
  <div class="homePage">
    <!-- echarts -->
    <div class="home_drawTotal">
      <!-- <div class="total_title clearfix">
        <div class="float_lf">数据统计</div>
      </div> -->
      <!-- v-if="username == 'admin'" -->
      <el-form style="margin-bottom: 20px">
        <el-form-item label="所属仓库">
          <el-select clearable multiple v-model="warehouseId" style="width: 300px" :disabled="username != 'admin'"
            @change="changeWarehouse">
            <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="home_echarts clearfix">
        <!-- 销售总额 折线图-->
        <div class="echarts_box clearfix" style="text-align: right">
          <el-date-picker v-model="saleTotalTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" @change="saleTimeChange">
          </el-date-picker>
          <div class="echarts_item" id="lineEcharts1"></div>
        </div>
        <!-- 毛利统计 -->
        <div class="echarts_box clearfix" style="text-align: right">
          <el-select class="goodsSelect" v-model="goodsId" filterable clearable style="width: 200px; margin-right: 20px"
            placeholder="按商品统计（可搜索）" @change="changeGoodsEvents">
            <el-option v-for="(item,index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker v-model="goodsGross" type="monthrange" range-separator="至" value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="goodsGrossChange">
          </el-date-picker>
          <div class="echarts_item" id="barEcharts"></div>
        </div>
        <!-- 产品下单及浏览人数统计 -->
        <div class="echarts_box clearfix" style="padding-top: 40px">
          <el-date-picker v-model="goodsTotalTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="goodsTotalChange" style="top: 30px">
          </el-date-picker>
          <div class="echarts_item" id="lineEcharts3" style="height: 600px"></div>
        </div>
        <!-- 单个产品总订单数及总销售额 -->
        <div class="echarts_box clearfix" style="padding-top: 40px">
          <el-select class="goodsSelect" v-model="oneGoodsId" filterable clearable
            style="width: 200px; margin-right: 20px; right: 20px; top: 30px" placeholder="按商品统计（可搜索）"
            @change="changeOneGoodsEvents">
            <el-option v-for="(item,index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            v-model="goodsTotalTime"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="goodsTotalChange"
            style="top: 30px"
          >
          </el-date-picker> -->
          <div class="echarts_item" id="lineEcharts4" style="height: 600px"></div>
        </div>
        <!-- 产品销售排行榜 -->
        <div class="echarts_box clearfix">
          <div class="table_box">
            <p class="sort_title">产品销售排行榜</p>
            <el-date-picker v-model="goodsRinkingTime" type="datetimerange" range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"
              @change="goodsRinkingChange" style="top: 30px">
            </el-date-picker>
            <template>
              <el-button type="primary" @click="exportEvent(1)" size="mini" class="float_rt"
                style="margin:0 0 15px 0;position: relative;z-index: 999;">导出产品销售排行</el-button>
              <el-table ref="goodsTable" :data="goodsRinkingData" style="width: 100%" height="380" border stripe>
                <el-table-column type="index" label="排名"> </el-table-column>
                <el-table-column prop="goodsName" label="产品名称">
                </el-table-column>
                <el-table-column prop="salePrice" label="销售额（元）">
                </el-table-column>
                <el-table-column prop="saleNum" label="销售数量">
                </el-table-column>
              </el-table>
            </template>
          </div>
        </div>
        <!-- 周期内上架的产品及销售额 -->
        <div class="echarts_box clearfix" style="padding-top: 40px">
          <el-date-picker v-model="goodsSaleTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" @change="goodsSaleChange"
            style="top: 30px">
          </el-date-picker>
          <el-button type="primary" @click="exportEvent(2)" size="mini" class="float_rt"
            style="margin:50px 0 15px 0;position: relative;z-index: 999;">导出上架产品数据</el-button>
          <div class="echarts_item" id="barEcharts2" style="height: 600px"></div>
        </div>
        <!-- 团长销售排行榜 -->
        <div class="echarts_box clearfix">
          <div class="table_box">
            <p class="sort_title">团长销售排行榜</p>
            <template>
              <el-button type="primary" @click="exportEvent(3)" size="mini" class="float_rt"
                style="margin:0 0 15px 0;position: relative;z-index: 999;">导出销售排行</el-button>
              <el-table ref="singleTable" :data="userSortData" :row-class-name="handleRowClassName"
                @sort-change="sortChange" style="width: 100%" height="380">
                <el-table-column type="index" label="排名" width="80">
                </el-table-column>
                <el-table-column prop="wechat" label="昵称"> </el-table-column>
                <el-table-column prop="username" label="用户名" sortable="custom">
                </el-table-column>
                <el-table-column prop="salePrice" label="销售额">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="lookSaleInfo(scope.row)">查看明细</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!-- <el-button
              v-if="userSortData.length > 8"
              type="primary"
              style="display: block; margin: 20px auto"
              @click="dialogGoodsVisible = true"
              >查看更多</el-button
            > -->
          </div>
        </div>
        <!-- 团长活跃情况统计 -->
        <!-- <div class="echarts_box clearfix" style="padding-top: 40px">
          <el-date-picker v-model="userTotalTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期" @change="userNumChange"
            style="top: 30px">
          </el-date-picker>
          <div class="echarts_item" id="lineEcharts2"></div>
        </div> -->
      </div>
    </div>
    <!-- 团长销售排行明细 -->
    <el-dialog title="团长销售明细" :visible.sync="detailShow" width="60%">
      <div class="pageMain">
        <!-- 检索 -->
        <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline">
          <el-form-item label="日期">
            <el-date-picker v-model="userDetailDate" @change="changeDatePicker" type="datetimerange" range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
            <el-button @click="resetForm('searchForm')">重置</el-button>
          </el-form-item>
          <el-form-item label="">
            <div class="nums">
              下单次数：<span>{{ priceNum.orderCnt }}</span> 总金额：<span>{{
                priceNum.totalPrice
                }}</span>
            </div>
          </el-form-item>
        </el-form>

        <div class="clearfix">
          <div class="boxMain fl" style="width: 100%; box-sizing: border-box">
            <p class="boxTitle">商品列表</p>
            <template>
              <el-table ref="multipleTable" :data="goodsList" style="width: 100%">
                <el-table-column prop="title" width="300" label="商品名称">
                </el-table-column>
                <el-table-column prop="num" label="下单次数"></el-table-column>
                <el-table-column prop="goodsNum" label="商品数量"></el-table-column>
                <el-table-column prop="price" label="商品单价"></el-table-column>
                <el-table-column prop="totalPrice" label="商品总价">
                </el-table-column>
              </el-table>
              <div class="block" style="padding: 10px 15px">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
                  layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
                </el-pagination>
              </div>
            </template>
          </div>
          <div class="boxMain" style="width: 100%; margin-top: 20px; box-sizing: border-box">
            <p class="boxTitle">售后列表</p>
            <template>
              <el-table ref="multipleTable" :data="orderList" style="width: 100%">
                <el-table-column prop="title" width="300" label="商品名称">
                </el-table-column>
                <el-table-column prop="cnt" label="退货次数"> </el-table-column>
                <el-table-column prop="num" label="商品数量"></el-table-column>
                <el-table-column prop="price" label="商品单价"></el-table-column>
                <el-table-column prop="amount" label="退货金额">
                </el-table-column>
              </el-table>
              <div class="block" style="padding: 10px 15px">
                <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                  :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows"
                  :page-size="pagesData2.currentRows" layout="total, sizes, prev, pager, next, jumper"
                  :total="pagesData2.total">
                </el-pagination>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog title="统计分析导出" :visible.sync="exportShow" width="40%" @close="exportShow = false;exportForm = {};">
      <el-form :model="exportForm" ref="exportForm" :inline="true" label-width="120px">
        <el-form-item label="所属仓库">
          <el-select clearable multiple v-model="exportForm.warehouseId" style="width: 300px"
            :disabled="username != 'admin'">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" v-if="exportType !=3">
          <el-date-picker v-model="exportForm.startTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" v-if="exportType !=3">
          <el-date-picker v-model="exportForm.endTime" type="datetime" style="width: 300px"
            value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false;exportForm = {};">取 消</el-button>
        <el-button @click="
            exportForm = {};
          ">清 空</el-button>
        <el-button type="primary" @click="orderExport">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "data-backups",
  data() {
    return {
      priceNum: {},
      goodsList: [],
      orderList: [],
      detailShow: false,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      searchForm: {
        endTime: "",
        startTime: "",
        userid: "",
      },
      userDetailDate: [],
      bannerSrc: require("../../assets/img/home_banner.png"),
      srcs: require("../../assets/home/user_lan.png"),
      // 产品昨日数据排名更多弹窗
      dialogGoodsVisible: false,
      // 销售总额（元）
      saleTotalPrice: "0.00",
      // 产品销售总额图表echart dom
      myEcharts_bar: null,
      //
      myEcharts_line1: null,
      myEcharts_line3: null,
      myEcharts_line4: null,
      myEcharts_bar2: null,

      // 用户adcode ---------------------
      adcode: "",
      adcodeList: "",
      username: sessionStorage.getItem("userName"),
      // 用户所在地区
      userArea: "",
      // 销售总额筛选时间段
      saleTotalTime: [],
      // 毛利筛选时间 yyyy-MM
      goodsGross: [],
      // 下拉商品
      selectGoods: [],
      goodsId: "",

      // 产品下单浏览
      goodsTotalTime: [],
      // 单个产品ID
      oneGoodsId: "",
      // 产品销售排行榜
      goodsRinkingData: [],
      goodsRinkingTime: [],
      goodsSaleTime: [],
      // 团长销量排行
      userSortData: [],
      userTotalTime: [],
      // 团长活跃
      UserNumData: [],
      allnum: "",
      // 仓库下拉
      warehouseList: [],
      warehouseId: [],
      warehouseName:'',
      username: sessionStorage.getItem("userName"),
      //订单导出
      exportShow:false,
      exportForm:{},
      exportPath:'',
      exportType:null,
    };
  },
  created() {
    // 获取用户adcode
    this.adcode = sessionStorage.getItem("adcode");
    this.userArea = CodeToText[this.adcode];
    console.log(this.adcode, this.userArea);
    this.getWarehouseList();
  },
  mounted() {
    this.drawEcharts();
    this.getSelectGoods();
  },
  methods: {
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
            // 通过用户adcode获取所属仓库ID
            res.data.data.forEach((item) => {
              if (_this.adcode == item.adcode) {
                _this.warehouseId = [item.id];
                _this.$set(_this.exportForm,'warehouseId',_this.warehouseId);
                _this.warehouseName = item.name;
                _this.initData();
              }
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeDatePicker(e) {
      this.searchForm.startTime = e[0];
      this.searchForm.endTime = e[1];
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
    },
    // 检索重置
    resetForm(formName) {
      this.searchForm.startTime = "";
      this.searchForm.endTime = "";
      this.userDetailDate = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
    },

    handleRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "el-bg-gray" : "";
    },
    toSale(scope) {
      const { username, userId } = scope.row;
      this.$router.push(`/sales?wechat=${username}&userId=${userId}&status=2`);
    },
    // 绘制echarts图表事件
    drawEcharts() {
      let _this = this;
      // 产品销售总额
      let myEcharts_line1 = echarts.init(
        document.getElementById("lineEcharts1")
      );
      let option = {
        title: {
          text: "产品销售总额",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: "日期：{b0}<br />销售总额：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "line",
            smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      myEcharts_line1.setOption(option);
      this.myEcharts_line1 = myEcharts_line1;

      // 毛利统计
      let barEcharts = echarts.init(document.getElementById("barEcharts"));
      let option2 = {
        title: {
          text: "毛利统计",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: function(params){
            var returnData='';
            if(params.length){
              returnData='日期：'+params[0].name+'<br />';
              params.forEach((item,i)=>{
                returnData+=item.seriesName+'：'+item.value+'<br />'
              })
            }else{
              returnData = '日期：'+params.name+'<br />'+params.seriesName+'：'+params.value
            }
            return returnData
          },
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      barEcharts.setOption(option2);
      this.barEcharts = barEcharts;

      // 产品下单及浏览人数统计
      let myEcharts_line3 = echarts.init(
        document.getElementById("lineEcharts3")
      );
      let option4 = {
        title: {
          text: "产品下单及浏览人数统计",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },
        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          // formatter: function (params) {
          //   console.log(params);
          //   var i = params.dataIndex;
          //   return (
          //     "日期：" +
          //     params.name +
          //     "<br />开团次数：" +
          //     params.value +
          //     "<br />开团团长：" +
          //     _this.UserNumData[i].wechatStr
          //   );
          // },
          // formatter: "日期：{b0}<br />开团次数：{c0}",
        },
        grid: {
          top: "14%",
          bottom: "50%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
            interval: 0,
            rotate: 40,
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            bottom: "22%",
          },
        ],
        series: [
          {
            type: "line",
            // smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
              distance: 15,
            },
            data: [],
          },
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#409EFF" },
                { offset: 1, color: "rgb(217, 236, 255)" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#409EFF" },
                  { offset: 1, color: "rgb(217, 236, 255)" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      myEcharts_line3.setOption(option4);
      this.myEcharts_line3 = myEcharts_line3;

      // 单个产品下单量及总销售额
      let myEcharts_line4 = echarts.init(
        document.getElementById("lineEcharts4")
      );
      let option5 = {
        title: {
          text: "单个产品总订单数及总销售额",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },
        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          // formatter: function (params) {
          //   console.log(params);
          //   var i = params.dataIndex;
          //   return (
          //     "日期：" +
          //     params.name +
          //     "<br />开团次数：" +
          //     params.value +
          //     "<br />开团团长：" +
          //     _this.UserNumData[i].wechatStr
          //   );
          // },
          // formatter: "日期：{b0}<br />开团次数：{c0}",
        },
        grid: {
          top: "14%",
          bottom: "50%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
            interval: 0,
            rotate: 40,
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            bottom: "22%",
          },
        ],
        series: [
          {
            type: "line",
            // smooth: true,
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
              distance: 15,
            },
            data: [],
          },
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#409EFF" },
                { offset: 1, color: "rgb(217, 236, 255)" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#409EFF" },
                  { offset: 1, color: "rgb(217, 236, 255)" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      myEcharts_line4.setOption(option5);
      this.myEcharts_line4 = myEcharts_line4;

      // 周期内上架产品及销售额
      let barEcharts2 = echarts.init(document.getElementById("barEcharts2"));
      let option6 = {
        title: {
          text: "周期内上架产品及销售额",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: "产品名称：{b0}<br />销售总额（元）：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "40%",
        },
        xAxis: {
          name: "上架产品",
          nameGap: 15,
          data: [],
          axisLabel: {
            color: "#333",
            interval: 0,
            rotate: 40,
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          nameTextStyle: {
            lineHeight: 40,
          },
          name: "销售总额（元）",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            bottom: "22%",
          },
        ],
        series: [
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      barEcharts2.setOption(option6);
      this.myEcharts_bar2 = barEcharts2;

      let myEcharts_line2 = echarts.init(
        document.getElementById("lineEcharts2")
      );
      let option3 = {
        title: {
          text: "团长活跃情况统计",
          left: 0,
          top: 0,
          textStyle: {
            width: "100%",
            fontSize: 20,
          },
        },

        tooltip: {
          show: true,
          textStyle: {
            align: "left",
          },
          formatter: function (params) {
            var i = params.dataIndex;
            console.log(_this.UserNumData)
            var name = _this.UserNumData[i].wechatStr?_this.UserNumData[i].wechatStr:_this.UserNumData[i].userStr
            return (
              "日期：" +
              params.name +
              "<br />下单次数：" +
              params.value +
              "<br />开团团长：" +
              name
            );
          },
          // formatter: "日期：{b0}<br />开团次数：{c0}",
        },
        grid: {
          top: "20%",
          bottom: "15%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            color: "#333",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          axisPointer: {
            show: true,
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
        },
        series: [
          {
            type: "bar",
            showBackground: false,
            barWidth: 20,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#07CFC6" },
                { offset: 1, color: "#06C160" },
              ]),
              borderRadius: [10, 10, 0, 0],
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#09E9DF" },
                  { offset: 1, color: "#04ED74" },
                ]),
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
            data: [],
          },
        ],
      };
      myEcharts_line2.setOption(option3);
      this.myEcharts_line2 = myEcharts_line2;
    },
    // 排名表格升序降序事件 传參：字段名+type
    sortChange(e) {
      console.log(e);
    },
    initData() {
      this.getSalePriceStatistics();
      this.getGoodsStatistics();
      this.getGoodsTotal();
      this.getOneGoodsTotal();
      this.getGoodsRinking();
      this.getGoodsSaleTotal();
      this.getGoodsDayStatistics();
      // this.getUserNum();
    },
    // 查询历史销售额列表
    async getSalePriceStatistics() {
      let _this = this;
      // 周期内销售总额计
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/grossSales",
        {
          params: {
            startTime: _this.saleTotalTime[0],
            endTime: _this.saleTotalTime[1],
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      const rea = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/salesCntNum",
        {
          params: {
            startTime: _this.saleTotalTime[0],
            endTime: _this.saleTotalTime[1],
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      console.log(res);
      if (res.data.errcode != 0) {
        return;
      }else if (JSON.stringify(res.data.data) == '{}') {
        this.myEcharts_line1.setOption({
          legend: {
            show: true,
            top: '10%',
            right: 10,
          },
          xAxis: {
            data: [],
          },
          title: {
            subtext: "总销售额（元）：" + rea.data.data,
            subtextStyle: {
              fontSize: 14,
            },
          },
          series:{
            data: []
          },
        });
        return;
      }
      let echartData = this.getEchartsDataEvent(res.data.data,'dayTime','saleAmount');
      this.myEcharts_line1.setOption({
        legend:{
          show:true,
          top:'10%',
          right:10,
        },
        xAxis: {
          data: echartData.xAxis,
        },
        title: {
          subtext: "总销售额（元）：" + rea.data.data,
          subtextStyle: {
            fontSize: 14,
          },
        },
        series: echartData.series,
      });
    },
     // 解析数据格式通用方法
    getEchartsDataEvent(data,key1,key2){
      // data:接口返回数据；key1、key2:需要的参数名；
      var series = []; //echarts数据
      var grossSales = data; 
      var dataArr = []; //所有数据集合
      var warehouse = []; //仓库集合（去重）
      var xAxisData = []; //x轴数据
      for(var key in grossSales){
        xAxisData.push(key);
        var data = grossSales[key]
        data.forEach(item=>{
          dataArr.push(item)
          warehouse.push(item.warehouse)
        })
      }
      warehouse = [...new Set(warehouse)]
      // 日期按从小到大排序
      xAxisData = xAxisData.sort(function(a,b){
        var aTime = new Date(a).getTime();
        var bTime = new Date(b).getTime();
        return aTime - bTime
      })
      // 以仓库为数组并以日期个数将数组中的data加入0占位
      var len = xAxisData.length;
      warehouse.forEach(item=>{
        var json = {
            name:item,
            type:'bar',
            data:[],
            barMaxWidth:20,
            itemStyle: {
               borderRadius: [10, 10, 0, 0],
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#666",
              },
            },
          }
          for(var i=0;i<len;i++){
          json.data.push(0)
        }
        dataArr.forEach((child,ind)=>{
          if(item == child.warehouse){
            // 获取该数据的日期对应xAxisData中的下标并以实际销售额替换对应下标的0值
            var ind = xAxisData.findIndex(function(date){
              return date == child[key1]
            })
            var sales = child[key2]
            json.data[ind] = sales
          }
        })
        series.push(json)
      })
      var endData = {
        xAxis:xAxisData,
        series:series
      }
      return endData
    },
    // 查询毛利
    async getGoodsStatistics() {
      let _this = this;
      let url = "";
      console.log(this.goodsId);
      if (this.goodsId) {
        url = "/goodsStatistics/goodsGrossByUser";
      } else {
        url = "/goodsStatistics/goodsGross";
      }
      console.log(_this.goodsGross);
      const res = await this.$axios.get(this.$axios.defaults.basePath + url, {
        params: {
          startTime:
            _this.goodsGross && _this.goodsGross.length > 0
              ? _this.goodsGross[0]
              : "",
          endTime:
            _this.goodsGross && _this.goodsGross.length > 0
              ? _this.goodsGross[1]
              : "",
          goodsId: _this.goodsId,
          warehouseId: _this.warehouseId.join(','),
        },
      });
      console.log(res);
      const xAxisData = [];
      const seriesData = [];
      if (res.data.errcode != 0) {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
        return;
      }
      if (res.data.errcode != 0) {
        this.$message({
          message: res.data.errmsg,
          type: "warning",
        });
        return;
      }else if (JSON.stringify(res.data.data) == '{}') {
        // this.$message({
        //   message: "暂无数据",
        //   type: "warning",
        // });
        this.barEcharts.setOption({
          xAxis: {
            data: [],
          },
          series: {
            data:[]
          },
        });
        return;
      }
      let echartData = null;
      if (this.goodsId) {
        echartData = this.getEchartsDataEvent(res.data.data, 'goodsName', 'gross');
      }else{
        echartData = this.getEchartsDataEvent(res.data.data, 'month', 'gross');
      }
      console.log(echartData)
      this.barEcharts.setOption({
        xAxis: {
          data: echartData.xAxis,
        },
        series: echartData.series,
      });
    },
    // 产品下单及浏览人数统计
    getGoodsTotal() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsStatistics", {
          params: {
            startTime:
              _this.goodsTotalTime && _this.goodsTotalTime.length > 0
                ? _this.goodsTotalTime[0]
                : "",
            endTime:
              _this.goodsTotalTime && _this.goodsTotalTime.length > 0
                ? _this.goodsTotalTime[1]
                : "",
            type: 2,
            warehouseId: _this.warehouseId.join(','),
          },
        })
        .then((res) => {
          console.log(res);
          let list = res.data.data;
          var listData = [];
          for(var key in list){
            console.log(_this.warehouseName,key)  
            if(_this.warehouseName == key){
              
              listData = list[key]
            }
          }
          const xAxisData = [];
          const seriesData = [[], []];
          let newArr = [];
          console.log(listData)
          listData.forEach((item) => {
            newArr.push(item.goodsId);
            seriesData[0].push(item.browseNum);
            seriesData[1].push(item.buyNum);
          });
          newArr = [...new Set(newArr)];
          let totalArr = [];
          newArr.forEach((child, index) => {
            var json = {
              goodsId: child,
              name: "",
              browseNum: 0,
              buyNum: 0,
            };
            listData.forEach((item) => {
              if (child == item.goodsId) {
                json.name = item.name;
                json.browseNum += item.browseNum;
                json.buyNum += item.buyNum;
                totalArr[index] = json;
              }
            });
          });
          console.log(listData)
          let arr = [[], []];
          totalArr.forEach((item) => {
            if(item.name.length>10){
              item.name = item.name.substring(0,10)+'...'
            }
            xAxisData.push(item.name);
            arr[0].push(item.browseNum);
            arr[1].push(item.buyNum);
          });
          console.log(arr, xAxisData)
          this.myEcharts_line3.setOption({
            xAxis: {
              data: xAxisData,
            },
            series: [
              {
                name: "浏览量",
                data: arr[0],
              },
              {
                name: "下单量",
                data: arr[1],
                type: "bar",
              },
            ],
          });
        });
    },
    // 单个产品总订单数及总销售额
    getOneGoodsTotal() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsStatistics", {
          params: {
            startTime:
              _this.goodsTotalTime && _this.goodsTotalTime.length > 0
                ? _this.goodsTotalTime[0]
                : "",
            endTime:
              _this.goodsTotalTime && _this.goodsTotalTime.length > 0
                ? _this.goodsTotalTime[1]
                : "",
            type: 2,
            goodsId: _this.oneGoodsId,
            warehouseId: _this.warehouseId.join(','),
          },
        })
        .then((res) => {
          let list = res.data.data;
          var listData = [];
          for(var key in list){
            
            if(_this.warehouseName == key){
              listData = list[key]
            }
          }
          const xAxisData = [];
          const seriesData = [[], []];
          let newArr = [];
          listData.forEach((item) => {
            newArr.push(item.goodsId);
            seriesData[0].push(item.totalAmount);
            seriesData[1].push(item.orderNum);
          });
          newArr = [...new Set(newArr)];
          let totalArr = [];
          newArr.forEach((child, index) => {
            var json = {
              goodsId: child,
              name: "",
              totalAmount: 0,
              orderNum: 0,
            };
            listData.forEach((item) => {
              if (child == item.goodsId) {
                json.name = item.name;
                json.totalAmount += item.totalAmount;
                json.orderNum += item.orderNum;
                totalArr[index] = json;
              }
            });
          });
          let arr = [[], []];
          totalArr.forEach((item) => {
            if(item.name.length>10){
              item.name = item.name.substring(0,10)+'...'
            }
            xAxisData.push(item.name);
            arr[0].push(item.totalAmount);
            arr[1].push(item.orderNum);
          });
          this.myEcharts_line4.setOption({
            xAxis: {
              data: xAxisData,
            },
            series: [
              {
                name: "总销售额（元）",
                data: arr[0],
              },
              {
                name: "总订单数量",
                data: arr[1],
                type: "bar",
              },
            ],
          });
        });
    },
    // 销售总额时间段
    saleTimeChange(e) {
      this.getSalePriceStatistics();
    },
    goodsGrossChange() {
      this.getGoodsStatistics();
    },
    // 获取商品下拉列表
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId: warehouseId || '',
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.selectGoods = res.data.data;
            _this.oneGoodsId = res.data.data.goodsId;
          }
        });
    },
    // 商品下拉选s
    changeGoodsEvents(e) {
      this.getGoodsStatistics();
    },
    goodsTotalChange(e) {
      this.getGoodsTotal();
    },
    // 单个产品总销售额查询商品
    changeOneGoodsEvents(e) {
      this.getOneGoodsTotal();
    },
    // 查询产品销售排行
    async getGoodsRinking() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/salesCntNumByGoods",
        {
          params: {
            startTime:
              _this.goodsRinkingTime && _this.goodsRinkingTime.length > 0
                ? _this.goodsRinkingTime[0]
                : "",
            endTime:
              _this.goodsRinkingTime && _this.goodsRinkingTime.length > 0
                ? _this.goodsRinkingTime[1]
                : "",
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      this.goodsRinkingData = res.data.data;
      console.log(res);
    },
    goodsRinkingChange(e) {
      this.getGoodsRinking();
    },
    goodsSaleChange(e) {
      this.getGoodsSaleTotal();
    },
    // 周期内上架产品及销售额
    getGoodsSaleTotal() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsStatistics/goodsSaleListAll", {
          params: {
            startTime:
              _this.goodsSaleTime && _this.goodsSaleTime.length > 0
                ? _this.goodsSaleTime[0]
                : "",
            endTime:
              _this.goodsSaleTime && _this.goodsSaleTime.length > 0
                ? _this.goodsSaleTime[1]
                : "",
            warehouseId: _this.warehouseId.join(','),
          },
        })
        .then((res) => {
          console.log(res);
          let list = res.data.data;
          let listData = [];
          for(var key in list){
            if(_this.warehouseName == key){
              listData = list[key]
            }
          }
          const xAxisData = [];
          const seriesData = [];
          let newArr = [];
          listData.forEach((item) => {
            if(item.title.length>10){
              item.title = item.title.substring(0,10)+'...'
            }
            xAxisData.push(item.title);
            seriesData.push(item.salePrice);
          });

          this.myEcharts_bar2.setOption({
            xAxis: {
              data: xAxisData,
            },
            series: [
              {
                name: "销售额",
                data: seriesData,
              },
            ],
          });
        });
    },
    // 获取地区团长总数 admin查看全部地区 用户查看本地区
    async getTotal() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/userSum",
        {
          params: {
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      this.adcodeList = [];
      console.log(res)
      res.data.data.forEach((item) => {
        var areaName = CodeToText[item.adcode];
        var json = {
          name: areaName,
          num: item.num,
        };
        this.adcodeList.push(json);
      });
      console.log(this.adcodeList);
    },
    // 查询团长销售排行
    async getGoodsDayStatistics() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/rankingList",
        {
          params: {
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      this.userSortData = res.data.data;
      console.log(res);
    },
    // 查询团长活跃情况统计
    async getUserNum() {
      let _this = this;
      const res = await this.$axios.get(
        this.$axios.defaults.basePath + "/goodsStatistics/userActiveSituation",
        {
          params: {
            startTime:
              _this.userTotalTime && _this.userTotalTime.length > 0
                ? _this.userTotalTime[0]
                : "",
            endTime:
              _this.userTotalTime && _this.userTotalTime.length > 0
                ? _this.userTotalTime[1]
                : "",
            warehouseId: _this.warehouseId.join(','),
          },
        }
      );
      console.log(res);
      const xAxisData = [];
      const seriesData = [];
      this.allnum = res.data.data.allnum;
      this.UserNumData = res.data.data.list;
      res.data.data.list.forEach((item) => {
        xAxisData.push(item.dateStr);
        seriesData.push(item.num);
      });

      this.myEcharts_line2.setOption({
        title: {
          subtext: "总下单次数：" + this.allnum,
          subtextStyle: {
            fontSize: 14,
          },
        },
        xAxis: {
          data: xAxisData,
        },
        series: [
          {
            data: seriesData,
          },
        ],
      });
    },
    // 查看团长明细
    lookSaleInfo(row) {
      this.searchForm.userid = row.userid;
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.detailShow = true;
    },
    //单个团长的详细数据-售后信息
    getUserReturnGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData2.currentPage;
      params.size = _this.pagesData2.currentRows;
      params.warehouseId = _this.warehouseId.join(',');
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsStatistics/userReturnGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.orderList = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单个团长的详细数据-商品列表
    getUserGoodsPage() {
      let _this = this;
      let params = this.searchForm;
      params.current = _this.pagesData.currentPage;
      params.size = _this.pagesData.currentRows;
      params.warehouseId = _this.warehouseId.join(',');
      this.$axios
        .get(
          _this.$axios.defaults.basePath + "/goodsStatistics/userGoodsPage",
          {
            params,
          }
        )
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsList = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单个团长的详细数据-下单次数/下单金额
    listEvent() {
      let _this = this;
      this.$set(this.searchForm,'warehouseId',this.warehouseId.join(','))
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsStatistics/userDetail", {
          params: _this.searchForm,
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.getUserGoodsPage();
            _this.getUserReturnGoodsPage();
            _this.priceNum = res.data.data;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 团长活跃度时间统计
    userNumChange(e) {
      this.getUserNum();
    },
    changeWarehouse(e) {
      console.log(e)
      this.warehouseId = e
      
      this.warehouseList.forEach(item=>{
        if(item.id == e){
          this.warehouseName = item.name
        }
      });
      this.getSelectGoods(e);
      this.initData();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.getUserGoodsPage();
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      this.getUserReturnGoodsPage();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.getUserGoodsPage();
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      this.getUserReturnGoodsPage();
    },

    // 导出事件
    exportEvent(type){
      if(type == 1){ // 产品销售排行榜 
        this.exportPath = '/export/exportGoodsRanking';
      }else if(type == 2){ // 周期内上架产品及销售额
        this.exportPath = '/export/exportGoodsSaleListAll';
      }else if(type == 3){ // 团长销售排行榜
        this.exportPath = '/export/rankingList';
      }
      this.exportType = type;
      this.$set(this.exportForm,'warehouseId',this.warehouseId);
      this.exportShow = true;
    },
    orderExport() {
      let _this = this;
      console.log(this.exportForm);
      _this.exportForm.warehouseId = _this.exportForm.warehouseId.join(',');
      this.$axios
        .get(this.$axios.defaults.basePath + this.exportPath, {
          params: _this.exportForm,
        })
        .then((res) => {
          console.log(res);
          var eleLink = document.createElement("a");
          eleLink.download = _this.exportType == 1?"产品销售排行.csv":_this.exportType == 2?"周期内上架产品.csv":_this.exportType == 3?"团长销售排行.csv":"";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          _this.exportForm = {};
            _this.exportCategoryId = [];
          // 然后移除
          document.body.removeChild(eleLink);
        });
    },
  },
};
</script>

<style scoped>
.homePage {
  margin-top: 56px;
  padding: 20px;
  box-sizing: border-box;
}
.home_banner {
  width: 100%;
  height: 336px;
  padding: 60px 70px 40px 70px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: url("../../assets/img/home_banner.png") no-repeat;
  background-size: 100% 100%;
}
.home_banner .home_title {
  color: #fff;
  font-size: 16px;
}
.home_title p:first-child {
  font-size: 30px;
  margin-bottom: 10px;
}
.home_total {
  margin-top: 0;
}
.home_total_title {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  margin-top: 15px;
}
.home_total ul li {
  float: left;
  min-width: 176px;
  padding: 25px;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  list-style: none;
  margin-right: 15px;
  background-color: rgba(243, 246, 253, 0.2);
}
.home_total ul li p:first-child + p {
  font-size: 30px;
  margin-top: 10px;
}
.home_drawTotal {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.home_drawTotal .total_title {
  font-size: 16px;
  margin-bottom: 10px;
}
.home_drawTotal .total_title .total_change span {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
}
.home_drawTotal .total_title .total_change span.active {
  color: #246eff;
}
.home_echarts {
  width: 100%;
}
.home_echarts > div {
  width: 50%;
  height: 600px;
  float: left;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div {
  border-top: 0;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div + div {
  border-right: 1px solid #e5e5e5;
}
.echarts_box {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.echarts_box >>> .el-date-editor {
  width: 380px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
.goodsSelect {
  position: absolute;
  top: 10px;
  right: 400px;
  z-index: 1000;
}
@media screen and (max-width: 1700px) {
  .goodsSelect {
    left: 24% !important;
  }
}
.echarts_box .echarts_item {
  width: 100%;
  height: 550px;
}

.weclone {
  color: #333;
  text-align: center;
  font-size: 50px;
  padding-top: 300px;
}
.pageMain .item {
  width: 100%;
  height: 180px;
  position: relative;
  cursor: pointer;
}
.pageMain .item .item_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: auto;
  height: 180px;
}
.pageMain .item .icon_zhuan {
  position: inherit;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  -webkit-animation: changeright 6s linear infinite;
}
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pageMain .item .item_main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.pageMain .item .item_main img {
  display: block;
  /*padding-left: 15px;*/
  margin: 0 auto 10px auto;
}
.home_sort {
  width: 100%;
  margin-top: 20px;
}
.table_box {
  width: 100%;
  height: 500px;
  float: left;
  padding: 20px 0;
  margin-left: 1.4%;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}
.table_box:first-child {
  margin-left: 0;
}
.table_box .sort_title {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-table {
  border: 1px solid #e3e3e3;
}

::v-deep .el-table thead {
  background: #e7efff;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-bg-gray,
::v-deep .el-table__row:hover {
  background: #f1f2f7 !important;
}
.nums {
  font-size: 20px;
  margin-left: 50px;
}
.nums span {
  font-size: 24px;
  font-weight: 600;
}
</style>
