<template>
  <div>
    <div id="print2">

      <!--配送单 -->
      <div>
        <div class="tlt" style="padding-top:20px">{{printOrderDatas.regionalName}}单据</div>
        <div v-for="(one,o) in printOrderDatas.multipleRoutes" :key="o">
          <div class="tlt">{{one.regionalName}}</div>
          <div v-for="(items,indexs) in one.multipleRoutes" :key="indexs" class="box padd">
            <div v-if="items.userOrderList.length > 0">
              <!-- 配送单 -->
              <div class="tlt">配送单{{ '（' + items.userOrderList[0].way + '）' }}</div>
              <div v-for="(item, index) in items.userOrderList" :key="index">
                <p class="userInfo">姓名:{{ item.userName || '' }}({{ item.wechat }})</p>
                <p class="userInfo">店铺名称:{{ item.shopName || '' }}</p>
                <p class="userInfo">手机号:{{ item.phone }}</p>
                <p class="userInfo" v-if="item.tell && item.tell != item.phone">备用电话:{{ item.tell }}</p>
                <p class="address">地址：{{ item.towns }}{{ item.address }} </p>
                <table v-if="item.saleChildOrderList.length">
                  <thead>
                    <tr>
                      <th>名称</th>
                      <th>规格</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>小计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(it, ind) in item.saleChildOrderList" :key="ind">
                      <td>{{ it.title }}</td>
                      <td style="max-width:60px;">{{ it.standard }}</td>
                      <td>{{ it.price }}</td>
                      <td>{{ it.num }}</td>
                      <td>{{ it.totalPrice }}</td>
                    </tr>
                    <tr style="height:80px">
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p class="totalPrice padd" v-if="item.saleChildOrderList.length">总计：<span style="font-size:20px">{{ item.totalPrice }}元</span></p>
                <p class="backTlt" v-if="item.backOrderList">退货商品</p>
                <table style="border-bottom: 2px dashed #000;" v-if="item.backOrderList">
                  <thead>
                    <tr>
                      <th>名称</th>
                      <th>规格</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>小计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(it, ind) in item.backOrderList" :key="ind">
                      <td>{{ it.title }}</td>
                      <td>{{ it.standard }}</td>
                      <td>{{ it.price }}</td>
                      <td>{{ it.num }}</td>
                      <td>{{ it.totalPrice }}</td>
                    </tr>
                    <tr style="height:40px">
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p class="orderTime padd">日期：{{ item.orderTime }}</p>
                <p class="xing">*****************************************<br>*****************************************</p>
              </div>
              <!-- 出库单 -->
              <div class="padd">
                <div class="tlt">
                  出库单<span v-if="items.userOrderList && items.userOrderList.length > 0">{{ '（' + items.userOrderList[0].way + '）' }}</span>
                  <p class="userNum" v-if="items.userOrderList && items.userOrderList.length > 0">客户数量：{{ items.userOrderList.length }}</p>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>名称</th>
                      <th>规格</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>小计</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(it, ind) in items.outboundOrderList" :key="ind">
                      <td>{{ it.title }}</td>
                      <td style="max-width:60px;">{{ it.standard }}</td>
                      <td>{{ it.price }}</td>
                      <td>{{ it.num }}</td>
                      <td>{{ it.totalPrice }}</td>
                    </tr>
                    <tr style="height:80px">
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p class="totalPrice padd">总计：<span style="font-size:20px">{{ items.totalPrice }}元</span></p>
                <p class="orderTime padd">日期：<span v-if="items.userOrderList && items.userOrderList.length > 0">{{ items.userOrderList[0].orderTime }}</span></p>
                <p class="xing" v-if="items.outboundOrderList&&items.outboundOrderList.length>0">*****************************************<br>*****************************************</p>
                <!-- 退货单 -->
                <div v-if="items.gatherOutboundOrderList && items.gatherOutboundOrderList.length > 0">
                  <div class="tlt">退货单<span>{{ '（' + items.userOrderList[0].way + '）' }}</span></div>
                  <table>
                    <thead>
                      <tr>
                        <th>名称</th>
                        <th>规格</th>
                        <th>单价</th>
                        <th>数量</th>
                        <th>小计</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(it, ind) in items.gatherOutboundOrderList" :key="ind">
                        <td>{{ it.title }}</td>
                        <td>{{ it.standard }}</td>
                        <td>{{ it.price }}</td>
                        <td>{{ it.num }}</td>
                        <td>{{ it.totalPrice }}</td>
                      </tr>
                      <tr style="height:40px">
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="orderTime padd">日期：<span>{{ items.userOrderList[0].orderTime }}</span></p>
                  <p class="xing">*****************************************<br>*****************************************</p>
                </div>

                <div class="padd">
                  <!-- 配送信息 -->
                  <table class="borderTable peiSong">
                    <tbody>
                      <tr>
                        <td>配送人</td>
                        <td></td>
                        <td>车牌号</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- 对账信息 -->
                  <table class="borderTable duiZhang">
                    <tbody>
                      <tr>
                        <td rowspan="3">对账</td>
                        <td>应收</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>实收</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>抹零</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- 物料携带 -->
                  <table class="borderTable wuLiao">
                    <tbody>
                      <tr>
                        <td rowspan="4">物料携带</td>
                        <td>黄框</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>白框</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>保温箱蓝色</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>保温箱红色</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="xing" v-if="one.multipleRoutes.length-1 != indexs">*****************************************<br>*****************************************</p>
              </div>
            </div>
          </div>
          <p class="xing">*****************************************<br>*****************************************</p>
          <!-- 二级总出库单 -->
          <div v-if="one.outboundOrderList&&one.outboundOrderList.length>0">
            <div class="tlt">总出库单
              <span>（{{one.regionalName}}）</span>
              <!-- <p class="userNum" v-if="printOrderData.multipleRoutes && printOrderData.multipleRoutes.length > 0"> 客户数量：{{printOrderData.userOrderList.length}}</p> -->
            </div>
            <table>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>规格</th>
                  <th>单价</th>
                  <th>数量</th>
                  <th>小计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(it,ind) in one.outboundOrderList" :key="ind">
                  <td>{{it.title}}</td>
                  <td style="max-width:60px;">{{it.standard}}</td>
                  <td>{{it.price}}</td>
                  <td>{{it.num}}</td>
                  <td>{{it.totalPrice}}</td>
                </tr>
                <tr style="height:80px">
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p class="totalPrice padd">总计：<span style="font-size:20px">{{ one.totalPrice}}元</span></p>
            <p class="orderTime padd">日期：<span>{{ one.multipleRoutes[0].userOrderList[0].orderTime}}</span></p>
            <p class="xing" v-if="one.gatherOutboundOrderList.length>0">*****************************************<br>*****************************************</p>
          </div>
          <!-- 二级总退货单 -->
          <div v-if="one.gatherOutboundOrderList&&one.gatherOutboundOrderList.length>0">
            <div class="tlt">总退货单<span>（{{one.regionalName}}）</span></div>
            <table>
              <thead>
                <tr>
                  <th>名称</th>
                  <th>规格</th>
                  <th>单价</th>
                  <th>数量</th>
                  <th>小计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(it,ind) in one.gatherOutboundOrderList" :key="ind">
                  <td>{{it.title}}</td>
                  <td>{{it.standard}}</td>
                  <td>{{it.price}}</td>
                  <td>{{it.num}}</td>
                  <td>{{it.totalPrice}}</td>
                </tr>
                <tr style="height:40px">
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p class="orderTime padd">日期：<span>{{ one.multipleRoutes[0].userOrderList[0].orderTime}}</span></p>
          </div>
          <p class="xing">*****************************************<br>*****************************************<br>*****************************************</p>
        </div>

      </div>

      <div class="padd">
        <!-- 总出库单 -->
        <div v-if="printOrderDatas.outboundOrderList&&printOrderDatas.outboundOrderList.length>0">
          <div class="tlt">出库单
            <span>（路线总计）</span>
            <!-- <p class="userNum" v-if="printOrderData.multipleRoutes && printOrderData.multipleRoutes.length > 0"> 客户数量：{{printOrderData.userOrderList.length}}</p> -->
          </div>
          <table>
            <thead>
              <tr>
                <th>名称</th>
                <th>规格</th>
                <th>单价</th>
                <th>数量</th>
                <th>小计</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it,ind) in printOrderDatas.outboundOrderList" :key="ind">
                <td>{{it.title}}</td>
                <td style="max-width:60px;">{{it.standard}}</td>
                <td>{{it.price}}</td>
                <td>{{it.num}}</td>
                <td>{{it.totalPrice}}</td>
              </tr>
              <tr style="height:80px">
                <td></td>
              </tr>
            </tbody>
          </table>
          <p class="totalPrice padd">总计：<span style="font-size:20px">{{ printOrderDatas.totalPrice}}元</span></p>
          <p class="orderTime padd">日期：<span>{{ printOrderDatas.multipleRoutes[0].multipleRoutes[0].userOrderList[0].orderTime}}</span></p>
          <p class="xing">*****************************************<br>*****************************************</p>
        </div>
        <!-- 总出库单 -->
        <div v-if="printOrderDatas.outboundOrderList&&printOrderDatas.outboundOrderList.length>0">
          <div class="tlt">出库单
            <span>（路线总计）</span>
            <!-- <p class="userNum" v-if="printOrderData.multipleRoutes && printOrderData.multipleRoutes.length > 0"> 客户数量：{{printOrderData.userOrderList.length}}</p> -->
          </div>
          <table>
            <thead>
              <tr>
                <th>名称</th>
                <th>规格</th>
                <th>单价</th>
                <th>数量</th>
                <th>小计</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it,ind) in printOrderDatas.outboundOrderList" :key="ind">
                <td>{{it.title}}</td>
                <td style="max-width:60px;">{{it.standard}}</td>
                <td>{{it.price}}</td>
                <td>{{it.num}}</td>
                <td>{{it.totalPrice}}</td>
              </tr>
              <tr style="height:80px">
                <td></td>
              </tr>
            </tbody>
          </table>
          <p class="totalPrice padd">总计：<span style="font-size:20px">{{ printOrderDatas.totalPrice}}元</span></p>
          <p class="orderTime padd">日期：<span>{{ printOrderDatas.multipleRoutes[0].multipleRoutes[0].userOrderList[0].orderTime}}</span></p>
          <p class="xing">*****************************************<br>*****************************************</p>
        </div>
        <!-- 总退货单 -->
        <div v-if="printOrderDatas.gatherOutboundOrderList&&printOrderDatas.gatherOutboundOrderList.length>0">
          <div class="tlt">退货单<span>（路线总计）</span></div>
          <table>
            <thead>
              <tr>
                <th>名称</th>
                <th>规格</th>
                <th>单价</th>
                <th>数量</th>
                <th>小计</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(it,ind) in printOrderDatas.gatherOutboundOrderList" :key="ind">
                <td>{{it.title}}</td>
                <td>{{it.standard}}</td>
                <td>{{it.price}}</td>
                <td>{{it.num}}</td>
                <td>{{it.totalPrice}}</td>
              </tr>
              <tr style="height:40px">
                <td></td>
              </tr>
            </tbody>
          </table>
          <p class="orderTime padd">日期：<span>{{ printOrderDatas.multipleRoutes[0].multipleRoutes[0].userOrderList[0].orderTime}}</span></p>
        </div>
      </div>
      <!-- <div class="kong">
      </div> -->
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
    <button id="printButtonRef2" style="display:none" v-print="printObj1">
      打印票单
    </button>
  </div>

</template>

<script>

export default {
  data() {
    return {
      printObj1: { // 打印配置对象
        id: 'print2',
        popTitle: '&nbsp;',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        previewBeforeOpenCallback() { console.log('正在加载预览窗口！'); console.log(that.msg, this) }, // 预览窗口打开之前的callback
        previewOpenCallback() { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
        beforeOpenCallback() { console.log('开始打印之前！') }, // 开始打印之前的callback
        openCallback() { console.log('执行打印了！') }, // 调用打印时的callback
        closeCallback() { console.log('关闭了打印工具！') }, // 关闭打印的callback(无法区分确认or取消)
        clickMounted() { console.log('点击v-print绑定的按钮了！') },
      },
      // 打印数据 以此为准
      printOrderDatas: {
        multipleRoutes: []
      },
    }
  },
  props: {
    printOrderData: Object
  },
  watch: {
    printOrderData: {
      handler(n) {

        this.printOrderDatas = this.printOrderData
        console.log('区域打印', this.printOrderDatas)
        if (n.multipleRoutes.length) {
          // 规避multipleRoutes下的userOrderList为空的情况 页面数据渲染用新增的'printOrderDatas'字段

          for (let i in this.printOrderData.multipleRoutes) {
            let multipleRoutes = []
            for (let j in this.printOrderData.multipleRoutes[i].multipleRoutes) {
              if (this.printOrderData.multipleRoutes[i].multipleRoutes[j].userOrderList.length > 0) {
                multipleRoutes.push(this.printOrderData.multipleRoutes[i].multipleRoutes[j])
              }
            }
            this.printOrderData.multipleRoutes[i].multipleRoutes = multipleRoutes
          }
          // this.printOrderData.multipleRoutes.forEach(item => {
          //   if (item.userOrderList.length > 0) {
          //     multipleRoutes.push(item)
          //   }
          // })
          // this.$set(this.printOrderDatas, 'multipleRoutes', multipleRoutes)
        }

        this.$nextTick(() => {
          document.getElementById('printButtonRef2').click()
        })
      }
    }
  },
  mounted() {

  },
  methods: {
    previewOpenCallback(e) {
      console.log(e, "打印")
    }
  }
}
</script>

<style scoped>
.backTlt {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}
#print {
  width: 100%;
  padding: 10px 0 300px 0;
  box-sizing: border-box;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
table {
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
.padd {
  padding: 0 10px 0 0;
  box-sizing: border-box;
}
.tlt {
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  margin-bottom: 30px;
  font-family: "仿宋";
  position: relative;
}
.tlt .userNum {
  position: absolute;
  font-size: 12px;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding-right: 5px;
}
.xing {
  width: 100%;
  margin: 30px 0;
  text-align: center;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}

.kong {
  width: 100%;
  height: 300px;
  background: #fff;
}
.totalPrice {
  margin-top: 20px;
  text-align: right;
  border-bottom: 2px dashed #000;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
.orderTime {
  text-align: right;
  margin-top: 10px;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
.userInfo {
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
.address {
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px dashed #000;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
.userInfo span {
  width: 100%;
}
table {
  width: 100%;
  font-size: 18px !important;
  font-weight: bold;
  font-family: "仿宋";
}
table th {
  width: 20%;
  text-align: center;
  /* font-size: 10px; */
}
table tr {
  max-width: 20%;
  margin: 35px 0;
  text-align: center;
  /* overflow: hidden; */
}
.borderTable {
  /* border: 1px solid #000; */
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.borderTable tr td {
  border: 1px solid #000;
}
.peiSong tbody tr td:nth-of-type(2) {
  width: 70px;
}
.peiSong tbody tr td:nth-of-type(4) {
  width: 70px;
}
.duiZhang tbody tr:nth-of-type(1) td:nth-of-type(1) {
  width: 70px;
}
.duiZhang tbody tr:nth-of-type(1) td:nth-of-type(2) {
  width: 70px;
}
.wuLiao tbody tr:nth-of-type(1) td:nth-of-type(1) {
  width: 75px;
}
.wuLiao tbody tr:nth-of-type(1) td:nth-of-type(2) {
  width: 100px;
}
/* .tableTlt{
  width: 100%;
  display: flex;
}
.tableTlt p{
  width: 20%;
  text-align: center;
}
.tableInfo{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.tableInfo p{
  width: 20%;
  text-align: center;
}
.tableInfo p:nth-of-type(1){
  width: 100%;
  text-align: left;
}
.tableInfo p:nth-of-type(2){
  padding-left: 20%;
} */
</style>