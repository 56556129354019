import Vue from 'vue'
import Router from 'vue-router'
import login from "@/views/login";
import home from "@/views/home";
import menu_list from "@/views/system/menu";
import menuForm from "@/views/system/menuForm";
import privilege_list from "@/views/system/privilege";
import role_list from "@/views/system/role";
import sysuser_list from "@/views/system/sysUser";
import operate_log from "@/views/system/operate_log";
import dictionaries from "@/views/system/dictionaries";
import dictionariesType from "@/views/system/dictionariesType";
import department from "@/views/system/department";
import swiperList from "@/views/swiperList";
import goodsIndex from "@/views/goods/goods_index";
import goodsAdd from "@/views/goods/goods_add";
import goodsType from "@/views/goods/goods_type";
import goodsUnit from "@/views/goods/goods_unit";
import salesGoods from "@/views/goods/sales_goods";
import salesGoodsAdd from "@/views/goods/salesGoods_add";
import salesChildGoodsAdd from "@/views/goods/salesChildGoods_add";
import integralGoods from "@/views/integralGoods";
import integralAccount from "@/views/integralAccount";
import integralOrder from "@/views/order/integralOrder";
import saleOrder from "@/views/order/saleOrder";
import refundOrder from "@/views/order/refundOrder";
// import warehouse from "@/views/warehouse";
import warehouse from "@/views/warehouse/warehouse";
import warehouseStockOut from "@/views/warehouse/warehouse_stock";
import warehouseStockIn from "@/views/warehouse/warehouse_stock";
import user from "@/views/user";
import dataTotalGoods from "@/views/dataTotal/dataTotal_goods";
import dataTotalUser from "@/views/dataTotal/dataTotal_user";
import dataTotalWarehouse from "@/views/dataTotal/dataTotal_warehouse";
import dataTotalAll from "@/views/dataTotal/dataTotal_all";
import wayList from "@/views/way_list";
import driver from "@/views/driver";
import warehouseAdd from "@/views/warehouse/warehouse_add";
import clearWarehouse from "@/views/clearWarehouse";
import checkout from "@/views/checkout";

Vue.use(Router)

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
};


const router = new Router({
    mode: 'hash',
    routes: [{
        path: '/',
        name: 'login',
        component: login,
        meta: {
            keepAlive: false,
            flag: true,
            title: '团购后台管理系统'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            keepAlive: false,
            flag: true,
            title: '团购后台管理系统'
        }
    }, {
        path: '/home',
        name: 'home',
        component: home,
        meta: {
            keepAlive: true,
            title: '首页'
        }
    },
    {
        path: '/system/menu',
        name: 'menu_list',
        component: menu_list,
        meta: {
            keepAlive: false,
            title: '菜单管理'
        }
    }, {
        path: '/system/menuForm',
        name: 'menuForm',
        component: menuForm,
        meta: {
            keepAlive: false,
            title: '菜单新增'
        }
    },
    {
        path: '/system/privilege',
        name: 'privilege_list',
        component: privilege_list,
        meta: {
            keepAlive: false,
            title: '权限管理'
        }
    },
    {
        path: '/system/role',
        name: 'role_list',
        component: role_list,
        meta: {
            keepAlive: false,
            title: '角色管理'
        }
    }, {
        path: '/system/operate_log',
        name: 'operate_log',
        component: operate_log,
        meta: {
            keepAlive: false,
            title: '操作日志'
        }
    }, {
        path: '/system/sysUser',
        name: 'sysuser_list',
        component: sysuser_list,
        meta: {
            keepAlive: false,
            title: '用户管理'
        }
    }, {
        path: '/system/dictionaries',
        name: 'dictionaries',
        component: dictionaries,
        meta: {
            keepAlive: false,
            title: '系统字典管理'
        }
    }, {
        path: '/system/dictionariesType',
        name: 'dictionariesType',
        component: dictionariesType,
        meta: {
            keepAlive: false,
            title: '系统字典类型管理'
        }
    }, {
        path: '/system/department',
        name: 'department',
        component: department,
        meta: {
            keepAlive: false,
            title: '部门管理'
        }
    }, {
        path: '/swiperList',
        name: 'swiperList',
        component: swiperList,
        meta: {
            keepAlive: false,
            title: '轮播图管理'
        }
    }, {
        path: '/goods/goods_index',
        name: 'goodsIndex',
        component: goodsIndex,
        meta: {
            keepAlive: true,
            title: '产品列表'
        }
    }, {
        path: '/goods/goods_add',
        name: 'goodsAdd',
        component: goodsAdd,
        meta: {
            keepAlive: true,
            title: '产品新增'
        }
    }, {
        path: '/goods/goods_type',
        name: 'goodsType',
        component: goodsType,
        meta: {
            keepAlive: true,
            title: '产品类型列表'
        }
    }, {
        path: '/goods/goods_unit',
        name: 'goodsUnit',
        component: goodsUnit,
        meta: {
            keepAlive: true,
            title: '产品单位列表'
        }
    }, {
        path: '/goods/sales_goods',
        name: 'salesGoods',
        component: salesGoods,
        meta: {
            keepAlive: true,
            title: '销售产品列表'
        }
    }, {
        path: '/goods/salesGoods_add',
        name: 'salesGoodsAdd',
        component: salesGoodsAdd,
        meta: {
            keepAlive: true,
            title: '销售产品新增'
        }
    }, {
        path: '/goods/salesChildGoods_add',
        name: 'salesChildGoodsAdd',
        component: salesChildGoodsAdd,
        meta: {
            keepAlive: false,
            title: '销售子产品新增'
        }
    }, {
        path: '/integralGoods',
        name: 'integralGoods',
        component: integralGoods,
        meta: {
            keepAlive: false,
            title: '积分商城'
        }
    }, {
        path: '/integralAccount',
        name: 'integralAccount',
        component: integralAccount,
        meta: {
            keepAlive: false,
            title: '积分账户'
        }
    }, {
        path: '/order/integralOrder',
        name: 'integralOrder',
        component: integralOrder,
        meta: {
            keepAlive: false,
            title: '积分订单管理'
        }
    }, {
        path: '/order/saleOrder',
        name: 'saleOrder',
        component: saleOrder,
        meta: {
            keepAlive: true,
            title: '销售单管理'
        }
    }, {
        path: '/order/refundOrder',
        name: 'refundOrder',
        component: refundOrder,
        meta: {
            keepAlive: true,
            title: '售后管理'
        }
    }, {
        path: '/warehouse',
        name: 'warehouse',
        component: warehouse,
        meta: {
            keepAlive: true,
            title: '仓库管理'
        }
    }, {
        path: '/warehouse_stock',
        name: 'warehouseStockIn',
        component: warehouseStockIn,
        meta: {
            keepAlive: true,
            title: '入库管理'
        }
    }, {
        path: '/warehouse_stock',
        name: 'warehouseStockOut',
        component: warehouseStockOut,
        meta: {
            keepAlive: true,
            title: '出库管理'
        }
    }, {
        path: '/user',
        name: 'user',
        component: user,
        meta: {
            keepAlive: true,
            title: '客户管理'
        }
    },
    {
        path: '/dataTotal/dataTotal_goods',
        name: 'dataTotalGoods',
        component: dataTotalGoods,
        meta: {
            keepAlive: false,
            title: '产品数据分析'
        }
    }, {
        path: '/dataTotal/dataTotal_user',
        name: 'dataTotalUser',
        component: dataTotalUser,
        meta: {
            keepAlive: false,
            title: '团长数据分析'
        }
    }, {
        path: '/dataTotal/dataTotal_warehouse',
        name: 'dataTotalWarehouse',
        component: dataTotalWarehouse,
        meta: {
            keepAlive: false,
            title: '各仓数据分析'
        }
    },
    {
        path: '/dataTotal/dataTotal_all',
        name: 'dataTotalAll',
        component: dataTotalAll,
        meta: {
            keepAlive: false,
            title: '总数据分析'
        }
    }, {
        path: '/dataStatistics',
        name: 'dataStatistics',
        component: () => import( /* webpackChunkName: "active_index" */ '@/views/dataTotal/dataStatistics.vue'),
        meta: {
            keepAlive: false,
            title: '数据统计'
        }
    }, {
        path: '/dataStatisticsRegion',
        name: 'dataStatisticsRegion',
        component: () => import( /* webpackChunkName: "active_index" */ '@/views/dataTotal/dataStatisticsRegion.vue'),
        meta: {
            keepAlive: false,
            title: '区域数据统计'
        }
    }, {
        path: '/active/active_index',
        name: 'activeIndex',
        component: () => import( /* webpackChunkName: "active_index" */ '@/views/active/active_index.vue'),
        meta: {
            keepAlive: false,
            title: '活动管理'
        }
    }, {
        path: '/order/activeOrder',
        name: 'activeOrder',
        component: () => import( /* webpackChunkName: "activeOrder" */ '@/views/order/activeOrder.vue'),
        meta: {
            keepAlive: false,
            title: '活动订单'
        }
    }, {
        path: '/goods/goods_gift',
        name: 'goodsGift',
        component: () => import('@/views/goods/goods_gift.vue'),
        meta: {
            keepAlive: false,
            title: '赠品库'
        }
    }, {
        path: '/dataTotal/dataTotal_activity',
        name: 'dataTotal_activity',
        component: () => import('@/views/dataTotal/dataTotal_activity.vue'),
        meta: {
            keepAlive: false,
            title: '活动数据分析'
        }
    }, {
        path: '/regionalRoute',
        name: 'regionalRoute',
        component: () => import('@/views/regionalRoute.vue'),
        meta: {
            keepAlive: false,
            title: '区域路线管理'
        }
    },
    {
        path: '/way',
        name: 'wayList',
        component: wayList,
        meta: {
            keepAlive: true,
            title: '路线列表'
        }
    }, {
        path: '/driver',
        name: 'driver',
        component: driver,
        meta: {
            keepAlive: true,
            title: '司机管理'
        }
    }, {
        path: '/purchase',
        name: 'Purchase',
        component: () => import( /* webpackChunkName: "Purchase" */ '../views/Purchase.vue'),
        meta: {
            keepAlive: true,
            title: '采购单列表'
        }
    }, {
        path: '/purchase_editNum',
        name: 'purchase_editNum',
        component: () => import( /* webpackChunkName: "Purchase" */ '../views/purchase_editNum.vue'),
        meta: {
            keepAlive: true,
            title: '修改采购数量'
        }
    },
    {
        path: '/purchaseForm',
        name: 'purchaseForm',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/purchaseForm.vue'),
        meta: {
            keepAlive: true,
            title: '采购单新增'
        }
    },
    {
        path: '/supplier',
        name: 'supplier',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/supplier.vue'),
        meta: {
            keepAlive: true,
            title: '供应商管理'
        }
    }, {
        path: '/supplierForm',
        name: 'supplierForm',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/supplierForm.vue'),
        meta: {
            keepAlive: false,
            title: '供应商新增'
        }
    },
    {
        path: '/warehouse/warehouse_add',
        name: 'warehouseAdd',
        component: warehouseAdd,
        meta: {
            keepAlive: true,
            title: '仓库新增'
        }
    },
    {
        path: '/saleOrder_add',
        name: 'saleOrder_add',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/order/saleOrder_add.vue'),
        meta: {
            keepAlive: true,
            title: '销售订单新增'
        }
    }, {
        path: '/purchaseOrderDetail',
        name: 'purchaseOrderDetail',
        component: () => import('../views/purchaseOrderDetail.vue'),
        meta: {
            keepAlive: true,
            title: '采购单明细'
        }
    },
    {
        path: '/supplier',
        name: 'supplier',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/supplier.vue'),
        meta: {
            keepAlive: false,
            title: '供应商管理'
        }
    }, {
        path: '/warehouseForm',
        name: 'warehouseForm',
        component: () => import('../views/warehouseForm.vue'),
        meta: {
            keepAlive: false,
            title: '仓库新增'
        }
    }, {
        path: '/userForm',
        name: 'userForm',
        component: () => import('../views/userForm.vue'),
        meta: {
            keepAlive: false,
            title: '客户'
        }
    }, {
        path: '/purchaseOrderSummary',
        name: 'purchaseOrderSummary',
        component: () => import('../views/purchaseOrderSummary.vue'),
        meta: {
            keepAlive: true,
            title: '采购单汇总'
        }
    }, {
        path: '/warehousingReturnList',
        name: 'warehousingReturnList',
        component: () => import('../views/warehousingReturnList.vue'),
        meta: {
            keepAlive: true,
            title: '采购退货单列表'
        }
    },
    {
        path: '/salesDeliveryOrder',
        name: 'salesDeliveryOrder',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/order/salesDeliveryOrder.vue'),
        meta: {
            keepAlive: true,
            title: '销售出库单列表'
        }
    },
    {
        path: '/salesDelivery',
        name: 'salesDelivery',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/order/salesDelivery.vue'),
        meta: {
            keepAlive: true,
            title: '销售出库单'
        }
    },
    {
        path: '/salesDetail',
        name: 'salesDetail',
        component: () => import( /* webpackChunkName: "supplier" */ '../views/order/salesDetail.vue'),
        meta: {
            keepAlive: false,
            title: '销售明细表'
        }
    },
    {
        path: '/salesSummary',
        name: 'salesSummary',
        component: () => import('../views/order/salesSummary.vue'),
        meta: {
            keepAlive: true,
            title: '销售汇总表'
        }
    },
    {
        path: '/salesReturnOrder',
        name: 'salesReturnOrder',
        component: () => import('../views/order/salesReturnOrder.vue'),
        meta: {
            keepAlive: false,
            title: '销售退货单列表'
        }
    },
    {
        path: '/salesReturn',
        name: 'salesReturn',
        component: () => import('../views/order/salesReturn.vue'),
        meta: {
            keepAlive: false,
            title: '销售退货单'
        }
    },
    {
        path: '/stockBalance',
        name: 'stockBalance',
        component: () => import('../views/stock/stockBalance.vue'),
        meta: {
            keepAlive: true,
            title: '商品库存余额表'
        }
    },
    {
        path: '/receiveDetailed',
        name: 'receiveDetailed',
        component: () => import('../views/stock/receiveDetailed.vue'),
        meta: {
            keepAlive: true,
            title: '商品收发明细表'
        }
    },
    {
        path: '/receiveSummary',
        name: 'receiveSummary',
        component: () => import('../views/stock/receiveSummary.vue'),
        meta: {
            keepAlive: true,
            title: '商品收发汇总表'
        }
    }, {
        path: '/purchaseReturnForm',
        name: 'purchaseReturnForm',
        component: () => import('../views/purchaseReturnForm.vue'),
        meta: {
            keepAlive: false,
            title: '采购退货单'
        }
    }, {
        path: '/checkout',
        name: 'checkout',
        component: () => import('../views/checkout.vue'),
        meta: {
            keepAlive: false,
            title: '结账/反结账'
        }
    },
    // costChange
    {
        path: '/clearWarehouse',
        name: 'clearWarehouse',
        component: clearWarehouse,
        meta: {
            keepAlive: false,
            title: '清库管理'
        }
    }, {
        path: '/warehouse/costChange',
        name: 'costChange',
        component: () => import('../views/warehouse/costChange.vue'),
        meta: {
            keepAlive: true,
            title: '成本调整单列表'
        }
    },
    {
        path: '/warehouse/costChange_add',
        name: 'costChangeAdd',
        component: () => import('../views/warehouse/costChange_add.vue'),
        meta: {
            keepAlive: true,
            title: '成本调整单新增'
        }
    },

    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        let token = sessionStorage.getItem('Authorization');
        if (!token) {
            next('/login');
        } else {
            next();
        }
    }
});

export default router;