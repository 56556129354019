<template>
  <div class="pageMain">
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      class="demo-form-inline"
      v-if="searchButton == '1'"
    >
      <el-form-item label="类型：">
        <el-select v-model="searchForm.type" placeholder="请选择类型">
          <el-option
            v-for="(item, index) in type"
            :key="index"
            :label="item.typeName"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字典名称">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入字典名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchSubmit" icon="el-icon-search"
          >搜索</el-button
        >
        <el-button @click="resetForm('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="boxMain">
      <p class="boxTitle">系统字典列表</p>
      <div class="tableTopBtn clearfix" style="padding: 15px">
        <el-button
          size="mini"
          type="primary"
          @click="handleAdd()"
          v-if="addButton == '1'"
          class="el-icon-plus"
        >
          添加</el-button
        >
        <el-button
          size="mini"
          type="danger"
          @click="handleDel()"
          :disabled="ids.length == 0"
          class="el-icon-delete"
          v-if="delButton == '1'"
          >批量删除</el-button
        >
      </div>
      <template>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="code" label="编码"> </el-table-column>
          <!-- <el-table-column prop="type" label="类型">
          </el-table-column> -->
          <el-table-column prop="typeName" label="类型名称"> </el-table-column>
          <el-table-column align="center" width="280" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="handleEdit(scope.row)"
                v-if="updateButton == '1'"
                class="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row.id)"
                class="el-icon-delete"
                v-if="delButton == '1'"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>

      <div class="block" style="padding: 10px 15px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagesData.current"
          :page-sizes="pagesData.rows"
          :page-size="pagesData.currentRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagesData.total"
        >
        </el-pagination>
      </div>
    </div>
    <!--新增字典-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" width="60%">
      <div class="postForm">
        <el-form
          :model="formData"
          :inline="true"
          :rules="addRules"
          ref="formData"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model="formData.name"
              style="width: 300px"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="code">
            <el-input
              v-model="formData.code"
              placeholder="请输入编码"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            <el-select
              v-model="formData.type"
              placeholder="请选择类型"
              style="width: 300px"
              @change="changeType($event)"
            >
              <el-option
                v-for="(item, index) in type"
                :key="index"
                :label="item.typeName"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="postBtn"
            style="display: block; text-align: center"
          >
            <el-button
              type="primary"
              :loading="submitBtnLoad"
              @click="handleSubmit('formData')"
              >提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitBtnLoad: false,
      isCollapse: false,
      addShow: false,
      dialogTitle: "",
      loading: true,
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        type: "",
        name: "",
      },
      type: [],
      id: "",
      formData: {
        name: "",
        code: "",
        type: "",
        typeName: "",
      },
      address: [],
      formLabelWidth: "100px",
      detailsShow: false,
      detailsData: [],
      multipleSelection: [],
      ids: [],
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      addRules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入编码",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {
    this.listEvent();
    this.getType();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "sys_dictionaries_update") {
        this.updateButton = "1";
      } else if (item.authority == "sys_dictionaries_query") {
        this.searchButton = "1";
      } else if (item.authority == "sys_dictionaries_create") {
        this.addButton = "1";
      } else if (item.authority == "sys_dictionaries_delete") {
        this.delButton = "1";
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },

  methods: {
    changeType(e) {
      this.type.forEach((item) => {
        if (item.type == e) {
          this.formData.typeName = item.typeName;
        }
      });
    },
    getType() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictType/all", {
          params: {},
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.type = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            current: "1",
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },

    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.pagesEvent(_this.pagesData.currentPage, val);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.pagesEvent(val, _this.pagesData.currentRows);
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            type: _this.searchForm.type,
            name: _this.searchForm.name,
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        });
    },

    //搜索操作
    searchSubmit() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            type: _this.searchForm.type,
            name: _this.searchForm.name,
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        });
    },

    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();

      this.$refs[formName].model.type = "";
      this.$refs[formName].model.name = "";
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //修改密码
    changePsd(val) {
      this.formData.password = this.$md5(val);
    },

    //批量删除
    handleDel() {
      var _this = this;
      _this.idArr = _this.ids.join(",");
      //return false;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/sysDictionaries/del", {
                params: {
                  id: _this.idArr,
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.pagesEvent(
                    _this.pagesData.currentPage,
                    _this.pagesData.currentRows
                  );
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    handleDelete(id) {
      var _this = this;
      _this
        .$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/sysDictionaries/del", {
              params: {
                id: id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.pagesEvent(
                  _this.pagesData.currentPage,
                  _this.pagesData.currentRows
                );
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleAdd() {
      var _this = this;
      _this.addShow = true;
      this.dialogTitle = "添加字典";
      this.formData = {};
    },

    handleEdit(row) {
      this.addShow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      this.id = row.id;
      this.dialogTitle = "编辑字典";
    },
    handleSubmit(formData) {
      var _this = this;
      this.$refs[formData].validate((val) => {
        if (val) {
          _this.submitBtnLoad = true;
          if (_this.dialogTitle == "添加字典") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysDictionaries/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                name: _this.formData.name,
                code: _this.formData.code,
                type: _this.formData.type,
                typeName: _this.formData.typeName,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: "添加成功",
                  type: "success",
                });
                _this.addShow = false;
                _this.pagesEvent(
                  _this.pagesData.currentPage,
                  _this.pagesData.currentRows
                );
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysDictionaries/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                id: _this.id,
                name: _this.formData.name,
                code: _this.formData.code,
                type: _this.formData.type,
                typeName: _this.formData.typeName,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.addShow = false;
                _this.pagesEvent(
                  _this.pagesData.currentPage,
                  _this.pagesData.currentRows
                );
              } else {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          }
        }
      });
    },

    //物资标签
    labelList() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            type: 2,
            current: "1",
            size: "100000",
          },
        })
        .then(function (res) {
          sessionStorage.setItem(
            "label",
            JSON.stringify(res.data.data.records)
          );
        });
    },

    //物资类型
    typeList() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            type: 3,
            current: "1",
            size: "100000",
          },
        })
        .then(function (res) {
          sessionStorage.setItem("type", JSON.stringify(res.data.data.records));
        });
    },

    //物资单位
    unitList() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectPage", {
          params: {
            type: 1,
            current: "1",
            size: "100000",
          },
        })
        .then(function (res) {
          sessionStorage.setItem("unit", JSON.stringify(res.data.data.records));
        });
    },
    //关闭弹框
    cancelAdd(s) {
      this.value = [];
      this[s] = false;
    },
  },
};
</script>
