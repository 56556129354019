<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="用户">
          <el-select v-model="searchForm.userid" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in userList" :key="index" :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单ID：">
          <el-input v-model="searchForm.orderId" clearable placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="商品：">
          <el-select filterable v-model="searchForm.goodsId" clearable placeholder="请选择商品" style="width: 150px">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货类型：">
          <el-select v-model="searchForm.refundType" clearable>
            <el-option v-for="(item, index) in listStatus" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="searchForm.date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <!-- <el-date-picker v-model="searchForm.date" type="date" clearable value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="是否需要带回：">
          <el-select v-model="searchForm.needBack" clearable>
            <el-option v-for="(item, index) in needBackList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带回状态：">
          <el-select v-model="searchForm.backStatus" clearable>
            <el-option v-for="(item, index) in backStatusList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">退货列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="back" :disabled="ids.length == 0">批量已带回</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectable">
            </el-table-column>
            <el-table-column prop="orderId" label="订单ID" min-width="140">
            </el-table-column>
            <el-table-column prop="title" label="商品名称" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.title }}
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格" width="150"> </el-table-column>
            <el-table-column prop="username" label="用户名" width="150"> </el-table-column>
            <el-table-column prop="wechat" label="微信名称"> </el-table-column>
            <el-table-column prop="num" label="退货量"> </el-table-column>
            <el-table-column prop="amount" label="退款金额">
              <template slot-scope="scope">
                {{ scope.row.amount }} 元
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="退货原因" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="reason" label="是否带回">
              <template slot-scope="scope">
                {{scope.row.needBack==0?'不需要':scope.row.needBack==1?'需要':''}}
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="带回状态">
              <template slot-scope="scope">
                {{scope.row.backStatus==0?'未带回':scope.row.backStatus==1?'已带回':''}}
              </template>
            </el-table-column>
            <el-table-column prop="auditor" label="审批人"> </el-table-column>
            <el-table-column prop="type" label="订单类型">
              <template slot-scope="scope">
                <p v-if="scope.row.type == 0">商品订单</p>
                <p v-else-if="scope.row.type == 1">积分订单</p>
                <p v-else-if="scope.row.type == 2">活动订单</p>
              </template>
            </el-table-column>
            <el-table-column prop="refundType " label="退款类型">
              <template slot-scope="scope">
                <p v-if="scope.row.refundType == 0" style="color: #409eff">
                  退货退款
                </p>
                <p v-else-if="scope.row.refundType == 1" style="color: #67c23a">
                  退货无需退款
                </p>
                <p v-else style="color: #f56c6c">仅退款</p>
              </template>
            </el-table-column>
            <el-table-column prop="created" label="日期" width="170"> </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ids: [],
      adcode: sessionStorage.getItem('adcode'),
      selectGoods: [],
      userList: [],
      needBackList: [
        {
          name: "不需要",
          id: 0
        },
        {
          name: "需要",
          id: 1
        }
      ],
      backStatusList: [
      {
          name: "未带回",
          id: 0
        },
        {
          name: "已带回",
          id: 1
        }
      ],
      //搜索
      searchForm: {
        needBack: "",
        backStatus: "",
        goodsId: "",
        userid: null,
        date: []
      },
      loading: true,
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      searchButton: "",
      listStatus: [
        {
          label: "退货退款",
          value: 0,
        },
        {
          label: "退货无需退款",
          value: 1,
        },
        {
          label: "仅退款",
          value: 2,
        },
        // {
        //   label: "未退款",
        //   value: 0,
        // },
        // {
        //   label: "成功",
        //   value: 1,
        // },
        // {
        //   label: "失败",
        //   value: 2,
        // },
      ],
    };
  },
  created() {
    this.listEvent();
    this.getUserList();
    this.getSelectGoods()
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "refund_order_query") {
        this.searchButton = "1";
      }
    });
  },
  methods: {
    // 获取用户下拉列表
    getUserList(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser", {
          params: {
            warehouseId: warehouseId || ""
          }
        })
        .then((res) => {
          if (res.status == 200) {
            res.data.data.forEach(item => {
              item.wechat = `${item.wechat}（${item.shopName}）`
            })
            _this.userList = res.data.data;
          }
        });
    },
    back() {
      let _this = this;
      this.$confirm("是否确认执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get(this.$axios.defaults.basePath + "/refundOrder/updateBackStatus", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {

        });
    },
    handleSelectionChange(e) {
      this.ids = []
      for (let i in e) {
        this.ids.push(e[i].id)
      }
    },
    selectable(row, index) {
      if (row.needBack == 0 || row.backStatus == 1) {
        return false
      } else {
        return true
      }
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId
          },
        })
        .then((res) => {
          if (res.status == 200) {

            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.selectGoods = list;
          }
        });
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/refundOrder", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            status: _this.searchForm.status,
            // date: _this.searchForm.date,
            startTime: _this.searchForm.date[0] ? _this.searchForm.date[0] : '',
            endTime: _this.searchForm.date[1] ? _this.searchForm.date[1] : '',
            orderCode: _this.searchForm.orderId,
            needBack: _this.searchForm.needBack,
            backStatus: _this.searchForm.backStatus,
            goodsId: _this.searchForm.goodsId,
            userid: _this.searchForm.userid,
            refundType:_this.searchForm.refundType
          },
        })
        .then(function (res) {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.searchForm.date = []
      this.departmentNameList = [];
      this.roles = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
  },
};
</script>

<style scoped>
</style>
