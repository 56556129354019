<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >p
        <el-form-item label="单位名称：">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="单位编码：">
          <el-input
            v-model="searchForm.code"
            placeholder="请输入单位编码"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">单位列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button
            size="mini"
            type="primary"
            @click="handleAdd"
            v-if="addButton == '1'"
            ><i class="el-icon-plus"></i>添加单位</el-button
          >
        </div>
        <template>
          <el-table
            :data="tableData"
            style="width: 100%"
            ref="multipleTable"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            row-key="id"
            :tree-props="{ children: 'childs', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="单位名称" width="180">
            </el-table-column>
            <el-table-column prop="desc" label="单位描述" width="180">
            </el-table-column>
            <el-table-column prop="code" label="编码"> </el-table-column>
            <el-table-column prop="created" label="创建时间"> </el-table-column>
            <el-table-column prop="lastUpdateTime" label="更新时间">
            </el-table-column>
            <!-- <el-table-column prop="type" label="类型">
              <template slot-scope="scope">
                <p>{{ scope.row.lvlCd | lvlCd }}</p>
              </template>
            </el-table-column> -->
            <el-table-column align="center" width="240" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleDetails(scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)"
                  v-if="auditButton == '1'"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDel(scope.row.id)"
                  class="el-icon-delete"
                  v-if="delButton == '1'"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>

    <!--单位详情-->
    <el-dialog :title="detailsTitle + '详情'" :visible.sync="detailsShow" width="60%">
      <div class="postForm">
        <el-form
          :model="detailsData"
          :inline="true"
          ref="formData"
          label-width="125px"
          class="demo-ruleForm text-center infoFrom"
        >
          <el-form-item label="名称：">
            <el-input
              v-model="detailsData.name"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述：">
            <el-input
              v-model="detailsData.desc"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="编码：">
            <el-input
              v-model="detailsData.code"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：">
            <el-input
              v-model="detailsData.lvlCd"
              disabled
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- <div class="alertEvent addPost" v-show="detailsShow">
      <div class="alertMsg" @click="cancelAdd('detailsShow')"></div>
      <div class="alertMain">
        <div class="alertTitle clearfix">
          <p class="float_lf">{{detailsTitle}}详情</p>
          <img class="float_rt" src="../../assets/img/del_icon.png" alt="" @click="cancelAdd('detailsShow')">
        </div>

      </div>
    </div> -->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" width="60%">
      <div class="postForm">
        <el-form
          :model="formData"
          :inline="true"
          :rules="rules_og"
          ref="formData"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入名称"
              style="width: 300px"
              maxlength="25"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="编码：" prop="code">
            <el-input
              v-model="formData.code"
              placeholder="请输入编码"
              style="width: 300px"
              maxlength="25"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="级别：" prop="lvlCd">
            <el-select
              v-model="formData.lvlCd"
              placeholder="请选择级别"
              style="width: 300px"
              @change="selectTrigger()"
            >
              <el-option label="一级" :value="1"></el-option>
              <el-option label="二级" :value="2"></el-option>
              <el-option label="三级" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级ID：" prop="parentId" v-show="level2">
            <template>
              <el-cascader
                v-model="value"
                :options="departmentArr"
                :show-all-levels="false"
                style="width: 300px"
                :props="{ checkStrictly: true }"
                @change="handleChange"
              >
              </el-cascader>
            </template>
          </el-form-item>
          <!-- <el-form-item label="状态：" prop="status" style="width:400px">
            <el-radio v-model="formData.status" :label="0">无效</el-radio>
            <el-radio v-model="formData.status" :label="1">有效</el-radio>
          </el-form-item> -->
          <!-- <el-form-item label="单位层级：" prop="lvlCd">
                            <el-select v-model="formData.lvlCd" placeholder="请选择类型" style="width: 300px;" @change="selectTrigger()">
                                <el-option label="公司" value="1"></el-option>
                                <el-option label="部门" value="2"></el-option>
                                <el-option label="岗位" value="3"></el-option>
                            </el-select>
                        </el-form-item> -->
          <el-form-item label="描述：" prop="desc">
            <el-input
              v-model="formData.desc"
              placeholder="请输入单位描述"
              style="width: 300px"
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="postBtn"
            style="display: block; text-align: center"
          >
            <el-button type="primary" @click="handleSubmit('formData')"
              >提交</el-button
            >
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--新增单位-->
    <!-- <div class="alertEvent addPost" v-show="addShow">
      <div class="alertMsg" @click="cancelAdd('addShow')"></div>
      <div class="alertMain" style="width: 60%">
        <div class="alertTitle clearfix">
          <p class="float_lf">{{dialogTitle}}</p>
          <img class="float_rt" src="../../assets/img/del_icon.png" alt="" @click="cancelAdd('addShow')">
        </div>

      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        name: "",
        code: "",
      },
      searchButton: "",
      multipleSelection: [],
      ids: "",
      detailsShow: false,
      userAddShow: false,
      detailsTitle: "",
      detailsData: {},
      companyData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      dialogTitle: "",
      addShow: false,
      level2: false,
      formData: {
        name: "",
        desc: "",
        lvlCd: "",
        parentId: "",
        status: "",
        type: "1",
        code: "",
      },
      departmentArr: [],
      children: [],
      value: "",
      rules_og: {
        name: [
          {
            required: true,
            message: "请输入单位或者部门名称",
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: true,
            message: "请添加相关描述",
            trigger: "blur",
          },
        ],
        lvlCd: [
          {
            required: true,
            message: "请选择所属类型",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入编码",
            trigger: "blur",
          },
        ],
      },
      auditButton: "",
      addButton: "",
      delButton: "",
    };
  },
  watch: {
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach((key) => {
            this.formData[key] = "";
          });
        }
      },
    },
  },
  created() {
    this.listTree();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "org_update") {
        this.auditButton = "1";
      } else if (item.authority == "org_query") {
        this.searchButton = "1";
      } else if (item.authority == "org_create") {
        this.addButton = "1";
      } else if (item.authority == "org_delete") {
        this.delButton = "1";
      }
    });
  },
  methods: {
    listTree() {
      //获取列表数据
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysOrg/selectOrgTree")
        .then(function (res) {
          if (res.status == 200) {
            _this.tableData = res.data;
          }

          console.log(res);
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.pagesEvent(_this.pagesData.currentPage, val);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.pagesEvent(val, _this.pagesData.currentRows);
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysOrg/selectPage", {
          params: {
            name: _this.searchForm.name,
            code: _this.searchForm.code,
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.records;
          _this.pagesData.total = res.data.total;
        });
    },

    //搜索操作
    searchSubmit() {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysOrg/selectPage", {
          params: {
            name: _this.searchForm.name,
            code: _this.searchForm.code,
            current: 1,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          _this.tableData = res.data.records;
          _this.pagesData.total = res.data.total;
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].model.name = "";
      this.$refs[formName].model.code = "";
      let that = this;
      this.listTree();
    },

    //详情
    handleDetails(row) {
      this.companyData = [];
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysOrg/getOrgDetail", {
          params: {
            id: row.id,
          },
        })
        .then(function (res) {
          _this.detailsData = res.data.data;
          _this.detailsShow = true;
        });
    },

    //选择下级时查询所有部门
    selectTrigger() {
      var _this = this;
      if (_this.formData.lvlCd == 2 || _this.formData.lvlCd == 3) {
        _this
          .$axios({
            url: _this.$axios.defaults.basePath + "/sysOrg/getOrgSelectList",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(function (res) {
            var resData = res.data;

            _this.departmentArr = [];
            if (resData.data != "") {
              resData.data.forEach((item) => {
                _this.children = [];
                var aa = item["childs"];
                aa.forEach((val) => {
                  _this.children.push({
                    value: val["id"],
                    label: val["name"],
                  });
                });
                _this.departmentArr.push({
                  value: item["id"],
                  label: item["name"],
                  children: _this.children,
                });
              });
            }

            _this.level2 = true;
          });
      } else if (_this.formData.lvlCd == 1) {
        _this.level2 = false;
      }
    },

    handleChange(value) {
      var end = value.slice(-1);
      this.formData.parentId = end[0];
    },
    //添加
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = "添加单位";
      this.formData = {};
    },
    //编辑
    handleEdit(row) {
      this.addShow = true;
      this.id = row.id;
      //获取信息
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysOrg/getOrgDetail", {
          params: {
            id: row.id,
          },
        })
        .then(function (res) {
          _this.formData = res.data.data;
          _this.formData.lvlCd = res.data.data.lvlCd;
        });

      this.dialogTitle = "编辑单位";
    },
    handleSubmit(formData) {
      var _this = this;
      _this.$refs[formData].validate((valid) => {
        if (valid) {
          if (_this.dialogTitle == "添加单位") {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysOrg/add",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                name: _this.formData.name,
                desc: _this.formData.desc,
                type: "1",
                parentId: _this.formData.parentId,
                parentKey: _this.formData.parentId,
                lvlCd: _this.formData.lvlCd,
                status: _this.formData.status,
                code: _this.formData.code,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.addShow = false;
                _this.listTree();
                _this.$message({
                  message: "保存成功！",
                  type: "success",
                });
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          } else {
            this.$axios({
              url: _this.$axios.defaults.basePath + "/sysOrg/update",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                id: _this.id,
                name: _this.formData.name,
                desc: _this.formData.desc,
                address: _this.formData.address,
                mobile: _this.formData.mobile,
                type: _this.formData.type,
                parentId: _this.formData.parentId,
                lvlCd: _this.formData.lvlCd,
                status: _this.formData.status,
                code: _this.formData.code,
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.addShow = false;
                _this.listTree();
                _this.$message({
                  message: "保存成功！",
                  type: "success",
                });
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    cancelAdd(s) {
      this[s] = false;
    },

    handleSelectionChange(val) {
      var _this = this;
      _this.ids = "";
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids += item.id + ",";
      });
    },

    handleDel(id) {
      var _this = this;
      _this
        .$confirm("此操作将永久删除该单位, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/sysOrg/delete", {
              params: {
                id: id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listTree();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    getTree() {
      var _this = this;
      _this
        .$axios({
          url: _this.$axios.defaults.basePath + "/sysOrg/selectOrgTree",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          var resData = res.data;

          _this.departmentArr = [];
          if (resData != "") {
            resData.forEach((item) => {
              _this.children = [];
              var aa = item["childs"];
              aa.forEach((val) => {
                var bb = val["childs"];
                var childs = [];
                bb.forEach((v) => {
                  childs.push({
                    value: v["id"],
                    label: v["name"],
                  });
                });

                _this.children.push({
                  value: val["id"],
                  label: val["name"],
                  children: childs,
                });
              });
              _this.departmentArr.push({
                value: item["id"],
                label: item["name"],
                children: _this.children,
              });
            });
          }
        });
    },
  },
  filters: {
    lvlCd(value) {
      if (value === 1) {
        return "公司";
      } else if (value === 2) {
        return "部门";
      } else if (value === 3) {
        return "岗位";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
