<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="产品名称">
          <el-input v-model="searchForm.name" clearable placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="goodsTypeId" placeholder="请选择产品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="产品单位">
          <el-select v-model="searchForm.unit" clearable>
            <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input v-model="searchForm.mfrs" clearable placeholder="请输入制造商"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.enabled" placeholder="请选择状态" clearable>
            <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">产品列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="danger" :disabled="ids.length == 0" @click="handleDel" v-if="delButton == '1'" class="el-icon-delete">删除</el-button>
          <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl3" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess" :on-error="handleAvatarErr" :headers="importHeaders" v-if="updateButton == '1'">
            <el-button type="primary" class="el-button--mini" v-loading="uploadLoading">导入产品</el-button>
          </el-upload>
          <el-button size="mini" type="primary" @click="exportData" v-if="searchButton == '1'">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="icons" label="产品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px; height: 60px" :src="scope.row.icons" :preview-src-list="scope.row.imgs.split(',')">
                  </el-image>
                </div>
                <img v-else src="../../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="产品名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="describe" label="产品描述" width="130px" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="color" label="颜色"> </el-table-column>
            <el-table-column prop="categoryName" label="类型">
            </el-table-column>
            <el-table-column prop="standard" label="规格" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="model" label="型号"> </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
            <!-- <el-table-column prop="num" label="库存"> </el-table-column> -->
            <el-table-column prop="mfrs" label="制造商"> </el-table-column>
            <el-table-column prop="enabled" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                <p v-else style="color: #67c23a">启用</p>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="130px" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" width="280" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleDetails(scope.row)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'">编辑
                </el-button>
                <el-button size="mini" type="primary" @click="handleUp(scope.row)" v-if="updateButton == '1'" :disabled="scope.row.enabled == '1'">启用</el-button>
                <el-button size="mini" type="danger" @click="handleDown(scope.row)" v-if="updateButton == '1'" :disabled="scope.row.enabled == '0'">禁用</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
export default {
  name: "goodsIndex",
  components: {
    vuedraggable,
    cropperAndUploadVue: () => import("@/components/cropperAndUpload.vue"),
  },
  data() {
    return {
      uploadLoading: false,
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      uploadUrl3: this.$axios.defaults.basePath + "/import/importGoods",
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      uploadUrl2: this.$axios.defaults.basePath + "/image/AliYunVodUpload",
      loading: false,
      btnLoading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
        imgs: [
          {
            required: true,
            message: "请至少上传一张产品图片",
            trigger: "blur",
          },
        ],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 产品类型
      goodsType: [],
      // 产品单位
      goodsUnit: [],
      // 产品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      videoData: [],
      fileList2: [],
      videoFileShow: true,
      // 图片拖拽排序
      groups: [],
      fileListInfo: [],
      imgDataInfo: [],
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_query") {
        this.searchButton = "1";
      }
    });
  },
  activated(){
    console.log(this.$route)
    if(this.$route.params.type == 'back'){
      this.listEvent()
    }
  },
  methods: {
    //导出
    exportData() {  
      let _this = this;
      let data = JSON.parse(JSON.stringify(this.searchForm))
      delete data.current
      delete data.size
      this.$axios
        .get(this.$axios.defaults.basePath + "/goods/goodsExport", {
          params: data,
          responseType: 'blob'
        })
        .then((res) => {
          var eleLink = document.createElement("a");
          eleLink.download = "产品表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          // 然后移除
          document.body.removeChild(eleLink);
        })
    },
    handleAvatarErr() {
      this.uploadLoading = false;
    },
    //导入修改订单状态数据
    uploadSuccess(res, file) {
      this.uploadLoading = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    beforeAvatarUpload(file) {
      this.uploadLoading = true;
    },
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goods", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            name: _this.searchForm.name,
            categoryId: _this.searchForm.categoryId,
            unit: _this.searchForm.unit,
            mfrs: _this.searchForm.mfrs,
            enabled: _this.searchForm.enabled,
          },
        })
        .then((res) => {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
            _this.goodsType.push({
              name:"无",
              id:"无",
              childs:[],
              parentId:null
            })
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显产品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取产品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      // this.dialogTitle = "新增";
      // this.dialogFormVisible = true;
      // this.addEditForm = {};
      // this.imgData = [];
      // this.fileList = [];
      // this.goodsTypeId2 = [];
      // this.detailsShow = false;
      this.$router.push({ name: 'goodsAdd',params:{type:'add'} })
    },
    // 修改
    handleEdit(row) {
      this.$router.push({ name: 'goodsAdd', params: { type: 'edit', id: row.id } })
    },
    // 新增/编辑取消
    addEditCancel() {
      window.location.reload();
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.fileList, this.imgData)
          // if (this.fileList.length != this.imgData.length) {
          //   await this.$confirm("有产品图片尚未上传, 是否继续?", "提示", {
          //     confirmButtonText: "确定",
          //     cancelButtonText: "取消",
          //     type: "warning",
          //   });
          // }
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/goods/add";
          } else {
            url = "/goods/update";
          }
          var videos = [];
          if (this.videoData.length > 0) {
            this.videoData.forEach((item) => {
              videos.push(item.data);
            });
            _this.addEditForm.videos = videos.join(",");
          } else {
            _this.addEditForm.videos = "";
          }
          if (this.imgData.length > 0) {
            this.addEditForm.imgs = this.imgData.join(",");
          }
          console.log(_this.addEditForm);
          this.btnLoading = true;
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          })
            .then((res) => {
              this.btnLoading = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                // _this.dialogFormVisible = false;
                // _this.listEvent();
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此产品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/goods/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      this.$router.push({ name: 'goodsAdd', params: { type: 'details', id: row.id } })
    },
    // 启用
    handleUp(row) {
      let _this = this;
      row.enabled = "1";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/goods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 禁用
    handleDown(row) {
      let _this = this;
      row.enabled = "0";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/goods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.imgData.push(res);
      console.log(this.imgData);
      if (this.imgData.length > 0) {
        this.$set(this.addEditForm, "imgs", this.imgData.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },
    handleChange(fileList, ind) {
      // if (fileList.length == 0){
      //   fileList.push({url:''})
      // }
      console.log(fileList, ind)
      this.fileList = fileList;
      if (fileList.length == 0) {
        this.imgData = []
      }
      // if(ind){
      //   this.imgData.splice(ind, 1)
      // }
    },
    handleSuccess(imgData, removeIndex) {
      console.log(imgData, removeIndex)
      if (removeIndex) {
        this.imgData = imgData
      } else {
        if (imgData.length > 1) {
          this.imgData = imgData
        } else if (imgData.length == 1) {
          this.imgData.push(imgData[0])
        }
      }

      console.log(this.imgData)






      if (this.imgData.length > 0) {
        this.$set(this.addEditForm, "imgs", this.imgData.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },
    handleChangeInfoImg(fileList) {
      this.fileList2 = fileList;
    },
    handleSuccessInfoImg(imgData) {
      this.imgDataInfo = imgData;
      if (this.imgDataInfo.length > 0) {
        this.$set(this.addEditForm, "goodsInfo", this.imgDataInfo.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },

    // 上传图片删除
    handleRemove(file) {
      console.log(file);
      let imgArr = [];
      this.fileList = [];
      let url = file.response ? file.response : file.url;
      this.imgData.forEach((item) => {
        if (url != item) {
          imgArr.push(item);
          this.fileList.push({ url: item });
        }
      });
      this.imgData = imgArr;
      if (this.imgData.length > 0) {
        this.addEditForm.imgs = this.imgData.join(",");
      } else {
        this.addEditForm.imgs = "";
      }
      console.log(this.imgData);
      // let ind = "";
      // this.fileList.forEach((item, index) => {
      //   if (file.response == item.url) {
      //     ind = index;
      //   }
      // });
      // this.fileList.splice(ind, 1);
    },
    // 上传详情图片删除
    // handleRemove2(file) {
    //   console.log(file);
    //   let imgArr = [];
    //   this.fileList = [];
    //   let url = file.response ? file.response : file.url;
    //   this.imgData.forEach((item) => {
    //     if (url != item) {
    //       imgArr.push(item);
    //       this.fileList.push({ url: item });
    //     }
    //   });
    //   this.imgData = imgArr;
    //   if (this.imgData.length > 0) {
    //     this.addEditForm.imgs = this.imgData.join(",");
    //   } else {
    //     this.addEditForm.imgs = "";
    //   }

    //   // let ind = "";
    //   // this.fileList.forEach((item, index) => {
    //   //   if (file.response == item.url) {
    //   //     ind = index;
    //   //   }
    //   // });
    //   // this.fileList.splice(ind, 1);
    // },
    //上传视频事件
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.videoData = [];
      this.fileList2 = [];
      this.videoData.push(res);
      this.fileList2.push({ url: res.data });
      this.$set(this.addEditForm, "videos", res.data);
      this.videoFileShow = false;
    },
    // 上传视频删除
    handleRemove2(file) {
      let videoArr = [];
      let url = file.url ? file.url : file.response;
      this.videoData.forEach((item) => {
        if (url != item) {
          videoArr.push(item);
        }
      });
      this.videoData = videoArr;
      let ind = "";
      this.fileList2.forEach((item, index) => {
        if (url == item.url) {
          ind = index;
        }
      });
      this.fileList2.splice(ind, 1);
    },
    // 限制视频大小
    handleFileChange(file) {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 <= 50;
        if (!isLt5M) {
          this.$message.error("上传视频大小不能超过 50MB!");
          return reject(false);
        } else {
          return resolve(true);
        }
      });
    },
    inputGoodsName(e) {
      console.log(e.length);
      if (e.length >= 50) {
        this.$message({
          message: "产品名称不能大于50个字符",
          type: "warning",
        });
      }
    },
    // 视频删除
    videoDelete() {
      this.$confirm("是否确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.videoData = [];
          this.$set(this.addEditForm, "videos", "");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style scoped>
.boxMain >>> .el-image img {
  height: auto !important;
}
</style>
