<template>
  <div class="homePage">
    <div class="home_title">欢迎进入团购后台管理系统</div>
  </div>
</template>

<script>

export default {
  name: "data-backups",
  data() {
    return {};
  },
  created() {},
  mounted() {
    
  },

  methods: {},
};
</script>

<style scoped>
.homePage {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: url(../assets/img/homeBg.jpg) no-repeat;
  background-size: 100% 100%;
}
.homePage .home_title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  color: rgb(54, 95, 204);
  font-weight: bold;
  font-size: 46px;
  font-family: Arial, Helvetica, sans-serif;
  transform: translate(-50%, -50%);
}
.home_banner {
  width: 100%;
  height: 336px;
  padding: 60px 70px 40px 70px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: url("../assets/img/home_banner.png") no-repeat;
  background-size: 100% 100%;
}
.home_banner .home_title {
  color: #fff;
  font-size: 16px;
}
.home_title p:first-child {
  font-size: 30px;
  margin-bottom: 10px;
}
.home_total {
  margin-top: 0;
}
.home_total_title {
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  margin-top: 15px;
}
.home_total ul li {
  float: left;
  min-width: 176px;
  padding: 25px;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  list-style: none;
  margin-right: 15px;
  background-color: rgba(243, 246, 253, 0.2);
}
.home_total ul li p:first-child + p {
  font-size: 30px;
  margin-top: 10px;
}
.home_drawTotal {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.home_drawTotal .total_title {
  font-size: 16px;
  margin-bottom: 10px;
}
.home_drawTotal .total_title .total_change span {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
}
.home_drawTotal .total_title .total_change span.active {
  color: #246eff;
}
.home_echarts {
  width: 100%;
}
.home_echarts > div {
  width: 50%;
  height: 600px;
  float: left;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div {
  border-top: 0;
}
.home_echarts > div:first-child,
.home_echarts > div:first-child + div + div {
  border-right: 1px solid #e5e5e5;
}
.echarts_box {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.echarts_box >>> .el-date-editor {
  width: 380px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1000;
}
.goodsSelect {
  position: absolute;
  top: 10px;
  right: 400px;
  z-index: 1000;
}
@media screen and (max-width: 1700px) {
  .goodsSelect {
    left: 24% !important;
  }
}
.echarts_box .echarts_item {
  width: 100%;
  height: 600px;
}

.weclone {
  color: #333;
  text-align: center;
  font-size: 50px;
  padding-top: 300px;
}
.pageMain .item {
  width: 100%;
  height: 180px;
  position: relative;
  cursor: pointer;
}
.pageMain .item .item_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: auto;
  height: 180px;
}
.pageMain .item .icon_zhuan {
  position: inherit;
  top: 0;
  left: 0;
  display: block;
  margin: 0 auto;
  -webkit-animation: changeright 6s linear infinite;
}
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pageMain .item .item_main {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.pageMain .item .item_main img {
  display: block;
  /*padding-left: 15px;*/
  margin: 0 auto 10px auto;
}
.home_sort {
  width: 100%;
  margin-top: 20px;
}
.table_box {
  width: 100%;
  height: 500px;
  float: left;
  padding: 20px 0;
  margin-left: 1.4%;
  box-sizing: border-box;
  /* box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
}
.table_box:first-child {
  margin-left: 0;
}
.table_box .sort_title {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: bold;
}

.el-table {
  border: 1px solid #e3e3e3;
}

::v-deep .el-table thead {
  background: #e7efff;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-bg-gray,
::v-deep .el-table__row:hover {
  background: #f1f2f7 !important;
}
.nums {
  font-size: 20px;
  margin-left: 50px;
}
.nums span {
  font-size: 24px;
  font-weight: 600;
}
</style>
