<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="选择用户" prop="userId">
          <el-select v-model="searchForm.userId" filterable clearable style="width: 200px" placeholder="可搜索选项">
            <el-option v-for="(item,index) in userList" :key="index"
              :label="item.wechat ? item.wechat : item.username" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">积分账户列表</p>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="ID"> </el-table-column>
            <el-table-column prop="userId" label="用户ID"> </el-table-column>
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="point" label="可用积分"> </el-table-column>
            <el-table-column prop="freeze" label="冻结积分"> </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 0" style="color: red">异常</p>
                <p v-if="scope.row.status == 1">正常</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handlePointDetails(scope.row.id)"
                  v-if="searchButton == '1'">积分明细</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
              :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows" :page-size="pagesData2.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData2.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 积分明细 -->
    <el-dialog title="积分明细" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
      <el-table :data="pointList" tooltip-effect="dark" style="width: 100%" v-loading="loading">
        <el-table-column prop="created" label="时间"> </el-table-column>
        <el-table-column prop="orderId" label="积分订单ID"> </el-table-column>
        <el-table-column prop="orderType" label="订单类型">
          <template slot-scope="scope">
            {{
            scope.row.orderType == 1
            ? "积分商品"
            : scope.row.orderType == 2
            ? "售后退货"
            : scope.row.orderType == 3
            ? "销售订单"
            : scope.row.orderType == 4
            ? "订单取消"
            : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="point" label="积分">
          <template slot-scope="scope">
            {{
            scope.row.type == 0
            ? "+" + scope.row.point
            : "-" + scope.row.point
            }}
          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="padding: 10px 15px">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
          layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cancelAdd('dialogFormVisible')">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //搜索
      searchForm: {
        id: "",
      },
      loading: true,
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      searchButton: "",
      pointList: [],
      accountId: "",
      dialogFormVisible: false,
      userList: [],
    };
  },
  created() {
    this.listEvent();
    this.getUserList();
    // this.getAccountList();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "point_account_query") {
        this.searchButton = "1";
      }
    });
  },
  // watch: {
  // 	// 如果路由有变化，会再次执行该方法
  // 	'$route': 'listEvent',
  // 	addShow: {
  // 		handler(n) {
  // 			if (!n) {
  // 				Object.keys(this.formData).forEach(key => {
  // 					this.formData[key] = ''
  // 				})
  // 				this.departmentNameList2 = []
  // 				this.roles = []
  // 			} else {
  // 				this.generateData()
  // 			}
  // 		}
  // 	}
  // },
  methods: {
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData2.currentPage;
      _this.searchForm.size = _this.pagesData2.currentRows;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/pointAccount/listAll", {
          params: _this.searchForm,
        })
        .then(function (res) {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            _this.tableData = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 获取用户下拉列表
    getUserList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/getUser")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.userList = res.data.data;
          }
        });
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.departmentNameList = [];
      this.roles = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 查询积分明细
    handlePointDetails(id) {
      var _this = this;
      this.accountId = id;
      this.$axios
        .get(this.$axios.defaults.basePath + "/pointAccount/info", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            id: id,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            if (res.data.data.logsList.records.length == 0) {
              _this.$message({
                message: "暂无积分明细",
                type: "warning",
              });
            } else {
              _this.pointList = res.data.data.logsList.records;
              _this.pagesData.total = res.data.data.logsList.total;
              _this.dialogFormVisible = true;
            }
          } else {
            _this.$message.error(res.data.errmsg);
          }
        });
    },
    //积分明细分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.handlePointDetails(this.accountId);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.handlePointDetails(this.accountId);
    },
  },
};
</script>

<style scoped>
</style>
