<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <!-- <el-form-item label="所属乡镇">
          <area-cascader :areaValue="searchTowns" :isStatus="false" :allLevel="false" @areaChangeAll="areaChangeAlls">
          </area-cascader>
        </el-form-item> -->
        <el-form-item label="选择路线" prop="wayId">
          <el-select v-model="wayIds" multiple filterable clearable style="width: 200px">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-cascader ref="tree" style="width: 300px" :options="regionalRouteData" v-model="searchRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="用户名">
          <el-input v-model="searchForm.username" clearable placeholder="请输入用户名"></el-input>
        </el-form-item> -->
        <el-form-item label="微信昵称">
          <el-input v-model="searchForm.wechat" clearable placeholder="请输入微信昵称"></el-input>
        </el-form-item>
        <el-form-item label="门店名称">
          <el-input v-model="searchForm.shopName" clearable placeholder="请输入门店名称"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="searchForm.phone" clearable placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="searchForm.auditStatus" clearable style="width: 180px">
            <el-option v-for="(item, index) in userStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户状态">
          <el-select v-model="searchForm.status" clearable style="width: 150px">
            <el-option label="已激活" :value="1"></el-option>
            <el-option label="已禁用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否设置路线">
          <el-select v-model="searchForm.isWay" clearable style="width: 150px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户等级">
          <el-select v-model="searchForm.level" placeholder="请选择级别" clearable style="width: 150px">
            <el-option v-for="(item, index) in userLevel" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否设置经纬度">
          <el-select v-model="searchForm.isLocation" clearable style="width: 150px">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">客户列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i>添加客户</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length == 0" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
            <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="uploadUrl" :before-upload="beforeAvatarUpload" :limit="1" :on-success="handleAvatarSuccess" :on-remove="handleRemove" :headers="importHeaders" v-if="importButton == '1'">
              <el-button type="primary" class="el-button--mini">导入客户数据</el-button>
            </el-upload>
            <el-button size="mini" type="primary" @click="exportEvent" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出客户数据</el-button>
            <el-button size="mini" type="primary" @click="wayInfoEvent" v-if="wayQueryButton == '1'" :disabled="tableData.length == 0">
              路线信息</el-button>
            <!-- <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false"
              :auto-upload="true" :show-file-list="false" :action="uploadUrl" :before-upload="beforeAvatarUpload" :limit="1"
              :on-success="handleAvatarSuccess" :on-remove="handleRemove" :headers="importHeaders" v-if="exportButton == '1'">
              <el-button type="primary" class="el-button--mini">导入路线数据</el-button>
            </el-upload> -->
            <el-button size="mini" type="primary" @click="exportWayEvent(1)" v-if="wayQueryButton == '1'" :disabled="tableData.length == 0">
              导入路线数据</el-button>
            <el-button size="mini" type="primary" @click="exportWayEvent(2)" v-if="wayQueryButton == '1'" :disabled="tableData.length == 0">
              导出路线数据</el-button>
            <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="upload" :multiple="false" :auto-upload="true" :show-file-list="false" :action="UserWayUpdateUrl" :before-upload="beforeAvatarUpload" :limit="1" :on-success="handleAvatarSuccess" :on-remove="handleRemove" :headers="importHeaders" v-if="importButton == '1'">
              <el-button type="primary" class="el-button--mini">导入修改路线</el-button>
            </el-upload>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="wechat" label="微信昵称"> </el-table-column>
            <el-table-column prop="towns" label="所属乡镇" min-width="120">
            </el-table-column>
            <el-table-column prop="shopName" label="门店名称">
            </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="phone" label="联系方式" width="120">
            </el-table-column>
            <el-table-column prop="level" label="等级">
              <template slot-scope="scope">
                <p v-if="scope.row.level == 0">普通用户</p>
                <p v-if="scope.row.level == 1">团长</p>
                <p v-if="scope.row.level == 2">批发商</p>
              </template>
            </el-table-column>
            <el-table-column prop="regionalName" label="区域" width="100"> </el-table-column>
            <el-table-column prop="way" label="路线" width="100"> </el-table-column>
            <el-table-column prop="statusFlag" label="账号状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 1" style="color: #409eff">
                  已激活
                </p>
                <p v-else-if="scope.row.status == 0" style="color: #f56c6c">
                  已禁用
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="auditStatus" label="审核状态" width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                <p v-if="scope.row.auditStatus == 0" style="color: #409eff">
                  待审核
                </p>
                <p v-else-if="scope.row.auditStatus == 1" style="color: #67c23a">
                  通过
                </p>
                <p v-else-if="scope.row.auditStatus == 2" style="color: #f56c6c">
                  拒绝
                </p>
                <p v-else-if="scope.row.auditStatus == null" style="color: #f56c6c">
                  授权登录未提交审核
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="created" label="创建时间" width="180">
            </el-table-column>
            <!-- <el-table-column prop="remark" label="备注"> </el-table-column> -->
            <el-table-column align="center" width="480" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleDetails(scope.row.id)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="handleLock(1, scope.row.id)" v-if="updateButton == '1'" :disabled="scope.row.status == 1">激活</el-button>
                <el-button size="mini" type="primary" @click="handleLock(0, scope.row.id)" v-if="updateButton == '1'" :disabled="scope.row.status == 0">禁用</el-button>
                <el-button size="mini" type="primary" @click="edit(scope.row)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                <!-- :disabled="scope.row.level == 1" -->
                <el-button size="mini" type="primary" @click="handleAudit(scope.row)" :disabled="scope.row.auditStatus == null" v-if="auditButton == '1'">审核</el-button>
                <el-button size="mini" type="primary" @click="handleWaySet(scope.row)" :disabled="scope.row.auditStatus != 1" v-if="wayAddButton == '1'">路线设置</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增、编辑客户-->
    <el-dialog :title="dialogTitle" :visible.sync="addShow" @close="cancelAdd" width="60%" :close-on-click-modal="false">
      <div class="postForm">
        <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm" :inline="true">
          <el-form-item label="用户名：" prop="username">
            <el-input v-model="formData.username" placeholder="请输入用户名" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="微信昵称：" prop="wechat">
            <el-input v-model="formData.wechat" placeholder="请输入微信昵称" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="电话：" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入联系方式" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="备用电话：" prop="tell">
            <el-input v-model="formData.tell" placeholder="请输入联系方式" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="级别：" prop="level">
            <el-select v-model="formData.level" placeholder="请选择级别" style="width: 300px" :disabled="dialogTitle == '客户详情'">
              <el-option v-for="(item, index) in userLevel" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门店名称：" prop="shopName">
            <el-input v-model="formData.shopName" placeholder="请输入门店名称" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="所属地区：" prop="adcode" v-if="dialogTitle == '添加客户'">
            <area-cascader :areaValue="formData.adcode" @areaChange="areaEvent" :status="dialogTitle == '客户详情'">
            </area-cascader>
          </el-form-item>
          <el-form-item label="所属乡镇：" prop="towns">
            <area-cascader ref="areaCascader" :areaValue="formData.code" :allLevel="true" @areaChangeAll="areaChangeAlls" :status="dialogTitle == '客户详情'">
            </area-cascader>
          </el-form-item>
          <el-form-item label="地址：" prop="address">
            <el-input v-model="formData.address" placeholder="请输入地址" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item label="所属仓库：" prop="place" v-if="formData.place">
            <el-input v-model="formData.place" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="经度：">
            <el-input v-model="formData.lng" style="width: 300px" placeholder="请输入用户经度" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="纬度：">
            <el-input v-model="formData.lat" style="width: 300px" placeholder="请输入用户纬度" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input v-model="formData.remark" placeholder="请输入备注" style="width: 300px" type="textarea" :disabled="dialogTitle == '客户详情'"></el-input>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center; margin-top: 30px">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')" v-if="dialogTitle != '客户详情'">提交
            </el-button>
            <el-button v-if="dialogTitle != '客户详情'" @click="cancelAdd('addShow')">取消</el-button>
            <el-button v-if="dialogTitle == '客户详情'" @click="cancelAdd('addShow')" type="primary">关闭</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 设为团长 -->
    <el-dialog title="客户审核" :visible.sync="tuanZhangShow" @close="cancelAdd" width="50%" :close-on-click-modal="false">
      <div class="postForm">
        <el-form :model="wareForm" ref="setForm" :rules="setTzRules" label-width="100px" class="demo-ruleForm" :inline="true">
          <el-form-item label="用户名：">
            <el-input v-model="userInfo.username" :disabled="true" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="昵称：">
            <el-input v-model="userInfo.wechat" :disabled="true" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="电话：">
            <el-input v-model="userInfo.phone" placeholder="请输入联系方式" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="级别：">
            <el-select v-model="userInfo.level" placeholder="请选择级别" style="width: 300px" :disabled="true">
              <el-option v-for="(item, index) in userLevel" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门店名称：">
            <el-input v-model="userInfo.shopName" placeholder="请输入门店名称" style="width: 300px" :disabled="true">
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="所属地区：">
            <area-cascader
              :areaValue="userInfo.adcode"
              @areaChange="areaEvent"
              :status="true"
            ></area-cascader>
          </el-form-item> -->
          <el-form-item label="所属乡镇：">
            <el-input v-model="userInfo.towns" placeholder="请输入所属乡镇" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="地址：">
            <el-input v-model="userInfo.address" placeholder="请输入地址" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <!-- prop="wareAdcode" -->
          <el-form-item label="所属仓库">
            <el-select v-model="warehouseId" style="width: 300px" @change="changeWarehouse">
              <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审核状态" prop="auditStatus">
            <el-radio v-model="wareForm.auditStatus" label="1">审核通过</el-radio>
            <el-radio v-model="wareForm.auditStatus" label="2">拒绝</el-radio>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSetTuanZhang('setForm')">提交
            </el-button>
            <el-button @click="cancelAdd('tuanZhangShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 导出客户 -->
    <el-dialog title="客户导出" :visible.sync="exportShow" width="40%" :close-on-click-modal="false">
      <el-form :model="exportForm" ref="exportForm" :inline="false" label-width="120px">
        <el-form-item label="审核状态" prop="auditStatus">
          <el-select v-model="exportForm.auditStatus" clearable>
            <el-option v-for="item in userStatus" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户状态">
          <el-select v-model="exportForm.status" clearable>
            <el-option label="已激活" :value="1"></el-option>
            <el-option label="已禁用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否设置经纬度">
          <el-select v-model="exportForm.isLocation" clearable>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区域:">
          <el-cascader ref="exportFormTree" :options="regionalRouteData" v-model="exportFormRegionalList" placeholder="请选择区域" :props="cascaderProps" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="选择路线：">
          <el-select v-model="exportForm.wayId" filterable multiple clearable>
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false; exportForm = {};exportFormRegionalList=[],exportForm.wayId=[]">取 消</el-button>
        <el-button @click="
  exportForm = {},exportForm.wayId=[],exportFormRegionalList=[];
        ">清 空</el-button>
        <el-button type="primary" @click="exportData">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导入导出路线 -->
    <el-dialog :title="wayFlag==1?'路线数据导入':'路线数据导出'" :visible.sync="exportWayShow" width="40%" @close="cancelExportWay" :close-on-click-modal="false">
      <el-form :model="exportWayForm" ref="exportWayForm" :rules="wayRules" :inline="false" label-width="120px">
        <el-form-item label="选择路线：" prop="wayId">
          <el-select v-model="exportWayForm.wayId" multiple filterable clearable style="width: 300px" @change="wayChange">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导入路线数据：" prop="wayId" v-if="wayFlag==1">
          <el-upload style="display: inline-block; margin: 0px 10px" class="image-uploader" ref="uploadWay" :multiple="false" :auto-upload="false" :show-file-list="true" :action="uploadWayUrl" :data="{wayId:exportWayForm.wayId}" :on-change="changeUpload2" :limit="1" accept=".xls,.xlsx,.csv" :on-success="handleAvatarSucces2" :on-remove="handleRemove2" :headers="importHeaders">
            <el-button type="primary" class="el-button--mini">选择文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelExportWay">取 消</el-button>
        <el-button @click="
              exportWayForm = {};
        ">清 空</el-button>
        <el-button type="primary" @click="exportWayData" :loading="submitBtnLoad">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 路线设置 -->
    <el-dialog title="路线设置" :visible.sync="waySetting" width="40%" :close-on-click-modal="false">
      <el-form :model="waySetForm" ref="waySetForm" :rules="wayRules" :inline="true" label-width="120px">
        <el-form-item label="选择路线" prop="wayId">
          <el-select v-model="waySetForm.wayId" filterable clearable style="width: 300px" @change="waySetChange">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="waySetting = false; waySetForm = {};">取 消</el-button>
        <el-button @click="
              waySetForm = {};
        ">清 空</el-button>
        <el-button type="primary" @click="waySetSubmit('waySetForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 路线信息规划 -->
    <el-dialog title="路线信息规划" :visible.sync="wayInfoShow" width="70%" @close="wayInfoShow = false; wayInfoForm = {};userWayData=[]" :close-on-click-modal="false">
      <el-form :model="wayInfoForm" ref="wayInfoForm" :inline="true" label-width="100px">
        <el-form-item label="选择路线：">
          <el-select v-model="wayInfoForm.wayId" filterable clearable style="width: 200px" @change="wayInfoChange">
            <el-option v-for="item in wayList" :key="item.id" :label="item.way" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="lookUserMap">查看地图</el-button>
      </el-form>
      <el-table :data="userWayData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="sort" label="排序">
          <template slot-scope="scope">
            <span>{{scope.row.sort == -1?'':scope.row.sort}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="wechat" label="微信昵称"> </el-table-column>
        <el-table-column prop="shopName" label="门店名称">
        </el-table-column>
        <el-table-column prop="towns" label="所属乡镇" min-width="120">
        </el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="联系方式" min-width="110">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleWaySet(scope.row,1)" v-if="wayAddButton == '1'">路线设置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" style="padding: 10px 15px">
        <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="pagesData2.currentPage" :page-sizes="pagesData2.rows" :page-size="pagesData2.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData2.total">
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="wayInfoShow = false; wayInfoForm = {};userWayData=[]">关 闭</el-button>
        <!-- <el-button @click="
              waySetForm = {};
        ">清 空</el-button>
        <el-button type="primary" @click="waySetSubmit('waySetForm')">确 定</el-button> -->
      </div>
    </el-dialog>

    <!-- 地图 -->
    <el-dialog title="客户分布地图" :visible.sync="mapShow" width="70%" @close="mapShow = false">
      <map-user></map-user>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mapShow = false;">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "../components/areaCascader.vue";
import mapUser from "../components/mapUser.vue";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  components: {
    areaCascader,
    mapUser,
  },
  data() {
    return {
      searchRegionalList:[],
      cascaderProps: { multiple: true, children: 'childs', checkStrictly: false, },
      buyListRegionalList: [],
      exportFormRegionalList: [],
      regionalRouteData: [],
      wayIds: [],
      userLevel: [
        {
          name: "普通用户",
          id: "0",
        },
        {
          name: "团长",
          id: "1",
        },
        {
          name: "批发商",
          id: "2",
        },
      ],
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      tableData: [],
      dialogTitle: "",
      loading: true,
      UserWayUpdateUrl: this.$axios.defaults.basePath + "/userWay/importUserWayUpdate",
      uploadUrl: this.$axios.defaults.basePath + "/user/importUserInfo",
      uploadWayUrl: this.$axios.defaults.basePath + "/userWay/importUserInfo",
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      //搜索
      searchForm: {
        username: "",
        wechat: "",
        shopName: "",
        phone: "",
        isWay: '',
        status: '',
        level: '',
      },
      //form表单数据
      formData: {
        adcode: "",
        address: "",
        driverId: 1,
        level: "0",
        phone: "",
        remark: "",
        shopName: "",
        towns: "",
        username: "",
        way: "",
        wechat: "",
        lng: "",
        lat: "",
        tell: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      driverList: [],
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      exportButton: "",
      importButton: "",
      auditButton: "",
      addPrivilege: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        shopName: [
          {
            required: true,
            message: "请输入门店名称",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请选择所属地区",
            trigger: "change",
          },
        ],
      },
      // 设为团长
      tuanZhangShow: false,
      userInfo: {},
      warehouseList: [],
      warehouseId: "",
      wareForm: {
        wareAdcode: "",
        place: "",
        auditStatus: "1",
      },

      setTzRules: {
        wareAdcode: [
          {
            required: true,
            message: "请选择所属仓库",
            trigger: "change",
          },
        ],
      },
      // 审核状态查询
      userStatus: [
        {
          label: '待审核',
          id: '0',
        },
        {
          label: '审核通过',
          id: '1',
        }, {
          label: '拒绝',
          id: '2',
        }, {
          label: '授权登录未提交审核',
          id: '3',
        },
      ],
      exportShow: false,
      exportForm: {},
      searchTowns: '',
      exportWayShow: false,
      exportWayForm: {
        wayId: [],
      },
      wayName: '',
      wayList: [],
      wayRules: {
        wayId: [
          {
            required: true,
            message: "请选择路线",
            trigger: "change",
          },
        ],
      },
      waySetForm: {
        wayId: '',
        way: '',
        userId: ''
      },
      waySetting: false,
      wayQueryButton: '',
      wayAddButton: '',
      wayUpdateButton: '',
      wayInfoShow: false,
      userWayData: [],
      //分页数据
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      wayInfoId: '',
      wayInfoForm: {
        wayId: ''
      },
      wayFlag: '', //导入导出标识
      uploadFile: '', //导入的文件
      isUpdate: false, //路线设置是否为编辑
      mapShow: false, //地图显示

    };
  },

  created() {
    this.listEvent();
    this.getRegionalRouteList()
    // this.getDriverAll();
    this.getWarehouseList();
    this.getWayList();
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "user_update") {
        this.updateButton = "1";
      } else if (item.authority == "user_query") {
        this.searchButton = "1";
      } else if (item.authority == "user_add") {
        this.addButton = "1";
      } else if (item.authority == "user_delete") {
        this.delButton = "1";
      } else if (item.authority == "user_import") {
        this.importButton = "1";
      } else if (item.authority == "user_export") {
        this.exportButton = "1";
      } else if (item.authority == "user_audit") {
        this.auditButton = "1";
      } else if (item.authority == "user_way_query") {
        this.wayQueryButton = "1";
      } else if (item.authority == "user_way_add") {
        this.wayAddButton = "1";
      } else if (item.authority == "user_way_update") {
        this.wayUpdateButton = "1";
      }

    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  methods: {
    // 处理数据方法
    changeKey(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].value = arr[i].id
        arr[i].label = arr[i].name
        if (arr[i].childs.length) {
          this.changeKey(arr[i].childs)
        } else {
          delete arr[i].childs
        }
      }
      return arr
    },
    // 区域列表
    async getRegionalRouteList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/regionalRoute/selectRegionalTree", {
          params: {
            current: 1,
            size: 1000
          }
        })
        .then(function (res) {
          if (res.status == 200) {
            if (res.data.length > 0) {
              // 递归 如果当前层级没有下级 则删除childs
              var newTree = _this.changeKey(res.data)
              _this.$nextTick(() => {
                _this.regionalRouteData = newTree
              })
            }
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 连级选择器
    areaChange(e) {
      let area = CodeToText[e];
      this.$set(this.searchForm, 'towns', area);

    },
    areaChangeAlls(e) {
      let p = CodeToText[e[0]]
      let c = CodeToText[e[1]]
      let a = CodeToText[e[2]]
      if (this.dialogTitle == '编辑客户' || this.dialogTitle == '添加客户') {
        this.$set(this.formData, 'towns', p + '-' + c + '-' + a);
      } else {
        this.searchTowns = e[2]
        this.$set(this.searchForm, 'towns', p + '-' + c + '-' + a);
      }
    },
    handleLock(e, id) {
      let _this = this;
      let api = "";
      if (e) {
        api = "/user/userActivate";
      } else {
        api = "/user/userLock";
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + api, {
          params: {
            id,
          },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.listEvent();
            _this.$message({
              message: "操作成功！",
              type: "success",
            });
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    exportEvent() {
      this.exportShow = true;
    },
    exportData() {
      let _this = this;
      let id = JSON.parse(JSON.stringify(_this.exportForm.wayId))
      var treedata = this.$refs.exportFormTree.getCheckedNodes();
      let ids = []
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      // var adcode = '';
      // if(_this.exportForm.status == 0 || _this.exportForm.status == 3 || sessionStorage.getItem('userName') == 'admin'){
      //   adcode = ''
      // }else{
      //   adcode = JSON.parse(sessionStorage.getItem("userInfo")).adcode
      // }
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/export", {
          params: {
            adcode: "",
            auditStatus: _this.exportForm.auditStatus,
            status: _this.exportForm.status,
            isLocation: _this.exportForm.isLocation,
            wayId: id.join(","),
            regionalId: ids.join(",")
          },
        })
        .then((res) => {
          console.log(res);
          var eleLink = document.createElement("a");
          eleLink.download = "客户信息.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          Object.keys(_this.exportForm).forEach((key) => {
            _this.exportForm[key] = "";
          });
          _this.exportFormRegionalList = []
          _this.exportForm.wayId = [];
          // 然后移除
          document.body.removeChild(eleLink);
        });
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      let type = this.$route.query.type
      if (type == 1) {
        this.$set(this.searchForm, 'isWay', 0);
        this.$set(this.searchForm, 'status', 1);
        this.$set(this.searchForm, 'auditStatus', '1');
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + "/user", {
          params: {
            username: _this.searchForm.username,
            wechat: _this.searchForm.wechat,
            shopName: _this.searchForm.shopName,
            phone: _this.searchForm.phone,
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            auditStatus: _this.searchForm.auditStatus,
            towns: _this.searchForm.towns,
            isWay: _this.searchForm.isWay,
            status: _this.searchForm.status,
            level: _this.searchForm.level,
            isLocation: _this.searchForm.isLocation,
            wayId: _this.wayIds.length ? _this.wayIds.join(',') : '',
            regionalId:_this.searchForm.regionalId
          },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.loading = false;
            let data = res.data.data.records;
            for (let i in data) {
              data[i].status == 1
                ? (data[i].statusFlag = true)
                : (data[i].statusFlag = false);
            }
            _this.tableData = data;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 查询客户详情
    handleDetails(id) {
      let that = this;
      that.dialogTitle = "客户详情";
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/details", {
          params: { id: id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            if (data.location) {
              data.location = data.location.split(",");
              data.lng = data.location[0];
              data.lat = data.location[1];
            }
            let towns = data.towns
            if (towns.indexOf('-') != -1) {
              let threeLsit = towns.split('-')
              if (threeLsit[1] == '北京市') {
                threeLsit[1] = '市辖区'
              }
              if (threeLsit[2] == '辛集市') {
                threeLsit[1] = '石家庄市'
              }
              data.code = (TextToCode[threeLsit[0]][threeLsit[1]][threeLsit[2]]).code;
            }
            data.level = data.level.toString();
            that.formData = data;
            that.id = data.id;
            that.addShow = true;
            console.log(data)
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },

    //搜索操作
    searchSubmit() {
      var treedata = this.$refs.tree.getCheckedNodes();
      let ids = []
      for (let i in treedata) {
        if (!treedata[i].children.length) {
          ids.push(treedata[i].value)
        }
      }
      this.searchForm.regionalId = ids.join(",")
      this.$set(this.pagesData, "currentPage", 1);
      this.$set(this.pagesData, "currentRows", 10);
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.searchTowns = '';
      this.wayIds = []
      this.searchRegionalList = []
      this.$set(this.searchForm, 'towns', '');
      this.$set(this.pagesData, "currentPage", 1);
      this.$set(this.pagesData, "currentRows", 10);
      if (this.$route.query.type) {
        this.$router.push({ name: 'user' });
      } else {
        this.listEvent();
      }
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.dialogTitle = "添加客户";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    add() {
      this.$router.push({ name: 'userForm', params: { type: 'add' } })
    },
    //添加采购单弹框  添加一条商品事件
    addGoods() {
      this.goodsDialogShow = true;
    },
    //编辑
    edit(row) {
      this.$router.push({ name: 'userForm', params: { type: 'edit', id: row.id } })
    },

    //点击编辑按钮操作
    handleEdit(value) {
      this.handleDetails(value.id);
      this.dialogTitle = "编辑客户";
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (!reg.test(_this.formData.phone)) {
        this.$message({
          message: "手机号格式不正确！",
          type: "warning",
        });
        _this.submitBtnLoad = false;
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          let api = "";
          if (this.dialogTitle == "添加客户") {
            api = "/user/add";
          } else {
            api = "/user/update";
          }
          if (_this.formData.lng && _this.formData.lat) {
            _this.formData.location =
              _this.formData.lng + "," + _this.formData.lat;
          } else {
            _this.formData.location = "";
          }

          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.formData),
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: res.data.data,
                type: "success",
              });
              _this.addShow = false;
              // setTimeout(()=>{
              //   window.location.reload()
              // },1000)
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
              _this.submitBtnLoad = false;
            }
          });
        } else {
          return false;
        }
      });
    },

    //删除操作
    handleDel() {
      var _this = this;
      if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        _this
          .$confirm("此操作将永久删除该客户, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            var _this = this;
            _this.$axios
              .get(_this.$axios.defaults.basePath + "/user/delete", {
                params: {
                  ids: _this.ids.join(","),
                },
              })
              .then(function (res) {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  _this.listEvent();
                }
              });
          })
          .catch(() => {
            _this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },

    handleImport() { },
    //导入数据
    handleAvatarSuccess(res, file) {
      this.loading = false;
      if (res.errcode == 0) {
        this.$message({
          message: res.data,
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
        // const mainFile = this.$refs.upload;
        //   if (mainFile && mainFile.length) {
        //       mainFile.forEach(item => {
        //       item.clearFiles();
        //       });
        //   }
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      //location. reload()
    },
    handleRemove(file) {
      console.log(file);
    },
    beforeAvatarUpload(file) {
      this.loading = true;
    },

    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    // 区域选择
    areaEvent(e) {
      if (this.dialogTitle == '编辑客户') {

        this.formData.towns = CodeToText[e]
        console.log(CodeToText[e])
      }
      this.$set(this.formData, "adcode", e);
    },
    // 选择仓库
    changeWarehouse(e) {
      console.log(e);
      this.warehouseList.forEach((item) => {
        if (e == item.id) {
          this.$set(this.wareForm, "wareAdcode", item.adcode);
          this.$set(this.wareForm, "place", item.name);
          this.$set(this.wareForm, "warehouseId", e);
        }
      });
    },
    // 审核
    handleAudit(row) {
      let _this = this;
      console.log(row, row.towns.indexOf('-'));
      if (row.towns.indexOf('-') == -1) {
        this.$confirm('此用户所属乡镇信息不正确, 请前往编辑修改?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleEdit(row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        row.level = row.level.toString();
        this.userInfo = row;
        this.tuanZhangShow = true;
        this.warehouseId = row.warehouseId;
        this.changeWarehouse(row.warehouseId);
      }
    },
    handleSetTuanZhang(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log({
            adcode: _this.wareForm.wareAdcode,
            auditStatus: _this.wareForm.auditStatus,
            place: _this.wareForm.place,
            warehouseId: _this.wareForm.warehouseId,
            id: _this.userInfo.id,
          });
          _this.submitBtnLoad = true;
          if (this.wareForm.auditStatus == 1 && !this.wareForm.warehouseId) {
            this.$message({
              message: "请选择所属仓库",
              type: "warning",
            });
            _this.submitBtnLoad = false;
            return;
          }
          this.$axios
            .get(_this.$axios.defaults.basePath + "/user/audit", {
              params: {
                adcode: _this.wareForm.wareAdcode,
                auditStatus: _this.wareForm.auditStatus,
                place: _this.wareForm.place,
                warehouseId: _this.wareForm.warehouseId,
                id: _this.userInfo.id,
              },
            })
            .then(function (res) {
              console.log(res);
              _this.submitBtnLoad = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.tuanZhangShow = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        } else {
          return false;
        }
      });
    },


    // 路线

    // 路线列表
    getWayList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/driverWay")
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.wayList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 路线设置
    handleWaySet(row, flag) {//flag是1 的话 是在路线信息规划里修改人员路线，这时取row的id

      if (row.way) {
        if (flag == 1) {
          this.$set(this.waySetForm, 'userId', row.id)
        } else {
          this.$set(this.waySetForm, 'userId', row.driverId)
        }

        this.wayList.forEach(item => {
          if (row.way == item.way) {
            this.$set(this.waySetForm, 'wayId', item.id)
          }
        })
        this.isUpdate = true;
      } else {
        this.isUpdate = false;
        this.$set(this.waySetForm, 'userId', row.id)
      }
      this.waySetting = true;
    },
    waySetChange(e) {
      this.wayList.forEach(item => {
        if (e == item.id) {
          this.$set(this.waySetForm, 'way', item.way)
        }
      })
    },
    // 设置路线提交
    waySetSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          if (this.isUpdate) { //修改路线
            this.$axios({
              url: _this.$axios.defaults.basePath + '/userWay/update',
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                id: _this.waySetForm.userId,
                way: _this.waySetForm.way,
                wayId: _this.waySetForm.wayId
              }),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.getWayUserInfo(_this.wayInfoId);
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.waySetting = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
                _this.submitBtnLoad = false;
              }
            });
          } else { //设置路线
            this.$axios({
              url: _this.$axios.defaults.basePath + '/userWay/add',
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.waySetForm),
            }).then(function (res) {
              if (res.data.errcode == 0) {
                _this.submitBtnLoad = false;
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.waySetting = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
                _this.submitBtnLoad = false;
              }
            });
          }

        } else {
          return false;
        }
      });
    },
    wayChange(e) {
      console.log(e, "e")
      let name = []
      this.wayList.forEach(item => {
        e.forEach(it => {
          if (it == item.id) {
            name.push(item.way)
          }
        })
      })
      this.wayName = name.join(",")
    },

    // 导入导出路线数据
    exportWayEvent(flag) {
      this.wayFlag = flag
      this.wayName = ''
      this.exportWayForm = {};
      this.exportWayShow = true;
    },
    // 取消关闭路线导入导出
    cancelExportWay() {
      window.location.reload();
    },
    // 路线信息规划
    wayInfoEvent() {
      this.wayInfoShow = true
    },
    wayInfoChange(e) {
      this.wayInfoId = e;
      this.pagesData2.currentPage = 1;
      this.pagesData2.currentRows = 10;
      this.getWayUserInfo(e);
    },
    // 获取路线用户信息
    getWayUserInfo(wayId) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/userWay", {
          params: {
            wayId: wayId,
            current: _this.pagesData2.currentPage,
            size: _this.pagesData2.currentRows,
          }
        })
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.userWayData = res.data.data.records;
            _this.pagesData2.total = res.data.data.total
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      this.getWayUserInfo(this.wayInfoId);
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      this.getWayUserInfo(this.wayInfoId);
    },
    //导入路线数据
    handleAvatarSucces2(res, file) {
      this.loading = false;
      this.submitBtnLoad = false;
      if (res.errcode == 0) {
        this.$message({
          message: res.data,
          type: "success",
        });
        this.exportWayShow = false;
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
      }
    },
    handleRemove2(file) {
      // console.log(file);
    },
    changeUpload2(file, fileList) {
      console.log(file, fileList)
    },
    exportWayData() {
      let _this = this;
      _this.submitBtnLoad = true;
      if (this.wayFlag == 1) { //导入
        if (!this.wayName) {
          this.$message({
            message: '请选择路线',
            type: 'warning'
          })
        } else {
          this.$refs.uploadWay.submit();
        }

      } else { //导出
        let id = JSON.parse(JSON.stringify(_this.exportWayForm.wayId))
        this.$axios
          .get(this.$axios.defaults.basePath + "/userWay/export", {
            params: {
              wayId: id.join(","),
            },
          })
          .then((res) => {
            console.log(res);
            _this.submitBtnLoad = false;
            var eleLink = document.createElement("a");
            eleLink.download = _this.wayName + "客户信息.csv";
            eleLink.style.display = "none";

            var blob = new Blob([res.data]);
            eleLink.href = URL.createObjectURL(blob);
            console.info(blob);
            // 触发点击
            document.body.appendChild(eleLink);
            eleLink.click();
            _this.$message({
              message: "导出成功",
              type: "success",
            });
            _this.exportForm = {};
            _this.exportWayShow = false;
            // 然后移除
            document.body.removeChild(eleLink);
          });
      }

    },
    // 显示地图
    lookUserMap() {
      this.mapShow = true;
    },

  },
};
</script>

