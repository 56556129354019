<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <!-- <el-button @click="addEditCancel">取 消</el-button> -->
        <el-button :disabled="dialogTitle != '产品编辑'" type="primary" :loading="btnLoading" @click="addEditSubmit('addEditForm')">保 存</el-button>
        <el-button :disabled="dialogTitle != '产品新增'" type="primary" :loading="btnLoading" @click="addEditSubmit('addEditForm')">保存并新增</el-button>
      </div>
      <div class="goods">
        <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px">
          <el-form-item label="产品名称" prop="name">
            <el-input v-model="addEditForm.name" placeholder="请输入产品名称" autocomplete="off" style="width: 300px" maxlength="50" clearable :disabled="detailsShow" @input="inputGoodsName"></el-input>
          </el-form-item>
          <el-form-item label="产品类型">
            <el-cascader ref="typeCascader" style="width: 300px" :options="goodsType" v-model="goodsTypeId2" placeholder="请选择产品类型" :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }" :disabled="detailsShow" clearable @change="cascaderChange2"></el-cascader>
          </el-form-item>
          <el-form-item label="产品单位">
            <el-select v-model="addEditForm.unitId" style="width: 300px" @change="unitChange" :disabled="detailsShow">
              <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="颜色">
            <el-input v-model="addEditForm.color" placeholder="请输入颜色" style="width: 300px" :disabled="detailsShow">
            </el-input>
          </el-form-item>
          <el-form-item label="型号">
            <el-input v-model="addEditForm.model" placeholder="请输入型号" style="width: 300px" :disabled="detailsShow">
            </el-input>
          </el-form-item>
          <el-form-item label="规格">
            <el-input v-model="addEditForm.standard" placeholder="请输入规格" style="width: 300px" :disabled="detailsShow">
            </el-input>
          </el-form-item>
          <el-form-item label="制造商">
            <el-input v-model="addEditForm.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="detailsShow">
            </el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="addEditForm.enabled" placeholder="请选择状态" style="width: 300px" :disabled="detailsShow">
              <el-option v-for="(item, index) in goodsStatus" :key="index" :label="item.label" :value="item.id" :disabled="detailsShow"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述">
            <el-input type="textarea" v-model="addEditForm.describe" placeholder="请输入描述" style="width: 300px" :rows="6" :disabled="detailsShow"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="addEditForm.remark" placeholder="请输入备注" style="width: 300px" :rows="6" :disabled="detailsShow"></el-input>
          </el-form-item>
          <el-form-item label="产品图片" style="display: block">
            <cropperAndUploadVue :disabled="detailsShow" :list="fileList" @change="handleChange" @success="handleSuccess">
            </cropperAndUploadVue>
          </el-form-item>
          <el-form-item label="拖拽排序" v-if="!detailsShow && imgData.length > 1">
            <vuedraggable class="flex_left" v-model="imgData">
              <div v-for="(item, index) in imgData" :key="index">
                <img :src="item" alt="" width="100px" style="margin-right: 15px" />
              </div>
            </vuedraggable>
          </el-form-item>
          <el-form-item label="产品详情图" prop="info_img" style="display: block">
            <cropperAndUploadVue :disabled="detailsShow" :list="fileListInfo" @change="handleChangeInfoImg" @success="handleSuccessInfoImg">
            </cropperAndUploadVue>
          </el-form-item>
          <el-form-item label="产品视频" style="display: block">
            <!-- multiple -->
            <el-upload class="upload-demo" :action="uploadUrl2" :headers="headers" v-if="!detailsShow" :on-success="handleAvatarSuccess2" :on-remove="handleRemove2" :before-upload="handleFileChange" accept=".mp4,.3gp,.m3u8" :show-file-list="videoFileShow">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传mp4/3gp/m3u8格式且不能大于50M。
              </div>
            </el-upload>
            <el-button size="small" type="danger" v-if="detailsShow == false && addEditForm.videos" @click="videoDelete" style="margin-bottom: 15px">删除视频</el-button>
            <!-- 视频预览 -->
            <div class="video_box" v-if="addEditForm.videos">
              <video :src="addEditForm.videos" controls width="200px"></video>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
export default {
  name: "goodsIndex",
  components: {
    vuedraggable,
    cropperAndUploadVue: () => import("@/components/cropperAndUpload.vue"),
  },
  data() {
    return {
      uploadLoading: false,
      importHeaders: { Authorization: sessionStorage.getItem("Authorization") },
      uploadUrl3: this.$axios.defaults.basePath + "/import/importGoods",
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUpload",
      uploadUrl2: this.$axios.defaults.basePath + "/image/AliYunVodUpload",
      loading: false,
      btnLoading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
        imgs: [
          {
            required: true,
            message: "请至少上传一张产品图片",
            trigger: "blur",
          },
        ],
      },
      // 产品类型
      goodsType: [],
      // 产品单位
      goodsUnit: [],
      // 产品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      videoData: [],
      fileList2: [],
      videoFileShow: true,
      // 图片拖拽排序
      groups: [],
      fileListInfo: [],
      imgDataInfo: [],
      goodsId: '',
    };
  },
  created() {
    this.getGoodsType();
    this.getGoodsUnit();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "goods_update") {
        this.updateButton = "1";
      } else if (item.authority == "goods_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_delete") {
        this.delButton = "1";
      } else if (item.authority == "goods_query") {
        this.searchButton = "1";
      }
    });

  },
  activated() {
    console.log(this.$route)
    if (this.$route.params.type == 'details') {
      this.dialogTitle = '产品详情'
      this.detailsShow = true
      this.goodsId = this.$route.params.id
      this.imgData = []
      this.handleGoodsInfo()
    } else if (this.$route.params.type == 'edit') {
      this.detailsShow = false
      this.dialogTitle = '产品编辑'
      this.goodsId = this.$route.params.id
      this.imgData = []
      this.handleGoodsInfo()
    } else if (this.$route.params.type == 'add' || this.$route.query.type == 'add') {
      this.dialogTitle = '产品新增'
      this.addEditForm = {}
      this.fileList = []
      this.fileListInfo = []
      this.imgData = []
    } else{
      this.dialogTitle = '产品新增'
    }
  },
  mounted() {
    switch(this.$route.params.type){
      case 'details':
        this.dialogTitle = '产品详情'
        this.detailsShow = true
        this.imgData = []
        this.goodsId = this.$route.params.id
        break;
      case 'edit':
        this.dialogTitle = '产品编辑'
        this.imgData = []
        this.goodsId = this.$route.params.id
        break;
      case 'add':
        this.dialogTitle = '产品新增'
        this.addEditForm = {}
        this.fileList = []
        this.fileListInfo = []
        this.imgData = []
        break;
    }
    this.handleGoodsInfo()
  },
  methods: {
    // 获取商品信息
    async handleGoodsInfo() {
      if(!this.goodsId) return;
      try{
        const res = await this.$axios
          .get(this.$axios.defaults.basePath + "/goods/getById", {
            params: { id: this.goodsId },
          })
          if (res.data.errcode == 0) {
          let data = res.data.data;
          this.fileList = [];
          data.enabled = data.enabled.toString();
          await this.$nextTick()

          this.addEditForm = data;
          // 连级选择器回显
          this.goodsTypeId2 = this
            .familyTree(this.goodsType, data.categoryId)
            .reverse();
          if (data.imgs) {
            const imgArr = data.imgs.split(",");
            this.imgData = imgArr;
            this.fileList = imgArr.map(item=> ({ url: item }))
          }
          if (data.goodsInfo) {
            const imgArr = data.goodsInfo.split(",");
            this.imgDataInfo = imgArr;
            this.fileListInfo = imgArr.map(item => ({ url: item }))
          }
          if (data.videos) {
            let videoArr = data.videos.split(",").map((data) => ({ data }));
            this.$set(this.addEditForm, "videos", videoArr[0].data);
            this.videoData = videoArr;
            this.fileList2 = videoArr.map(item => ({ url: item.data }))
          }
          
        }
      }catch(error){
        console.error(error)
      }
    },
    handleAvatarErr() {
      this.uploadLoading = false;
    },
    //导入修改订单状态数据
    uploadSuccess(res, file) {
      this.uploadLoading = false;
      if (res.errcode == 0) {
        this.listEvent()
        this.$message({
          message: res.data,
          type: "success",
        });
      } else {
        this.$message({
          message: res.errmsg,
          type: "warning",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    beforeAvatarUpload(file) {
      this.uploadLoading = true;
    },
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    familyTree(arr1, id) {
      const findFamilyTree = (arr, currentId) => {
        const item = arr.find(item => item.id === currentId);
        if (!item) {
          return [];
        }
        const family = [item.id];
        if (item.parentId !== null) {
          family.push(...findFamilyTree(arr, item.parentId));
        }
    
        return family;
      };
    
      return findFamilyTree(arr1, id);
    },
    
    // 获取产品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      window.location.reload();
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.fileList, this.imgData)
          let url = "";
          if (this.dialogTitle == "产品新增") {
            url = "/goods/add";
          } else {
            url = "/goods/update";
          }
          if (this.videoData.length > 0) {
            let videos = this.videoData.map(item=>(item.data))
            _this.addEditForm.videos = videos.join(",");
          } else {
            _this.addEditForm.videos = "";
          }
          if (this.imgData.length > 0) {
            this.addEditForm.imgs = this.imgData.join(",");
          }
          console.log(_this.addEditForm);
          this.btnLoading = true;
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          })
            .then((res) => {
              this.btnLoading = false;
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.$router.push({ name: 'goodsIndex',params:{type:'back'}})
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.imgData.push(res);
      console.log(this.imgData);
      if (this.imgData.length > 0) {
        this.$set(this.addEditForm, "imgs", this.imgData.join(","));
      }
    },
    handleChange(fileList, ind) {
      console.log(fileList, ind)
      this.fileList = fileList;
      if (fileList.length == 0) {
        this.imgData = []
      }
    },
    handleSuccess(imgData, removeIndex) {
      if (removeIndex) {
        this.imgData = imgData
      } else {
        if (imgData.length > 1) {
          this.imgData = imgData
        } else if (imgData.length == 1) {
          this.imgData.push(imgData[0])
        }
      }
      console.log(this.imgData)
      if (this.imgData.length > 0) {
        this.$set(this.addEditForm, "imgs", this.imgData.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },
    handleChangeInfoImg(fileList) {
      console.log('12')
      this.fileList2 = fileList;
    },
    handleSuccessInfoImg(imgData) {
      this.imgDataInfo = imgData;
      if (this.imgDataInfo.length > 0) {
        this.$set(this.addEditForm, "goodsInfo", this.imgDataInfo.join(","));
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },

    handleRemove(file) {
      console.log(file);
    
      const url = file.response ? file.response : file.url;
    
      // 使用过滤函数移除不匹配的项并创建新的 fileList
      this.fileList = this.imgData.filter(item => item !== url).map(item => ({ url: item }));
    
      // 更新 imgData 数组
      this.imgData = this.imgData.filter(item => item !== url);
    
      // 更新 addEditForm.imgs
      this.addEditForm.imgs = this.imgData.join(",");
    
      console.log(this.imgData);
    },
    //上传视频事件
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.videoData = [];
      this.fileList2 = [];
      this.videoData.push(res);
      this.fileList2.push({ url: res.data });
      this.$set(this.addEditForm, "videos", res.data);
      this.videoFileShow = false;
    },
    // 上传视频删除
    handleRemove2(file) {
      let url = file.url ? file.url : file.response;
      this.videoData = this.videoData.filter(item=>item !==url);
      const ind = this.fileList2.findIndex(item => item.url === url)
      this.fileList2.splice(ind, 1);
    },
    // 限制视频大小
    handleFileChange(file) {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 <= 50;
        if (!isLt5M) {
          this.$message.error("上传视频大小不能超过 50MB!");
          return reject(false);
        } else {
          return resolve(true);
        }
      });
    },
    inputGoodsName(e) {
      if (e.length >= 50) {
        this.$message({
          message: "产品名称不能大于50个字符",
          type: "warning",
        });
      }
    },
    // 视频删除
    videoDelete() {
      this.$confirm("是否确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.videoData = [];
          this.$set(this.addEditForm, "videos", "");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style scoped>
.boxMain >>> .el-image img {
  height: auto !important;
}
.goods {
  height: 70vh;
  overflow: auto;
}
</style>
