<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="产品名称：">
          <el-input v-model="searchForm.goodsName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="订单编码：">
          <el-input v-model="searchForm.orderCode" placeholder="请输入订单编码"></el-input>
        </el-form-item>
        <el-form-item label="所属仓库">
          <el-select v-model="searchForm.warehouseId" placeholder="请选择所属仓库">
            <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="warehouseId" label="出库类型" v-if="type == 2">
          <el-select v-model="searchForm.type" @change="typeChange">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="请选择状态">
            <el-option v-for="item in goodsStatus" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- v-if="type == 2" -->
        <el-form-item label="日期:" >
          <el-date-picker :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">{{ type == 1 ? "入库管理" : "出库管理" }}</p>
        <div class="tableTopBtn">
          <el-button type="primary" v-if="addButton == '1'" @click="handleAdd" size="mini">新增</el-button>
          <el-button type="primary" v-if="addButton == '1'" @click="handlePrint" size="mini">打印</el-button>
          <!-- <el-button type="primary"  v-if="approvalButton == '1'" @click="handleShenHe" size="mini">审核</el-button> -->
          <!-- <el-button
            type="danger"
            v-if="delButton == '1'"
            @click="handleDels"
            size="mini"
            >删除</el-button
          > -->
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :show-summary="type==2?true:false" :summary-method="getSummaries" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库名称">
            </el-table-column>
            <el-table-column prop="type" label="订单类型">
              <template slot-scope="scope">
                {{
                  scope.row.type == 1
                    ? "采购单"
                    : scope.row.type == 2
                    ? "销售单"
                    : scope.row.type == 3
                      ? "其他"
                      : scope.row.type == 5
                        ? "盘亏"
                        : scope.row.type == 6
                          ? "生产领料"
                          : scope.row.type == 7
                            ? "借出"
                            : scope.row.type == 8
                              ? "委外发料"
                              : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="form" label="出入库类型">
              <template slot-scope="scope">
                {{
                  scope.row.form == 1
                    ? "入库"
                    : scope.row.form == 2
                    ? "出库"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="goodsInfo" label="商品信息" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column prop="status" label="审核状态">
              <template slot-scope="scope" width="70px">
                <p v-if="scope.row.status == 0" style="color: red">未审核</p>
                <p v-if="scope.row.status == 1" style="color: #67c23a">
                  已审核
                </p>
              </template>
            </el-table-column> -->
            <el-table-column prop="returnType" :label="type==1?'入库类型':'出库类型'"></el-table-column>
            <el-table-column prop="orderCode" label="关联订单号" width="200px" show-overflow-tooltip>
              <template slot-scope="scope">
                <p class="numberId" @click="$router.push({ name: 'purchaseForm', params: { type: 'details', id: scope.row.orderCode }})">{{scope.row.orderCode}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="金额" show-overflow-tooltip v-if="type == 2">
              <template slot-scope="scope">
                {{scope.row.totalPrice}} 元
              </template>
            </el-table-column>
            <el-table-column prop="arrivalTime" label="预计到货时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="created" label="创建时间" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="260">
              <template slot-scope="scope">

                <el-button type="primary" size="mini" v-if="updateButton == '1'" :disabled="scope.row.status == 9" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="primary" @click="handleGoodsList(scope.row)" size="mini" v-if="searchButton == '1'">商品明细</el-button>
                <el-button type="danger" @click="handleDels(scope.row.id)" size="mini" :disabled="scope.row.type == 1 || scope.row.status == 9" v-if="delButton == '1'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--商品明细-->
    <el-dialog title="商品明细" :visible.sync="goodsListShow" width="70%">
      <div>
        <el-table :data="goodsList" tooltip-effect="dark" style="width: 100%">
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
          <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
          <el-table-column prop="price" label="采购单价(元)"> </el-table-column>
          <el-table-column prop="sale" label="销售单价(元)"> </el-table-column>
          <el-table-column prop="type" label="订单类型">
            <template slot-scope="scope">{{
              scope.row.type == 1
                ? "采购单"
                : scope.row.type == 2
                ? "销售单"
                : ""
            }}</template>
          </el-table-column>
          <el-table-column prop="form" label="出入库类型">
            <template slot-scope="scope">{{
              scope.row.form == 1 ? "入库" : "出库"
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="warehouseName" label="仓库名称"> -->
          <!-- </el-table-column> -->
          <el-table-column prop="status" label="审核状态">
            <template slot-scope="scope">
              {{
                scope.row.status == 0
                  ? "未审核"
                  : scope.row.status == 1
                  ? "已审核"
                  : ""
              }}</template>
          </el-table-column>
          <el-table-column prop="created" label="创建时间" min-width="120">
          </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsListShow = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 新增/修改 -->
    <el-dialog :title="addEditTitle" :visible.sync="addEditShow" width="85%">
      <div>
        <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true">
          <el-form-item prop="warehouseId" label="选择仓库">
            <el-select v-model="addEditForm.warehouseId" @change="warehouseChange">
              <el-option v-for="item in warehouseData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="warehouseId" label="选择出库类型" v-if="type == 2">
            <el-select v-model="addEditForm.returnType">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择时间" prop="created">
            <el-date-picker v-model="addEditForm.created" type="datetime" style="width: 300px" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" placeholder="请输入备注" style="width: 300px" v-model="addEditForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="changeInputGoods" type="primary" style="margin-bottom: 20px">选择商品</el-button>
        <el-table ref="goodsFormTable" :data="addEditForm.warehouseDetailedList" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
          <el-table-column prop="standard" label="规格"> </el-table-column>
          <el-table-column prop="num" label="库存"> </el-table-column>
          <el-table-column prop="goodsNum" label="数量" width="210px">
            <template slot-scope="scope">
              <el-popover :ref="'popover'+scope.$index" placement="bottom" width="200" trigger="focus">
                <p>销售库存：{{goodsNum.saleSum}}</p>
                <p>实际库存：{{goodsNum.num}}</p>
                <p>库存总量：{{goodsNum.saleTotalNum}}</p>
              </el-popover>
              <el-input v-if="type == 1" @input="changeNum(scope.row, $event, 1)" v-model="scope.row.goodsNum"></el-input>
              <el-input v-popover="'popover'+scope.$index" @focus="numFocus(scope)" v-if="type == 2" @input="changeNum(scope.row, $event, 2)" v-model="scope.row.goodsNum"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="price" :label="type==1?'入库单位成本':'出库单位成本'">
            <template slot-scope="scope">
              <el-input :disabled="type == 2" @input="changePrice(scope.row, $event, 'price')" v-model="scope.row.price"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="sale" :label="type==1?'入库成本':'出库成本'">
            <template slot-scope="scope">
              <el-input disabled @input="changePrice(scope.row, $event, 'sale')" v-model="scope.row.sale"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="enabled" label="状态">
            <template slot-scope="scope">
              <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
              <p v-else-if="scope.row.enabled == 1" style="color: #67c23a">
                启用
              </p>
              <p v-else></p>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注">
            <template slot-scope="scope">
              <el-input placeholder="备注" v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button size="medium" type="danger" class="el-icon-minus" @click="chengeOrderItem(scope)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addEditShow = false">关 闭</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 商品列表 -->
    <el-dialog :title="addEditTitle" :visible.sync="changeGoodsShow" width="85%">
      <div>
        <!-- :warehouseName="warehouseName" -->
        <goods v-if="changeGoodsShow" :type="type" :fromPage="pageName" :warehouseId="addEditForm.warehouseId" @goodsSelected="goodschange" @cancelDialog="cancelDialog"></goods>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            sections = [];
            changeGoodsShow = false;
          "
          >关 闭</el-button
        >
        <el-button type="primary" @click="setSections">确 定</el-button>
      </div> -->
    </el-dialog>
    <!-- <el-dialog :title="type==1?'入库单打印':'出库单打印'" :visible.sync="printStockShow" width="85%" @close="closeDialog('printStockShow')">
      
    </el-dialog> -->
    <printStock :printOrderData="printData" :printType="type" v-show="printStockShow"></printStock>
    
  </div>
</template>

<script>
import goods from "../../components/purchaseGoods2.vue";
import printStock from "../../components/printStock.vue";
export default {
  name: "goodsIndex",
  components: {
    goods,
    printStock
  },
  data() {
    return {
      goodsNum: {},
      datePicker: [],
      warehouseName: "",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      approvalButton: "",
      // type=1入库 2出库
      type: "",
      searchButton: "",
      searchForm: {
        goodsName: "",
        name: "",
        warehouseId: "",
        status: "",
        type: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [1000, 5000, 10000, 100000],
      },
      pagesData2: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      // 商品状态
      goodsStatus: [
        {
          label: "未审核",
          id: "0",
        },
        {
          label: "已审核",
          id: "1",
        },
      ],
      // 仓库数据
      warehouseData: [],
      // 商品明细
      goodsListShow: false,
      goodsList: [],
      // 出入库列表复选框
      ids: [],
      multipleSelection: [],
      // 新增编辑
      addEditForm: {
        // 1入库，2出库
        form: "",
        status: 0,
        warehouseName: "",
        warehouseId: "",
        type: "",
        // 商品列表
        warehouseDetailedList: [],
      },
      addEditShow: false,
      addEditRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ],
      },
      addEditTitle: "",
      // 选择商品
      changeGoodsShow: false,
      addEditGoodsList: [],
      // 选择商品回參
      sections: [],
      // 商品组件传參
      pageName: "warehouse",
      // 出库类型
      typeList: [
      ],
      // 打印弹框
      printStockShow:false,
      printData:[],
    };
  },
  // 导航守卫
  beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    console.log(to)
    next((vm) => {
      vm.type = to.query.type;
      vm.listEvent();
      vm.warehouse();
      vm.$set(vm.addEditForm, "form", vm.type);
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.type = this.$route.query.type;
    if (this.$route.query.orderCode) {
      this.$set(this.searchForm, 'orderCode', this.$route.query.orderCode)
    }
    this.listEvent();
    this.warehouse();
    this.$set(this.addEditForm, "form", this.type);
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    next();
  },
  created() {
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "warehouse_in_out_order_edit") {
        this.updateButton = "1";
      } else if (item.authority == "warehouse_in_out_order_add") {
        this.addButton = "1";
      } else if (item.authority == "goods_unit_delete") {
        this.delButton = "1";
      } else if (item.authority == "warehouse_in_out_order_approval") {
        this.approvalButton = "1";
      } else if (item.authority == "warehouse_in_out_order_query") {
        this.searchButton = "1";
      }
    });

    this.type = this.$route.query.type;
    if (this.$route.query.orderCode) {
      this.$set(this.searchForm, 'orderCode', this.$route.query.orderCode)
    }
    this.listEvent();
    this.warehouse();
    this.getOuterList();
    this.$set(this.addEditForm, "form", this.type);

    // 采购单生成其他出库单
    if (this.$route.query.data) {
      this.type = 2
      let newData = JSON.parse(this.$route.query.data)
      newData.returnType = '其他出库'
      newData.id = ''
      newData.purchaseChildOrderList.map(item => {
        return item.goodsNum = item.num
      })
      this.handleAdd()
      let newJson = {
        form: 2,
        returnType: "其他出库",
        status: '',
        type: "",
        warehouseDetailedList: newData.purchaseChildOrderList,
        warehouseId: newData.warehouseId,
        warehouseName: newData.warehouseName
      }
      this.addEditForm = newJson
      console.log(newJson)
    }

  },
  watch: {
    addEditShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.addEditForm).forEach((key) => {
            this.addEditForm[key] = "";
          });
          this.sections = [];
          this.addEditForm.warehouseDetailedList = [];
        }
      },
    },
  },
  methods: {
    closeDialog(s){
      this[s] = false
    },
    // 打印
    handlePrint(){
      let list = JSON.parse(JSON.stringify(this.multipleSelection));
      if(list.length ==0){
        this.$message({
          message:'请勾选数据',
          type:'warning'
        })
        return
      }
      list.forEach(item=>{
        item.totalNum = 0
        item.totalPrice = 0
        item.warehouseDetailedList.forEach(child=>{
          child.price = child.price.toFixed(2)
          child.goodsNum = child.goodsNum.toFixed(2)
          child.totalPrice = (Number(child.price) * Number(child.goodsNum)).toFixed(2)
          item.totalNum+= Number(child.goodsNum)
          item.totalPrice += Number(child.totalPrice)
        })
        item.totalNum = item.totalNum.toFixed(2)
        item.totalPrice = item.totalPrice.toFixed(2)
        item.moneyZh = this.numberParseChina(item.totalPrice)
      })
      this.printData = list
      this.printStockShow = true
      console.log(list)
    },
    // 金额数字转大写
    numberParseChina(money) {
      //汉字的数字
      var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      //基本单位
      var cnIntRadice = ['', '拾', '佰', '仟'];
      //对应整数部分扩展单位
      var cnIntUnits = ['', '万', '亿', '兆'];
      //对应小数部分单位
      var cnDecUnits = ['角', '分', '毫', '厘'];
      //整数金额时后面跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '圆';
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = '';
      //分离金额后用的数组，预定义
      var parts;
      if (money == '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return '';
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
    //数量输入框 获取焦点
    numFocus(scope) {
      let { goodsId } = scope.row
      let warehouseId = this.addEditForm.warehouseId
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/getGoodsNum", {
          params: {
            goodsId, warehouseId
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsNum = res.data.data;
          }
        });
    },
    chengeOrderItem(scope) {
      this.addEditForm.warehouseDetailedList.splice(scope.$index, 1)
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[1] = '总价';
      const values = data.map(item => Number(item["totalPrice"]));
      sums[7] = values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return (Number(prev) + curr).toFixed(2);
        } else {
          return prev;
        }
      }, 0);
      sums[7] += ' 元';
      return sums;
    },
    cancelDialog() {
      this.changeGoodsShow = false;
    },
    // 获取出库类型列表
    getOuterList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectByList?type=4")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.typeList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 商品数量修改
    changeNum(row, e, type) {
      row.goodsNum = this.$cjs.getNum(e);
      row.sale = (row.goodsNum * row.price).toFixed(2)
      if (type == 2) {
        if (row.goodsNum > row.num) {
          row.goodsNum = "";
          this.$message({
            message: "当前数量超出库存，请重新输入！",
            type: "error",
          });
          return;
        }
      }
    },
    // 商品采购价、销售价修改
    changePrice(row, e, name) {
      row[name] = this.$cjs.getNum(e);
      row.sale = (row.goodsNum * row.price).toFixed(2)
      // if (Number(e) <= 0) {
      //   this.$message({
      //     message: "请输入正确的价格",
      //     type: "error",
      //   });
      //   row[name] = 1;
      //   return;
      // } else {
      //   row[name] = e;
      // }
    },
    // 入库列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      if (this.type) {
        let data = {
          current: _this.pagesData.currentPage,
          size: _this.pagesData.currentRows,
          form: _this.type,
          status: _this.searchForm.status,
          warehouseId: _this.searchForm.warehouseId,
          type: _this.searchForm.type,
          orderCode: _this.searchForm.orderCode,
          goodsName: _this.searchForm.goodsName,
        }
        if (this.type == 2) {
          data.startTime = this.datePicker.length ? this.datePicker[0] : ''
          data.endTime = this.datePicker.length ? this.datePicker[1] : ''
        }
        this.$axios
          .get(_this.$axios.defaults.basePath + "/warehouseInOutOrder", {
            params: data,
          })
          .then((res) => {
            _this.loading = false;
            if (res.data.errcode == 0) {
              res.data.data.warehouseInOutOrderPage.records.forEach((item) => {
                if (item.imgs) {
                  item.icons = item.imgs.split(",")[0];
                }
                // 商品明细
                item.goodsInfo = "";
                var arr = [];
                if (item.warehouseDetailedList.length > 0) {
                  item.warehouseDetailedList.forEach((child) => {
                    arr.push(child.goodsName + "*" + child.goodsNum);
                  });
                  item.goodsInfo = arr.join("，");
                }
              });
              _this.tableData = res.data.data.warehouseInOutOrderPage.records;
              _this.pagesData.total = res.data.data.warehouseInOutOrderPage.total;
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
      }
    },
    // 复选框多选
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增/编辑复选框多选
    handleSelectionChange2(val) {
      var _this = this;
      let newArr = [];
      val.forEach((item) => {
        let json = {
          warehouseId: _this.addEditForm.warehouseId,
          form: _this.type,
          goodsId: item.id,
          goodsNum: item.goodsNum,
          goodsName: item.name,
          remark: item.remark,
          status: item.enabled,
        };
        newArr.push(json);
      });
      this.$set(this.addEditForm, "warehouseDetailedList", newArr);
    },
    // 仓库列表
    warehouse() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          console.log(res, "asdasdd")
          if (res.data.errcode == 0) {
            _this.warehouseData = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 商品列表
    getGoodsList() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsMall/selectByPage", {
          params: {
            current: _this.pagesData2.currentPage,
            size: _this.pagesData2.currentRows,
            warehouseName: _this.addEditForm.warehouseName,
            mainGoods: 1
          },
        })
        .then((res) => {
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.addEditGoodsList = res.data.data.records;
            _this.pagesData2.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      // this.pagesData.currentPage = 1;
      // this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this.datePicker = []
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this[formName] = {};
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //分页--每页条数切换
    handleSizeChange2(val) {
      var _this = this;
      _this.pagesData2.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange2(val) {
      var _this = this;
      _this.pagesData2.currentPage = val;
      this.listEvent();
    },
    // 新增
    handleAdd() {
      if (this.type == 1) {
        this.addEditTitle = "新增入库信息";
      } else {
        this.addEditTitle = "新增出库信息";
      }
      this.addEditShow = true;
    },
    // 编辑
    handleEdit(row) {
      let _this = this;
      if (this.type == 1) {
        this.addEditTitle = "编辑入库信息";
      } else {
        this.addEditTitle = "编辑出库信息";
      }
      this.addEditShow = true;
      _this.$axios
        .get(_this.$axios.defaults.basePath + "/warehouseInOutOrder/getById", {
          params: {
            id: row.id,
          },
        })
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.addEditForm = JSON.parse(JSON.stringify(res.data.data));
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
      // this.sections = [];
      // this.$nextTick(() => {
      //   // row.warehouseId = +row.warehouseId;
      //   this.addEditForm = JSON.parse(JSON.stringify(row));
      // });

      // row.warehouseDetailedList.forEach((item) => {
      //   let newJson = {
      //     name: item.goodsName,
      //     num: item.num,
      //     type: item.type,
      //     status: item.status,
      //     goodsId: item.goodsId,
      //     goodsNum: item.goodsNum,
      //     enabled: item.status,
      //     warehouseId: item.warehouseId,
      //     orderCode: item.orderCode,
      //     remark: item.remark,
      //     form: item.form,
      //     warehouseName: item.warehouseName,
      //   };
      //   this.sections.push(newJson);
      // });
    },
    // 选择仓库
    warehouseChange() {
      var id = this.addEditForm.warehouseId;
      var name = "";
      this.warehouseData.forEach((item) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      this.$set(this.addEditForm, "warehouseName", name);
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          let txt = "";
          if (this.type == 1) {
            txt = "新增入库信息";
            _this.addEditForm.returnType = "其他入库"
          } else {
            txt = "新增出库信息";
          }
          if (this.addEditTitle == txt) {
            url = "/warehouseInOutOrder/add";
          } else {
            url = "/warehouseInOutOrder/edit";
          }

          if (this.addEditForm.warehouseDetailedList.length > 0) {
            if (this.type == 1) {
              for (let i in this.addEditForm.warehouseDetailedList) {
                this.addEditForm.warehouseDetailedList[i].returnType = "其他入库"
              }
            }
            _this.addEditForm.form = _this.type;
            this.addEditForm.warehouseDetailedList.forEach(item => {
              item.type = 3
            })
            this.$axios({
              url: _this.$axios.defaults.basePath + url,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(_this.addEditForm),
            }).then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.addEditShow = false;
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          } else {
            _this.$message({
              message: "请选择商品",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 审核
    handleShenHe() {
      let _this = this;
      if (_this.ids.length > 0) {
        this.$confirm("是否确认执行此操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            _this.$axios
              .get(
                _this.$axios.defaults.basePath +
                "/warehouseInOutOrder/approval",
                {
                  params: {
                    ids: _this.ids.join(","),
                    status: 1,
                  },
                }
              )
              .then((res) => {
                if (res.data.errcode == 0) {
                  _this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  _this.listEvent();
                } else {
                  _this.$message({
                    message: res.data.errmsg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        _this.$message({
          message: "请选择要操作的数据",
          type: "warning",
        });
      }
    },
    // 商品明细
    handleGoodsList(row) {
      this.goodsList = row.warehouseDetailedList;
      this.goodsListShow = true;
    },
    // 选择商品显示商品列表
    changeInputGoods() {
      if (this.addEditForm.warehouseName) {
        this.warehouseName = this.addEditForm.warehouseName;
        // this.getGoodsList();
        this.changeGoodsShow = true;
      } else {
        this.$message({
          message: "请选择仓库",
          type: "warning",
        });
      }
    },
    // 商品组件选中商品回參
    goodschange(e) {
      e.forEach((item) => {
        item.goodsNum = 1;
        item.goodsId = item.id;
        item.goodsName = item.name;
        item.remark = item.purchaseRmark;
        item.sale = item.price;
      });
      this.changeGoodsShow = false;
      this.addEditForm.warehouseDetailedList =
        this.addEditForm.warehouseDetailedList.concat(
          JSON.parse(JSON.stringify(e))
        );
    },
    //商品选中
    // setSections() {
    //   let _this = this;
    //   this.sections.forEach((item) => {
    //     item.goodsNum = 1;
    //     item.goodsId = item.id;
    //     item.goodsName = item.name;
    //     item.remark = item.purchaseRmark;
    //   });
    //   _this.changeGoodsShow = false;
    //   this.addEditForm.warehouseDetailedList = JSON.parse(
    //     JSON.stringify(this.sections)
    //   );
    // },

    // 输入商品数量
    goodsNumEvent(e) {
      let val = e.row.goodsNum;
      this.$set(this.sections[e.$index], "goodsNum", val);
    },
    //多选框禁用
    selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    // 删除
    handleDels(id) {
      let _this = this;
      this.$confirm("是否确认删除此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(
              _this.$axios.defaults.basePath + "/warehouseInOutOrder/delete",
              {
                params: {
                  ids: id,
                },
              }
            )
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    typeChange() {
      this.searchForm.form = '2';
    }
  },
};
</script>

<style scoped>
</style>
