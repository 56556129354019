<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="类型名称">
          <el-input v-model="searchForm.name" placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">字典类型列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i> 新增字典类型</el-button>
            <el-button size="mini" type="danger" :disabled="ids.length==0" @click="del(2,2)" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="type" label="字典类型">
            </el-table-column>
            <el-table-column prop="typeName" label="字典类型名称">
            </el-table-column>
            <!-- <el-table-column prop="bz" label="备注">
            </el-table-column> -->
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" v-if="updateButton == '1'" @click="edit(scope.row.id)">编辑</el-button>
                <!-- <el-button size="mini" type="danger"  @click="del(1,scope.row.id)">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>

    </div>
    <!--新增字典类型-->
    <el-dialog :title="alertTlt" :visible.sync="addShow" width="60%">
      <div class="postForm">
        <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px" class="demo-ruleForm text-center">
          <el-form-item label="字典类型：">
            <el-input v-model.number="formData.type" type="tel" @input="formData.type = String(formData.type).replace(/[^\d]/g,'')" placeholder="请输入字典类型(只能输入数字)" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="类型名称：">
            <el-input v-model="formData.typeName" placeholder="请输入类型名称" style="width: 300px;"></el-input>
          </el-form-item>
          <!-- <el-form-item label="备注：">
            <el-input v-model="formData.bz" placeholder="请输入备注" style="width: 300px;" type="textarea" :rows="4"></el-input>
          </el-form-item> -->
          <el-form-item class="postBtn" style="display: block;text-align: center;">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交</el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitBtnLoad: false,
      formData: {
        type: "",//字典类型,
        typeName: "",//类型名称,
        // bz: "",//备注
      },
      addShow: false,
      loading: true,
      alertTlt: "",
      //搜索
      searchForm: {
        name: ""
      },
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      updateButton: '',
      addButton: '',
      delButton: '',
      addRules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入角色编码', trigger: 'blur' }
        ],
      },
    }
  },
  created() {
    this.listEvent();
    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'sys_dict_type_update') {
        this.updateButton = '1'
      } else if (item.authority == 'sys_dict_type_select') {
        this.searchButton = '1'
      } else if (item.authority == 'sys_dict_type_create') {
        this.addButton = '1'
      } else if (item.authority == 'sys_dict_type_delete') {
        this.delButton = '1'
      }
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    '$route': 'listEvent',
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach(key => { this.formData[key] = '' })
        }
      }
    }
  },
  methods: {
    add() {
      this.addShow = true
      this.alertTlt = "新增字典类型"
    },
    //编辑
    edit(id) {
      let that = this
      this.$axios.get(this.$axios.defaults.basePath + '/sysDictType/info', {
        params: {
          id: id
        }
      }).then(function (res) {
        if (res.data.errcode == 0) {
          that.alertTlt = "编辑字典类型"
          that.addShow = true
          that.formData = res.data.data
        } else {
          that.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      this.$axios.get(_this.$axios.defaults.basePath + '/sysDictType/selectPage', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.tableData = res.data.data.records;
          _this.pagesData.total = res.data.data.total;
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },

    handleSubmit(formData) {
      var _this = this;
      let api = ""
      if (_this.alertTlt == "编辑字典类型") {
        api = '/sysDictType/update'
      } else {
        api = '/sysDictType/add'
      }

      this.$refs[formData].validate(() => {
        _this.submitBtnLoad = true
        this.$axios({
          url: _this.$axios.defaults.basePath + api,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          data: _this.formData
        }).then(function (res) {
          if (res.data.errcode == 0) {
            _this.submitBtnLoad = false
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.addShow = false;
            _this.formData = {};
            _this.listEvent();
          } else {
            _this.submitBtnLoad = false
            _this.$message.error(res.data.errmsg);
          }
        })
      })

    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    del(flag, id) {
      var _this = this;
      let ids = ""
      if (flag == 1) {//单个删除
        ids = id
      } else {//批量删除
        if (_this.ids.length == 0) {
          _this.$message.error('请选择要删除的字典类型');
          return false;
        } else {
          ids = _this.ids.join(",")
        }
      }
      _this.$confirm('是否确认删除该字典类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$axios.get(_this.$axios.defaults.basePath + '/sysDictType/del', {
          params: {
            id: ids
          }
        })
          .then(function (res) {
            if (res.data.errcode == 0) {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.listEvent();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: 'error'
              });
            }
          })
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    }
  }
}
</script>

<style  scoped>
</style>