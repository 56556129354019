<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="用户名：">
          <el-input
            v-model="searchForm.username"
            placeholder="请输入用户名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input
            v-model="searchForm.fullname"
            placeholder="请输入姓名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属仓库：" prop="warehouseId">
          <el-select v-model="searchForm.warehouseId" clearable placeholder="请选择所属仓库" style="width: 300px">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属机构：">
          <el-cascader
            v-model="departmentNameList"
            :options="departmentArr"
            @change="handleChange"
            filterable
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'name',
              children: 'childs',
            }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchForm.status" placeholder="请选择状态">
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">用户列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button
              @click="add"
              type="primary"
              class="el-button--mini"
              v-if="addButton == '1'"
              ><i class="el-icon-plus"></i> 新增用户</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="del(2, 2)"
              v-if="delButton == '1'"
              :disabled="ids.length == 0"
              class="el-icon-delete"
              >批量删除</el-button
            >
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="username" label="用户名"> </el-table-column>
            <el-table-column prop="departmentName" label="所属机构">
            </el-table-column>
            <el-table-column prop="fullname" label="姓名"> </el-table-column>
            <el-table-column prop="role_strings" label="角色">
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.status == 1" style="color: #409eff">
                  已激活
                </p>
                <p v-else-if="scope.row.status == 0" style="color: #f56c6c">
                  已禁用
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="380">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleActivate(scope.row)"
                  v-if="updateButton == '1'"
                  :disabled="scope.row.status == 1"
                  >激活</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleLock(scope.row)"
                  v-if="updateButton == '1'"
                  :disabled="scope.row.status == 0"
                  >禁用</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleRepeat(scope.row)"
                  v-if="updateButton == '1'"
                  >重置密码</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="edit(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="del(1, scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增用户-->
    <el-dialog :title="alertTlt" :visible.sync="addShow" width="60%">
      <div class="postForm" v-if="addShow">
        <el-form
          :model="formData"
          :inline="true"
          :rules="alertTlt == '编辑用户' ? editRules : addRules"
          ref="formData"
          label-width="120px"
          class="demo-ruleForm"
          style="padding-top: 20px"
          autocomplete="off"
        >
          <el-form-item label="用户名：" prop="username">
            <el-input
              v-model="formData.username"
              style="width: 300px"
              placeholder="请输入用户名"
              maxlength="25"
              autocomplete="off"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名：" prop="fullname">
            <el-input
              v-model="formData.fullname"
              style="width: 300px"
              autocomplete="off"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              v-model="formData.password"
              style="width: 300px"
              type="password"
              autocomplete="off"
              name="input"
              placeholder="请输入密码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="重复密码：" prop="confirmPassword">
            <el-input
              v-model="formData.confirmPassword"
              style="width: 300px"
              type="password"
              name="input"
              autocomplete="off"
              placeholder="请输入重复密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属地区：" prop="adcode">
            <area-cascader
              :areaValue="formData.adcode"
              @areaChange="areaEvent"
            ></area-cascader>
          </el-form-item>
          <el-form-item label="所属机构：" prop="departmentName">
            <el-cascader
              v-model="departmentNameList2"
              :options="departmentArr"
              placeholder="请选择所属机构"
              style="width: 300px"
              filterable
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'childs',
              }"
              @change="handleChange2"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所属仓库：" prop="warehouseId">
              <el-select v-model="formData.warehouseId" clearable placeholder="请选择所属仓库" style="width: 300px">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择角色：" prop="role_strings">
            <el-select
              v-model="roles"
              multiple
              placeholder="请选择角色"
              style="width: 300px"
              @change="changeRole"
            >
              <el-option
                v-for="(item, index) in rolesData"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="postBtn"
            style="display: block; text-align: center"
          >
            <el-button
              type="primary"
              :loading="submitBtnLoad"
              @click="handleSubmit('formData')"
              >提交
            </el-button>
            <el-button @click="cancelAdd('addShow')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import areaCascader from "../../components/areaCascader.vue";
export default {
  components: {
    areaCascader,
  },
  data() {
    var reg = /^.{6,18}$/;
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (reg.test(value) == false) {
        callback(new Error("请输入6～18位字符"));
      } else {
        if (this.formData.confirmPassword !== "") {
          this.$refs.formData.validateField("confirmPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入重复密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      submitBtnLoad: false,
      formData: {
        username: "", //用户名,
        // fullname: "",//姓名,
        password: "", //密码,
        confirmPassword: "", //重复密码,
        // departmentName: "",//所属机构,
        role_strings: "", //角色
      },
      roles: [],
      departmentNameList: [],
      departmentNameList2: [],
      //搜索
      searchForm: {
        username: "", //用户名
        // fullname: "",//姓名
        departmentName: "", //所属机构
        status: "", //状态
      },
      status: [
        {
          id: "0",
          text: "已禁用",
        },
        {
          id: "1",
          text: "已激活",
        },
      ],
      addRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: "blur",
          },
        ],
        fullname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        departmentName: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请选择所属区域",
            trigger: "blur",
          },
        ],
        warehouseId:[
          {
            required: true,
            message: "请选择所属仓库",
            trigger: "change",
          }
        ],
        role_strings: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
      },

      editRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        fullname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        departmentName: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请选择所属区域",
            trigger: "blur",
          },
        ],
        warehouseId: [
          {
            required: true,
            message: "请选择所属仓库",
            trigger: "change",
          }
        ],
        role_strings: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
      },

      addShow: false,
      loading: true,
      alertTlt: "",

      tableData: [],
      departmentArr: [], //机构列表
      rolesData: [], //角色列表
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      warehouseList:[],
    };
  },
  created() {
    this.listEvent();
    this.getTree();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "sys_role_update") {
        this.updateButton = "1";
      } else if (item.authority == "sys_role_query") {
        this.searchButton = "1";
      } else if (item.authority == "sys_role_create") {
        this.addButton = "1";
      } else if (item.authority == "sys_role_delete") {
        this.delButton = "1";
      }
    });
    this.getWarehouseList();
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach((key) => {
            this.formData[key] = "";
          });
          this.departmentNameList2 = [];
          this.roles = [];
        } else {
          this.generateData();
        }
      },
    },
  },
  methods: {
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //递归差机构父级name
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    //编辑
    edit(id) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/sysUser/userId", {
          params: {
            userId: id,
          },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            that.alertTlt = "编辑用户";
            that.addShow = true;
            res.data.data.password = "";
            that.formData = res.data.data;
            let arr = that
              .familyTree(that.departmentArr, res.data.data.departmentId)
              .reverse();
            that.departmentNameList2 = arr;
            if (res.data.data.role_strings != null) {
              that.roles = res.data.data.role_strings.split(",");
            }
            that.formData.role_strings = that.roles.join(",");
          } else {
            that.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    changeRole(e) {
      this.formData.role_strings = e.join(",");
    },

    //获取机构数据
    getTree() {
      var _this = this;
      _this
        .$axios({
          url: _this.$axios.defaults.basePath + "/sysOrg/selectOrgTree",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          console.log(res);
          _this.departmentArr = res.data;
        });
    },
    add() {
      this.addShow = true;
      this.alertTlt = "新增用户";
      this.formData = {};
    },
    //禁用
    handleLock(row) {
      var _this = this;
      _this
        .$confirm("是否确认禁用此用户?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(this.$axios.defaults.basePath + "/sysUser/userLock", {
              params: {
                id: row.id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "success",
                });
                _this.listEvent();
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //重置密码
    handleRepeat(row) {
      var that = this;
      that
        .$confirm("是否确定重置此用户密码?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$axios
            .get(this.$axios.defaults.basePath + "/sysUser/initPassword", {
              params: {
                id: row.id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                that.$message({
                  message: res.data.data,
                  type: "success",
                });
                that.listEvent();
              } else {
                that.$message({
                  message: res.data.errmsg,
                  type: "success",
                });
              }
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //激活
    handleActivate(row) {
      var _this = this;
      _this
        .$confirm("是否确认激活此用户?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(this.$axios.defaults.basePath + "/sysUser/userActivate", {
              params: {
                id: row.id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: res.data.data,
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "success",
                });
                _this.listEvent();
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysUser", {
          params: _this.searchForm,
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.loading = false;

            // for (let i in res.data.data.records) {
            // 	let roles = []
            // 	for (let j in res.data.data.records[i].roles) {
            // 		roles.push(res.data.data.records[i].roles[j].name)
            // 	}
            // 	res.data.data.records[i].role_strings = roles.join(',')
            // }
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //搜索部门选择
    handleChange(value) {
      if (value) {
        var end = value.slice(-1);
        this.searchForm.departmentName = end[0];
      }
    },
    //搜索部门选择
    handleChange2(value) {
      if (value) {
        var end = value.slice(-1);
        this.formData.departmentId = end[0];
        var temp = "";
        var forFn = function (arr, id) {
          for (var i = 0; i < arr.length; i++) {
            var item = arr[i];
            if (item.id == id) {
              temp = item.name;
              // forFn(arr1, item.parentId)
              return;
            } else {
              if (item.childs.length != 0) {
                forFn(item.childs, id);
              }
            }
          }
        };
        forFn(this.departmentArr, this.formData.departmentId);
        this.formData.departmentName = temp;
      }
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //获取所有角色列表
    generateData() {
      var _this = this;
      _this
        .$axios(_this.$axios.defaults.basePath + "/sysRole/all")
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.rolesData = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.departmentNameList = [];
      this.roles = [];
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },

    handleSubmit(formData) {
      var _this = this;
      let api = "";
      if (_this.alertTlt == "编辑用户") {
        api = "/sysUser/update";
      } else {
        api = "/sysUser/add";
      }

      this.$refs[formData].validate((val) => {
        if (val) {
          _this.submitBtnLoad = true;
          let data = JSON.parse(JSON.stringify(_this.formData));
          if (data.password) {
            data.password = _this.$md5(data.password);
          }
          if (data.confirmPassword) {
            data.confirmPassword = _this.$md5(data.confirmPassword);
          }
          console.log(data);
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.addShow = false;
              _this.formData = {};
              _this.listEvent();
              let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
              if (
                _this.alertTlt == "编辑用户" &&
                data.id == userInfo.id &&
                data.password
              ) {
                _this.$message({
                  message: "修改成功，请重新登录！",
                  type: "success",
                });
                sessionStorage.clear();
                setTimeout(function () {
                  _this.$router.push({ path: "/login" });
                }, 1000);
              } else {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
              }
            } else {
              _this.submitBtnLoad = false;
              _this.$message.error(res.data.errmsg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    del(flag, id) {
      var _this = this;
      let ids = "";
      if (flag == 1) {
        //单个删除
        ids = id;
      } else {
        //批量删除
        if (_this.ids.length == 0) {
          _this.$message.error("请选择要删除的用户");
          return false;
        } else {
          ids = _this.ids.join(",");
        }
      }
      _this
        .$confirm("是否确认删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/sysUser/delete", {
              params: {
                ids: ids,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 区域选择
    areaEvent(e) {
      console.log(e);
      this.$set(this.formData, "adcode", e);
    },
  },
};
</script>

<style scoped>
</style>
