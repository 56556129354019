<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="商品名称">
          <el-input v-model="searchForm.name" clearable placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.isTopCarriage" clearable placeholder="请选择状态">
            <el-option v-for="(item,index) in goodsStatus" :key="index" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">积分商品列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button size="mini" type="primary" @click="handleAdd()" v-if="addButton == '1'" class="el-icon-plus">
            添加</el-button>
          <el-button size="mini" type="danger" :disabled="ids.length == 0" @click="handleDel" v-if="delButton == '1'"
            class="el-icon-delete">删除</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selectStatus">
            </el-table-column>
            <el-table-column prop="icons" label="商品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image style="width: 60px" :src="scope.row.icons" :preview-src-list="scope.row.imgs.split(',')">
                  </el-image>
                </div>
                <img v-else src="../assets/img/no_img.png" style="width: 60px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="describe" label="商品描述" width="130px" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column prop="color" label="颜色"> </el-table-column> -->
            <!-- <el-table-column prop="categoryName" label="类型">
            </el-table-column> -->
            <el-table-column prop="standard" label="规格"> </el-table-column>
            <!-- <el-table-column prop="model" label="型号"> </el-table-column> -->
            <!-- <el-table-column prop="unit" label="单位"> </el-table-column> -->
            <!-- <el-table-column prop="mfrs" label="制造商"> </el-table-column> -->
            <el-table-column prop="num" label="库存"> </el-table-column>
            <el-table-column prop="price" label="原价"> </el-table-column>
            <el-table-column prop="salePrice" label="销售价"> </el-table-column>
            <el-table-column prop="point" label="所需积分"> </el-table-column>
            <el-table-column prop="isTopCarriage" label="状态" min-width="60">
              <template slot-scope="scope">
                <p v-if="scope.row.isTopCarriage == 0" style="color: red">
                  未上架
                </p>
                <p v-else style="color: #67c23a">已上架</p>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="remark"
              label="备注"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <!-- fixed="right" -->
            <el-table-column align="center" width="300" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleDetails(scope.row)" v-if="searchButton == '1'">详情
                </el-button>
                <el-button size="mini" type="primary" @click="handleEdit(scope.row)" v-if="updateButton == '1'"
                  :disabled="scope.row.isTopCarriage == 1">编辑</el-button>
                <el-button size="mini" type="primary" @click="handleUp(scope.row)" v-if="updateButton == '1'"
                  :disabled="scope.row.isTopCarriage == 1">上架</el-button>
                <el-button size="mini" type="danger" @click="handleDown(scope.row)" v-if="updateButton == 1"
                  :disabled="scope.row.isTopCarriage == 0">下架</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="60%" :close="addEditCancel" :close-on-click-modal="false">
      <el-form :model="addEditForm" ref="addEditForm" :rules="addEditRules" :inline="true" label-width="120px">
        <el-form-item label="选择商品" prop="goodsId">
          <el-select v-model="addEditForm.goodsId" filterable style="width: 300px" :disabled="detailsShow"
            placeholder="可搜索选项" @change="changeGoodsEvents">
            <el-option v-for="(item,index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="商品类型" v-if="addEditForm.goodsId">
          <el-cascader
            ref="typeCascader"
            style="width: 300px"
            :options="goodsType"
            v-model="goodsTypeId2"
            placeholder="请选择商品类型"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }"
            :disabled="true"
            clearable
            @change="cascaderChange2"
          ></el-cascader>
        </el-form-item> -->
        <el-form-item label="商品单位" v-if="addEditForm.goodsId">
          <el-select v-model="addEditForm.unitId" style="width: 300px" @change="unitChange" :disabled="true">
            <el-option v-for="(item,index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色" v-if="addEditForm.goodsId">
          <el-input v-model="addEditForm.color" placeholder="请输入颜色" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="型号" v-if="addEditForm.goodsId">
          <el-input v-model="addEditForm.model" placeholder="请输入型号" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="规格" v-if="addEditForm.goodsId">
          <el-input v-model="addEditForm.standard" placeholder="请输入规格" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="制造商" v-if="addEditForm.goodsId">
          <el-input v-model="addEditForm.mfrs" placeholder="请输入制造商" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="原价" prop="price">
          <el-input v-model="addEditForm.price" placeholder="请输入原价" style="width: 300px" :disabled="detailsShow">
          </el-input>
        </el-form-item>
        <el-form-item label="销售价" prop="salePrice">
          <el-input v-model="addEditForm.salePrice" placeholder="请输入销售价" style="width: 300px" :disabled="detailsShow">
          </el-input>
        </el-form-item>
        <el-form-item label="库存" prop="num">
          <el-input v-model="addEditForm.num" placeholder="请输入库存" style="width: 300px" :disabled="detailsShow">
          </el-input>
        </el-form-item>

        <el-form-item label="所需积分" prop="point">
          <el-input v-model="addEditForm.point" placeholder="请输入所需积分" style="width: 300px" :disabled="detailsShow">
          </el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addEditForm.remark" placeholder="请输入备注" style="width: 300px"
            :disabled="detailsShow"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="addEditForm.describe" placeholder="请输入描述" style="width: 300px" :rows="6"
            :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="产品图片" style="display: block">
          <template>
            <img v-for="(items, i) in imgData" :key="i" :src="items" alt="" width="100px" style="margin-right: 15px" />
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "goodsIndex",
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      upDownButton: "",
      searchButton: "",
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        goodsId: [{ required: true, message: "请选择商品", trigger: "change" }],
        price: [{ required: true, message: "请输入商品原价", trigger: "blur" }],
        salePrice: [
          { required: true, message: "请输入商品销售价", trigger: "blur" },
        ],
        num: [{ required: true, message: "请输入商品库存", trigger: "blur" }],
        point: [{ required: true, message: "请输入所需积分", trigger: "blur" }],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 商品类型
      goodsType: [],
      // 商品单位
      goodsUnit: [],
      // 商品状态
      goodsStatus: [
        {
          label: "未上架",
          id: "0",
        },
        {
          label: "已上架",
          id: "1",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      // 下拉商品
      selectGoods: [],
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    this.getSelectGoods();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "points_mall_update") {
        this.updateButton = "1";
      } else if (item.authority == "points_mall_add") {
        this.addButton = "1";
      } else if (item.authority == "points_mall_delete") {
        this.delButton = "1";
      } else if (item.authority == "points_mall_query") {
        this.searchButton = "1";
      }
      //  else if (item.authority == "points_mall_upDown") {
      //   this.upDownButton = "1";
      // }
    });
  },
  methods: {
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/pointsMall", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            name: _this.searchForm.name,
            isTopCarriage: _this.searchForm.isTopCarriage,
          },
        })
        .then((res) => {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显商品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品下拉列表
    getSelectGoods() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goods/selectGoodsList")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            _this.selectGoods = res.data;
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
      this.imgData = [];
      this.fileList = [];
      this.goodsTypeId2 = [];
      this.detailsShow = false;
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.imgData = [];
      this.$axios
        .get(this.$axios.defaults.basePath + "/pointsMall/details", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = false;
            that.fileList = [];
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
            data.isTopCarriage = data.isTopCarriage.toString();
            that.$nextTick(() => {
              that.addEditForm = data;
              // 连级选择器回显
              // that.goodsTypeId2 = that
              //   .familyTree(that.goodsType, data.categoryId)
              //   .reverse();
              if (data.imgs) {
                let imgArr = data.imgs.split(",");
                that.imgData = imgArr;
                imgArr.forEach((item) => {
                  let json = {
                    url: item,
                  };
                  that.fileList.push(json);
                });
              }
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      this.dialogFormVisible = false;
      this.addEditForm = {};
      this.fileList = [];
      this.imgData = [];
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/pointsMall/add";
          } else {
            url = "/pointsMall/update";
          }
          console.log(_this.addEditForm);

          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.listEvent();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/pointsMall/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      let that = this;
      this.imgData = [];
      this.$axios
        .get(this.$axios.defaults.basePath + "/pointsMall/details", {
          params: { id: row.id },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = true;
            that.fileList = [];
            that.dialogTitle = "详情";
            that.dialogFormVisible = true;
            data.isTopCarriage = data.isTopCarriage.toString();
            that.$nextTick(() => {
              that.addEditForm = data;
              // 连级选择器回显
              that.goodsTypeId2 = that
                .familyTree(that.goodsType, data.categoryId)
                .reverse();
              if (data.imgs) {
                let imgArr = data.imgs.split(",");
                that.imgData = imgArr;
                imgArr.forEach((item) => {
                  let json = {
                    url: item,
                  };
                  that.fileList.push(json);
                });
              }
            });
          }
        });
    },
    // 上架
    handleUp(row) {
      let _this = this;
      _this.dialogTitle = "编辑";
      row.isTopCarriage = "1";
      console.log(row);
      this.$axios({
        url: _this.$axios.defaults.basePath + "/pointsMall/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.dialogFormVisible = false;
          _this.listEvent();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 下架
    handleDown(row) {
      let _this = this;
      _this.dialogTitle = "编辑";
      row.isTopCarriage = "0";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/pointsMall/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.dialogFormVisible = false;
          _this.listEvent();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.imgData.push(file.response);
    },
    // 上传图片删除
    handleRemove(file) {
      let imgArr = [];
      this.imgData.forEach((item) => {
        if (file.url != item) {
          imgArr.push(item);
        }
      });
      this.imgData = imgArr;
      let ind = "";
      this.fileList.forEach((item, index) => {
        if (file.url == item.url) {
          ind = index;
        }
      });
      this.fileList.splice(ind, 1);
    },
    // 下拉选择商品
    changeGoodsEvents(e) {
      if (e) {
        var goods = {};
        this.selectGoods.forEach((item) => {
          if (item.id == e) {
            goods = item;
          }
        });
        console.log(goods);
        // this.addEditForm.categoryId = goods.categoryId;
        // this.$set(this.addEditForm, "categoryId", goods.categoryId);
        this.goodsTypeId2 = this.familyTree(
          this.goodsType,
          goods.categoryId
        ).reverse();
        this.addEditForm.name = goods.name;
        this.addEditForm.categoryId = goods.categoryId
          ? goods.categoryId.toString()
          : "";
        this.addEditForm.describe = goods.describe;
        this.addEditForm.unitId = goods.unitId;
        this.addEditForm.color = goods.color;
        this.addEditForm.model = goods.model;
        this.addEditForm.standard = goods.standard;
        this.addEditForm.mfrs = goods.mfrs;
        this.addEditForm.imgs = goods.imgs;
        this.addEditForm.enabled = goods.enabled.toString();
        this.fileList = [];
        if (goods.imgs) {
          let imgArr = goods.imgs.split(",");
          this.imgData = imgArr;
          imgArr.forEach((item) => {
            let json = {
              url: item,
            };
            this.fileList.push(json);
          });
        }
      }
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.isTopCarriage != 0) {
        //已上架状态不可选中
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
</style>
